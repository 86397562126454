@import "../../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-odeme-islemleri-faturami-ode-main {
  @include withAuthLayout;

  &__header {
    justify-content: space-between;
  }

  &__content {
    margin-top: 0px;

    &--right {
      margin-top: -30px;

      .progressBox {
        height: auto;
        padding-bottom: 30px;
      }
    }
  }
}

