.customer-operations-abonelik-islemleri-dask {
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__body {
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__content {
      &__image {
        margin: 30px 0;

        img {
          width: 100%;
          border-radius: 10px;
        }
      }

      &__letter {
        // margin: 30px 50px 467px 7px;
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: var(--black);
      }
    }
  }
}
