// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.corporate-register {
  .customerSmsCode {
    font-family: "Hind", sans-serif !important;

    @media (max-width: 600px) {
      margin-left: 0;
    }

    .modal__content__body {
      margin-top: 30px;
    }

    &__Prev {
      margin-top: 30px;
      width: fit-content;
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      img {
        width: 24px;
        height: 24px;
      }

      &__prevBtn {
        margin-left: 10px;
        font-weight: 600;
        font-size: 15px;
        margin-top: 2px;
      }

      @media (max-width: 600px) {
        margin-left: 0px;
      }
    }

    &__helper {
      margin-bottom: 30px;

      &__text {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
      }
    }

    &__section {
      &__text {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #486072;
        margin-top: 30px;
      }

      &__input {
        margin-top: 10px;
        display: flex;
        align-items: center;

        @media (max-width: 600px) {
          flex-direction: column;
        }

        &__codeInput {
          margin-right: 30px;

          input {
            &:focus {
              border-color: rgb(204, 204, 204) !important;
              box-shadow: none !important;
            }
          }

          @media (max-width: 600px) {
            margin-right: 0px;
            margin-bottom: 30px;
            width: 100%;

            .customInputBoxes {
              width: 100%;
            }
          }
        }

        &__countdown {
          display: flex;
          align-items: center;

          span {
            margin-left: 10px;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.3px;
            text-align: left;
          }
        }
      }
    }

    &__resend {
      display: flex;
      align-items: center;
      margin-top: 30px;

      @media (max-width: 600px) {
        flex-direction: column;
        margin-top: 30px;
      }

      &__label {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #486072;

        @media (max-width: 600px) {
          margin-bottom: 10px;
        }
      }

      &__resendBtn {
        display: flex;
        align-items: center;
        margin-left: 20px;

        &__label {
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #f3bc45;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &__resendError {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-top: 30px;
      width: 332px;

      &__textWrapper {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
      }

      &__icon {
        margin-right: 12px;
      }

      &__label {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: red;
        margin-bottom: 10px;
      }

      &__resendBtn {
        display: flex;
        align-items: center;
        margin-left: 20px;

        &__label {
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #f3bc45;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .footerBtn {
    margin-bottom: 30px;
  }
}

// .customInputBoxes {
//   input {
//     width: 44px;
//     height: 44px;
//     border-radius: 3px;
//     border: solid 1px #e8ecf2 !important;
//     margin-right: 13.6px;
//     color: #486072;
//     font-family: Hind;
//     font-size: 18px;
//     font-weight: normal;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.33;
//     letter-spacing: normal;
//     text-align: center;
//     &:last-child {
//       margin-right: 0;
//     }
//     @media (max-width: 600px) {
//       margin-right: 0;
//     }
//   }
// }

.customInputBoxes--error {
  input {
    border: solid 1px #ff0000 !important;
    color: red !important;

    &:focus {
      border-color: #ff0000 !important;
    }
  }
}

.disabled-cursor {
  cursor: default !important;
  opacity: 0.65;
}