.customer-operations-odeme-islemleri-fatura-odeme-yontemi {
  @media screen and (max-width: 768px) {
    margin-top: -30px;
  }

  .infoBox img {
    margin-right: 35px !important;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__modal__120 {
    .modal2__content {
      max-height: 650px;
    }
  }

  &__modal {
    .modal2__content {
      max-height: 550px;
    }

    &__maskot {
      width: 50%;
      margin-bottom: 30px;
    }

    &__header {
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #486072;
    }

    &__input {
      width: 100%;
      margin-top: 20px;

      .customInput {
        width: 100%;

        input {
          width: 100%;
          margin-bottom: 0px;
        }

        .input-error {
          margin-bottom: 0px;
        }

        .errorBox {
          margin-left: 0px;
        }
      }
    }

    &__downloads {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 30px;

      &__download {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
      }
    }

    &__description {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin-top: 30px;
      color: #000000;
    }
  }

  &__body {
    padding-inline: 30px;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__creditCard {
      background: #e8ecf2;
      border: 1px solid #486072;
      border-radius: 10px;
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      align-items: center;

      &__left {
        display: flex;
        flex-direction: column;

        &__up {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #486072;
        }

        &__detail {
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #486072;
          margin-top: 10px;
        }
      }

      &__right {
        button {
          margin-top: 0;

          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }
        }
      }
    }

    &__enerjisaPay {
      background: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;

      &__text {
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #486072;
        margin-top: 10px;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__left {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #486072;
        }

        &__right {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.02em;
          color: #f3bc45;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &__fastPay {
      background: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__left {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #486072;
        }

        &__right {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.02em;
          color: #f3bc45;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &__footerText {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      margin-top: 30px;
      display: flex;
      align-items: center;
      padding-left: 20px;

      @media screen and (max-width: 768px) {
        padding-left: 0;
        display: block;
        padding-inline: 22px;
      }

      br {
        display: none;

        @media screen and (max-width: 768px) {
          display: block;
        }
      }

      span {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        display: inline-flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        color: #f3bc45;
        margin-left: 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}