.settings-go-back {
  font-family: Hind;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  color: #000000;
  margin-bottom: 30px;
  &:hover {
    cursor: pointer;
  }
  @media  (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.password-settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 30px;
  margin-bottom: 30px;
  @media  (max-width: 768px) {
    padding-inline: 0px;
  }
  &__button {
    display: flex;
    justify-content: center;
  }
  &__headerText {
    margin-top: 30px;
    font-family: Hind;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: var(--pure-black);
  }
  &__lastchanges {
    margin-top: 30px;
    padding: 20px;
    background-color: #f3f3f3;
    border-radius: 10px;
    &__headerText {
      font-family: Hind;
      font-size: 25px;
      font-weight: 600;
      line-height: 1.2;
      color: var(--turuncu);
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      padding: 10px 15px;
      margin-top: 20px;
      border-radius: 10px;
      &__text {
        display: flex;
        flex-direction: column;
        span {
          &:first-child {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            color: var(--lacivert);
          }
          &:nth-child(2) {
            font-family: Hind;
            font-size: 16px;
            line-height: 1.5;
            color: #444;
          }
        }
      }
      &__date {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: right;
        color: var(--lacivert);
      }
    }
  }
}
