.dilekce-basvuru-step5 {
  padding-inline: 30px;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__card {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    margin-top: 30px;

    &__header {
      margin: 0px 62px 30px 57px;
      font-family: Hind;
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: var(--turuncu);

      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
    }

    &__info {
      font-family: Hind;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: var(--pure-black);

      @media screen and (max-width: 768px) {
        margin: 0;
      }

      &__up {
        font-size: 16px !important;
        margin-bottom: 30px;
        line-height: 24px;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    &__home {
      background-color: none !important;
    }
  }
}
