.ohm-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: red;
    border-radius: 3px;
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    margin-top: 30px;
    white-space: pre-line;
    cursor: pointer;

    @media (max-width: 1024px) {
        width: 100% !important;
    }

    span {
        white-space: pre-line;
    }
}

.ohm-whiteBtn {
    color: #f3bc45;
    background: rgba(255, 255, 255, 0.5);
    border: none;
}

.ohm-primaryBtn {
    color: #fff;
    background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%);
    border: none;
}

.ohm-secondaryBtn {
    color: #f3bc45;
    // box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 #fff;
    border: solid 1px #f3bc45;
    background-color: #fff;
}

.ohm-tertiaryBtn {
    background-color: #f3f3f3;
    border: none;
    color: #486072;
}

.ohm-primaryBtn-disabled {
    opacity: 0.5;
    cursor: default !important;
}

.ohm-secondaryBtn-disabled {
    border: none;
    background-color: #f3f3f3;
    opacity: 0.5;
    cursor: default !important;
}

.btn-clicked {
    justify-content: space-between;
    padding: 0 15px;
}

.ohm-secondaryBtn-borderless {
    border: none;
    background-color: #fff;
}