@import "../../../../assets/styles/layouts/authLayout.scss";

.customer-operations-abonelik-basvuru-sonuc-page {
  @include authLayout;
  &__content {
    .progressBox {
      height: 92px;
    }
    &--left {
      &__lockInfo {
        @media screen and (max-width: 1200px) {
          display: flex;
        }
        align-items: flex-start;
        padding: 12px;
        border: 1px solid #486072;
        margin-top: 30px;
        border-radius: 10px;
        display: none;
        gap: 10px;
        div {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          /* or 160% */

          /* Lacivert */

          color: #486072;
        }
      }
    }
    &--right {
      &__lockInfo {
        display: flex;
        align-items: flex-start;
        padding: 12px;
        border: 1px solid #486072;
        margin-top: 30px;
        border-radius: 10px;
        gap: 10px;
        div {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          /* or 160% */

          /* Lacivert */

          color: #486072;
        }
      }
    }
    &__noValidity {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      &__mobileButton {
        display: none;
        @media screen and (max-width: 768px) {
          display: flex;
          width: 100%;
        }
      }
      &__card {
        margin-top: 100px;
        width: 740px;
        height: 687px;
        background-color: #fbfbfb;
        border-radius: 10px;
        padding: 30px;
        gap: 30px;
        @media screen and (max-width: 768px) {
          margin-top: 0;
          width: 100%;
          height: 100%;
        }
        &__header {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 35px;
          text-align: center;
          letter-spacing: 0.02em;
          color: #486072;
        }
        &__body {
          display: grid;
          grid-template-columns: auto 325px;
          gap: 30px;
          height: 100%;
          @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
          }
          &--left {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            @media screen and (max-width: 768px) {
              width: 100%;
              img {
                width: calc(100% - 30px);
                margin-top: 10px;
              }
            }
          }
          &--right {
            display: flex;
            flex-direction: column;
            &--text {
              margin-top: 30px;
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: #444444;
            }
            &--buttons {
              display: flex;
              flex-direction: column;
              @media screen and (max-width: 768px) {
                button {
                }
              }
            }
            &--button {
              display: flex;
              flex-direction: column;
              @media screen and (max-width: 768px) {
                display: none;
              }
            }
            &__contactsection {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100px;
              background-color: #f3f3f3;
              border-radius: 10px;
              margin-top: 30px;
              margin-left: auto;
              margin-right: auto;
              img {
                width: 24px;
                height: 24px;
                margin-left: 0px;
                margin-right: 30px;
                margin-top: 38px;
                margin-bottom: 38px;
              }
              span {
                font-family: Hind;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                span {
                  font-size: 22px;
                  font-weight: 600;
                  a {
                    color: #444;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
