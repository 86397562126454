.customer-operations-odeme-islemleri-baskasina-ait-fatura-ode {
  @media screen and (max-width: 768px) {
    margin-top: -30px;
  }

  .infoBox img {
    margin-right: 35px !important;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__card {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;

      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #f3bc45;
      }

      &__button {
        button {
          margin-top: 20px;

          @media (min-width: 769px) {
            width: 317px !important;
          }
        }
      }

      &__input {
        margin-top: 20px;

        input {
          width: 317px;
          margin-bottom: 0;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        .customInput .errorBox {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }

      &__insideCard {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        margin-top: 20px;

        &__hr {
          height: 1px;
          width: 100%;
          background-color: #dadada;
          margin: 20px 0;
        }

        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
          }

          &__left {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            color: #f3bc45;
            display: flex;
            align-items: center;

            @media screen and (max-width: 768px) {
              justify-content: space-between;
              width: 100%;
            }

            &__text {
              max-width: 292px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;

              @media screen and (max-width: 768px) {
                max-width: 200px;
              }

              @media screen and (max-width: 400px) {
                max-width: 170px;
              }
            }

            span {
              font-size: 18px;
              font-weight: 400;

              &:hover {
                cursor: pointer;
              }
            }

            input {
              outline: none;
              border: none;
              opacity: 1;
              padding-right: 15px;

              @media screen and (max-width: 768px) {
                width: 100%;
              }
            }

            img {
              &:hover {
                cursor: pointer;
              }

              margin-left: 10px;
            }
          }

          &__right {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            color: #f3bc45;
            opacity: 0.5;
          }
        }

        &__content {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-top: 20px;

          @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
          }

          &__left {
            div {
              &:first-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
              }

              &:last-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #444444;
                margin-top: 10px;
                display: flex;
                align-items: center;

                i {
                  margin-right: 10px;
                  color: #adb3bc;

                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }

          &__right {
            @media screen and (max-width: 768px) {
              margin-top: 20px;
            }

            div {
              &:first-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
              }

              &:last-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #444444;
                margin-top: 10px;
                max-width: 297px;
                word-wrap: break-word;

                @media screen and (max-width:768px) {
                  max-width: 200px;
                }
              }
            }
          }
        }

        &__footer {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;

          @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
          }

          &__debt {
            div {
              &:first-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
              }

              &:last-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
                margin-top: 10px;
              }
            }
          }

          button {
            margin-top: 0;

            @media screen and (max-width: 768px) {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

  &__modal {
    &__header {
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #486072;
      margin-bottom: 30px;
    }

    &__description {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }
  }
}