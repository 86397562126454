@import "../../../../../assets/styles/layouts/withAuthLayout.scss";

.account-information-tarife-degistirme-detay {
  @include withAuthLayout;

  &__content {
    &--right {
      margin-top: -30px;
    }
  }

  .spinnerContainer {
    display: flex;
    justify-content: center;
    height: 50px;
    margin-top: 100px;
  }
}