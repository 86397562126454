.x-randevu-guncelleme-step1 {
  padding-inline: 30px;

  @media screen and (max-width: 800px) {
    padding-inline: 0px;
  }

  .ant-select-selection-item {
    margin-top: 8px;
  }

  .customSelect {
    @media (max-width: 376px) {
      width: 286px !important;
    }
  }

  .customInput {
    input {
      @media (max-width: 376px) {
        width: 286px !important;
      }
    }
  }

  &__card {
    margin-top: 30px;
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;

    &__header {
      font-family: Hind;
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: var(--turuncu);

      @media screen and (max-width: 800px) {
        margin-bottom: 20px !important  ;
      }
    }

    &__details {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      margin-top: 20px;

      &__header {
        font-family: Hind;
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #444;
        margin-bottom: 10px;
      }

      &__description {
        font-family: Hind;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #444;
        margin-bottom: 10px;
      }

      &__hr {
        height: 1px;
        background-color: #dadada;
        margin-bottom: 10px;
      }

      &__address {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #444;
      }
    }

    &__selectArea {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      &__input {
        margin-top: 20px;

        @media screen and (max-width: 800px) {
          width: 100% !important;
          margin-bottom: 20px !important;
          margin-top: 0px;
        }

        input {
          width: 317px;
          margin: 0;
          margin-top: 10px;
          border: 1px solid #f3bc45;
          border-radius: 3px;

          @media screen and (max-width: 800px) {
            width: 100% !important;
            margin-bottom: 0;
          }
        }

        label {
          margin-bottom: 0px;
        }

        @media screen and (max-width: 800px) {
          width: 100% !important;
        }
      }

      .ant-select {
        background-color: #fff;
        border-radius: 3px;
      }

      @media screen and (max-width: 800px) {
        flex-direction: column;
      }

      &__select {
        display: flex;
        flex-direction: column;
        width: 317px;
        position: relative;

        @media screen and (max-width: 800px) {
          width: 100% !important;
          margin-bottom: 10px;
        }
        .p-date-time-picker {
          background-color: #fff !important;
          width: 317px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
        label {
          margin-top: 20px;
          margin-bottom: 10px;
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);

          @media screen and (max-width: 800px) {
            margin-top: 0px;
          }
        }

        .ant-select-selector {
          height: 44px !important;
        }
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
  }
}
