@import "../../../assets/styles/layouts/authLayout.scss";
.d2d-onay-ek-ekran {
  @include authLayout;
  .btttn {
    button {
      width: 305px !important;
      margin: auto;
      margin-top: 30px;
    }
    @media (max-width: 768px) {
      margin: auto;
    }
  }
  .forgotPassword-inputCont--smsCodeCont--countDown {
    margin-left: 0;
    margin-top: 20px;
  }
  .loginCode-page-inputCont__resend {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .smsErrorBox {
    margin-top: 20px;
    margin-bottom: 0;
  }
  .smsCodeINput {
    input {
      &:focus {
        border-color: #e8ecf2 !important;
        box-shadow: none !important;
      }
    }
  }
  &__whiteBody {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 136px;
    @media screen and (max-width: 768px) {
      padding-inline: 0px;
      margin-top: 0px;
    }
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        margin-top: 50px;
      }
      &__left {
        @media screen and (max-width: 768px) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        &__desktopMaskot {
          margin-top: -100px;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
        &__mobilMaskot {
          display: none;
          @media screen and (max-width: 768px) {
            display: block;
            width: 95%;
            margin-top: -60px;
          }
        }
      }
      &__right {
        display: flex;
        flex-direction: column;
        width: 352px;
        &__button {
          width: 100%;
          @media screen and (max-width: 768px) {
            // padding-inline: 30px;
          }
        }
        &__sms {
          display: flex;
          flex-direction: column;
          margin-top: 30px;

          &__input {
            margin-top: 30px;
            input {
              width: 37.3px !important;
              @media screen and (max-width: 768px) {
                width: 100%;
                margin-bottom: 0;
                .loginCode-page-inputCont--smsCodeCont--countDown {
                  margin-top: 10px !important;
                }
              }
            }
            .loginCode-page-inputCont--smsCodeCont--countDown {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          &__header {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 35px;
            letter-spacing: 0.02em;
            color: #486072;
            @media screen and (max-width: 768px) {
              text-align: center;
            }
          }
          &__description {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
            margin-top: 30px;
            @media screen and (max-width: 768px) {
              text-align: center;
            }
          }
        }
        &__header {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 35px;
          letter-spacing: 0.02em;
          color: #486072;
          width: 292px;
          @media screen and (max-width: 768px) {
            width: 100%;
            text-align: center;
            margin-top: -30px;
          }
        }
        &__description {
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-top: 30px;
          width: 292px;
          @media screen and (max-width: 768px) {
            margin: 0 auto;
            margin-top: 30px;
            text-align: center;
          }
        }
        &__item {
          // button {
          //   height: max-content;
          // }
          .customCheckBox {
            margin-top: 10px;
            margin-bottom: 0;
            @media screen and (max-width: 768px) {
              align-items: center;
            }
          }
          .spanFirst {
            max-width: 263px;
          }
          .customCheckBox .spanFirst {
            color: #000;
            font-weight: 500;
          }
          button {
            span {
              word-break: break-word;
            }
          }
        }
        &__recaptcha {
          @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &__recaptchaclass {
            margin-top: 30px;
          }
        }
        &__showMore {
          display: flex;
          flex-direction: column;
          &__description {
            margin-top: 30px;
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
            width: 292px;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
    &__noPageValidity {
      background-color: #fbfbfb;
      padding: 30px;
      width: 740px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      &__body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
          gap: 0px;
        }
        &__left {
          @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          &__desktopmaskot {
            display: block;
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
          &__mobilmaskot {
            display: none;
            @media screen and (max-width: 768px) {
              display: flex;
              margin-top: 30px;
            }
          }
          &__mobilHeader {
            display: none;
            @media screen and (max-width: 768px) {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 28px;
              line-height: 35px;
              text-align: center;
              letter-spacing: 0.02em;
              color: #486072;
            }
          }
        }
        &__right {
          display: flex;
          flex-direction: column;
          width: 292px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          a {
            color: #444;
          }
          &__header {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 35px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #486072;
            @media screen and (max-width: 768px) {
              display: none !important;
            }
          }
          &__description {
            margin-top: 30px;
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #444444;
          }
        }
      }
    }
  }
}

.btnM {
  button {
    height: max-content;
  }
}

.mtopCont {
  margin-top: 80px;
  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.mtopT {
  @media (min-width: 768px) {
    margin-top: 80px;
  }
}
.c4c-checkbox {
  .spanFirst {
    font-weight: 400 !important;
    span {
      font-weight: 500 !important;
    }
  }
}