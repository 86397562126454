.elektrik-kesintileri-kesinti-bildir-step2 {
  padding-inline: 30px;
  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-top: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
    &__inputArea {
      display: flex;
      align-items: flex-start;
      width: 100%;
      gap: 40px;
      &:not(&:first-child) {
        margin-top: 30px;
        @media screen and (max-width: 768px) {
          margin-top: 0;
        }
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 0px;
      }
      &__input {
        .customInput .errorBox {
          margin-top: 10px;
          margin-bottom: -10px;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
          margin-top: 30px;
        }
        input {
          width: 332px;
          margin-bottom: 0;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
    &__afterInputArea {
      margin-top: 30px;
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      color: #000000;
      b {
        font-weight: 600;
      }
    }
    &__pdfViewer {
      width: 100% !important;
      margin-top: 30px;

      .rpv-core__viewer {
        width: 100% !important;
        height: 432px !important;
      }

      .rpv-core__inner-page {
        height: unset !important;
      }

      .rpv-core__viewer .rpv-core__canvas-layer {
        width: 100% !important;
        height: 100% !important;
      }
    }

    &__footerCheckbox {
      .customCheckBox .spanFirst {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    &__footerBtn {
      align-items: center;
      margin-bottom: 30px;
      display: flex;
      justify-content: flex-start;
      width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      button {
        margin-top: 0px;
      }

      .obligation {
        display: none;
        font-family: "Hind";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-left: 30px;

        @media screen and (max-width: 768px) {
          display: block;
          margin-left: 0px;
          margin-bottom: 30px;
        }
      }

      .active {
        display: block;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}
