@import "../../../assets/styles/layouts/withAuthLayout.scss";

.appointment-homepage {
  @include withAuthLayout;

  &__goback {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;

    &:hover {
      cursor: pointer;
    }
    @media(max-width: 1181px) {
      margin-top: 30px;
    }
  }

  &__content {
    &--left {
      margin-top: -30px;
    }
  }

  .infoBox {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .appointment-homepage {
    &__goback {
      margin-bottom: 20px;
    }

    &__content {
      margin-top: 0;
    }

    .infoBox {
      margin-top: 0;
    }
  }
}
