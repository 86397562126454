.account-information-tarife-degistirme-detay-step4 {
  padding-inline: 30px;
  margin-top: 30px;
  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }
  &__header {
    font-family: Hind;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: var(--turuncu);
  }
  &__description {
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--pure-black);
    margin-top: 30px;
  }
  &__cards {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    position: relative;
    &__maskot {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateX(-20%) translateY(2%);
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }
    &__card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f3f3f3;
      padding: 20px;
      width: 332px;
      margin-top: 30px;
      @media screen and (max-width: 768px) {
        width: 100% !important;
      }
      div {
        &:first-child {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
        }
        &:last-child {
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          display: flex;
          align-items: center;
          i {
            margin-left: 10px;
            color: #486072;
            font-size: 20px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
