@keyframes blur {
    0% {
        -webkit-filter: blur(0px);
    }
    100% {
        -webkit-filter: blur(5px);
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.blur {
    filter: blur(5px);
    -webkit-animation: blur 1.5s ease;
}
.progressBoxLockIcon {
    filter: blur(0px);
    position: absolute;
    top: 37%;
    left: 45%;
    z-index: 9999;
    -webkit-animation: fadeIn 1.5s ease;
}

.progressBox {
    width: 362px;
    height: 270px;
    margin: 30px 0;
    border-radius: 10px;
    background-color: #e8ecf2;
    padding-top: 30px;

    &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #f3bc45;
        margin-left: 20px;
        margin-top: -10px;
      }
      
    &__step {
        display: flex;
        margin-top: 30px;
        margin-left: 20px;
        position: relative;
        &:first-child {
            margin-top: 0;
        }
        &--content {
            display: flex;
            span {
                &:first-child {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 20px;
                    background-color: #f3f3f3;
                    font-size: 15px;
                    font-weight: 600;
                    color: #fff;
                    margin-right: 10px;
                    z-index: 100;
                }
                &:last-child {
                    padding-top: 2px;
                    font-family: Hind;
                    font-size: 18px;
                    color: #444;
                    line-height: 1.67;
                }
            }
        }
        &--verticalLine {
            width: 2px;
            height: 60px;
            background-color: #f3f3f3;
            position: absolute;
            left: 14px;
            top: 7px;
        }
    }
}

.active-step {
    span {
        &:first-child {
            background-color: #f3bc45 !important;
            color: #fff !important;
            font-weight: 600;
        }
        &:last-child {
            color: #444;
        }
    }
}
.active-text {
    font-weight: 600 !important;
}

.active-line {
    background-color: #f3bc45 !important;
}

.skeleton-tabIndex {
}

.skeleton-text {
    margin-top: 5px;
    width: 176px;
    height: 18px;
    border-radius: 3px;
    background-image: linear-gradient(
        to right,
        #f3f3f3 0%,
        rgba(243, 243, 243, 0) 24%,
        rgba(243, 243, 243, 0.74) 62%,
        rgba(243, 243, 243, 0.19) 100%
    );
}

.progressBoxMobile {
    .ant-collapse-header-text {
        width: 100% !important;
    }
    margin-top: 30px !important;
    width: 100% !important;
    outline: none !important;
    border: none !important;
    border-radius: 10px !important;
    background-color: #e8ecf2 !important;
    .skeleton-text {
        margin-top: 0px !important;
        width: 176px !important;
        height: 18px !important;
        border-radius: 3px !important;
        background-image: linear-gradient(
            to right,
            #f3f3f3 0%,
            rgba(243, 243, 243, 0) 24%,
            rgba(243, 243, 243, 0.74) 62%,
            rgba(243, 243, 243, 0.19) 100%
        ) !important;
    }
    .ant-collapse-header {
        height: 50px !important;
        padding: 0 !important;
    }
    .ant-collapse-arrow {
        display: none !important;
    }
    .ant-collapse-item {
        border: none !important;
    }
    .ant-collapse-content {
        background-color: #e8ecf2 !important;
        border: none !important;
        width: 100% !important;
        &:last-child {
            border-bottom-left-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
        }
    }
    .ant-collapse-content-box {
        padding: 0 !important;
    }
    &__headerWrapper {
        display: flex !important;
        justify-content: space-between !important;
        width: 100% !important;
        img {
            margin-left: auto !important;
            margin-right: 20px !important;
        }
        &__step {
            display: flex !important;
            margin-left: 20px !important;
            position: relative !important;
            width: 100% !important;
            display: flex !important;
            align-items: center !important;
            &:first-child {
                margin-top: 0 !important;
            }
            &--content {
                height: 50px;
                display: flex;
                align-items: center;
                span {
                    &:first-child {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        height: 30px;
                        border-radius: 20px;
                        background-color: #f3f3f3;
                        font-size: 15px;
                        font-weight: 600;
                        color: #fff !important;
                        margin-right: 10px;
                        z-index: 100;
                    }
                    &:last-child {
                        padding-top: 2px;
                        font-family: Hind;
                        font-size: 18px;
                        color: #444;
                        line-height: 1.67;
                    }
                }
            }
            &--verticalLine {
                width: 2px;
                height: 40px;
                background-color: #f3f3f3;
                position: absolute;
                left: 14px;
                top: 10px;
            }
            &--verticalLine-minus {
                width: 2px;
                height: 40px;
                background-color: #ffffff;
                position: absolute;
                left: 14px;
                top: 0px;
            }
        }
    }
    &__step {
        display: flex;
        margin-left: 32px;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        &:last-child {
            border-bottom-left-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
        }
        &:first-child {
            margin-top: 0;
        }
        &--content {
            height: 50px;
            display: flex;
            align-items: center;
            span {
                &:first-child {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 20px;
                    background-color: #ffffff;
                    font-size: 15px;
                    font-weight: 600;
                    color: #f3bc45;
                    margin-right: 10px;
                    z-index: 100;
                }
                &:last-child {
                    padding-top: 2px;
                    font-family: Hind;
                    font-size: 18px;
                    color: #444;
                    line-height: 1.67;
                }
            }
        }
        &--verticalLine {
            width: 2px;
            height: 40px;
            background-color: #ffffff;
            position: absolute;
            left: 14px;
            top: 10px;
        }
        &--verticalLine-minus {
            width: 2px;
            height: 40px;
            background-color: #ffffff;
            position: absolute;
            left: 14px;
            top: -10px;
        }
    }
}
.openChevronDown {
    transform: rotate(180deg);
    transition: 0.5s;
}
