.customer-operations-after-login-elektrik-kesintileri-kesinti-bildir-step2 {
  padding-inline: 30px;
  margin-top: 30px;
  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }
  &__header {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #f3bc45;
  }
  &__description {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 30px;
  }
  &__card {
    background-color: #f3f3f3;
    padding: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 332px;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 10px;
    &__item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      div {
        &:first-child {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #486072;
        }
        &:last-child {
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: right;
          color: #486072;
          max-width: 161px;
          i {
            margin-left: 10px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
