@import "../../../../assets/styles/layouts/authLayout.scss";

.customer-operations-serbest-tuketici-basvurular {
  @include authLayout;

  .btmbtn {
    margin: auto;
    @media(max-width: 1025px) {
      width: 100%;
    }
  }

  .hr {
    border: 0.5px solid #f3f3f3 !important;
  }

  .content-p {
    max-width: 300px;
    justify-content: space-between;
    text-align: end;
  }

  .customer-operations-abonelik-iptal__noContract {
    background-image: none;
    background-color: rgba(251, 251, 251, 1);
    height: 312.2px;
    width: 100%;
    img {
      width: 282px;
      height: 224px;
      margin: auto;
      margin-right: 60px;
    }
    @media(max-width: 600px) {
      flex-direction: column !important;
      margin: 0;
      height: auto !important;
      img {
        margin: auto !important;
      }
      padding: 20px;
    }
  }
  .customer-operations-abonelik-iptal__noContract__header {
    flex-grow: 0;
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal !important;
    text-align: center;
    color: black !important;
    margin-right: 70px;
    margin-top: 90px;
    max-width: 250px;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    @media(max-width: 600px) {
      margin: auto !important;
      margin-top: 20px !important;
    }
  }

  .content-p-wrapper {
    align-items: flex-start;
  }

  .infoComponent {
    &__info {
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;
      @media screen and (max-width: 1180px) {
        flex-direction: column;
      }

      &__detail {
        display: flex;
        align-items: flex-start;
        &__icon {
          width: 24px;
          padding: 12px;
          height: 24px;
          margin-right: 14px;
          &__img {
            width: 20px;
            height: 20px;
          }
        }
      }

      button {
        margin: 0;

        @media screen and (max-width: 768px) {
          display: block;
          margin-top: 0px;
          width: 100% !important;
        }
      }
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    // margin-top: -30px;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__body {
    padding-inline: 30px;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__items {
      background-color: #f3f3f3;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin-top: 30px;

      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #f3bc45;
        margin-bottom: 20px;
      }

      &__content {
        background: #ffffff;
        border-radius: 10px;
        padding: 12px;
        display: flex;
        flex-direction: column;

        hr {
          margin: 10px 0;
          border: 0.5px solid #f3f3f3;
        }

        &__list {
          display: flex;
          flex-direction: row; 
          justify-content: space-between;
          align-items: center;

          .cap {
            width: 120px;
            text-align: end;
            font-family: "Hind" !important;
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 15px !important;
            line-height: 24px !important;
            color: #f3bc45 !important;
          }

          div {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: #486072;

            &:last-child {
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #000000;
            }
          }
        }
      }

      &__content2 {
        background: #ffffff;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;

        hr {
          margin: 10px 0;
          border: 1px solid #f3f3f3;
        }

        &__list {
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          div {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: #486072;

            &:last-child {
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #000000;
            }
          }
        }

        &__footer {
          display: flex;
          justify-content: space-between;
          gap: 150px;

          @media screen and (max-width: 1180px) {
            gap: 50px;
          }

          @media screen and (max-width: 768px) {
            gap: 0px;
          }

          @media screen and (max-width: 600px) {
            flex-direction: column;
          }

          &__list {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            @media screen and (max-width: 600px) {
              &:last-child {
                margin-top: 20px !important;
              }
            }

            div {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              color: #486072;

              &:last-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
              }
            }
          }
        }
      }
    }
  }
}
