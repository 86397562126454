.customer-operations-odeme-islemleri-fatura-odeme-detay {
  // @media screen and (max-width: 768px) {
  //   margin-top: -30px;
  // }

  .infoBox img {
    margin-right: 35px !important;
  }

  &__modal {
    &__description {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }

    &__button {
      width: 100%;
    }
  }

  @mixin upper {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #486072;
  }

  @mixin down {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #486072;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    .infoComponent {
      margin-top: 20px;
      margin-bottom: 0;
    }

    &__haveBill {
      &__card {
        background-color: #f3f3f3;
        padding: 20px;
        border-radius: 10px;
        margin-top: 30px;

        &__hr {
          background-color: #dadada;
          width: 100%;
          height: 1px;
          margin: 20px 0px;
        }

        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: unset;
          }

          &__left {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            color: #f3bc45;
          }

          &__right {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            color: #f3bc45;
            opacity: 0.5;

            @media screen and (max-width: 768px) {
              margin-top: 20px;
            }
          }
        }

        &__content {
          margin-top: 20px;

          &__item {
            display: grid;

            &:not(:first-child) {
              margin-top: 20px;
            }

            grid-template-columns: 1fr 1fr;

            @media screen and (max-width: 768px) {
              grid-template-columns: 1fr !important;
            }

            &__left {
              div {
                &:first-child {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  color: #444444;
                }

                &:last-child {
                  display: flex;
                  align-items: center;
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 300;
                  font-size: 16px;
                  line-height: 24px;
                  color: #444444;
                  margin-top: 10px;

                  i {
                    margin-right: 10px;
                    color: #adb3bc;

                    &:hover {
                      cursor: pointer;
                    }
                  }
                }
              }
            }

            &__right {
              @media screen and (max-width: 768px) {
                margin-top: 20px;
              }

              div {
                &:first-child {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  color: #444444;
                }

                &:last-child {
                  // display: flex;
                  // align-items: center;
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 300;
                  font-size: 16px;
                  line-height: 24px;
                  color: #444444;
                  margin-top: 10px;
                  max-width: 300px;
                  word-wrap: break-word;

                  i {
                    margin-right: 10px;
                    color: #adb3bc;

                    &:hover {
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }

        &__footer {
          display: flex;
          justify-content: space-between;
          height: 50px;

          @media screen and (max-width: 768px) {
            flex-direction: column;
            height: fit-content;
          }

          &__left {
            div {
              &:first-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
              }

              &:last-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
                margin-top: 10px;
              }
            }
          }

          &__right {
            button {
              margin-top: 0;

              @media screen and (max-width: 768px) {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }

    &__card {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;

      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #f3bc45;
      }

      &__tableheader {
        display: grid;
        grid-template-columns: repeat(2, 153px);
        gap: 10px;
        margin-top: 20px;

        @media screen and (max-width: 768px) {
          grid-template-columns: 80px 120px;
          gap: 10px;
        }

        div {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #486072;
        }
      }

      &__items {
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;

        &__item {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;

          @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
          }

          &:not(:first-child) {
            margin-top: 20px;
          }

          &__left {
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 768px) {
              flex-direction: row;
              justify-content: space-between;
            }

            &__blue {
              padding: 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              background-color: #e8ecf2;
              border-radius: 10px;

              &__left {
                @include upper;
              }

              &__right {
                @include down;
              }
            }

            &__upper {
              @include upper;
            }

            &__down {
              @include down;
              margin-top: 10px;

              @media screen and (max-width: 768px) {
                margin-top: 0;
              }
            }
          }

          &__right {
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 768px) {
              flex-direction: row;
              justify-content: space-between;
            }

            &__upper {
              @include upper;
            }

            &__upper2 {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: right;
              color: #f3bc45;

              &:hover {
                cursor: pointer;
              }
            }

            &__down {
              @include down;
              margin-top: 10px;

              @media screen and (max-width: 768px) {
                margin-top: 0 !important;
              }

              &__true {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: right;
                color: #f3bc45;

                &:hover {
                  cursor: pointer;
                }
              }

              &__false {
                padding: 5px 8px;
                background: #f3bc45;
                border-radius: 10px;
                gap: 10px;
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: right;
                color: #ffffff;
              }
            }
          }

          &__right2 {
            display: flex;
            align-items: center;
            @media screen and (max-width: 768px) {
              justify-content: flex-end;
            }

            &--done {
              @media screen and (max-width: 768px) {
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
              }

              @media screen and (max-width: 330px) {
                flex-direction: column;
              }
            }

            &__upper {
              @include upper;
            }

            &__upper2 {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: right;
              color: #f3bc45;

              &:hover {
                cursor: pointer;
              }

              &__icon {
                @media screen and (max-width: 768px) {
                  display: none;
                }
              }
            }

            &__down {
              @include down;
              margin-top: 0px;

              @media screen and (max-width: 768px) {
                margin-top: 0px !important;
              }

              &__true {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: right;
                color: #f3bc45;

                &:hover {
                  cursor: pointer;
                }

                &__icon {
                  margin-left: 0 !important;
                }
              }

              &__false {
                padding: 5px 8px;
                background: #f3bc45;
                border-radius: 10px;
                gap: 10px;
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: right;
                color: #ffffff;

                @media screen and (max-width: 768px) {
                  margin-top: 8px;
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }

                @media screen and (max-width: 400px) {
                  font-size: 13px;

                }
              }
            }
          }
        }
      }
    }
  }
}