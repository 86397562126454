.dilekce-basvuru-map-view {
  position: relative;
  margin-top: 30px 0;

  // map + - button hide
  .leaflet-touch .leaflet-control-layers,
  .leaflet-touch .leaflet-bar {
    display: none !important;
  }

  .leaflet-bottom .leaflet-right {
    display: none !important;

    .leaflet-control-attribution .leaflet-control {
      display: none !important;
    }
  }

  .leaflet-container {
    border-radius: 10px !important;
    // @media screen and (max-width: 768px) {
    //   height: 410px !important;
    // }
  }

  .leaflet-control-attribution {
    display: none;
  }

  .swiper-slide {
    // width: 332px !important;
    margin: 0;
    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }

  .swiper-container {
    padding: 10px 20px;
  }
  .swiper-slide-prev {
    margin-left: 156.5px !important;
    // margin-left: 200px !important;
    @media screen and (max-width: 768px) {
      margin-left: 0px !important;
    }
  }
  .swiper-slide-active {
    margin: 0 17px !important;
    @media screen and (max-width: 768px) {
      margin: 0 !important;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__search {
    position: absolute;
    z-index: 9999;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    @media screen and (max-width: 768px) {
      left: 10px;
      width: calc(100% - 20px);
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__slider {
    display: flex;
    position: absolute;
    bottom: 80px;
    z-index: 500;
    width: 100% !important;
    @media screen and (max-width: 768px) {
      bottom: 90px;
    }
    &__prevbg {
      position: absolute;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 5%,
        rgba(255, 255, 255, 0.8) 15%,
        rgba(255, 255, 255, 0) 50%
      );
      height: 249px;
      width: 10%;
      top: 0;
      z-index: 99;
      left: 0;
    }

    &__nextbg {
      position: absolute;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 15%,
        rgba(255, 255, 255, 0.8) 70%,
        rgba(255, 255, 255, 1) 100%
      );
      height: 249px !important;
      width: 10%;
      top: 0;
      z-index: 99;
      right: 0;
    }

    &__prevBtn {
      position: absolute;
      left: 10px;
      top: 50%;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      transform: translateY(-100%);
      z-index: 500;
      @media screen and (max-width: 768px) {
        transform: translateY(-50%);
      }
      img {
        z-index: 501;
      }

      &:hover {
        cursor: pointer;
      }

      &__rectangle__left {
        position: absolute;
        width: 67px;
        height: 251px;
        z-index: 1;
        background: linear-gradient(
          90deg,
          #f3f3f3 0%,
          rgba(243, 243, 243, 0) 100%
        );
      }
    }

    &__nextBtn {
      position: absolute;
      right: 10px;
      top: 50%;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(-100%);
      z-index: 500;
      background-color: #fff;
      border-radius: 50%;
      @media screen and (max-width: 768px) {
        transform: translateY(-50%);
      }

      img {
        z-index: 501;
      }

      &:hover {
        cursor: pointer;
      }

      &__rectangle__right {
        position: absolute;
        width: 67px;
        height: 251px;
        z-index: 1;
        background: linear-gradient(
          270deg,
          #f3f3f3 0%,
          rgba(243, 243, 243, 0) 100%
        );
      }
    }

    &__card {
      min-height: 251px;
      left: 90px;
      display: flex;
      flex-direction: column;
      padding: 20px;
      background-color: #f3f3f3;
      margin-bottom: 20px;
      border-radius: 10px;
      width: 332px;
      z-index: 499;
      // min-height: 167px;

      @media screen and (max-width: 768px) {
        width: calc(100% - 20px) !important;
        margin: 0 auto;
      }

      &__headerText {
        font-family: Hind;
        font-size: 17px;
        font-weight: 600;
        text-align: left;
        color: #444;
      }

      &__descText {
        font-family: Hind;
        font-size: 15px;
        line-height: 1.6;
        text-align: left;
        color: #444;
        margin-top: 10px;
      }

      &__content {
        font-size: 14px;
        margin-bottom: 10px;
      }

      &__hr {
        border: 1px solid #e6e6e6;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &__address {
        font-family: Hind;
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
        color: #444;
      }
    }
  }
}


.hizmet-noktasi__map {
  margin-bottom: 30px;
  position: relative;
  border-radius: 10px;
  background-color: #f3f3f3;

  .leaflet-popup-tip-container {
    bottom: -20px;
  }
  .leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
    bottom: 0px !important;
    @media screen and (max-width: 768px) {
      width: max-content;
      left: -150px;
    }
  }
  .leaflet-popup-content {
    margin: 0 !important;
    padding: 12px;
    width: unset !important;
  }
  .leaflet-container a.leaflet-popup-close-button {
    display: none !important;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 3px;
    box-shadow: 0 0 20px 0 rgba(163, 175, 199, 0.2);
    @media screen and (max-width: 768px) {
      width: calc(100% - 10px) !important;
    }
  }
  // .leaflet-popup {
  //   width: 315px;
  //   height: 200px;
  // }
  &__popup {
    display: flex;
    flex-direction: column;
    position: relative;
    &__icon {
      position: absolute;
      right: 12px;
      top: 12px;
      @media screen and (max-width: 768px) {
        right: 16px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &__header {
      font-family: Hind;
      font-size: 17px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.41;
      letter-spacing: normal;
      text-align: left;
      color: var(--black);
    }
    &__description {
      font-family: Hind;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: left;
      color: var(--black);
      margin: 10px 0;
    }
    &__address {
      font-family: Hind;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: left;
      color: var(--black);
      margin-top: 10px;
    }
    hr {
      background-color: #dadada;
    }
    &__button {
      button {
        margin-top: 10px;
      }
    }
  }

  &__card {
    position: absolute;
    right: 2%;
    top: 5%;
    z-index: 500;
    border-radius: 3px;
    padding: 20px;
    width: 348px;
    background: #fff;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      width: 80% !important;
      right: 5%;
      font-size: 19px !important;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 44px !important;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      margin-top: 8px;
    }

    &__text {
      font-family: Hind;
      font-size: 25px;
      font-weight: 600;
      line-height: 1.2;
      text-align: left;
      color: #486072;
      margin-bottom: 40px;
      @media screen and (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    &__selectArea {
      position: relative;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }

      &__select {
        width: 100% !important;
      }

      &__icon {
        position: absolute;
        right: 15px;
        top: 20%;
      }
    }
  }

  .leaflet-control-attribution {
    display: none;
  }
}

.daha-fazla-hizmet-noktalari {
  margin: 30px 0;

  .leaflet-bottom .leaflet-right {
    display: none !important;

    .leaflet-control-attribution .leaflet-control {
      display: none !important;
    }
  }

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-container {
    border-radius: 10px !important;
  }

  .swiper-slide {
    // width: 332px !important;
    margin: 0;
    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    display: none !important;
  }

  .swiper-container {
    padding: 10px 20px;
  }

  .swiper-slide-prev {
    margin-left: 166.5px !important;
    @media screen and (max-width: 768px) {
      margin-left: 0px !important;
    }
  }

  .swiper-slide-active {
    margin: 0 17px !important;
    @media screen and (max-width: 768px) {
      margin: 0 !important;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 250px;
  }

  &__slider {
    display: flex;
    position: absolute;
    bottom: 0;
    z-index: 500;
    width: 100% !important;
    @media(max-width: 768px) {
      bottom: 20px;
    }

    &__prevbg {
      position: absolute;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 5%,
        rgba(255, 255, 255, 0.8) 15%,
        rgba(255, 255, 255, 0) 50%
      );
      height: 249px;
      width: 10%;
      top: 0;
      z-index: 99;
      left: 0;
    }

    &__nextbg {
      position: absolute;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 15%,
        rgba(255, 255, 255, 0.8) 70%,
        rgba(255, 255, 255, 1) 100%
      );
      height: 249px !important;
      width: 10%;
      top: 0;
      z-index: 99;
      right: 0;
    }

    // &__prevBtn {
    //   position: absolute;
    //   left: 10px;
    //   top: 52%;
    //   width: 34px;
    //   height: 34px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   background-color: #fff;
    //   border-radius: 50%;
    //   transform: translateY(-100%);
    //   z-index: 500;

    //   img {
    //     z-index: 501;
    //   }

    //   &:hover {
    //     cursor: pointer;
    //   }

    //   &__rectangle__left {
    //     position: absolute;
    //     width: 67px;
    //     height: 251px;
    //     z-index: 1;
    //     background: linear-gradient(
    //       90deg,
    //       #f3f3f3 0%,
    //       rgba(243, 243, 243, 0) 100%
    //     );
    //   }

    //   @media screen and (max-width: 1024px) {
    //     display: none;
    //   }
    // }

    // &__nextBtn {
    //   position: absolute;
    //   right: 10px;
    //   top: 52%;
    //   width: 34px;
    //   height: 34px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   transform: translateY(-100%);
    //   z-index: 500;
    //   background-color: #fff;
    //   border-radius: 50%;

    //   img {
    //     z-index: 501;
    //   }

    //   &:hover {
    //     cursor: pointer;
    //   }

    //   &__rectangle__right {
    //     position: absolute;
    //     width: 67px;
    //     height: 251px;
    //     z-index: 1;
    //     background: linear-gradient(
    //       270deg,
    //       #f3f3f3 0%,
    //       rgba(243, 243, 243, 0) 100%
    //     );
    //   }

    //   @media screen and (max-width: 1024px) {
    //     display: none;
    //   }
    // }

    &__prevBtn {
      position: absolute;
      left: 10px;
      top: 50%;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      transform: translateY(-100%);
      z-index: 500;
      @media screen and (max-width: 768px) {
        transform: translateY(-50%);
      }
      img {
        z-index: 501;
      }

      &:hover {
        cursor: pointer;
      }

      &__rectangle__left {
        position: absolute;
        width: 67px;
        height: 251px;
        z-index: 1;
        background: linear-gradient(
          90deg,
          #f3f3f3 0%,
          rgba(243, 243, 243, 0) 100%
        );
      }
    }

    &__nextBtn {
      position: absolute;
      right: 10px;
      top: 50%;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(-100%);
      z-index: 500;
      background-color: #fff;
      border-radius: 50%;
      @media screen and (max-width: 768px) {
        transform: translateY(-50%);
      }

      img {
        z-index: 501;
      }

      &:hover {
        cursor: pointer;
      }

      &__rectangle__right {
        position: absolute;
        width: 67px;
        height: 251px;
        z-index: 1;
        background: linear-gradient(
          270deg,
          #f3f3f3 0%,
          rgba(243, 243, 243, 0) 100%
        );
      }
    }

    &__card {
      left: 90px;
      display: flex;
      flex-direction: column;
      padding: 20px;
      background-color: #f3f3f3;
      margin-bottom: 20px;
      margin-right: 20px;
      border-radius: 10px;
      width: 332px;
      z-index: 499;
      min-height: 250px;

      @media screen and (max-width: 768px) {
        width: calc(100% - 20px) !important;
        margin: 0 auto;
      }

      &__headerText {
        font-family: Hind;
        font-size: 17px;
        font-weight: 600;
        text-align: left;
        color: #444;
      }

      &__descText {
        font-family: Hind;
        font-size: 15px;
        line-height: 1.6;
        text-align: left;
        color: #444;
        margin-top: 10px;
      }

      &__content {
        font-size: 14px;
        margin-bottom: 10px;
      }

      &__hr {
        border: 1px solid #e6e6e6;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &__address {
        font-family: Hind;
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
        color: #444;
      }
      &__button {
        margin-top: auto;
      }
    }
  }
}