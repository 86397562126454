// input[type="checkbox"] {
//   position: relative;
//   width: 40px;
//   height: 20px;
//   -webkit-appearance: none;
//   outline: none;
//   border: 2px solid rgb(243, 188, 69);
//   border-radius: 10px;
//   background-color: #fff;
//   box-shadow: inset 0 0 5px rgba(243, 188, 69, 0.2);
//   transition: 0.5s;
//   &:hover {
//     cursor: pointer;
//   }
//   &:checked {
//     &::before {
//       left: 19px;
//     }
//   }
//   &::before {
//     content: "";
//     position: absolute;
//     top: -2px;
//     left: -2px;
//     transform: scale(1);
//     width: 19px;
//     height: 19px;
//     border-radius: 10px;
//     background-color: #f3bc45;
//     /*background-image: linear-gradient(130deg, #fcee50 -6%, #eea540 108%),
//       linear-gradient(130deg, #fcee50 -6%, #eea540 108%);*/
//     box-shadow: inset 0 0 5px rgba(243, 188, 69, 0.2);
//     transition: 0.5s;
//   }
// }

.active-switch {
  input[type="checkbox"] {
    position: relative;
    width: 40px;
    height: 20px;
    -webkit-appearance: none;
    outline: none;
    border: 2px solid rgb(243, 188, 69);
    border-radius: 10px;
    background-color: #fff;
    box-shadow: inset 0 0 5px rgba(243, 188, 69, 0.2);
    transition: 0.5s;
    &:hover {
      cursor: pointer;
    }
    &:checked {
      &::before {
        left: 19px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      transform: scale(1);
      width: 19px;
      height: 19px;
      border-radius: 10px;
      background-color: #f3bc45;
      /*background-image: linear-gradient(130deg, #fcee50 -6%, #eea540 108%),
        linear-gradient(130deg, #fcee50 -6%, #eea540 108%);*/
      box-shadow: inset 0 0 5px rgba(243, 188, 69, 0.2);
      transition: 0.5s;
    }
  }
}

.nonact-switch {
  input[type="checkbox"] {
    position: relative;
    width: 40px;
    height: 20px;
    -webkit-appearance: none;
    outline: none;
    border: 2px solid #e9e9ea;
    border-radius: 10px;
    background-color: #e9e9ea;
    // box-shadow: inset 0 0 5px rgba(243, 188, 69, 0.2);
    transition: 0.5s;
    &:hover {
      cursor: pointer;
    }
    &:checked {
      &::before {
        left: 19px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 0.5px;
      left: 0px;
      transform: scale(1);
      width: 15px;
      height: 15px;
      border-radius: 10px;
      background-color: #fff;
      /*background-image: linear-gradient(130deg, #fcee50 -6%, #eea540 108%),
        linear-gradient(130deg, #fcee50 -6%, #eea540 108%);*/
      // box-shadow: inset 0 0 5px rgba(243, 188, 69, 0.2);
      transition: 0.5s;
    }
  }
}
