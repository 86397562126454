.customer-operations-serbest-tuketici-basvurusu-step3 {
  margin-top: 30px;
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__body {
    padding-inline: 30px;
    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }
    &__headerText {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #444444;
      span {
        color: #f3bc45;
        font-weight: 600;
      }
    }
    &__card {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;
      &__hr {
        width: 100%;
        height: 1px;
        background-color: #dadada;
        margin: 20px 0;
      }
      &__address {
        div {
          &:first-child {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #444444;
          }
          &:last-child {
            font-family: "Hind";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
            margin-top: 10px;
          }
        }
      }
      &__yearlyConsumption {
        div {
          &:first-child {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #444444;
          }
          &:last-child {
            font-family: "Hind";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
            margin-top: 10px;
          }
        }
      }
      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #f3bc45;
      }
      &__info {
        display: grid;
        grid-template-columns: 317px 317px;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }
        &--left {
          display: flex;
          flex-direction: column;
          div {
            &:first-child {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #444444;
            }
          }
          &--text {
            margin-top: 10px;
            font-family: "Hind";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #444444;
            i {
              margin-right: 12px;
              color: #adb3bc;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
        &--right {
          display: flex;
          flex-direction: column;
          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }
          div {
            &:first-child {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #444444;
            }
          }
          &--text {
            margin-top: 10px;
            font-family: "Hind";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #444444;
            i {
              margin-right: 12px;
              color: #adb3bc;
            }
          }
        }
      }
    }
    &__buttons {
      display: grid;
      grid-template-columns: 332px 332px;
      gap: 30px;
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        button {
          &:last-child {
            margin-top: 0;
            margin-bottom: 30px;
          }
        }
      }
    }
    &__modal {
      &__description {
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #444444;
      }
      &__button {
        width: 100%;
      }
    }
  }
}
