.notification-mobile-detail {
  &__content {
    &__prev {
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      text-align: left;
      color: #000000;
      margin-top: 20px;
      width: fit-content;
      &:hover {
        cursor: pointer;
      }
    }
    &__body {
      background: #e8ecf2;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &__title {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          /* identical to box height */

          /* Lacivert */

          color: #486072;
          display: flex;
          align-items: center;
          span {
            display: flex;
            width: 10px;
            height: 10px;
            background-color: #f3bc45;
            border-radius: 50%;
            content: "";
            color: #f3bc45;
            margin-right: 10px;
            font-size: 1px;
          }
        }
        &__date {
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          text-align: right;
          color: #486072;
        }
      }
      &__description {
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #486072;
        margin-top: 20px;
        word-wrap: break-word;
      }
      &__buttons {
        button {
          margin-top: 20px;
        }
      }
    }
  }
}
