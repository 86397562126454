.invoicePdfViewer {
  .rpv-core__viewer {
    border-radius: 30px;
    width: 100% !important;
    height: 100% !important;
    /* width */

    .rpv-core__canvas-layer {
      width: 100% !important;
      margin: 0 !important;
    }

    .core__inner-pages {
      border-radius: 3px 0 0 3px;
    }

    .rpv-core__page-layer {
      width: calc(100% - 200px);
      box-shadow: none;
    }

    .rpv-core__text-layer {
      height: 850px !important;
      width: 740px !important;

      @media (max-width: 850px) {
        width: 400px !important;
        height: 850px !important;

      }

      @media (max-width: 500px) {
        width: 300px !important;
        height: 850px;
      }
    }

    .rpv-core__inner-page {
      background-color: #878787 !important;
      padding: 0;
      height: 100% !important;
    }

    .rpv-core__inner-pages {
      overflow-x: hidden !important;
      border-radius: 3px;

      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: white;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #486072;
        border-radius: 3px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    @media (max-width: 850px) {
      width: 100% !important;
    }
  }

  &__nothing {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f3f3f3;
    width: 518px;
    margin: 0 auto;
    padding: 50px 20px 20px 10px;
    border-radius: 10px;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 20px;
      margin-top: 30px;
    }

    span {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
      display: inline-block;
      margin-bottom: 20px;
    }

    img {
      @media screen and (max-width: 768px) {
        width: calc(50%);
      }
    }
  }

  &__pageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: "100%";
    height: 100vh;
    background-color: #878787;

    &__spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
    }
  }
}