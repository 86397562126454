.account-information {
  .rc-virtual-list {
    z-index: 999;
  }
  .listbox {
    z-index: 999;
  }
  .rc-virtual-list-holder {
    z-index: 999;
  }
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__body {
    padding-inline: 30px;
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      padding-inline: 0px;
    }

    &__info {
      background-color: #f3f3f3;
      width: 332px;
      border-radius: 10px;
      padding: 20px;

      .infoComponent {
        margin-bottom: 0 !important;
        margin-top: 30px !important;
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      &__header {
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        line-height: 1.2;
        color: var(--turuncu);
      }

      &__content {
        display: flex;
        flex-direction: column;

        &__item {
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          &__title {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            color: #444;
            margin-bottom: 10px;
          }

          &__value {
            font-family: Hind;
            font-size: 16px;
            font-weight: 300;
            line-height: 1.5;
            color: #444;
          }
        }

        &__blueinfo {
          .infoComponent {
            width: auto;
            margin-top: 20px;
          }
        }
      }
    }

    &__info2 {
      background-color: #f3f3f3;
      width: 332px;
      border-radius: 10px;
      padding: 20px;

      @media (max-width: 768px) {
        margin-top: 30px;
        width: 100%;
        height: auto;
      }

      &__header {
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        line-height: 1.2;
        color: var(--turuncu);
      }

      &__content {
        display: flex;
        flex-direction: column;

        &__item {
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          &__title {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            color: #444;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
              &:hover {
                cursor: pointer;
              }

              span {
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                line-height: 1.6;
                letter-spacing: 0.3px;
                text-align: center;
                color: var(--turuncu);

                @media (max-width: 768px) {
                  font-size: 14px;
                }
              }
            }
          }

          &__value {
            background-color: #fff;
            border: 1px solid #486072;
            height: 65px;
            border-radius: 3px;
            padding: 10px;

            &__text {
              font-family: Hind;
              font-size: 16px;
              font-weight: 500;
              line-height: 0.94;
              color: #444;
            }

            &__noValue {
              font-family: Hind;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              color: var(--lacivert);
              p {
                opacity: 0.5;
                margin-bottom: 0;
                display: inline;
              }
              span {
                opacity: 1 !important;
              }
            }

            &__selected {
              width: fit-content;
              background-color: #30a353;
              margin-top: 10px;
              padding: 5px 10px;
              border-radius: 11px;
              height: 20px;
              font-family: Hind;
              font-size: 10px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 0.5px;
              text-align: left;
              color: var(--white);
            }
          }
        }
      }
    }

    &__info4 {
      width: 332px;
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 10px;
      position: relative;
      margin-top: 30px;

      @media (max-width: 768px) {
        width: 100%;
      }

      &__header {
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        line-height: 1.2;
        color: var(--turuncu);
      }

      &__item {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        &__title {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          color: #444;
          margin-bottom: 10px;
        }

        &__value {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            &:first-child {
              font-family: Hind;
              font-size: 16px;
              font-weight: 300;
              line-height: 1.5;
              color: #444;
            }

            &:last-child {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              line-height: 1.6;
              letter-spacing: 0.3px;
              text-align: center;
              color: var(--turuncu);

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  &__modal {
    .customSelect {
      width: 290px !important;
      z-index: 999;
    }

    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
    }

    &__select {
      margin-top: 30px;
    }

    &__buttons {
      width: 100% !important;

      @media (max-width: 768px) {
        width: 100% !important;
      }
    }
  }
}

.account-information__modal {
  .ant-select-selection-item {
    opacity: 1 !important;
  }
}

.disabled-btn-account {
  opacity: 0.5;
  pointer-events: none;
}
