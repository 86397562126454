.account-information-tarife-degistirme-detay-step1 {
  padding-inline: 30px;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__card {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      span {
        &:first-child {
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: var(--turuncu);
        }

        &:last-child {
          opacity: 0.5;
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: var(--turuncu);

          @media screen and (max-width: 768px) {
            display: inline-block;
            margin-top: 10px;
          }
        }
      }
    }

    &__address {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      span {
        &:first-child {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--black);
        }

        &:last-child {
          font-family: Hind;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--black);
        }
      }
    }

    &__details {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      width: 70%;

      &__detail {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        span {
          &:first-child {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);
          }
        }

        div {
          display: flex;
          align-items: center;
          margin-top: 10px;
          font-family: Hind;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--black);

          i {
            margin-right: 10px;
            color: #adb3bc;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  &__cards {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 30px 0px 15px 0px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }


    &__left {
      width: 332px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &__header {
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: var(--turuncu);
        margin-bottom: 30px;
      }

      &__card {
        background-color: #f3f3f3;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 30px;

        &__header {
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: var(--turuncu);
        }

        &__description {
          font-family: Hind;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--black);
          margin-top: 20px;
        }

        &__hr {
          height: 1px;
          width: 100%;
          background-color: #dadada;
          margin-top: 20px;
        }

        &__details {
          margin-top: 20px;

          div {
            &:first-child {
              margin-top: 10px;
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }

            &:last-child {
              font-family: Hind;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }
          }
        }
      }
    }

    &__right {
      width: 332px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &__header {
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: var(--turuncu);
        margin-bottom: 30px;
      }

      &__card {
        background-color: #f3f3f3;
        border-radius: 10px;
        padding: 20px;

        &__header {
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: var(--turuncu);
        }

        &__description {
          font-family: Hind;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--black);
          margin-top: 20px;
        }

        &__hr {
          height: 1px;
          width: 100%;
          background-color: #dadada;
          margin-top: 20px;
        }

        &__details {
          margin-top: 20px;

          div {
            &:first-child {
              margin-top: 10px;
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }

            &:last-child {
              font-family: Hind;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }
          }
        }

        &__button {
          button {
            margin-top: 20px;
          }
        }
      }
    }
  }

  &__footerinfo {
    font-family: Hind;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
  }

  &__footerBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    button {
      margin-top: 10px;
    }
  }
}