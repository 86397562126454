// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(68, 68, 68, 0.5);
  z-index: 10000 !important;
  display: none;
  &.show {
    display: block;
  }
  @media  (max-width: 600px) {
    z-index: 9999;
  }

  &__content {
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    max-height: 490px;
    width: 352px;
    margin: 0 auto;
    max-width: 400px;
    background: #fff;
    border-radius: 10px;
    @media (max-width: 600px) {
      width: 352px;
    }
    &__close {
      position: absolute;
      top: -15px;
      right: -15px;
      width: 30px;
      height: 30px;
      background: #fff;
      border-radius: 50%;
      cursor: pointer;
      img {
        background-color: #f3bc45;
        border-radius: 20px;
        width: 30px;
        height: 30px;
      }
      @media (max-width: 600px) {
        width: 30px;
        height: 30px;
        top: -10px;
        right: -10px;
      }
    }
    &__header {
      width: 292px;
      height: auto;
      font-family: "Hind", sans-serif !important;
      font-size: 28px;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: 0.56px;
      color: #486072;
      text-align: center;
      margin: 30px;
    }
    &__body {
      font-family: "Hind", sans-serif !important;
      font-size: 16px;
      font-weight: normal;
      color: #444;
      text-align: center;
      width: 292px;
      &__description {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        text-align: center;
        color: #444;
      }
      &__info {
        display: flex;
        justify-content: center;
        width: 292px;
        height: 100px;
        background-color: #f3f3f3;
        border-radius: 10px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        img {
          width: 24px;
          height: 24px;
          margin-left: 30px;
          margin-right: 10px;
          margin-top: 38px;
          margin-bottom: 38px;
        }
        span {
          width: 201px;
          height: 47px;
          font-size: 18px;
          line-height: 1.33;
          margin-left: 7px;
          margin-right: 20px;
          margin-bottom: 27px;
          margin-top: 26px;
          text-align: center;
          color: #444;
          span {
            font-size: 22px;
            font-weight: 600;
          }
        }
      }
    }
    &__footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 30px;
      padding-right: 30px;
      padding-left: 30px;
      @media (max-width: 600px) {
        // width: 292px !important;
      }
      .ohm-btn {
        &:last-child {
          margin-top: 15px !important;
        }
      }
      a {
        width: 100%;
      }
    }
  }
}

.btn-margin-class {
  .ohm-btn {
    &:nth-child(2) {
      margin-top: 15px !important;
    }
  }
}

.underlineOnHover {
  &:hover {
    text-decoration: underline;
  }
}