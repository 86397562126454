.customer-register-page {
  .registerComplete {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    font-family: "Hind", sans-serif !important;

    &__headerText {
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #f3bc45;
      margin-top: 30px;
    }
    &__bodyText {
      margin-top: 30px;

      display: flex;
      justify-content: center;
      span {
        font-family: "Hind", sans-serif !important;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
      }
    }
    &__photo {
      position: relative;
      .registerCompleteMaskot {
        position: absolute;
        top: 211px;
        left: -56px;
        @media(max-width: 768px) {
          display: none;
        }
      }
    }
    &__bodyInfo {
      position: relative;
      width: 332px;
      height: auto;
      background-color: #f3f3f3;
      padding: 20px;
      gap: 31px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      span {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #486072;
      }
      &__copyText {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        background-color: #486072;
        color: #fff;
      }
      img {
        &:hover {
          cursor: pointer;
        }
      }
      &__sections {
        display: flex;
        flex-direction: column;
        &__section {
          display: flex;
          width: 292px;
          justify-content: space-between;
          margin-bottom: 20px;
          &__text {
            width: 130px;
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    &__footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .highlight {
    color: #f3bc45;
    font-weight: 500;
  }
}

.registerComplete {
  .modal__content {
    .modal__content__header {
      margin-bottom: -10px !important;
    }
    .modal__content__footer {
      margin-top: 0 !important;
    }
  }
}

.span-text {
  font-family: "Hind" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: right;
  color: #486072;
}
