// @import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "../css/icons.scss";
@import "./layouts/authLayout.scss";

@font-face {
  font-family: "Hind";
  src: url("../fonts/Hind/Hind-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Hind";
  src: url("../fonts/Hind/Hind-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Hind";
  src: url("../fonts/Hind/Hind-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Hind";
  src: url("../fonts/Hind/Hind-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Hind";
  src: url("../fonts/Hind/Hind-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "enerjisaohm";
  src: url("../fonts/enerjisaohm.ttf") format("truetype");
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --pure-black: #000;
  --soft-grey: #f3f3f3;
  --correct: #6cb425;
  --turuncu: #f3bc45;
  --white: #fff;
  --lacivert: #486072;
  --cool-grey: #e8ecf2;
  --error: #f00;
}

html {
  font-family: "Hind";
}

body {
  font-family: "Hind";
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;
  padding-inline: 30px 27px;

  @media (max-width: 576px) {
    width: 100%;
    padding-inline: 25px;
  }
}

.give-opacity-to-home-page {
  animation: makeOpacity 4s ease-in-out;
}

@keyframes makeOpacity {
  0% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes makeOpacity {
  0% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
}

// .rc-virtual-list-scrollbar  {
//   display: flex !important;
// }

.rc-virtual-list-scrollbar-show {
  display: flex !important;
}

// rc-virtual-list-scrollbar-show


.leaflet-left {
  right: 20px !important;
  left: auto
}

.ant-tour {
  @media screen and (max-width: 1180px) {
    width: 350px;
  }
}

a {
  text-decoration: none;
}

.ant-tour-buttons button {
  background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%) !important;
  color: white !important;
  font-size: 14px !important;
  font-family: Hind !important;
  border: none !important;
  box-shadow: none !important;
  padding: 3px 7px !important;
}

.ant-tour-buttons button:hover {
  background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%) !important;
  color: white !important;
  border: none !important;
}

.ant-tour-indicator-active {
  background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%) !important
}

.ant-tour-description {
  font-size: 16px !important;
  font-family: Hind !important;
}

.ant-tour-title {
  font-size: 18px !important;
  font-family: Hind !important;
}

::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e8ecf2 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #486072 !important;
  border-radius: 3px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

