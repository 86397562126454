.mahalle-tuketim-karsilastirma {

  .topInfo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .nothing {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 30px;
    border-radius: 10px;
    background-color: #F3F3F3;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 20px;
      margin-top: 30px;
    }

    span {
      width: 100%;
      margin: 0 0 10px;
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
    }

    p {
      width: 100%;
      margin: 10px 0;
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: var(--lacivert);
    }

    img {
      @media screen and (max-width: 768px) {
        width: calc(50%);
      }
    }
  }



  .swiper-wrapper {
    align-items: flex-end;
    min-height: 250px;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__body {
    margin-top: 30px;
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__selectArea {
      display: grid;
      grid-template-columns: 332px 332px;
      gap: 30px;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      &__select {
        width: 100%;

        .customSelect {
          .ant-select-selection-item {
            opacity: 1;
          }
        }
      }
    }

    .customer-operations-mybills__body__card2__content__billbar__kwh {
      // padding-top: 40px;
      font-family: "Hind";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      margin-bottom: 20px;
      line-height: 1.38;
      /* or 108% */

      display: flex;
      align-items: center;
      text-align: center;

      /* Lacivert */

      color: #486072;

      @media screen and (max-width: 768px) {
        padding-top: 15px;
      }
    }

    .customer-operations-mybills__body__card2__content__billbar__date {
      font-family: "Hind";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #486072;
      height: 40px;
    }

    .customer-operations-mybills__body__card2__content__billbar__bars {
      justify-content: center;
      align-items: flex-end;
    }

    .customer-operations-mybills__body__card2__content__billbar__bars__top {
      display: flex;
      gap: 10px;
      flex-direction: row;
      justify-content: flex-start;
      height: 100%;
    }

    .customer-operations-mybills__body__card2__content__billbar__bars__top__inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

    }

    .customer-operations-mybills__body__card2__content__billbar {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .customer-operations-mybills__body__card2__content__billbar__bars__top__bar {
      align-items: flex-end;
      // align-self: flex-end;
    }

    .customer-operations-mybills__body__card2__content__billbar__bars__top__bar2 {
      align-items: flex-end;
      align-self: flex-end;
      background: #e3e3e3;
      width: 30px;
      border-radius: 5px;
    }

    .customer-operations-mybills__body__card2__content__billbar__bars__top__bar3 {
      align-items: flex-end;
      align-self: flex-end;
      background: #b7bfc6;
      width: 30px;
      border-radius: 5px;
    }

    &__chartArea {
      display: flex;
      flex-direction: column;
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 10px;
      margin-top: 30px;

      .swiper-slide {
        &:not(:first-child) {
          @media screen and (max-width: 768px) {
            margin-right: 20px;
          }
        }
      }

      .swiper-button-disabled {
        display: none !important;
      }

      &__toggleCategory {
        display: flex;
        align-items: center;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 3px;

        &__hr {
          width: 1px;
          height: 30px;
          background-color: #000000;
          margin: 0 5px;
        }

        &__button {
          width: 100%;

          @media screen and (max-width: 1024px) {
            width: 100%;

            .ohm-secondaryBtn {
              height: 80px;
            }

            .ohm-primaryBtn {
              height: 80px;
            }
          }

          &:last-child {
            border-right: unset;
          }

          .ohm-secondaryBtn {
            background: rgba(255, 255, 255, 0.5);
          }

          button {
            margin-top: 0;
          }
        }

        &__slider {
          margin-top: 30px;
          position: relative;

          &__orangeline {
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #f3bc45;
            transition: 500ms;
          }

          &__rightKw {
            position: absolute;
            right: -30px;
            top: 50%;
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            color: #f3bc45;
            transform: translateY(-60%) rotate(90deg);
            z-index: 99;
          }

          .customer-operations-mybills__body__card2__content__billbar__bars__top__bar {
            background: linear-gradient(131.92deg,
                #fcee50 -5.41%,
                #eea540 107.64%);
          }

          .customer-operations-mybills__body__card2__content__billbar__bars__top__bar2 {
            width: 30px;
            height: 20px;
            background: #b7c0c6;
            border-radius: 5px 5px 0px 0;
            align-self: center;
          }
        }
      }
    }

    &__afterChart {
      padding: 20px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__item {
        display: flex;
        align-items: center;
        gap: 10px;

        &__right {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #000000;
        }

        &__left {
          width: 62px;
          height: 24px;

          background: linear-gradient(131.92deg,
              #fcee50 -5.41%,
              #eea540 107.64%);
          border-radius: 5px;

          @media screen and (max-width: 768px) {
            width: 62px !important;
            min-width: 62px !important;
          }

          @media screen and (max-width: 400px) {
            width: 42px !important;
            min-width: 42px !important;
          }
        }

        &__left2 {
          width: 62px;
          height: 24px;

          background: rgba(211, 211, 211, 0.5);
          border-radius: 5px;

          @media screen and (max-width: 768px) {
            width: 62px !important;
          }

          @media screen and (max-width: 400px) {
            width: 42px !important;
            min-width: 42px !important;
          }
        }

        &__left3 {
          width: 62px;
          height: 24px;

          background: rgba(72, 96, 114, 0.35);
          border-radius: 5px;

          @media screen and (max-width: 768px) {
            width: 62px !important;
          }

          @media screen and (max-width: 400px) {
            width: 42px !important;
            min-width: 42px !important;
          }
        }
      }
    }

    .higherTooltip__left__span__top__tooltip__content__text {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      /* White */

      color: #ffffff;

      div {
        text-align: left;
      }
    }

    .higherTooltip__left__span__top__tooltip {
      @media screen and (max-width: 768px) {
        &:after {
          top: -4px;
          left: 118%;
        }
      }
    }

    .higherTooltip__left__span__top__tooltip__content {
      @media screen and (max-width: 1200px) {
        left: 0;
        width: 317px;
      }

      @media screen and (max-width: 768px) {
        left: -30vw;
        width: calc(100vw - 9vw);
        top: 5px;
      }
    }

    &__tableArea {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      position: relative;
      padding: 10px 0;

      @media screen and (max-width: 768px) {
        margin-top: 50px;
      }

      &__dropshadow {
        position: absolute;
        top: -5px;
        right: 0;
        height: 102%;
        width: 163px;
        box-shadow: -5px -5px 20px #ffffff,
          5px 5px 20px rgba(174, 174, 192, 0.4);
        border-radius: 10px;
        z-index: -1;

        @media screen and (max-width: 768px) {
          width: 172px;
          height: calc(100% + 20px);
          top: -20px;
        }

        @media screen and (max-width: 600px) {
          width: 122px;
        }

        @media screen and (max-width: 500px) {
          width: 92px;
        }


      }

      &__header {
        display: grid;
        grid-template-columns: repeat(4, 150px);
        gap: 30px;

        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(4, 1fr);
          gap: 25px;
        }

        @media screen and (max-width: 550px) {
          gap: 20px;
        }

        @media screen and (max-width: 330px) {
          gap: 5px;
        }

        &__item {
          height: 48px;
          font-family: "Hind";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #000000;

          br {
            display: none;

            @media screen and (max-width: 768px) {
              display: block;
            }
          }

          @media screen and (max-width: 768px) {
            font-family: "Hind";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */

            display: flex;
            align-items: center;
            text-align: center;

            color: #000000;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        gap: 30px;

        &__item {
          display: grid;
          grid-template-columns: repeat(4, 150px);
          gap: 30px;
          align-items: center;
          align-self: center;

          @media screen and (max-width: 768px) {
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            width: 100%;
            min-width: 350px;
            //max-width: 350px;
          }

          @media screen and (max-width: 400px) {
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            width: 100%;
            min-width: 340px;
            //max-width: 350px;
          }

          @media screen and (max-width: 330px) {
            min-width: unset !important;
          }

          .inside1 {
            @media screen and (max-width: 400px) {
              margin-left: -10px;
            }
          }

          .inside2 {
            @media screen and (max-width: 420px) {
              max-width: 65px;
            }
          }

          .inside3 {
            @media screen and (max-width: 768px) {
              margin-left: -5px;
            }

            @media screen and (max-width: 400px) {
              margin-left: -18px;
            }
          }

          .inside4 {
            @media screen and (max-width: 768px) {
              margin-left: 0px;
            }

            @media screen and (max-width: 400px) {
              margin-left: -5px;
            }

          }

          &__inside {
            font-family: "Hind";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
            justify-content: center;
            gap: 5px;

            .higherTooltip {
              width: 29px;
            }

            .higherTooltip__left__span__top {
              img {
                transform: rotate(180deg);
              }
            }

            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.tuketim-karsilastirma__body__chartArea__toggleCategory__slider__swiper__swipe__prevBtn {
  position: absolute;
  left: 0px;
  top: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  transform: translateY(-100%);
  z-index: 500;
}

.tuketim-karsilastirma__body__chartArea__toggleCategory__slider__swiper__swipe__prevBtn__rectangle__left {
  background: linear-gradient(90deg, #f3f3f3, hsla(0, 0%, 95%, 0));
  position: absolute;
  width: 67px;
  height: 229px;
  z-index: 1;
}

.tuketim-karsilastirma__body__chartArea__toggleCategory__slider__swiper__swipe__nextBtn {
  position: absolute;
  right: 0px;
  top: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-100%);
  z-index: 500;
  background-color: #fff;
  border-radius: 50%;
}

.tuketim-karsilastirma__body__chartArea__toggleCategory__slider__swiper__swipe__nextBtn__rectangle__right {
  background: linear-gradient(270deg, #f3f3f3, hsla(0, 0%, 95%, 0));
  position: absolute;
  width: 67px;
  height: 229px;
  z-index: 1;
}