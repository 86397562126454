.kurumsal-musteri-ol {
  .information {
    font-family: "Hind", sans-serif !important;
    display: flex;
    flex-direction: column;
    justify-content: center;


    @media (max-width: 600px) {
      width: 100% !important;

      &__infoBox {
        width: 100%;
        margin-inline: 0;
      }
    }

    .modal__content {
      .modal__content__header {
        margin-bottom: 0;
      }
    }

    b {
      font-weight: 500;
    }

    &__title {
      margin-top: 30px;

      span {
        font-family: "Hind", sans-serif !important;
        font-size: 25px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #f3bc45;
      }
    }

    &__content {
      margin-top: 30px;
      font-family: "Hind", sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: var(--pure-black);

      &__underline {
        text-decoration: underline;
        font-weight: 600;
      }
    }

    &__body {
      &__hr {
        margin-top: 30px;
        border: 0;
        border-top: 1px solid #486072;

        @media (max-width: 600px) {
          &__hr {
            width: 100% !important;
          }
        }
      }

      @media (max-width: 600px) {
        &__hr {
          width: 100% !important;
        }

        &__image {
          display: none;
        }
      }

      &__text {
        margin-top: 30px;

        &__title {
          font-family: "Hind", sans-serif;
          font-size: 25px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: var(--turuncu);
        }

        &__description {
          margin-top: 30px;
          font-family: "Hind", sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--pure-black);
        }
      }
    }

    &__bodyFooter {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      &__text {
        font-family: "Hind", sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 20px;
        letter-spacing: normal;
        text-align: left;
        color: var(--pure-black);
      }
    }

    &__hr {
      margin-top: 30px;
      border: 0;
      border-top: 1px solid #486072;
      width: 100%;

      @media (max-width: 600px) {
        &__hr {
          width: 100% !important;
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      &__title {
        font-size: 25px;
        font-weight: 600;
        line-height: 1.2;
      }

      &__text {
        margin-top: 30px;
        font-family: "Hind", sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--pure-black);
      }

      &__btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
      }
    }
  }

  .orange {
    color: #f3bc45;
    font-weight: 500;
  }

  .mt-3 {
    margin-top: 30px;
  }

  .underline {
    text-decoration: underline;
    font-weight: 500;
  }
}