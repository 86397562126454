.account-information-phone {
  .errorBoxLogin__errorText {
    font-size: 15px !important;
  }
  .customInput .errorBoxLogin {
    align-items: flex-start !important;
  }
  .customInput .errorBoxLogin__errorIcon {
    margin-top: 6px !important;
  }
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  // .activeEditMode {
  //   button {
  //     cursor: default !important;
  //   }
  // }
  .pointer-events-none {
    pointer-events: none;
    &:hover {
      cursor: none;
    }
  }
  &__body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-inline: 30px;
    @media (max-width: 768px) {
      padding-inline: 0px;
      flex-direction: column;
    }
    .addNewNumberDisabled {
      opacity: 0.5;
      pointer-events: none !important;
      &:hover {
        cursor: none;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 30px;
      margin-right: 30px;
      @media (max-width: 768px) {
        margin-right: 0px;
      }
      &__header {
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        line-height: 1.2;
        color: var(--turuncu);
        margin-bottom: 30px;
        @media (max-width: 768px) {
          &:last-child {
            margin-top: -20px;
          }
        }
      }
      &__label {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 30px;
        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
      .active-box {
        transform: translateY(-50%) translateZ(-50%) scale(1);
        transition: transform 1s ease !important;
        z-index: 50;
        position: relative;
        background-color: white;
      }
      &__box {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        position: relative;
        z-index: 0;

        &__item {
          height: 65px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #486072;
          padding: 10px;
          width: 332px;
          border-radius: 3px;
          @media (max-width: 768px) {
            width: 100%;
          }

          &__left {
            input {
              display: flex;
              align-items: center;
              padding: 5px 10px;
              height: 35px;
              width: 223px;
              border: none;
              resize: none;
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              // display: flex;
              // align-items: center;
              background-color: #fff;
              color: #486072;

              &:focus {
                outline: none;
              }
            }
            span {
              font-family: Hind;
              font-size: 15px;
              font-weight: 500;
              line-height: 1;
              color: #444;
            }
            div {
              padding: 5px 10px;
              background-color: #30a353;
              border-radius: 11px;
              display: flex;
              width: fit-content;
              font-family: Hind;
              font-size: 10px;
              font-weight: 500;
              line-height: 1;
              letter-spacing: 0.5px;
              color: #fff;
              margin-top: 10px;
            }
          }
          &__right {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            line-height: 1.6;
            letter-spacing: 0.3px;
            text-align: center;
            color: var(--turuncu);
            &:hover {
              cursor: pointer;
            }
            span {
              &:last-child {
                margin-left: 5px;
              }
            }
          }
        }
      }
      &__card {
        background-color: #f3f3f3;
        padding: 20px;
        border-radius: 10px;
        width: 332px;
        margin-bottom: 80px;
        height: auto;
        position: relative;
        @media (max-width: 768px) {
          width: 100%;
        }
        &__descText {
          margin-bottom: 10px;
        }

        &__button2 {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          button {
            margin-top: 0px;
          }
        }
        &__button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;

          button {
            margin-top: 30px;
          }
        }

        &__maskot {
          display: flex;
          justify-content: center;
          align-content: center;
        }

        &__photo {
          bottom: -32px;
          right: -10px;
          position: relative;
        }
        .lastBox {
          input {
            padding-left: 15px;
          }
          .errorBox {
            margin-bottom: 0px;
          }
        }
        &__inputArea {
          display: flex;
          flex-direction: column;

          &__input {
            position: relative;
            &__prefix {
              position: absolute;
              top: 42px;
              left: 15px;
              font-family: Hind;
              font-size: 18px;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--lacivert);
            }
            &__prefix2 {
              position: absolute;
              top: 42px;
              left: 15px;
              font-family: Hind;
              font-size: 18px;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--lacivert);
            }
            input {
              width: 292px;
              padding-left: 40px;
              @media (max-width: 768px) {
                width: 100% !important;
              }
            }
            .errorBox {
              width: 292px;
              @media (max-width: 768px) {
                width: 100%;
              }
              //padding-inline: 20px;
            }
            .errorBox {
              background: #fff;
              margin-left: 0;
              padding: 12px;
              border: 1px solid #ff0000;
              // margin-bottom: 20px;
              .customInput .errorBox__errorIcon {
                width: 26px;
              }
              &:first-child {
                margin-top: -10px;
              }
              &:last-child {
                // margin-top: -10px;
              }
            }
          }
        }
      }
    }
  }
  &__modal {
    display: flex;
    flex-direction: column;
    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
    }
    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }
      span {
        font-family: Hind;
        font-size: 18px;
        // font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        font-weight: 600;

        a {
          color: #444;
        }
      }
    }
    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
      margin-top: 30px;
    }
    &__button {
      width: 100%;
    }
  }
}
