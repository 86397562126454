@import "../../../assets/styles/layouts/authLayout.scss";

.contract-move-out-ek-ekran {
  .btnCen {
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      margin: auto;
    }
  }
  @include authLayout;
  &__whiteBody {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 136px;
    @media screen and (max-width: 768px) {
      padding-inline: 30px;
      margin-top: 30px;
    }
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        margin-top: 80px;
      }
      &__left {
        @media screen and (max-width: 768px) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        &__desktopMaskot {
          margin-top: -100px;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
        &__mobilMaskot {
          display: none;
          @media screen and (max-width: 768px) {
            display: block;
            margin-top: -130px;
          }
        }
      }
      &__right {
        display: flex;
        flex-direction: column;
        width: 352px;
        button {
          @media (max-width: 768px) {
            width: 305px !important;
          }
        }
        &__header {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 35px;
          letter-spacing: 0.02em;
          color: #486072;
          width: 292px;
          @media screen and (max-width: 768px) {
            width: 100%;
            text-align: center;
            margin-top: -30px;
          }
        }
        &__description {
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-top: 30px;
          width: 292px;
          @media screen and (max-width: 768px) {
            margin: 0 auto;
            margin-top: 30px;
            text-align: center;
          }
        }
        &__recaptcha {
          @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &__recaptchaclass {
            margin-top: 30px;
          }
        }
        &__showMore {
          display: flex;
          flex-direction: column;
          &__description {
            margin-top: 30px;
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
            width: 292px;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
    &__noPageValidity {
      background-color: #fbfbfb;
      padding: 30px;
      width: 740px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      &__body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
          gap: 0px;
        }
        &__left {
          @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          &__desktopmaskot {
            display: block;
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
          &__mobilmaskot {
            display: none;
            @media screen and (max-width: 768px) {
              display: flex;
            }
          }
          &__mobilHeader {
            display: none;
            @media screen and (max-width: 768px) {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 28px;
              line-height: 35px;
              text-align: center;
              letter-spacing: 0.02em;
              color: #486072;
            }
          }
        }
        &__right {
          display: flex;
          flex-direction: column;
          width: 292px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          a {
            color: #444;
          }
          &__header {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 35px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #486072;
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
          &__description {
            margin-top: 30px;
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #444444;
          }
        }
      }
    }
  }
}
.aTagBtn {
  width: 305px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: red;
  background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%) !important;
  border-radius: 3px;
  font-family: Hind;
  font-size: 15px;
  color: white !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  margin-top: 30px;
  white-space: pre-line;
  cursor: pointer;
  text-decoration: none;
}

.aTagBtn-disabled {
  opacity: 0.5;
  cursor: default !important;
}
