@import "../../../assets/styles/layouts/authLayout.scss";

.dilekce-basvuru {
  @include authLayout;
}

.isJetProggress {
  .progressBox {
    height: 395px;
  }
}

.noJetProggress {
  .progressBox {
    height: 335px;
  }
}
