.customer-operations-odeme-islemleri-fatura-odeme-detay {
  // @media screen and (max-width: 768px) {
  //   margin-top: -30px;
  // }

  .infoBox img {
    margin-right: 35px !important;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__card {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;

      &__hr {
        background-color: #dadada;
        height: 1px;
        width: 100%;
        margin-top: 20px;
      }

      &__info {
        .infoComponent {
          margin-top: 20px;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #f3bc45;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &__left {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
          color: #f3bc45;

          &__name {
            img {
              margin-left: 10px;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }

        &__right {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
          color: #f3bc45;
          opacity: 0.5;

          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }
        }
      }

      &__content {
        &__item {
          margin-top: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr;

          @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
          }

          &__left {
            div {
              &:first-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
              }

              &:last-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #444444;
                margin-top: 10px;
                display: flex;
                align-items: center;

                i {
                  color: #adb3bc;
                  margin-right: 10px;

                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }

          &__right {
            @media screen and (max-width: 768px) {
              margin-top: 20px;
            }

            div {
              &:first-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
              }

              &:last-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #444444;
                margin-top: 10px;
              }
            }
          }
        }
      }

      &__tableheader {
        display: grid;
        grid-template-columns: repeat(2, 153px);
        gap: 10px;
        margin-top: 20px;

        @media screen and (max-width: 768px) {
          grid-template-columns: 80px 120px;
          gap: 10px;
        }

        div {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #486072;
        }
      }

      &__items {
        background-color: #fff;
        border-radius: 10px;
        padding: 12px;
        margin-top: 20px;

        &__item {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;

          @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
          }

          &__first {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #486072;

            @media screen and (max-width: 768px) {
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #486072;
            }
          }

          &__second {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #486072;
            margin-right: 150px;

            @media screen and (max-width: 768px) {
              margin-right: 0;
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #486072;
            }
          }

          &__third {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: right;

            a {
              color: #f3bc45;

              @media screen and (max-width: 768px) {
                display: none;
              }
            }

            img {
              display: none;

              @media screen and (max-width: 768px) {
                display: block;
              }
            }

            &:hover {
              cursor: pointer;
            }
          }

          &__fourth {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: right;
            color: #f3bc45;

            &:hover {
              cursor: pointer;
            }

            img {
              margin-left: 10px;
            }
          }
        }
      }

      &__bottom {
        display: grid;
        grid-template-columns: 420px auto;
        gap: 30px;
        height: max-content;

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
          height: max-content;
          gap: 0px;
        }

        &__left {
          &__text {
            font-family: "Hind";
          }

          &__success {
            background-color: rgba(255, 255, 255, 0.5);

            padding: 27px;
            margin-top: 20px;
            display: flex;
            align-items: center;

            i {
              color: #f3bc45;
              font-size: 24px;
              opacity: 1 !important;
              margin-right: 10px;
            }

            span {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #f3bc45;
            }
          }

          &__addNew {
            display: flex;
            align-items: flex-end;
            margin-top: 20px;

            @media screen and (max-width: 768px) {
              flex-direction: column;
            }

            input {
              width: 300px;
              margin-bottom: 0;

              @media screen and (max-width: 768px) {
                width: 100%;
              }
            }

            button {
              height: 44px;

              @media screen and (max-width: 768px) {
                margin-top: 20px;
              }
            }
          }
        }

        &__right {
          position: relative;

          @media screen and (max-width: 768px) {
            display: none;
          }

          img {
            position: absolute;
            right: 0px;
            top: -45px;
          }
        }
      }
    }
  }

  &__modal {
    &__header {
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #486072;
      margin-bottom: 30px;
    }

    &__description {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }
  }
}