.progressbox-memnuniyet-anketi {
  display: flex;
  align-items: center;
  &--steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // @media screen and (max-width: 768px) {
    //   justify-content: center;
    // }
    &__step {
      height: 35px;
      border-radius: 7px;
      background-color: var(--soft-grey);
      width: 106px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 768px) {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
      &:hover {
        cursor: default;
      }
      span {
        font-family: Hind;
        font-size: 19.9px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.89;
        letter-spacing: 0.4px;
        text-align: center;
        color: var(--lacivert);
        span {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }
    }
    &__activeStep {
      width: 370px;
      background-image: radial-gradient(
        at top left,
        #fcee50 -10%,
        #eea540 112%
      );
      height: 35px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 20px;
      @media screen and (max-width: 768px) {
        align-items: center;
        justify-content: center;
        width: calc(100% - 135px);
        margin-right: 10px;
        height: 30px;
      }
      @media screen and (max-width: 350px) {
        width: 90px;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        img {
          opacity: 0.25;
          @media screen and (max-width: 768px) {
            margin-left: -35px;
          }
        }
      }
      span {
        font-family: Hind;
        font-size: 19.9px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.89;
        letter-spacing: 0.4px;
        text-align: left;
        color: var(--white);
        @media screen and (max-width: 768px) {
          display: flex;
          align-items: center;
          span {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
