.ek-ekran-ssb-step4 {
  padding-inline: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__header {
    font-family: 'Hind', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #f3bc45;

    &__img {
      margin: 30px 0;
      text-align: center;
    }
  }

  &__description {
    font-family: 'Hind', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 30px;

    div {
      margin-top: 10px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}