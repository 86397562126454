@import "../../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-basvuru-islemleri-giris-oncesi-anasayfa {
  @include withAuthLayout;

  &__header {
    justify-content: space-between;
  }

  &__content {
    &--left {
      &__prev {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 30px;
        width: fit-content;
        &:hover {
          cursor: pointer;
        }
      }

      &__body {
        padding-inline: 30px;

        &__info {
          margin-top: 30px;

          .infoComponent {
            margin-top: 0;

            &__info {
              padding: 12px;
              background-color: #fff;
              display: flex;
              align-items: center;
              border-radius: 3px;
              &__infoArea {
                display: flex;
                align-items: flex-start;

                &__icon {
                  width: 24px;
                  margin: 0 !important;
                  padding: 2px;
                }

                &__text {
                  font-family: Hind;
                  font-size: 15px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.6;
                  letter-spacing: normal;
                  text-align: left;
                  color: var(--lacivert);
                  margin-right: -8px;
                  padding: 0px;
                  margin-left: 15px !important;
                  width: 269px;
                }
              }

              button {
                margin-top: 0px;
                margin-left: 40px;
              }
            }
          }
        }

        &__cardArea {
          display: grid;
          grid-template-columns: 332px 332px;
          gap: 30px;

          &__card {
            background-color: #f3f3f3;
            padding: 20px;
            border-radius: 10px;
            margin-top: 30px;

            &__header {
              font-family: Hind;
              font-size: 25px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.2;
              letter-spacing: normal;
              text-align: left;
              color: var(--turuncu);
            }

            &__description {
              font-family: Hind;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--pure-black);
              margin-top: 20px;
            }

            ul {
              margin-top: 20px;
              font-family: Hind;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--pure-black);
              padding-inline: 30px;
            }

            &__infoBox {
              border-radius: 10px;
              border: solid 2px var(--lacivert);
              background-color: var(--cool-grey);
              display: flex;
              align-items: center;
              padding: 20px;
              color: #444;
              // justify-content: space-evenly;

              &:hover {
                color: #444;
              }

              img {
                margin-right: 20px;
              }

              &__text {
                font-family: Hind;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                // text-align: left;
                color: var(--black);
                visibility: visible;
                @media screen and (max-width: 768px) {
                  font-size: 16px;
                }
                &__mobileText {
                  display: none;
                }

                &__boldText {
                  font-size: 22px;
                  font-weight: 600;
                  text-align: center;
                }
              }
            }

            &__button {
              margin-top: 87px;
            }
          }
        }
      }
    }

    &--right {
      &__maskot {
        background: #e8ecf2;
        border-radius: 10px;
        width: 362px;
        height: 308px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 188px;
          height: 287px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-basvuru-islemleri-giris-oncesi-anasayfa {
    &__content {
      &--left {
        &__prev {
          margin-bottom: 30px;
          width: fit-content;
        }

        &__body {
          padding-inline: 0px;

          &__cardArea {
            grid-template-columns: 1fr;
            gap: 0px;

            &__card {
              &__button {
                margin-top: 0;

                button {
                  margin-top: 20px;
                }
              }

              &__infoBox {
                &__text {
                  text-align: center;

                  &__mobileText {
                    display: inline;
                  }
                }
              }
            }
          }

          &__info {
            .infoComponent__info {
              display: block;

              &__infoArea__text {
                width: 100%;
              }

              button {
                display: block;
                margin-top: 10px;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-device-width: 668px) and (max-device-width: 768px) {
  .customer-operations-basvuru-islemleri-giris-oncesi-anasayfa__content--left__body__cardArea__card__infoBox {
    gap: 35% !important;
  }
}

@media screen and (min-device-width: 468px) and (max-device-width: 668px) {
  .customer-operations-basvuru-islemleri-giris-oncesi-anasayfa__content--left__body__cardArea__card__infoBox {
    gap: 30% !important;
  }
}

@media screen and (max-device-width: 468px) {
  .customer-operations-basvuru-islemleri-giris-oncesi-anasayfa__content--left__body__cardArea__card__infoBox {
    gap: 0px !important;
  }
}
