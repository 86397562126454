// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.customer-register-page {
  .customerContract {
    width: 100%;
    margin-top: 30px;
    &__text {
      font-family: "Hind", sans-serif !important;
      font-size: 16px;
      font-weight: 500;
      @media (max-width: 600px) {
        font-size: 16px;
        line-height: 1.5;
      }
    }
    &__prev {
      margin-bottom: 30px;
      &:hover {
        cursor: pointer;
      }
      &__icon {
        margin-bottom: 2px;
      }
      &__prevBtn {
        margin-left: 10px;

        font-family: "Hind", sans-serif !important;
        font-size: 15px;
        font-weight: 600;
        @media (max-width: 600px) {
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
    @media (max-width: 600px) {
      width: 100% !important;
      margin: 30px 5px;
    }
  }
}
