.ek-ekranlar-fatura-odeme-step2 {
  padding-inline: 30px;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__header {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #f3bc45;
  }

  &__description {
    font-family: "Hind";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000;
    margin-top: 30px;

    span {
      color: #f3bc45;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  &__bills {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__button {
      display: flex;
      justify-content: center;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    &__footertext {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-top: 30px;
      color: #444444;
      width: 332px;
      margin-left: auto;
      margin-right: auto;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    &__bill {
      margin-top: 30px !important;
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 12px;
      display: flex;
      align-items: center;
      width: 332px;
      // margin: 0 auto;
      max-width: 332px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &__right {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;

        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &__left {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #486072;
          }

          &__right {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: right;
            color: #486072;
          }
        }

        &__item2 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #fff;
          border-radius: 10px;
          padding: 10px;

          &__left {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #486072;
          }

          &__right {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: right;
            color: #486072;
          }
        }
      }
    }

    &__contactsection {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 332px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
      color: #444;

      img {
        width: 24px;
        height: 24px;
      }

      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;

        span {
          font-size: 22px;
          font-weight: 600;

          a {
            color: #444;
          }
        }
      }
    }
  }
}