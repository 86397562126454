.customer-operations-basvuru-islemleri-odeme-sonuc-step1 {
  padding-inline: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__body {
    &__card {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;


      &__inputArea {
        display: grid;
        grid-template-columns: 317px 317px;
        margin-top: 20px;
        gap: 20px;

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }

        &__input {
          display: flex;
          flex-direction: column;

          &__label {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: #486072;
            margin-bottom: 10px;
          }

          &__text {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #486072;
            background: #ffffff;
            border-radius: 3px;
            padding: 10px 15px;
            height: 44px;
          }
        }
      }

      .customSelect img {
        z-index: 0;
      }

      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #f3bc45;
        margin-bottom: 20px;
      }

      &__header2 {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
          color: #f3bc45;
        }
      }

      &__button {
        button {
          margin-top: 20px;
        }
      }

      &__notes {
        background-color: #fff;
        padding: 10px 15px;
        margin-top: 20px;
        border-radius: 3px;
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: flex-start;
        color: #486072;
        max-height: 80px;
        overflow-y: auto;

        @media screen and (max-width: 768px) {
          max-height: 140px;
        }

      }

      &__notesSms {
        background-color: #fff;
        padding: 10px 15px;
        margin-top: 20px;
        border-radius: 3px;
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        // display: flex;
        // align-items: flex-start;
        color: #486072;
        max-height: 108px;
        overflow-y: auto;

        @media screen and (max-width: 768px) {
          max-height: 178px;
          overflow-x: hidden;
          word-wrap: break-word;

        }

      }

      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #e8ecf2;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #486072;
        border-radius: 3px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      &__result {
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 20px;

        span {
          color: #f3bc45;
          font-weight: 600;
        }
      }

      &__upload {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .uploadFiles {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fff;
          border-radius: 3px;
          padding: 10px;

          img {
            cursor: pointer;
          }

          div {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #486072;
          }
        }

        .uploadFiles1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fff;
          border-radius: 3px;
          padding: 10px;

          img {
            cursor: pointer;
          }

          div {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #486072;
          }
        }
      }

      &__description {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        gap: 10px;

        &__input {
          width: 317px;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          .customInput {
            width: 100%;

            input {
              margin-bottom: 0px;
              width: 100%;
              border: none
            }
          }
        }
      }

      &__activeApplications {
        background: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 12px;
        margin-top: 20px;

        hr {
          border: 0.5px solid #f3f3f3 !important;
          margin: 7px 0;
        }

        .type {
          display: flex;
          justify-content: space-between;
          margin: 3px 0;

          img {
            margin-top: -2px;
            margin-right: -5px;
            margin-left: 5px;
            cursor: pointer;
          }

          &__left {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: #486072;
          }

          &__right {
            font-family: "Hind";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            text-align: right;
          }

          &__right2 {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            text-align: right;
            color: #f3bc45;
            // min-width: 120px;
          }
        }
      }
    }

    &__secretButton {
      display: none;

      @media screen and (max-width: 1180px) {
        display: flex;
      }
    }

    &__info {
      .infoComponent {
        margin-top: 30px;

        &__info {
          padding: 2px;
          display: flex;
          // justify-content: center;
          align-items: center;
          background-color: #fff;
          border-radius: 3px;

          &__detail {
            display: flex;
            align-items: flex-start;

            &__icon {
              width: 24px;
              padding: 12px;
              height: 24px;
              margin-right: 14px;

              &__img {
                width: 20px;
                height: 20px;
              }
            }
          }

          button {
            margin: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-basvuru-islemleri-odeme-sonuc-step1 {
    &__body {
      &__card {
        &__description {
          flex-direction: column;
          width: 100%;

          &__inputs {
            &__input {
              width: 100% !important;
            }
          }
        }

        &__activeApplications {
          img {
            margin-left: 0px;
            margin-right: 0px;
          }
        }

        &__upload {
          .uploadFiles {
            div {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}