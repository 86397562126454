@import "../../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-after-login-elektrik-kesintileri-farkli-adreste-kesinti-bildir {
  @include withAuthLayout;

  &__header {
    justify-content: space-between;
  }

  &__content {
    &--right {
      margin-top: -30px;

      .progressBox {
        height: auto;
        padding-bottom: 30px;
      }
    }
  }
}