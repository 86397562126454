$question-blue: url("../../assets/icons/question-blue.svg");
$user-blue: url("../../assets/icons/user-blue.svg");
$search-blue: url("../../assets/icons/search-blue.svg");
$counter-blue: url("../../assets/icons/counter-blue.svg");

.home-before-login {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 36px;

    &__headerText {
      font-family: Hind;
      font-size: 34px;
      font-weight: 600;
      text-align: center;
      color: #444;
    }

    &__text {
      font-family: Hind;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      color: #444;
      width: 693px;
      margin-bottom: 40px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &__map {
    margin-bottom: 30px;
    position: relative;
    border-radius: 10px;
    background-color: #f3f3f3;

    .leaflet-container {
      border-radius: 10px;
    }

    .leaflet-touch .leaflet-control-layers,
    .leaflet-touch .leaflet-bar {
      display: none !important;
    }

    .leaflet-popup-tip-container {
      bottom: -20px;
    }

    .leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
      bottom: 0px !important;

      @media screen and (max-width: 768px) {
        width: max-content;
        left: -150px;
      }
    }

    .leaflet-popup-content {
      margin: 0 !important;
      padding: 12px;
      width: unset !important;
    }

    .leaflet-container a.leaflet-popup-close-button {
      display: none !important;
    }

    .leaflet-popup-content-wrapper {
      border-radius: 3px;
      box-shadow: 0 0 20px 0 rgba(163, 175, 199, 0.2);

      @media screen and (max-width: 768px) {
        width: calc(100% - 10px) !important;
      }
    }

    // .leaflet-popup {
    //   width: 315px;
    //   height: 200px;
    // }
    &__popup {
      display: flex;
      flex-direction: column;
      position: relative;

      &__icon {
        position: absolute;
        right: 12px;
        top: 12px;

        @media screen and (max-width: 768px) {
          right: 16px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &__header {
        font-family: Hind;
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: normal;
        text-align: left;
        color: var(--black);
      }

      &__description {
        font-family: Hind;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: left;
        color: var(--black);
        margin: 10px 0;
      }

      &__address {
        font-family: Hind;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: var(--black);
        margin-top: 10px;
      }

      hr {
        background-color: #dadada;
      }

      &__button {
        button {
          margin-top: 10px;
        }
      }
    }

    &__card {
      position: absolute;
      right: 2%;
      top: 5%;
      z-index: 500;
      border-radius: 3px;
      padding: 20px;
      width: 348px;
      background: #fff;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        width: 90%;
        right: 5%;
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 44px;
      }

      .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        margin-top: 8px;
      }

      &__text {
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        line-height: 1.2;
        text-align: left;
        color: #486072;
        margin-bottom: 30px;

        @media screen and (max-width: 768px) {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }

      &__selectArea {
        position: relative;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0px;
        }

        .customSelect {
          width: 308px;
        }

        .ant-select-borderless .ant-select-selector {
          border: 1px solid #e8ecf2 !important;
        }
      }
    }

    .leaflet-control-attribution {
      display: none;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__section {
      display: flex;
      flex-direction: column;
      max-width: 1140px;

      @media screen and (max-width: 1199px) {
        width: 100%;
        justify-content: center;
      }

      &__img {
        height: 228px;
        object-fit: cover;
        width: 558px;
        border-radius: 10px;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);

        @media screen and (max-width: 1200px) {
          width: 100%;
          height: 372px;
          margin: 0 auto;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &__header {
        &__headerText {
          display: inline-block;
          font-family: Hind;
          font-size: 20px;
          font-weight: 600;
          line-height: 1.5;
          text-align: left;
          color: #444;
          margin-left: 20px;
          border-bottom: 5px solid #f0b143;

          @media screen and (max-width: 768px) {
            margin-bottom: 115px;
            margin-top: 0px;
          }
        }
      }

      &__rect {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__text {
          font-family: Hind;
          font-size: 18px;
          line-height: 1.33;
          text-align: left;
          color: #444;
          margin-top: -64px;
          z-index: 2;
          padding: 8px 25px;
          width: 480px;
          border-radius: 5px 5px 0 0;
          background: #fff;
          padding-inline: 25px;

          @media screen and (max-width: 768px) {
            width: 100%;
            padding: 0px;
            margin-top: -85px;
            margin-bottom: 20px;
            padding-left: 20px;
          }

          @media (min-width: 768px) and(max-width:1024px) {
            padding: 9px 25px;

          }
        }

        &__bottom {
          display: flex;
          background-image: linear-gradient(101deg, #fcee50 -10%, #eea540 112%);

          &:hover {
            cursor: pointer;
          }

          height: 110px;
          width: 480px;
          border-radius: 0 0 5px 5px;
          padding: 20px;
          margin-bottom: 70px;
          color: #fff;

          @media screen and (max-width: 768px) {
            width: 100% !important;
            margin-bottom: 30px;
            border-radius: 10px;
          }

          &__text {
            display: flex;
            flex-direction: column;
            margin-left: 30px;
            justify-content: center;

            &__header {
              margin-bottom: 15px;
              font-family: Hind;
              font-size: 25px;
              font-weight: 600;
              line-height: 1.2;
              text-align: left;

              @media screen and (max-width: 768px) {
                font-size: 20px;
              }
            }

            &__footer {
              display: flex;
              align-items: center;

              img {
                margin-top: -3px;
              }

              span {
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                line-height: 1.6;
                letter-spacing: 0.3px;
                text-align: center;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    &__enerjisa {
      position: relative;
      width: calc(100% - 41px);
      height: 223px;
      z-index: 0;
      border-radius: 120px 0 0 120px;
      margin: 58px 30px 140px 41px;
      padding: 46px 125px 47px 367px;
      background: linear-gradient(90deg,
          rgba(255, 255, 255, 0) 53.81%,
          #ffffff 99.28%),
        linear-gradient(131.92deg,
          rgba(252, 238, 80, 0.75) -5.41%,
          rgba(238, 165, 64, 0.75) 107.64%);

      @media screen and (max-width: 1100px) {
        padding-left: 150px;
        padding-right: 80px;
      }

      @media screen and (max-width: 1100px) {
        display: none;
      }

      &__maskot {
        position: absolute;
        width: 590px;
        transform: scale(-1, 1);
        z-index: -4;
        left: -192px;
        top: -264px;
      }

      &__content {
        display: flex;
        flex-direction: column;

        &__text {
          font-family: Hind;
          width: 593px;
          font-size: 20px;
          font-weight: 600;
          line-height: 1.5;
          text-align: center;
          color: #444;

          @media screen and (max-width: 1100px) {
            width: 100%;
          }
        }

        &__links {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;

          img {
            margin-right: 18px;

            &:hover {
              cursor: pointer;
            }
          }
        }


        &__ydalinks {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;

          img {
            margin-right: 18px;

            &:hover {
              cursor: default;
            }
          }
        }
      }
    }

    &__enerjisa-mobile {
      font-family: "Hind", sans-serif !important;
      width: 100%;
      background-image: url("../../assets/images/bg-maskot.png");
      margin-bottom: 50px;
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: contain;

      &__content {
        width: 100%;
        padding-top: 350px;

        @media screen and (max-width: 350px) {
          padding-top: 250px;
        }

        &__text {
          display: block;
          margin: 0 auto;
          width: 320px;
          line-height: 1.5;
          padding-inline: 30px;
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          color: #444;

          @media screen and (max-width: 350px) {
            width: 220px;
          }
        }

        &__links {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            margin-bottom: 20px;
          }
        }

        &__ydalinks {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            margin-bottom: 20px;

            &:hover {
              cursor: default;
            }
          }
        }
      }

      @media screen and (min-width: 1100px) {
        display: none;
      }
    }

    &__slider {
      position: relative;
      margin-bottom: 63px;
      width: 100%;

      &__text {
        display: block;
        width: 100%;
        font-family: Hind;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.18;
        text-align: center;
        margin-bottom: 15px;
      }

      .bg {
        position: absolute;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg,
            rgba(255, 255, 255, 1) 15%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0) 100%);
        height: 401px;
        width: 10%;
        top: 0;
        z-index: 99;
        left: 0;
      }

      .bg2 {
        position: absolute;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 1) 85%);
        height: 401px;
        width: 10%;
        top: 0;
        z-index: 99;
        right: 0;
      }

      .swiper-button-prev {
        background: rgb(243, 188, 69);
        background: linear-gradient(90deg,
            rgba(243, 188, 69, 1) 70%,
            rgba(243, 188, 69, 0.2) 100%);
        width: 55px;
        height: 50px;
        position: absolute;
        left: calc(25% - 20px);
        transform: translateX(-50%);
        border-radius: 50% 0 0 50%;

        &::after {
          display: none;
        }

        img {
          transform: rotate(180deg);
        }
      }

      .swiper-button-next {
        position: absolute;
        right: calc(25% - 20px);
        transform: translateX(50%);
        background: rgb(243, 188, 69);
        background: linear-gradient(90deg,
            rgba(243, 188, 69, 0.2) 0%,
            rgba(243, 188, 69, 1) 30%);
        width: 55px;
        height: 50px;
        border-radius: 0 50% 50% 0;

        &::after {
          display: none;
        }
      }

      @media screen and (max-width: 768px) {
        display: none;
      }

      .swiper-wrapper {
        font-family: "Hind", sans-serif !important;
        height: auto;
        padding-block: 15px;
      }

      .swiper-slide {
        width: calc(50% - 15px) !important;
        height: 410px;
        opacity: 0.3;
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
          width: 100%;
          border-radius: 10px;
          height: 300px;
          object-fit: cover;
          overflow: hidden;
        }

        .textContainer {
          height: 120px;
          overflow: hidden;
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .text {
          font-size: 25px;
          font-weight: 600;
          color: #444;
          line-height: 1.4;
          overflow: hidden;

        }


        .link {
          display: block;
          color: #f3bc45;
          font-size: 15px;
          font-weight: 600;
          margin-top: 12px;
          height: 36px;
          cursor: pointer;

          &::after {
            font-family: enerjisaohm !important;
            content: "\f152";
            font-size: 24px;
            font-weight: 300;
            position: absolute;
            margin-top: -8px;
            margin-left: 10px;
          }
        }


      }

      .swiper-slide-active {
        opacity: 1;
        box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4),
          -5px -5px 20px 0 #fff;
        border-radius: 10px !important;
      }
    }

    &__slider2 {
      margin-bottom: 50px;
      width: 100%;

      .swiper-wrapper {
        font-family: "Hind", sans-serif !important;
        height: auto;
        padding-block: 15px;
      }

      &__text {
        display: block;
        width: 100%;
        font-family: Hind;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.18;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 20px;
      }

      .swiper-slide {
        width: calc(85%) !important;
        opacity: 0.3;
        padding: 12px;
        height: 335px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 450px) {
          height: 315px;
        }

        @media screen and (max-width: 400px) {
          height: 300px;
        }

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          overflow: hidden;
          border-radius: 10px;
        }

        .textContainer {
          height: 120px;
          overflow: hidden;
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .text {
          font-size: 18px !important;
          font-weight: 600;
          color: #444;
          line-height: 1.4;
          max-height: 80px;
          overflow: hidden;

        }


        .link {
          display: block;
          color: #f3bc45;
          font-size: 15px;
          font-weight: 600;
          margin-top: 12px;
          cursor: pointer;

          &::after {
            font-family: enerjisaohm !important;
            content: "\f152";
            font-size: 24px;
            position: absolute;
            margin-top: -8px;
            margin-left: 10px;
            font-weight: 300;
          }
        }
      }

      .swiper-slide-active {
        opacity: 1;
        box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4),
          -5px -5px 20px 0 #fff;
      }

      .swiper-button-next {
        display: none;
      }

      .swiper-button-prev {
        display: none;
      }

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    &__afterSlider {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
      width: 100%;
      height: 60px;
      border-radius: 10px;
      background: radial-gradient(at top left, #fcee50 -10%, #eea540 112%);
      margin-bottom: 30px;
      padding: 1px;

      @media screen and (max-width: 992px) {
        grid-template-columns: 1fr;
        height: calc(100% + 5px);
      }

      &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        img {
          margin-top: -4px;
        }

        &:hover {
          cursor: pointer;
          background-color: #fff;
          color: #486072;
          border-radius: 10px;
          padding-right: 10px;
          padding-left: 3px;

          .home-before-login__body__afterSlider__link__text {
            color: #486072;
          }

          .home-before-login__body__afterSlider__link__questionIcon {
            content: $question-blue;
          }

          .home-before-login__body__afterSlider__link__searchIcon {
            content: $search-blue;
          }

          .home-before-login__body__afterSlider__link__counterIcon {
            content: $counter-blue;
          }

          .home-before-login__body__afterSlider__link__userIcon {
            content: $user-blue;
          }
        }

        @media screen and (max-width: 992px) {
          display: block;
          width: 100%;
          // border-bottom: 1px solid #fff;
          padding: 5px;
          height: auto;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0px;
          }
        }

        &__text {
          font-family: Hind;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0.3px;
          text-align: left;
          color: #fff;
          margin-left: 10px;

          &:hover {
            color: #486072;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;

    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;

    .unvisible {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &__hr {
      width: 100%;
      height: 1px;
      background-color: #486072;
      margin-top: 20px;
      opacity: 0.5;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: 768px) {
        justify-content: center;
      }

      .ydasocial {
        div {
          &:hover {
            cursor: default;
          }
        }

        img {
          &:hover {
            cursor: default;
          }
        }
      }

      &__social {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area: CallCenter;

        .linkdin {
          width: 14px;
          height: 13.7px;
        }

        .twitterIcon {
          img {
            width: 18px;
            height: 18px;
            margin-left: 2px;
          }
        }

        div {
          margin-left: 13px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #486072;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        img {
          width: 20px;
          height: 20px;

          &:hover {
            cursor: pointer;
          }
        }

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &__info {
        display: flex;
        justify-content: space-between;

        &__icon {
          display: none;

          @media (max-width: 768px) {
            display: block;
            width: 50px;
            height: 50px;
            margin-right: 14px;
          }
        }

        &__logo {
          width: 200px;
          height: 38px;
          object-fit: contain;

          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        &__hr {
          height: 50px;
          background-color: #486072;
          border-left: 1px solid #486072;
          margin-left: 40px;
          margin-right: 40px;
          opacity: 0.5;

          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        &__textArea {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          resize: none;

          @media screen and (max-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }

          &__small {
            font-family: Hind;
            font-size: 15px;
            font-weight: 500;
            text-align: center;
            color: #486072;
          }

          &__big {
            // font-family: Hind;
            // font-size: 18px;
            // font-weight: normal;
            // font-stretch: normal;
            // font-style: normal;
            // line-height: 1.33;
            // letter-spacing: normal;
            // text-align: center;
            // font-weight: 600;

            font-family: Hind;
            font-size: 25px;
            font-weight: 600;
            line-height: 1;
            text-align: center;
            color: #486072;

            a {
              color: #444 !important;

              &:hover {
                color: #444 !important;
              }
            }

            span {
              color: #444 !important;
            }
          }
        }
      }
    }

    .ydadownload {
      img {
        &:hover {
          cursor: default;
        }
      }
    }

    &__download {
      display: flex;
      align-items: center;
      margin-top: 20px;

      img {
        margin-right: 20px;
        width: 150px;

        &:hover {
          cursor: pointer;
        }
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &__body {
      &__content {
        width: 100%;
        display: grid;
        grid-template-columns: 4fr 4fr 3fr;
        grid-template-rows: 1fr auto;
        grid-row-start: auto;
        padding: 30px 0;

        grid-template-areas:
          "Link1 Link5 Link9"
          "Link2 Link6 Link10"
          "Link3 Link7 Link11"
          "Link4 Link8 Link12";

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr 1fr;
          grid-template-areas:
            "Link1 Link6"
            "Link2 Link7"
            "Link3 Link8"
            "Link4 Link9"
            "Link5 Link10"
            "Link11 Link12";

          grid-row-gap: 30px;
        }

        .link-1 {
          grid-area: Link1;
        }

        .link-2 {
          grid-area: Link2;
        }

        .link-3 {
          grid-area: Link3;
        }

        .link-4 {
          grid-area: Link4;
        }

        .link-5 {
          grid-area: Link5;
        }

        .link-6 {
          grid-area: Link6;
        }

        .link-7 {
          grid-area: Link7;
        }

        .link-8 {
          grid-area: Link8;
        }

        .link-9 {
          grid-area: Link9;
        }

        .link-10 {
          grid-area: Link10;
        }

        .link-11 {
          grid-area: Link11;

          // @media screen and (max-width: 768px) {
          //   font-family: Hind;
          //   font-weight: 500;
          //   font-size: 15px;
          //   line-height: 1.33;
          // }
        }

        .link-12 {
          grid-area: Link12;
        }

        &__link {
          font-family: Hind;
          font-size: 20px;
          font-weight: 500;
          line-height: 2;
          color: #000;

          &:hover {
            cursor: pointer;
            color: #000;
          }

          @media screen and (max-width: 768px) {
            margin-right: 0px;
            font-size: 15px;
            line-height: 1.33;
          }
        }
      }
    }

    &__copyright {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 25px;
      margin-bottom: 10px;

      @media screen and (max-width: 768px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &__logo {
        // margin-left: -50px;
        display: flex;
        justify-content: space-between;
        gap: 30px;
      }

      &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Hind;
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
        color: #000;

        &__one {
          margin-right: 10px;
        }

        &__two {
          margin-left: 10px;
        }

        @media screen and (max-width: 768px) {
          flex-direction: column;
          margin-left: 0px;
          margin-bottom: 10px;

          &__one {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  // .sabanci-footer {
  //   position: relative;
  //   height: 2px;
  //   width: 100%;
  //   background-color: #004b93;
  //   margin-top: 58px;
  //   margin-bottom: 41px;

  //   img {
  //     position: absolute;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //   }

  //   @media screen and (max-width: 768px) {
  //     margin-top: 30px;
  //     margin-bottom: 31px;
  //     background: #fff;
  //   }
  // }
}