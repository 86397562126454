.more-info-language-support {
  &__prev {
    margin-bottom: 30px;
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__modal {
    display: flex;
    flex-direction: column;
    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }
    &__button {
      width: 100%;
    }
    &__contact {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      img {
        width: 24px;
        height: 24px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 38px;
        margin-bottom: 38px;
      }
      span {
        font-family: Hind;
        font-size: 18px;
        // font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        font-weight: 600;
        a {
          color: #444;
        }
      }
    }
  }
}
