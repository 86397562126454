.customer-register-page {
  .fileUpload {
    margin-top: 30px;

    .webcamModalCustomerRegister {
      .modal2__content {
        max-height: max-content !important;
      }
    }

    .modal__content {
      .modal__content__header {
        margin-bottom: 30px !important;
      }

      .modal__content__body {
        margin-bottom: 10px;
        margin-top: -15px;
      }
    }

    @media (max-width: 600px) {
      margin-left: 0px;
    }

    .active {
      margin-top: 0px;
    }

    &__prev {
      &__text {
        margin-left: 10px;
        font-weight: 600;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__text {
      margin-top: 30px;
      font-family: Hind;
      font-size: 16px;
      line-height: 1.5;
    }

    &__btn {
      margin-top: 40px;
      margin-bottom: 30px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    &__boxes {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      @media (max-width: 780px) {
        flex-wrap: wrap;
      }

      &__box {
        height: auto;
        width: 332px;
        border-radius: 10px;
        background: #f3f3f3;
        margin-top: 30px;
        // margin-right: 30px;

        @media (max-width: 780px) {
          // width: 100% !important;
          margin-top: 30px;
          margin-right: 0px;
        }

        &__body {
          display: flex;
          flex-direction: column;
          padding: 20px;

          &__headerText {
            font-family: Hind;
            font-size: 25px;
            font-weight: 600;
            line-height: 1.2;
          }

          &__bodyText {
            margin-top: 20px;
            font-family: 'Hind', sans-serif;
            font-size: 16px;
            // font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: var(--pure-black);
            font-weight: 500;

            span {
              // &:first-child {
              //   color: #f3bc45;
              // }
              // &:last-child {
              //   font-size: 14px;
              //   font-weight: 600;
              //   line-height: 1.43;
              // }
            }
          }

          &__image {
            border-radius: 3px;
            position: relative;
            margin-top: 30px;
            width: 292px;
            height: 292px;

            @media (max-width: 600px) {
              width: 100%;
              height: 100%;
            }

            @media (max-width: 780px) {
              width: 100%;
              height: 100%;
            }

            img:first-child {
              border-radius: 3px;
              width: 292px;
              height: 292px;
              object-fit: cover;

              @media (max-width: 600px) {
                width: 100%;
                height: 100%;
              }
            }

            img:nth-child(2) {
              @media (max-width: 600px) {
                width: 45px;
                height: 45px;
                margin-right: 20px;
                bottom: 26px;
              }
            }

            &__searchIcon {
              position: absolute;
              bottom: 10px;
              right: 10px;
              width: 34px;
              height: 34px;
              background-size: cover;

              &:hover {
                cursor: pointer;
              }
            }

            &__closeIcon {
              position: absolute;
              top: 10px;
              right: 5px;
              width: 34px;
              background-color: #f3f3f3;
              border-radius: 50%;
              padding: 3px;
              height: 34px;
              background-size: cover;

              &:hover {
                cursor: pointer;
              }

              @media (max-width: 600px) {
                display: none;
              }
            }
          }

          &__image__yda {
            border-radius: 3px;
            position: relative;
            margin-top: 30px;
            width: 292px;
            height: 292px;
            background-color: white;

            @media (max-width: 600px) {
              width: 100%;
              height: 100%;
            }


            img:first-child {
              border-radius: 3px;
              width: 292px;
              height: auto;
              object-fit: cover;

              @media (max-width: 600px) {
                width: 100%;
                height: 100%;
              }
            }

            img:nth-child(2) {
              @media (max-width: 600px) {
                width: 45px;
                height: 45px;
                margin-right: 20px;
                bottom: 26px;
              }
            }

            &__searchIcon {
              position: absolute;
              bottom: 10px;
              right: 10px;
              width: 34px;
              height: 34px;
              background-size: cover;

              &:hover {
                cursor: pointer;
              }
            }

            &__closeIcon {
              position: absolute;
              top: 10px;
              right: 5px;
              width: 34px;
              background-color: #f3f3f3;
              border-radius: 50%;
              padding: 3px;
              height: 34px;
              background-size: cover;

              &:hover {
                cursor: pointer;
              }

              @media (max-width: 600px) {
                display: none;
              }
            }
          }

          &__photoText {
            display: flex;
            justify-content: center;
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.3px;
            margin-top: 40px;
          }

          &__btn {
            position: relative;

            label {
              position: absolute;
              margin-top: 30px;
              width: 292px;
              height: 50px;
              z-index: 0;
              top: 0px;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .hiddenInput {
    display: none;
  }
}

.boldText {
  font-weight: 600;
}

.bold-yellow {
  font-weight: 600;
  color: #f3bc45;
  text-decoration: underline;
}