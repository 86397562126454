@import "../../../assets/styles/layouts/authLayout.scss";
.d2d-goruntule-ek-ekran {
  @include authLayout;
  &__whiteBody {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 136px;
    @media screen and (max-width: 768px) {
      padding-inline: 30px;
      margin-top: 0px;
    }
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        margin-top: 80px;
      }
      &__left {
        @media screen and (max-width: 768px) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        &__desktopMaskot {
          margin-top: -100px;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
        &__mobilMaskot {
          display: none;
          @media screen and (max-width: 768px) {
            display: block;
            margin-top: -130px;
          }
        }
      }
      &__right {
        display: flex;
        flex-direction: column;
        width: 352px;
        &__header {
          font-family: "Hind", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 35px;
          letter-spacing: 0.02em;
          color: #486072;
          width: 292px;
          @media screen and (max-width: 768px) {
            width: 100%;
            text-align: center;
            margin-top: -30px;
          }
        }
        &__description {
          font-family: "Hind", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-top: 30px;
          width: 292px;
          @media screen and (max-width: 768px) {
            margin: 0 auto;
            margin-top: 30px;
            text-align: center;
          }
        }
        &__recaptcha {
          @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &__recaptchaclass {
            margin-top: 30px;
          }
        }
        &__showMore {
          display: flex;
          flex-direction: column;
          &__description {
            margin-top: 30px;
            font-family: "Hind", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
            width: 292px;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
    &__noPageValidity {
      padding: 30px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      &__body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
          gap: 0px;
        }
        &__left {
          @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          &__desktopmaskot {
            display: block;
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
          &__mobilmaskot {
            display: none;
            @media screen and (max-width: 768px) {
              display: flex;
              width: 85%;
            }
          }
          &__mobilHeader {
            display: none;
            @media screen and (max-width: 768px) {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: "Hind", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 28px;
              line-height: 35px;
              text-align: center;
              letter-spacing: 0.02em;
              color: #486072;
            }
          }
        }
        &__right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 558px;
          margin-left: auto;
          padding-inline: 30px;
          @media screen and (max-width: 768px) {
            width: 100%;
            padding-inline: 0px;
            align-items: center;
          }
          &__header {
            font-family: "Hind", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 35px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #486072;
            @media screen and (max-width: 768px) {
              margin-top: 30px;
            }
          }
          &__description {
            margin-top: 30px;
            font-family: "Hind", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #444444;
          }
          &__button {
            width: 100%;
          }
          &__button2 {
            button {
              span {
                @media screen and (max-width: 768px) {
                  max-width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
}
