.customer-operations-mybills-details {
  .sendReceiptEmailModal {
    .modal2__content {
      max-height: 530px;
    }
  }

  .sendReceiptResponseModal {
    .customer-operations-odeme-islemleri-fatura-odeme-yontemi__modal__description {
      margin-top: 0px;
    }
  }

  .infoComponent {
    &__info {
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;

      &__detail {
        display: flex;
        align-items: flex-start;

        &__icon {
          width: 24px;
          padding: 12px;
          height: 24px;
          margin-right: 14px;

          &__img {
            width: 20px;
            height: 20px;
          }
        }
      }

      button {
        margin: 0;
      }

      .randevu-detay__info__infoButton2 {
        padding: 9px 10px;
      }
    }

    &:last-child {
      margin: 30px 0;
    }
  }

  .higherTooltip__left__span__top__tooltip {
    top: -20px !important;
    right: -10px;
  }

  .higherTooltip__left__span__top__tooltip__content {
    @media screen and (min-width: 768px) {
      width: auto !important;
      display: flex;
      align-items: center;
    }
  }

  .higherTooltip__left__span__top__tooltip__content__text {
    @media screen and (min-width: 768px) {
      width: auto !important;
      word-wrap: normal;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  hr {
    margin-bottom: 20px;
    border: 1px solid #dadada;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    padding-inline: 30px;
    margin-top: 30px;
    display: flex;
    gap: 30px;

    &__card {
      width: 332px;
      margin-bottom: 30px;

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;

        &__billsArea {
          display: flex;
          flex-direction: column;
          width: 340px;
          margin-top: 10px;
          margin-bottom: 30px;

          &__options {
            display: flex;
            flex-direction: row;
            gap: 20px;
            justify-content: center;

            &__item {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0.02em;
              color: #ffffff;
              border-radius: 10px;
              padding: 5px 10px;
              background: linear-gradient(131.92deg,
                  #fcee50 -5.41%,
                  #eea540 107.64%);
              border: none;
              cursor: pointer;

              &__spinner {
                img {
                  width: 20px;
                  margin-right: 10px
                }
              }

              a {
                all: unset;
              }

              img {
                margin-top: -2px;
                margin-left: 10px;
              }
            }
          }

          &__bills {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 20px;

            &__billItem {
              padding: 16px 12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;
              font-size: 13px;
              width: 105px;
              height: 105px;
              border-radius: 50%;
              border: 5.4px solid transparent;
              background: linear-gradient(#fff 0 0) padding-box,
                linear-gradient(to right, #fcee50, #eea540) border-box;

              &__headerText {
                width: 90px;
                font-family: Hind;
                font-size: 13px;
                font-weight: 600;
                line-height: 1.25;
                text-align: center;
                color: #444;
              }

              &__detailText {
                font-family: Hind;
                font-size: 15px;
                line-height: 1.13;
                text-align: center;
                color: #444;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }

    .active {
      height: max-content;
    }

    .active2 {
      margin-top: -90px !important;
    }

    &__card2 {
      width: 332px;
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 30px;

      &__final {
        display: flex;
        font-family: "Hind";
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        border: 1px solid #f3bc45;
        border-radius: 10px;
        padding: 12px;

        span {
          font-weight: 600;
        }

        img {
          margin-right: 12px;
          margin-top: -2px;
        }
      }

      &__content {
        margin-bottom: 20px;

        hr {
          margin-bottom: 20px;
          border: 1px solid #dadada;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &__header {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #444444;
          margin-bottom: 10px;

          .higherTooltip__left__span__top__tooltip__content {
            @media screen and (max-width: 768px) {
              top: 50px;
              width: calc(100vw - 65px);
            }

            @media screen and (max-height: 768px) and (min-width: 768px) {
              width: calc(100vw - 60vw);
              top: 50px;
            }
          }

          .higherTooltip__left__span__top__tooltip {
            &:after {
              top: 30px;

              @media screen and (max-width: 768px) {
                top: 42px;
              }

              @media screen and (max-height: 768px) and (max-width: 1200px) {
                top: 42px;
              }
            }
          }

          img {
            margin-top: -4px;
          }
        }

        &__detail {
          font-family: "Hind";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: #444444;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &__text {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
          color: #f3bc45;
        }

        &__moreInfo {
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          span {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            line-height: 1.6;
            letter-spacing: 0.3px;
            text-align: center;
            color: #f3bc45;
            margin-right: 10px;
          }
        }
      }

      &__bottomText {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--pure-black);

        span {
          display: inline-block;
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.3px;
          text-align: center;
          color: var(--turuncu);

          &:hover {
            cursor: pointer;
          }
        }

      }
    }

    &__card3 {
      width: 332px;
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 10px;
      height: fit-content;
      margin-bottom: 30px;


      &__content {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &__header {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #444444;
          margin-bottom: 10px;
        }

        &__detail {
          font-family: "Hind";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: #444444;
        }

        &__detail2 {
          display: flex;
          gap: 10px;

          span {
            font-family: "Hind";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
          }

          i {
            color: #adb3bc;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &__text {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
          color: #f3bc45;
        }

        &__moreInfo {
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          span {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            line-height: 1.6;
            letter-spacing: 0.3px;
            text-align: center;
            color: #f3bc45;
            margin-right: 10px;
          }
        }
      }
    }

    &__card4 {
      width: 332px;
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 10px;
      height: fit-content;

      &__content {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &__header {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #444444;
          margin-bottom: 10px;
        }

        &__detail {
          font-family: "Hind";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: #444444;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &__text {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
          color: #f3bc45;
        }

        &__moreInfo {
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          span {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            line-height: 1.6;
            letter-spacing: 0.3px;
            text-align: center;
            color: #f3bc45;
            margin-right: 10px;
          }
        }
      }
    }

    &__modal {
      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-track {
        background: #E8ECF2;
        margin: 6px 0px;
      }

      ::-webkit-scrollbar-thumb {
        background: #486072;
      }

      .modal2__content {
        max-width: 700px;
        max-height: 650px;
        width: 700px;
        height: 650px;
        background-color: white;

        @media (max-width: 850px) {
          width: 95%;
          height: 500px;
          padding: 10px;
        }
      }

      .customer-operations-mybills-details-page-pdf {
        .viewer {
          display: flex;
          justify-content: space-between;
        }

        .wrapper__btn {
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: 850px) {
            margin-top: 0;

            .ohm-primaryBtn {
              margin-top: 0;
            }
          }
        }

        .rpv-core__viewer {
          margin: 10px;
          border-radius: 30px;
          width: 700px !important;
          min-height: 650px;

          @media (max-width: 850px) {
            width: 350px !important;
            height: 500px;
            min-height: 500px;
          }

          @media (max-width: 500px) {
            width: 300px !important;
            height: 500px;
            margin: 0;
          }

          .rpv-core__canvas-layer {
            width: 700px !important;
            // height: 850px !important;
            margin: 15px 20px !important;

            @media (max-width: 850px) {
              width: 400px !important;
              height: 500px;
              margin: 0;
            }

            @media (max-width: 500px) {
              width: 300px !important;
              height: 500px;
              margin: 15px 0px 15px 30px !important;

            }

            @media (max-width: 340px) {
              width: 280px !important;
              height: 500px;
            }
          }

          .core__inner-pages {
            border-radius: 3px 0 0 3px;
            overflow-y: scroll;

          }

          .rpv-core__page-layer {
            width: 700px !important;
            // height: 850px !important;
            box-shadow: none;

            @media (max-width: 850px) {
              height: 400px !important;
              width: 650px !important;
            }

            @media (max-width: 340px) {
              width: 640px !important;
              height: 500px;
            }
          }

          .rpv-core__inner-page {
            background-color: transparent !important;
            padding: 0;
            // height: 800px !important;
            width: 700px !important;
            border-radius: 10px;

          }

          .rpv-core__text-layer {
            height: 850px !important;
            width: 740px !important;

            @media (max-width: 850px) {
              width: 400px !important;
              height: 850px !important;

            }

            @media (max-width: 500px) {
              width: 300px !important;
              height: 850px;
            }
          }

          .rpv-core__inner-pages {
            overflow-x: hidden !important;
            border-radius: 3px;

            &::-webkit-scrollbar {
              width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: white;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #486072;
              border-radius: 3px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }
          }

          @media (max-width: 850px) {
            width: 400px !important;
          }
        }

      }

    }
  }

  .customer-operations-mybills__body__footer {
    padding-inline: 30px;

    &:last-child {
      padding-bottom: 50px;
    }
  }


}


@media screen and (max-width: 1180px) {
  .customer-operations-mybills-details {
    .infoComponent {
      margin-top: 0;

      &__info {
        flex-direction: column;

        button {
          display: block;
          margin-top: 0px;
          width: 100% !important;
        }

        .randevu-detay__info__infoButton {
          width: 100% !important;
          padding: 0 10px 10px 10px;

          button {
            width: 100% !important;
          }
        }

        .randevu-detay__info__infoButton2 {
          width: 100% !important;
          padding: 0 10px 10px 10px;

          button {
            width: 100% !important;
          }
        }
      }
    }

    .customer-operations-mybills__body__footer {
      width: 100% !important;

      .infoComponent {
        margin-top: 0px;

        &__info {
          &__detail {
            align-self: flex-start !important;
          }
        }
      }
    }

    &__body {
      flex-direction: column;

      &__card {
        width: 100% !important;
        margin-top: -20px;

        &__content {
          &__billsArea {
            flex-direction: column;
            width: 100% !important;
            margin-bottom: 0;
            margin-top: 0;

            &__options {
              justify-content: center;

              &__item {
                font-size: 12px;
              }
            }

            &__bills {
              margin: 0;
              width: 100% !important;
              margin-bottom: 20px;
              justify-content: center;
              gap: 20px;

              &__billItem {
                width: 102px;
                height: 102px;
                margin-top: 30px;
                padding: 12px 12px;

                &__headerText {
                  @media screen and (max-width: 400px) {
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }
      }

      &__card2 {
        width: 100% !important;
      }

      &__card3 {
        width: 100% !important;
      }

      &__card4 {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-mybills-details {
    .infoComponent {
      &__info {
        button {
          display: block;
          margin-top: 0px;
          width: 100% !important;
        }
      }
    }

    .customer-operations-mybills__body__footer {
      padding-inline: 0px;
    }

    &__prev {
      margin-bottom: 20px;
    }

    &__body {
      padding-inline: 0px;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      margin-top: 0 !important;

      .active2 {
        margin-top: 0 !important;
      }

      &__card {
        margin-top: 0px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-mybills-details {
    &__body {
      &__card {
        &__content {
          &__billsArea {
            &__bills {
              gap: 12px;

              &__billItem {
                width: 105px;
                height: 105px;
                padding: 15px 12px;

                &__headerText {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .customer-operations-mybills-details {
    &__body {
      &__card {
        &__content {
          &__billsArea {
            &__bills {
              gap: 8px;
              justify-content: space-between;

              &__billItem {
                width: 99px;
                height: 99px;

                &__headerText {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
}