.ek-ekran-e-arsiv-onay-step4 {
  margin-top: 30px;
  padding-inline: 30px;
  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }
  &__header {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #f3bc45;
  }
  &__description {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 30px;
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
