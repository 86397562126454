@import "../../assets/styles/layouts/authLayout.scss";

.guvence-bedeli {
  @include authLayout;
}

.guvence-bedeli-hesaplama {
  #price {
    scroll-margin: 20px;
  }
  .selectSelected {
    .ant-select-selection-item {
      opacity: 1 !important;
    }
  }

  .guvence-bedeli-hesaplama__body__items__price__footertext {
    margin-top: -10px !important;
  }

  .errorBox {
    margin-top: -10px !important;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__body {
    max-width: 754px;
    padding-inline: 30px;
    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }
    &__text {
      font-family: Hind;
      font-size: 16px;
      line-height: 1.5;
      color: #444;
      margin-top: 30px;
    }
    &__items {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      &__select {
      }
      &__button {
        @media screen and (max-width: 1024px) {
          width: 100% !important;
        }
        button {
          margin-top: 10px;
          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }
        }
      }
      &__price {
        padding: 20px;
        background-color: #f3f3f3;
        margin-top: 30px;
        border-radius: 10px;
        .infoComponent {
          margin-top: 10px !important;
        }
        @media screen and (max-width: 768px) {
          // display: none;
        }
        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          @media screen and (max-width: 768px) {
            flex-direction: column;
            .price {
              margin-top: 10px;
            }
          }
          span {
            &:first-child {
              font-family: Hind;
              font-size: 25px;
              font-weight: 600;
              line-height: 1.2;
              text-align: center;
              color: var(--turuncu);
            }
            &:last-child {
              font-family: Hind;
              font-size: 25px;
              line-height: 1;
              text-align: center;
              color: #444;
            }
          }
        }
        &__content {
          .infoComponent {
            margin-top: 20px;
          }
        }
        &__footertext {
          margin-top: 20px;
          font-family: Hind;
          font-size: 16px;
          line-height: 1.5;
          text-align: center;
          color: #444;
        }
      }
      &__input {
        input {
          width: 332px;
          @media screen and (max-width: 768px) {
            width: 100% !important;
          }
        }
      }
    }
  }
  &__modal2 {
    display: none;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    .modal__content {
      max-height: none;
    }
    &__header {
      font-family: Hind;
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: var(--turuncu);
    }
    &__price {
      font-family: Hind;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: var(--black);
      margin-top: 20px;
    }
    &__infoBox {
      .infoComponent {
        margin-top: 20px;
      }
    }
    &__btn {
      width: 100%;
      button {
        margin-top: 20px;
      }
    }
    &__footerText {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: var(--black);
      margin-top: 20px;
    }
  }
  &__modal {
    display: flex;
    flex-direction: column;
    a {
      color: #000000 !important;
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }
    &__contactsection {
      @media screen and (max-width: 768px) {
        // display: none;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }
      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        span {
          font-size: 22px;
          font-weight: 600;
          a {
            color: #444;
          }
        }
      }
    }
    &__button {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 768px) {
        // display: none;
      }
    }
    &__buttonMobile {
      display: none;
      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        button {
          &:hover {
            cursor: pointer;
          }
          &:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #fff;
            height: 65px;
            border-radius: 3px;
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.3px;
            text-align: center;
            margin-top: 30px;
            background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%);
            border: none;
          }
        }
      }
    }
  }
}
// .mInfo {
//   .higherTooltip__left__span__top__tooltip {
//     @media screen and (max-width: 1180px) {
//       width: 100%;
//       left: -120px;
//       top: 33px;
//       transform: translateX(0);
//     }
//   }
//   .higherTooltip__left__span__top__tooltip:after {
//     @media screen and (max-width: 1180px) {
//       content: " ";
//       position: absolute;
//       top: -9px;
//       left: 178%;
//       margin-left: 7px;
//       border-width: 10px;
//       border-style: solid;
//       border-color: transparent transparent #486072 transparent;
//     }
//   }
// }
