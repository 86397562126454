@import "../../../assets/css/icons.scss";

.auth-side-bar {
  background-color: #e8ecf2;
  height: auto;
  padding: 30px;
  border-radius: 10px;
  .auth-side-bar-flex {
    display: flex;
    align-items: center;
  }
  &__headerText {
    font-family: Hind;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.07;
    letter-spacing: 0.56px;
    color: #486072;
  }
  &__body {
    display: flex;
    flex-direction: column;
    .active {
      .auth-side-bar__body__item__text {
        a {
          color: #f3bc45;
          font-weight: 600;
        }
      }
    }
    &__item {
      margin-top: 40px;
      /* display: flex
          align-items: center;
          Dropdown bozuluyor, text düzeliyor.
        */
      &:hover {
        cursor: pointer;
      }
      &__icon {
        margin-right: 20px;
        margin-bottom: 3px;
      }
      &__text {
        a {
          font-family: Hind;
          font-weight: 400;
          font-size: 20px;
          line-height: 1.5;
          letter-spacing: 0.4px;
          color: #486072;
          text-decoration: none;
          &:hover {
            color: #486072 !important;
          }
        }
      }

      &__dropdown {
        display: flex;
        flex-direction: column;
        &__text {
          font-family: Hind;
          font-size: 20px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: 0.4px;
          text-align: left;
          margin-left: 45px;
          margin-top: 40px;
          a {
            color: #486072 !important;
          }
        }
        .active {
          a {
            color: #f3bc45 !important;
            font-weight: 500;
            &:hover {
              color: #f3bc45 !important;
            }
          }
        }
      }
    }
  }
  .active {
    a {
      &:hover {
        color: #f3bc45 !important;
      }
    }
  }
  &__modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }
    &__button {
      width: 100%;
    }
    &__contact {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      padding: 10px;
      color: #444;
      &:hover {
        color: #444;
      }
      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      &__firstSpan {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: var(--black);
        &__notMobile {
          @media (max-width: 768px) {
            display: none;
          }
        }
        &__span {
          font-family: Hind;
          font-size: 22px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: center;
          color: var(--black);
          a {
            color: #444;
            &:hover {
              color: #444;
            }
          }
        }
      }
    }
  }
}

.firstSpan {
  font-family: Hind;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: var(--black);
  &:hover {
    color: #444 !important;
  }
  a {
    color: #444 !important;
    &:hover {
      color: #444 !important;
    }
    span {
      color: #444 !important;
      &:hover {
        color: #444 !important;
      }
    }
  }
}
