.customSelect {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 332px;

  &__special_height {
    .ant-select-item {
      height: 42px !important;
    }
  }

  &__bigOption {
    .ant-select-item {
      height: max-content !important;
      align-items: center;
      flex-grow: 1;
      font-family: Hind;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #486072 !important;
      border: solid 1px #e8ecf2;
      padding: 10px;
    }

    .ant-select-item-option-content {
      white-space: unset;
      text-overflow: unset;
    }
  }

  &__dropdown {
    background-color: #fff !important;

    &:active {
      background-color: #fff !important;
    }

    &:hover {
      background-color: #fff !important;
    }
  }

  .ant-select-borderless .ant-select-selector {
    border: 1px solid #e8ecf2 !important;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  .ant-select-arrow {
    display: none;
  }

  .ant-select {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #ff0000 !important;
    }

    &:hover {
      border: none !important;
      outline: none !important;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #f3bc45 !important;
  }

  .ant-select-item .ant-select-item-option .ant-select-item-option-selected {
    background-color: #ff0000 !important;
  }

  .ant-select-item-option-active {
    border-bottom-color: #4a235a;
    border-style: dotted;
    background-color: red !important;
    font-size: 34px !important;
  }

  .ant-select-dropdown {
    border: none !important;
    outline: none !important;
    background-color: #fff !important;
    z-index: 99999 !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #e8ecf2 !important;
  }

  .ant-select-selector {
    border: 1px solid #e8ecf2 !important;

    &::selection {
      border: none !important;
      background-color: #fff !important;
    }

    &:hover {
      border: 1px solid #486072 !important;
    }

    &:focus {
      border: 1px solid #486072 !important;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: red !important;
  }

  .ant-select-focused {
    border: none !important;

    &:hover {
      border: none !important;
    }
  }

  .ant-select-selection-item {
    color: #486072 !important;
    font-size: 18px !important;
  }

  .ant-select-item-option-content {
    font-size: 18px !important;
  }

  .ant-select-item .ant-select-item-option {
    font-size: 18px !important;
  }

  .ant-select-dropdown-menu-item:not(.ant-select-dropdown-menu-item-disabled):hover {
    border: none !important;
  }

  .ant-select-selector {
    height: 44px !important;
  }

  .ant-select-selection-placeholder {
    margin-top: 7px;
    font-family: Hind;
    font-size: 18px;
    line-height: 1.33;
    color: var(--lacivert);
    opacity: 0.5;
  }

  .ant-select-selection-item {
    margin-top: 7px;
  }

  &__label {
    margin-bottom: 10px;
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: var(--lacivert);
    line-height: normal;
  }

  img {
    position: absolute;
    right: 15px;
    top: 57%;
    animation: rotateRSelectComponent 0.3s;
    z-index: -1;

    &:hover {
      cursor: pointer;

      .ant-select-selector {
        border: 1px solid #486072;
      }
    }
  }
}

.customSelectOpen {
  img {
    position: absolute;
    right: 15px;
    top: 57%;
    animation: rotateSelectComponent 0.3s;
    animation-fill-mode: forwards;
  }
}

@keyframes rotateSelectComponent {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotateRSelectComponent {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

// .customSelect-maxHeight {
//   max-height: 150px !important;
//   overflow-y: scroll;
// }

.customSelect-maxHeight {
  .rc-virtual-list-holder-inner {
    max-height: 150px !important;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #486072;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      border-radius: 5px;
    }
  }

  .ant-select-item {
    min-height: 44px;
  }
}

.select-box-disabled {
  opacity: 0.5;
  pointer-events: disabled;

  .ant-select-selector {
    &:hover {
      border: 1px solid #e8ecf2 !important;
    }
  }
}

.basvuruSelectBox {
  padding: 0 !important;
  height: 45px !important;
}