.customer-operations-odeme-islemleri-fatura-odeme-yontemi-papara__payment_by_link {
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  .infoBox img {
    margin-right: 35px !important;
  }

  &__body {
    margin-top: 30px;
    padding-inline: 30px;
    width: 67%;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
      width: 100%;

    }

    &__header {
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #486072;
    }

    &__content {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &__item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 30px;

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }

        &__left {
          position: relative;
          background-image: url("../../../../assets/images/papara-mockup.png");
          background-repeat: no-repeat;
          width: 100%;
          height: 533px;
          background-position: center;

          &__maskot {
            position: absolute;
            bottom: 10px;
            left: -65px;
          }

          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        &__right {
          display: flex;
          flex-direction: column;
          padding-inline: 20px;

          &__downloads {
            display: flex;
            flex-direction: column;
            gap: 10px;

            &__download {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 15px;

              img {
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }

          &__description {
            margin-top: 30px;
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #000000;
            width: 292px;

            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }

          &__qr {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 280px;
              height: 280px;
            }
          }
        }
      }
    }
  }
}