.customer-operations-elektrik-kesintileri-kesinti-bildir-step1 {

  .customer-operations-elektrik-kesintileri-bildirim-detayi__content--left__body__card__map {
    position: relative;

    // map + - button hide
    .leaflet-touch .leaflet-control-layers,
    .leaflet-touch .leaflet-bar {
      display: flex !important;
      flex-direction: column;
      top: 70px !important;
      left: 630px;

      @media screen and (max-width: 768px) {
        left: 325px;

      }
    }

    .leaflet-control-attribution {
      display: none;
    }
  }

  .dilekce-basvuru-map-view__search {
    position: absolute;
    z-index: 9999;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-top: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__modal {
    &__description {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;

      color: #444444;
    }
  }

  &__body {
    &__map {
      padding-inline: 0px;
      margin-top: 30px;
    }

    @media screen and (max-width: 840px) {
      padding-inline: 0px;
    }

    &__card {


      @media screen and (max-width: 840px) {
        margin-top: 30px;
      }
    }

    &__notes {
      margin-top: 30px;

      .customTextArea {
        width: 100%;
      }
    }

    &__inputs {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 30px;
      margin-top: 30px;

      .customInputDisabled {
        opacity: 0.5;
      }

      @media screen and (max-width: 840px) {
        flex-direction: column;
      }

      &__input {
        width: 100%;

        .customSelect {
          width: 100%;

          .ant-select-selection-item {
            opacity: 1;
          }
        }

        @media screen and (max-width: 840px) {
          width: 100%;

          .customSelect {
            width: 100%;
          }

          .customInput {
            width: 100%;
          }

          input {
            margin-bottom: 0;
          }
        }
      }
    }

    &__input {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .customer-operations-elektrik-kesintileri-kesinti-bildir-step1__body__button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .customer-operations-elektrik-kesintileri-bildirim-detayi__content--left__body__card__map {
      position: relative;



      // map + - button hide
      .leaflet-touch .leaflet-control-layers,
      .leaflet-touch .leaflet-bar {
        display: flex !important;
        top: 30px
      }

      .leaflet-control-attribution {
        display: none;
      }
    }


  }

  .customer-operations-elektrik-kesintileri-bildirim-detayi__content--left__body__card__map__button {
    position: absolute;
    bottom: 20px;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 1024px) {
      width: calc(100% - 30px);
    }
  }


}

.mapTooltip {
  white-space: normal;
  width: 300px;
}