.login {

    .customInput {
        @media(max-width:1024px) {
            width: 100%;
        }

        input {
            margin-bottom: 10px;
            margin-top: 5px;
        }

        .errorBoxLogin {
            margin-top: -10px;
        }

        .error-icon {
            top: 40px;
        }

        .check {
            &--btn {
                top: 40px;
            }

            &--checked {
                top: 40px;
            }
        }
    }

    .tcknInput {
        input::placeholder {
            font-size: 17px;

            @media(max-width:400px) {
                font-size: 14px;
            }
        }
    }

    .ohm-btn {
        margin-top: 13px !important;
    }

    &__label {
        font-size: 15px;
        font-weight: 600;
        color: #486072;
        display: block;
        margin-bottom: 10px;
    }

    &__input {
        margin-bottom: 20px;
        padding: 10px 15px;
        width: 100%;
        height: 44px;
        border-radius: 3px;
        color: #486072;
        border: none;
        outline: none;

        &::placeholder {
            color: #486072;
            opacity: 0.5;
        }
    }

    &__bottom {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        width: 100%;
        justify-content: space-between;

        &__label {
            cursor: pointer;
            display: flex;
            font-size: 15px;
            font-weight: 600;
            color: #486072;

            >div {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #486072;

                >div {
                    background-color: #486072;
                    border: 1px solid white;
                    border-radius: 3px;
                }

                width: 19px;
                height: 19px;

                border-radius: 5px;
                border: solid 2px #486072;

                .checked {
                    position: relative;
                    width: 15.5px;
                    height: 15.5px;
                    background-color: #486072;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            span {
                margin-left: 10px;
            }

            input {
                display: none;
            }
        }

        span {
            cursor: pointer;
            color: #486072;
            font-size: 15px;
            font-weight: 600;
        }

        a {
            cursor: pointer;
            color: #486072 !important;
            font-size: 15px;
            font-weight: 600;
        }
    }

    &__button {
        cursor: pointer;
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%);
        border: none;
        font-size: 15px;
        font-weight: 600;
        color: white;
        border-radius: 3px;
        padding-top: 3px;
    }

    &__register {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .ohm-btn {
            margin-top: 0px !important;
        }
    }

    &__register_divider {
        margin: 15px 0px;
        border: 1px solid #FFFFFF
    }

    &__register_info {
        font-family: 'Hind', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24.02px;
        color: #486072;
        text-align: center;
    }

    &__register_button_link {
        font-family: 'Hind', sans-serif;
        font-size: 15px;
        font-weight: 600;
        line-height: 24.02px;
        text-align: center;
        text-decoration: underline;
        color: #486072;
        cursor: pointer;
    }
}

.disabled-bottom-label {
    opacity: 0.5;
}