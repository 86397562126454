.rpv-core__viewer {
  border-radius: 30px;
  width: 332px !important;
  height: 393px !important;
  /* width */

  .rpv-core__canvas-layer {
    width: 332px !important;
    margin: 0 !important;

    @media (min-width: 600px) and(max-width:1024px) {
      width: 655px !important;
    }
  }

  .core__inner-pages {
    border-radius: 3px 0 0 3px;
  }

  .rpv-core__page-layer {
    width: calc(100% - 80px);
    box-shadow: none;
  }

  .rpv-core__inner-page {
    background-color: #878787 !important;
    padding: 0;
    height: 355px !important;

    @media(max-width:1024px) {
      height: auto !important;
    }
  }

  .rpv-core__inner-pages {
    overflow-x: hidden !important;
    border-radius: 3px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #486072;
      border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  @media (max-width: 850px) {
    width: 100% !important;
  }
}