.customer-operations-elektrik-kesinti-bildirimleri {
  .infoExtra {
    margin-top: 0px;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__body {
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }



    &__card {
      margin-top: 30px;
      padding: 20px;
      background-color: #f3f3f3;
      border-radius: 10px;

      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #f3bc45;
      }

      &__insideCard {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        margin-top: 13px;

        &:not(:first-child) {
          margin-top: 20px;
        }

        &__hr {
          margin: 20px 0;
          background-color: #f3f3f3;
          width: 100%;
          height: 1px;

          @media screen and (max-width: 768px) {
            margin: 25px 0;
          }
        }

        &__item {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 30px;

          @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 25px;
          }

          &__left {
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 768px) {
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }

            &__upper {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #486072;
            }

            &__down {
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #444444;
              margin-top: 10px;

              @media screen and (max-width: 768px) {
                margin-top: 0;
              }
            }
          }

          &__right {
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 768px) {
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              &:last-child {
                border-top: 1px solid #f3f3f3;
                padding-top: 25px;
              }
            }

            &__upper {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #486072;
            }

            &__down {
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #444444;
              margin-top: 10px;

              @media screen and (max-width: 768px) {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }

  .customer-operations-basvuru-islemleri-basvuru-olustur__body__card__activeApplications .type {
    margin-right: 0px;

  }

  .customer-operations-basvuru-islemleri-basvuru-olustur__body__card__activeApplications hr {
    margin-right: 0px;
  }
}