.login-register-support {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    height: 103px;

    @media (min-width: 1024px) {
        display: none;
    }

    @media (max-width: 1024px) {
        justify-content: center;

        &--button {
            &:first-child {
                margin-right: 24px;
            }
        }
    }

    &--button {
        position: relative;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        width: 166px;
        height: 103px;
        border-radius: 10px;
        background-color: #e8ecf2;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 20px;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        color: #486072;
        z-index: 1;

        &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 0;
            top: 0;
            left: 0;
            z-index: -1;
            border-radius: 5px;
            background-color: #486072;
            box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 7px 7px 20px 0px rgba(0, 0, 0, 0.1),
                4px 4px 5px 0px rgba(0, 0, 0, 0.1);
            transition: all 0.2s ease;
        }

        &:hover {
            color: #486072;
        }

        &:first-child {
            @media (min-width: 600px) {
                &:hover::after {
                    font-family: "enerjisaohm";
                    position: absolute;
                    content: "444 4 372";
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    z-index: 3;
                    top: auto;
                    bottom: 0;
                    font-size: 24px;
                    font-weight: 500;
                    font-family: Hind;
                }
            }
        }

        &:last-child {
            @media (min-width: 600px) {
                &:hover {
                    .btn-span {
                        display: none;
                    }

                    .worldIcon {
                        display: flex;
                        z-index: 10;
                        width: 40px;
                        height: 40px;
                    }
                }

                &:hover::after {
                    font-family: "enerjisaohm";
                    position: absolute;
                    // content: "\f168";
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    z-index: 3;
                    top: auto;
                    bottom: 0;
                    font-size: 40px;
                    font-weight: 100 !important;
                }
            }
        }
    }
}

.worldIcon {
    display: none;
}