.ek-ekran-ssb-step3 {
  margin-top: 30px;
  padding-inline: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__description {
    font-family: 'Hind', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--pure-black);
  }

  .customCheckBox__label>div {
    background-color: transparent;
  }

  @mixin header {
    font-family: 'Hind', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--lacivert);
  }

  @mixin text {
    font-family: 'Hind', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--pure-black);
    margin-top: 10px;
  }

  &__checkAll {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-inline: 19px;
    margin-top: 30px;

    &__left {
      .customCheckBox .spanFirst {
        font-family: 'Hind', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #444444;
      }

      .customCheckBox {
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    &__right {
      font-family: 'Hind', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: var(--pure-black);

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__card {
    &:not(:first-child) {
      margin-top: 30px;
    }

    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 768px) {
      align-items: center;
    }

    &__right {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      hr {
        border: 1px solid #DADADA !important;
      }

      &__upper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;

        @media screen and (max-width: 768px) {
          flex-direction: column-reverse;
          gap: 20px;
        }

        &__left {
          width: 50%;

          div {
            &:first-child {
              @include header;
            }

            &:last-child {
              @include text;
              display: flex;
              align-items: center;

              i {
                margin-right: 10px;
                color: #adb3bc;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }

        &__right {
          width: 50%;

          @media screen and (max-width: 768px) {
            width: 100%;

          }

          div {
            &:first-child {
              @include header;
            }

            &:last-child {
              @include text;
              display: flex;
              align-items: center;

              i {
                margin-right: 10px;
                color: #adb3bc;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      &__down {
        div {
          &:first-child {
            @include header;
          }

          &:last-child {
            @include text;
          }
        }
      }
    }
  }
}