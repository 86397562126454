.customInput {
    font-family: Hind;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 322px;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    &__label {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #486072;
        display: flex;
        align-items: center;
        position: relative;

        .higherTooltip__left__span__top__tooltip__content {
            @media screen and (max-width: 1010px) {
                //left: calc(-100% - 105px);
            }

            @media screen and (max-width: 768px) {
                left: 0%;
            }
        }

        .higherTooltip__left__span__top__tooltip {
            &::after {
                @media screen and (max-width: 768px) {
                    left: 41%;
                    top: -7px;
                }
            }
        }

        .higherTooltip__left__span {
            position: initial;
        }
    }

    input {
        margin-top: 10px;
        margin-bottom: 20px;
        height: 44px;
        border-radius: 3px;
        padding: 10px 15px;
        border: solid 1px #e8ecf2;
        background-color: #ffffff;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #486072;
        width: 322px;

        :-webkit-autofill {
            background-color: white !important;
        }

        &:disabled {
            appearance: none;
            opacity: 1;
            color: #486072 !important;
            background-color: #fff !important;
            -webkit-text-fill-color: #486072 !important;
        }

        &::placeholder {
            color: #486072;
            opacity: 0.5;
        }

        @media (max-width: 1024px) {
            width: 100%;
        }

        &:focus {
            outline: none;
        }
    }

    .errorBox {
        display: flex;
        margin-left: 14px;
        margin-top: 10px;

        &__errorIcon {
            margin-top: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            width: 20px;
            height: 20px;
            border-radius: 10px;
        }

        &__errorText {
            margin-left: 14px;
            max-width: 272px;
            font-size: 15px;
            font-weight: 600;
            color: #f00;
            text-align: left;
            font-family: Hind;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;

            @media (max-width: 600px) {
                max-width: 90%;
            }
        }

        @media (max-width: 600px) {
            margin-top: 20px;
            margin-bottom: 0px !important;
            margin-left: 0;
        }
    }

    .errorBoxLogin {
        display: flex;
        align-items: center;
        margin-left: 14px;
        margin-top: -13px;
        margin-bottom: 10px;

        &__errorIcon {
            // margin-top: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            width: 16.7px;
            height: 16.7px;
            border-radius: 10px;
        }

        &__errorText {
            padding-top: 3px;
            margin-left: 14px;
            max-width: 272px;
            font-size: 12px;
            font-weight: 600;
            color: #f00;
            text-align: left;
            font-family: Hind;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;

            @media (max-width: 600px) {
                max-width: 90%;
            }
        }

        @media (max-width: 600px) {
            margin-top: -10px;
            margin-bottom: 10px !important;
            margin-left: 0;
        }
    }

    .input-error {
        border-color: #ff0000 !important;
        color: #ff0000 !important;

        &::placeholder {
            color: #ff0000 !important;
            opacity: 0.5;
        }
    }

    .input-success {
        border-color: #6cb425 !important;
        color: #6cb425 !important;
    }

    .anketInputText {
        &__hr {
            height: 42px;
            width: 1px;
            background-color: #486072;
            position: absolute;
            right: 62px;
            top: 11px;
        }

        &__text {
            position: absolute;
            right: 13px;
            top: 50%;
            transform: translateY(-20%);
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Hind", sans-serif;
            height: min-content;
        }
    }
}

.customInputDisabled {
    opacity: 0.5;
}

.customInputDisabledWithBg {
    opacity: 1;


    input {
        border: 1px solid rgb(72, 96, 114);
        background-color: #f3f3f3;

        &:disabled {
            appearance: none;
            opacity: 1;
            color: #486072 !important;
            -webkit-text-fill-color: #486072 !important;
        }
    }
}

.customInputValidated {
    input {
        color: rgba(72, 96, 114, 0.5) !important;
    }
}

.passwordIcon {
    position: absolute;
    top: 44px;
    right: 10px;
}

.check {
    &--btn {
        border: none;
        width: 75px;
        height: 24px;
        background-color: #f3bc45;
        border-radius: 3px;
        cursor: pointer;
        position: absolute;
        top: 44px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 550;
        padding-top: 3px;
    }

    &--loadingIndicator {
        position: absolute;
        top: 43px;
        right: 10px;
    }

    &--checked {
        position: absolute;
        top: 43px;
        right: 10px;
    }
}

.checkbtn-disabled {
    background-color: #f3f3f3;
}

.error-icon {
    width: 20px;
    height: 20px;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2.8px;
    border-radius: 15px;
    position: absolute;
    top: 45px;
    right: 10px;
}

.check--btn {
    @media (max-width: 389px) {
        margin-top: 23px;
    }
}

.check--checked {
    @media (max-width: 389px) {
        margin-top: 23px;
    }
}

.error-icon {
    @media (max-width: 389px) {
        margin-top: 23px;
    }
}