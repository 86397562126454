.notification-mobile {
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    &__notifications {
      display: flex;
      flex-direction: column;

      margin-top: 30px;
      &__body {
        &:last-child {
          .notification-mobile__content__notifications__body__doublehr {
            display: none;
          }
        }
        &__doublehr {
          width: 100%;
          height: 1px;
          background-color: #486072;
          margin: 20px 0;
        }
        &__item {
          display: flex;
          flex-direction: column;
          padding: 12px;
          border-radius: 10px;

          &:first-child {
            margin-top: 0;
          }
          &:hover {
            cursor: pointer;
          }
          &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            &__title {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              /* identical to box height */

              /* Lacivert */

              color: #486072;
              display: flex;
              align-items: center;
              span {
                display: flex;
                width: 10px;
                height: 10px;
                background-color: #f3bc45;
                border-radius: 50%;
                content: "";
                color: #f3bc45;
                margin-right: 10px;
                font-size: 1px;
              }
            }
            &__date {
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 24px;
              text-align: right;
              color: #486072;
            }
          }
          &__hr {
            margin: 10px 0;
            background-color: #f3f3f3;
            height: 1px;
            width: 100%;
          }
          &__description {
            // line-height: 1em;
            max-height: 3em;
            display: -webkit-box;
            /*! autoprefixer: off */
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            word-wrap: break-word;
            /* or 150% */

            /* Lacivert */

            color: #486072;
          }
        }
      }
    }
    &__no-notification {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      background-color: #fbfbfb;
      border-radius: 10px;
      padding: 20px;
      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 35px;
        /* or 125% */

        text-align: center;
        letter-spacing: 0.02em;

        /* Lacivert */

        color: #486072;
      }
      &__maskot {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
