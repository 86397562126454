@import "../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-main-home {
  @include withAuthLayout;
  &__goback {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__maskot {
    border-radius: 10px;
    height: 590px;
    background-image: linear-gradient(
      105deg,
      rgba(251, 233, 79, 0) 32%,
      rgba(243, 188, 69, 0.77) 85%,
      #f0ae42 100%
    );
    position: relative;
    img {
      position: absolute;
      right: -6%;
      bottom: -4%;
    }
  }
}
