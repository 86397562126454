.new-password-step1 {
  padding-inline: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__button {
    @media (max-width: 1024px) {
      width: 332px !important;
    }

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }

  @media (max-width: 768px) {
    padding-inline: 0px;
  }

  &__current {
    margin-top: 30px;
    margin-bottom: 0px;

    input {
      margin-bottom: 10px;
    }

    @media (max-width: 768px) {
      width: 100% !important;

      .errorBox {
        margin-top: 10px;
      }
    }
  }

  &__info {
    width: 100% !important;

    .infoComponent {
      margin-top: 10px;
    }
  }

  &__inputs {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }

    flex-wrap: wrap;

    .errorBox {
      margin-left: 0px;
    }

    &__input {
      @media (max-width: 768px) {
        width: 100% !important;
      }

      input {
        width: 332px !important;

        @media (max-width: 768px) {
          width: 100% !important;
        }
      }
    }

    &__select {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        width: 100% !important;
      }

      label {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        color: var(--lacivert);
        margin-bottom: 10px;
      }

      .ant-select-selection-placeholder {
        margin-top: 5px;
      }

      .ant-select-selection-item {
        opacity: 1 !important;
      }

      .ant-select-arrow {
        display: none;
      }

      .ant-select-selector {
        width: 332px !important;
        height: 44px;

        @media (max-width: 768px) {
          width: 100% !important;
        }
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 44px;
      }

      .ant-select-selection-item {
        margin-top: 6px;
      }

      &__icon {
        position: absolute;
        right: 5%;
        top: 57%;
      }
    }
  }

  .customInput {
    .errorBox {
      margin-top: 5px !important;
    }
  }

  .errors-wrapper {
    margin-top: -10px;
  }
}

.ReCAPTCHA-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.ReCAPTCHA {
  margin-top: 0px !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}