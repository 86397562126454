.memnuniyet-anketi-modal-max2 {
  .modal2__content {
    overflow-y: unset !important;
  }
}

.abonelik-memnuniyet-anketi-modal {
  .modal2__content {
    max-width: 814px;
    width: 100%;
    max-height: max-content;
    justify-content: start;
    @media screen and (max-height: 1200px) {
      max-height: calc(100% - 50px) !important;
      overflow-y: auto ;
    }
    @media screen and (max-width: 768px) {
      max-width: calc(100% - 30px);
      max-height: calc(100% - 50px);
      overflow-y: auto;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    img {
      &:hover {
        cursor: pointer;
      }
    }
    div {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: left;
      color: var(--lacivert);
      width: calc(100% - 20%);
    }
  }
  &__steps {
    display: grid;
    grid-template-columns: auto;
    gap: 30px;
    width: 100%;
    margin-top: 30px;
  }
  &__reviews {
    height: 180px;
    background-color: #e8ecf2;
    border-radius: 10px;
    width: 100%;
    margin-top: 30px;
    padding: 20px 0;
    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
      height: max-content;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: var(--pure-black);
      padding-inline: 20px;
    }
    &__body {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
      &__left {
        position: absolute;
        top: 100%;
        width: max-content;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--pure-black);
        left: 22%;
        @media screen and (max-width: 768px) {
          left: 15%;
        }
        @media screen and (max-width: 500px) {
          left: 0;
          top: 90%;
          width: 100%;
          max-width: 87px;
          font-size: 12px;
        }
        @media screen and (max-width: 350px) {
          left: 0;
        }
      }
      &__right {
        position: absolute;
        top: 100%;
        width: max-content;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--pure-black);
        right: 23%;
        @media screen and (max-width: 768px) {
          right: 15%;
        }
        @media screen and (max-width: 500px) {
          right: 0;
          top: 90%;
          width: 100%;
          max-width: 87px;
          font-size: 12px;
        }
        @media screen and (max-width: 350px) {
          right: 0;
        }
      }
      .active-anket-item {
        box-shadow: 0px 0px 10px rgba(247, 166, 0, 1) !important;
      }

      .active-hover-item {
        box-shadow: 0px 0px 10px rgba(247, 166, 0, 1) !important;
        img {
          animation: opacity 0.1s forwards;
        }
      }
      &__item {
        width: 50px;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 0 10px 12px 10px;
        padding: 2px;
        border-radius: 25px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background-color: var(--white);
        margin-top: 30px;
        position: relative;
        &:hover {
          cursor: pointer;
        }
        div {
          position: absolute;
          bottom: -30px;
          width: 70px;
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: var(--pure-black);
        }
        @media screen and (max-width: 768px) {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          margin: 0;
          margin-right: 10px;
          margin-top: 30px;
          margin-bottom: 20px;
          img {
            width: 25px;
            height: 25px;
          }
          &:nth-child(6) {
            margin-right: 0px !important;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__buttons {
    display: grid;
    grid-template-columns: 362px 362px;
    width: 100%;
    gap: 30px;
    grid-template-areas: "Button1 Button2";
    .grid-area-button1 {
      grid-area: Button1;
    }
    .grid-area-button2 {
      grid-area: Button2;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "Button2"
        "Button1";
      width: 100%;
      button {
        &:last-child {
          margin-top: 0;
        }
      }
      &__button {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
  &__lastStepHeader {
    margin-top: 30px;
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--pure-black);
    b {
      font-weight: 600;
    }
  }
  &__textArea {
    width: 100%;
    margin-top: 30px;
    .customTextArea {
      width: 100%;
      .customTextArea__textarea__label {
        display: none;
      }
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }
}
