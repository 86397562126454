.register-page-pdf {
    .viewer {
        display: flex;
        justify-content: space-between;
        @media (max-width: 850px) {
            flex-direction: column;
        }
    }

    .modal__content {
        .modal__content__header {
            margin-bottom: 0 !important;
        }
        .modal__content__footer {
            margin-top: 75px;
        }
    }

    .wrapper__btn {
        // margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 850px) {
            margin-top: 0;
            .ohm-primaryBtn {
                margin-top: 0;
            }
        }
    }
}
