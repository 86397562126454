.style-guide {
  padding-inline: 30px;

  &__hr {
    height: 1px;
    width: 100%;
    background-color: #f3f3f3;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__header {
    margin-bottom: 20px;

    a {
      font-size: 16px;
      margin-right: 20px;
      margin-bottom: 20px;
      color: var(--black);
      text-decoration: underline;
    }
  }

  &__colors {
    margin-top: 30px;

    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
      margin-bottom: 30px;
    }

    &__list {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;

      &__card {
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid #dadada;
        border-radius: 4px;
        height: auto;
        width: 320px;
        margin-bottom: 30px;
        margin-right: 60px;

        &__pure-black {
          background-color: var(--pure-black);
          width: 100%;
          height: 72px;
          border-bottom: 1px solid #dadada;
        }

        &__soft-grey {
          background-color: var(--soft-grey);
          width: 100%;
          height: 72px;
          border-bottom: 1px solid #dadada;
        }

        &__correct {
          background-color: var(--correct);
          width: 100%;
          height: 72px;
          border-bottom: 1px solid #dadada;
        }

        &__white {
          background-color: var(--white);
          width: 100%;
          height: 72px;
          border-bottom: 1px solid #dadada;
        }

        &__lacivert {
          background-color: var(--lacivert);
          width: 100%;
          height: 72px;
          border-bottom: 1px solid #dadada;
        }

        &__orange {
          background-color: var(--turuncu);
          width: 100%;
          height: 72px;
          border-bottom: 1px solid #dadada;
        }

        &__cool-grey {
          background-color: var(--cool-grey);
          width: 100%;
          height: 72px;
          border-bottom: 1px solid #dadada;
        }

        &__error {
          background-color: var(--error);
          width: 100%;
          height: 72px;
          border-bottom: 1px solid #dadada;
        }
      }
    }
  }

  &__buttons {
    margin-top: 30px;

    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
    }

    &__button {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      @media screen and (max-width: 768px) {
        width: 100% !important;
      }
    }
  }

  &__buttons2 {
    display: flex;
    align-items: center;

    .ohm-secondaryBtn {
      background-color: rgba(243, 243, 243, 0.5);
    }

    .ohm-primaryBtn {
      box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4),
        -5px -5px 20px 0 #ffffff;
    }

    &__button {
      @media screen and (max-width: 768px) {
        width: 100%;
      }

      button {
        margin-top: 0;
      }
    }

    &__hr {
      height: 30px;
      width: 1px;
      border-left: 1px solid #486072;
      margin: 0px 4px 0px 4px;
    }
  }

  &__inputs {
    margin-top: 30px;

    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
    }

    &__input {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      @media screen and (max-width: 768px) {
        width: 100% !important;

        &__first {
          margin: 0px !important;
        }

        &__second {
          margin: 0px !important;
        }
      }

      &__first {
        margin-right: 30px;
      }

      &__second {
        margin-right: 30px;
      }
    }
  }

  &__checkboxes {
    margin-top: 30px;

    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
      margin-bottom: 30px;
    }

    &__checkbox {
      display: flex;
      align-items: flex-start;

      .customCheckBox {
        margin-top: 0;
      }

      &__one {
        margin-right: 30px;
      }
    }

    &__roundedcheckbox {
      display: flex;
      align-items: flex-start;

      &__one {
        margin-right: 30px;
      }
    }
  }

  &__select {
    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
      margin-bottom: 30px;
    }
  }

  &__infobox {
    width: 100%;

    .infoComponent {
      margin-top: 0;
    }

    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
      margin-bottom: 30px;
    }

    &__items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      &__item {
        width: 352px;
        margin-bottom: 30px;
      }

      &__itemBtn {
        width: 352px;
      }
    }
  }

  &__toggle-category {
    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
      margin-bottom: 30px;
    }

    &__buttons {
      button {
        margin-top: 0;
      }
    }
  }

  &__modal {
    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
      margin-bottom: 30px;
    }

    &__buttons {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;

      button {
        margin-top: 0;
      }
    }
  }

  &__modalComponent {
    &__header {
      font-family: Hind;
      font-size: 24px;
      color: #486072;
      text-align: center;
      font-weight: 600;
    }

    &__content {
      font-family: Hind;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      margin-top: 30px;
    }
  }

  &__coverbtn {
    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
      margin-bottom: 30px;
    }

    &__body {
      width: 40%;
    }
  }

  &__contentheader {
    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
      margin-bottom: 30px;
    }
  }

  &__headercomponent {
    .header {
      max-width: 70% !important;
    }

    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
      margin-bottom: 30px;
    }
  }

  &__progressbox {
    .progressBox {
      margin-top: 0;
    }

    .progressBoxMobile {
      margin-top: 0;
    }

    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
      margin-bottom: 30px;
    }

    &__body {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;

      &__mobile {
        width: 50%;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__icons {
    &__title {
      font-family: Hind;
      font-size: 28px;
      color: #f3bc45;
      margin-bottom: 30px;
    }
  }
}
