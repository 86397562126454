@import "../../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-after-login-elektrik-kesintileri-kesinti-bildir {
  @include withAuthLayout;

  &__header {
    justify-content: space-between;
  }

  &__content {
    &--left {
      &__prev {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-top: 30px;
        width: fit-content;
        &:hover {
          cursor: pointer;
        }
      }

      &__body {
        padding-inline: 30px;

        &__text {
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--pure-black);
          margin-top: 30px;
          margin-bottom: 30px;

          &__highlight {
            font-weight: 600;
            color: var(--turuncu);
          }
        }

        &__firstlabel {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          margin-bottom: 10px;
        }

        &--smsCodeCont {
          display: flex;
          margin-bottom: 30px;
          align-items: center;
          margin-top: 10px;

          &--countDown {
            display: flex;
            margin-left: 30px;

            span {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.3px;
              color: #000000;
              margin-left: 10px;
            }
          }
        }

        .yellowLabel {
          color: #f3bc45;
        }

        &__resend {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          &__label {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--lacivert);
          }

          &--resendBtn {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 0.3px;
            text-align: center;
            color: var(--turuncu);
            margin-left: 20px;
            display: flex;
            align-items: center;

            label {
              margin-right: 10px;

              &:hover {
                cursor: pointer;
              }
            }

            img {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    &--right {
      margin-top: 0 !important;

      .progressBox {
        margin: 0 !important;
        height: auto;
        padding-bottom: 30px;
      }
    }
  }

  &__modal {
    .modal__content {
      max-height: 682px !important;
    }

    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
      margin-bottom: 30px;
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }

    &__buttons {
      width: 100%;
    }

    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }

      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;

        span {
          font-size: 22px;
          font-weight: 600;

          a {
            color: #444;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .customer-operations-elektrik-kesintileri-kesinti-bildir {
    &__content {
      &--left {
        &__prev {
          margin-bottom: 30px;
          width: fit-content;
        }

        &__body {
          padding-inline: 0px;

          &__text {
            margin-top: 0px;
          }

          &--smsCodeCont {
            flex-direction: column;

            &--countDown {
              width: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 30px;
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .customer-operations-elektrik-kesintileri-kesinti-bildir {
    &__content {
      &--left {
        &__body {
          &--smsCodeCont {
            flex-direction: column;

            &--countDown {
              width: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 30px;
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
}
