.close-yda {
  height: 200px !important;
}

.welcomeModal {
  .modal {
    z-index: 10002 !important;
  }

  .modal2__content {
    width: auto;
    min-width: 370px;
    min-height: 350px;
    max-width: fit-content;
    max-height: fit-content;
    padding: 0px;

    @media (max-width: 420px) {
      min-width: 330px;
      min-height: fit-content;
    }

    @media screen and (max-height: 1200px) {
      max-height: calc(100% - 50px) !important;
    }
  }

  &__modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px;

    @media screen and (max-height: 1200px) {
      max-height: calc(100% - 50px) !important;
      overflow-y: auto;
    }

    &__images {
      width: 100%;

      img {
        width: 100%;
      }
    }

    &__content {
      margin-top: 30px;
      width: 100%;
      font-family: "Hind";

      p {
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
.ydaQrModal {
  .modal {
    z-index: 10002 !important;
  }

  .modal2__content {
    width: 350px;
    min-width: 360px;
    min-height: 350px;
    max-width: fit-content;
    max-height: fit-content;
    padding: 0px;

    @media screen and (max-height: 1200px) {
      max-height: calc(100% - 50px) !important;
    }
  }

  &__modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px;

    @media screen and (max-height: 1200px) {
      max-height: calc(100% - 50px) !important;
      overflow-y: auto;
    }

    &__header {
      font-family: "Hind", sans-serif !important;
      font-size: 28px;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: 0.56px;
      color: #486072;
      text-align: center;
      margin-bottom: 30px;
    }

    &__description {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      text-align: center;
      color: #444;
    }

    &__buttons {
      width: 100%;
    }

    &__images {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 30px;

      img {
        width: 100%;
      }
    }

    &__content {
      margin-top: 30px;
      width: 100%;
      font-family: "Hind";

      p {
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.ydaEnvironment {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.qr-container {
  z-index: 9999;
  position: relative;
  @media (max-width: 820px) {
    display: none !important;
  }
}

.qr-sidebar-open {
  transform: translateX(0px) !important; /* Position when opened */
}

.qr-sidebar-close-yda {
  width: 255px !important;
  .qr-sidebar {
    padding: 0 !important;
    height: 200px !important;
  }
  .qr-btn {
    background: linear-gradient(131.92deg, #fcee50 -5.41%, #eea540 107.64%);
    width: 60px !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    span {
      margin-bottom: 0 !important;
      margin-left: 0 !important;
    }
  }
}

.qr-sidebar-close {
  width: 255px !important;
  .qr-sidebar {
    padding: 0 !important;
    height: 215px !important;
  }
  .qr-btn {
    background: linear-gradient(131.92deg, #fcee50 -5.41%, #eea540 107.64%);
    width: 60px !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    span {
      margin-bottom: 0 !important;
      margin-left: 0 !important;
    }
  }
}

.qr-sidebar {
  position: fixed;
  left: 0;
  top: 65%;
  // transform: translateY(-50%);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: transform 300ms ease-in-out, width 300ms ease-in-out;
  width: 202px;
  height: 234px;
  background: linear-gradient(131.92deg, #fcee50 -5.41%, #eea540 107.64%);
  transform: translateX(-195px); /* Default position off-screen */
  .qr-img {
    width: 128px;
    height: auto;
    margin-top: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
  }
  .qr-btn {
    height: 100%;
    width: 35px;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span {
      // margin-left: 10px;
      margin-bottom: 30px;
      display: inline-block;
      transform: rotate(-90deg);
      white-space: nowrap;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .qr-sidebar-close {
  }
  .qr-sidebar-open {
  }
}

.qr-sidebar-mhm {
  .qr-sidebar-close {
    .qr-sidebar {
      @media (min-width: 820px) and (max-width: 1280px) {
        top: 61% !important;
      }
    }
  }

  .qr-sidebar {
    @media (min-width: 820px) and (max-width: 1280px) {
      top: 61% !important;
    }
  }
}