@import "../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-elektrik-kesintileri-kesinti-sorgulama {
  @include withAuthLayout;

  %left {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #486072;
  }

  %right {
    font-family: "Hind";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: right;
  }

  %right2 {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    text-align: right;
    color: #f3bc45;
    width: 228px !important;
  }

  .sub {
    padding: 12px;
  }

  .infoComponent {
    margin-bottom: 0 !important;
    margin-top: 20px !important;

    &__info {
      display: flex;
      background-color: #fff;
      border-radius: 3px;

      &__text {
        span {
          color: #f3bc45;
        }
      }

      &__detail {
        display: flex;
        align-items: flex-start;

        &__icon {
          width: 24px;
          padding: 12px;
          height: 24px;
          margin-right: 14px;

          &__img {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__infoArea {
        display: flex;
        align-items: flex-start;

        &__icon {
          width: 24px;
          margin: 0 !important;
          padding: 2px;
        }

        &__text {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          margin-right: -8px;
          padding: 0px;
          margin-left: 15px !important;
          width: 269px;
        }
      }

      button {
        margin: 0;
      }
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  &__header {
    justify-content: space-between;
  }

  &__content {
    &--left {
      &__prev {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 30px;
        margin-top: 0 !important;
        width: fit-content;

        &:hover {
          cursor: pointer;
        }
      }

      &__body {
        padding-inline: 30px;

        .higherTooltip__left__span__top__tooltip__content {
          @media screen and (max-width: 768px) {
            left: calc(-20vw - 100px);
            width: calc(100vw - 80px);
          }

          // @media screen and (max-width: 300px) {
          //   right: calc(100% + 5%);
          //   transform: translateX(43%);
          //   width: calc(100vw - 52px);
          // }
        }



        &__card {
          background-color: #f3f3f3;
          border-radius: 10px;
          padding: 20px;
          margin-top: 30px;

          &__buttons {
            display: flex;
            align-items: center;
            background-color: rgba(243, 243, 243, 0.5);
            margin-top: 20px;

            .ohm-secondaryBtn {
              background-color: rgba(243, 243, 243, 0.5);
            }

            &__button {
              button {
                width: 322px !important;
                margin-top: 0;

                span {
                  @media screen and (max-width: 768px) {
                    max-width: 100px;
                    word-break: break-word;
                  }
                }
              }
            }

            &__hr {
              height: 30px;
              width: 1px;
              border-left: 1px solid #486072;
              margin: 0px 4px 0px 4px;
            }
          }



          &__header {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            color: #f3bc45;
          }

          &__button {
            button {
              margin-top: 20px;
            }
          }

          &__activeApplications2 {
            background: #fff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            padding: 20px;
            margin-top: 20px;

            hr {
              // display: none;
              color: #f3f3f3;
              border: 0.3px solid #f3f3f3 !important;
              margin: 20px 0;
            }

            .type {
              display: flex;
              justify-content: space-between;

              @media screen and (max-width: 768px) {
                flex-direction: column;
              }

              .description {
                margin-top: 0px !important;
                padding-bottom: 0px !important;

              }

              .withBottomLine {
                @media screen and (max-width: 768px) {
                  border-bottom: 1.2px solid #f3f3f3;
                }
              }

              .withNoTopBottomSpace {
                margin-top: 0px !important;
                padding-bottom: 0px !important;

              }

              &__header {
                width: 327px;

                @media screen and (max-width: 768px) {
                  width: 100%;
                }


                &:first-child {
                  @media screen and (max-width: 768px) {
                    padding-bottom: 20px;

                  }
                }

                &:last-child {
                  @media screen and (max-width: 768px) {
                    margin-top: 20px;
                  }
                }

                &__top {
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  color: #486072;
                  margin-bottom: 10px;

                  img {
                    margin-left: 10px;
                  }
                }

                &__bottom {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  color: #444444;

                  span {
                    &:last-child {
                      margin-left: 10px;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }

        &__map {
          margin-top: 30px;
          position: relative;

          // map + - button hide
          .leaflet-touch .leaflet-control-layers,
          .leaflet-touch .leaflet-bar {
            display: none !important;
          }

          .leaflet-control-attribution {
            display: none;
          }



          &__button {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 999;

            @media screen and (max-width: 768px) {
              width: calc(100% - 30px);
            }
          }
        }

        &__inputs {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: flex-start;

          &__input {
            margin-top: 30px;


            .customSelect {
              .ant-select-selection-item {
                opacity: 1;
              }
            }
          }
        }

        &__button {
          display: flex;
          justify-content: center;
        }

        &__input {
          display: flex;
          justify-content: center;

        }

        &__text {
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--pure-black);
          margin-top: 30px;
          margin-bottom: 30px;

          &__highlight {
            font-weight: 600;
            color: var(--turuncu);
          }
        }

        &__firstlabel {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          margin-bottom: 10px;
        }

        .yellowLabel {
          color: #f3bc45;
        }
      }
    }

    &--right {
      margin-top: 0px !important;
      margin-left: 30px;

      .progressBox {
        margin: 0 !important;
      }

      .elektrik-kesintileri-right-card {
        background: #e8ecf2;
        padding: 20px;
        border-radius: 10px;

        .infoComponent {
          margin: 0 !important;

          &__info {
            display: flex;
            align-items: center;
            background-color: #fff;
            border-radius: 3px;

            &__detail {
              display: flex;
              align-items: flex-start;

              &__icon {
                width: 24px;
                padding: 12px;
                height: 24px;
                margin-right: 14px;

                &__img {
                  width: 20px;
                  height: 20px;
                }
              }
            }

            &__infoArea {
              display: flex;
              align-items: flex-start;

              &__icon {
                width: 24px;
                margin: 0 !important;
                padding: 2px;
              }

              &__text {
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: normal;
                text-align: left;
                color: var(--lacivert);
                margin-right: -8px;
                padding: 0px;
                margin-left: 15px !important;
                width: 269px;
              }
            }

            button {
              margin: 0;
            }
          }

          &:last-child {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  &__modal {
    .modal__content {
      max-height: 682px !important;
    }

    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
      margin-bottom: 30px;
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }

    &__buttons {
      width: 100%;
    }

    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }

      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;

        span {
          font-size: 22px;
          font-weight: 600;

          a {
            color: #444;
          }
        }
      }
    }
  }

  &__bottom-info-card {
    display: none;

    @media screen and (max-width: 1180px) {
      display: block;

    }
  }
}

@media screen and (max-width: 1180px) {
  .customer-operations-elektrik-kesintileri-kesinti-sorgulama {
    .infoComponent {
      flex-direction: column;
    }
  }
}

@media (max-width: 768px) {
  .customer-operations-elektrik-kesintileri-kesinti-sorgulama {
    &__content {
      &--left {
        &__prev {
          margin-bottom: 30px;
          width: fit-content;
        }

        &__body {
          padding-inline: 0px;

          &__card {
            &__buttons {
              &__button {
                width: 100% !important;

                button {
                  width: 100% !important;
                }
              }
            }
          }

          &__text {
            margin-top: 0px;
          }

          &__inputs {
            flex-direction: column;

            &__input {
              width: 100%;
            }
          }

          .infoComponent {
            &__info {
              flex-direction: column;

              button {
                display: block;
                margin-top: 10px;
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }

  .elektrik-kesinti-step1 {
    padding-inline: 30px;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__prev {
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      text-align: left;
      color: #000000;
      margin-bottom: 30px;
      width: fit-content;

      &:hover {
        cursor: pointer;
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;

      &__desktopmap {
        display: block;
        margin-top: 30px;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &__mobilemap {
        display: none;

        margin-top: 30px;

        @media screen and (max-width: 768px) {
          display: block;
        }
      }

      &__buttons {
        display: flex;
        align-items: center;
        width: 100% !important;
        background-color: rgba(243, 243, 243, 0.5);

        .ohm-secondaryBtn {
          background-color: rgba(243, 243, 243, 0.5);
        }

        button {
          margin-top: 0;
          border: none !important;
        }

        &__verticalhr {
          margin-right: 5px;
          margin-left: 5px;
          border-left: 1px solid #486072;
          width: 1px;
          height: 30px;
          background-color: rgba(243, 243, 243, 0.5);
          box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 var(--white);

          @media screen and (max-width: 768px) {
            margin-right: 10px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}