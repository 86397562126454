.customer-operations-serbest-tuketici-basvurusu-step1 {
  margin-top: 30px;
  // .customer-operations-serbest-tuketici-basvurusu-step1__body__tesisatlar {
  //   background-color: rgba(251, 251, 251, 1);
  //   width: 100%;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   padding: 20px 0;
  //   border-radius: 10px;
  // }
  .customer-operations-abonelik-iptal__noContract {
    background-image: none;
    background-color: rgba(251, 251, 251, 1);
    height: 312.2px;
    width: 100%;
    img {
      width: 282px;
      height: 224px;
      margin: auto;
    }
    @media(max-width: 600px) {
      flex-direction: column !important;
      margin: 0;
    }
  }
  .customer-operations-abonelik-iptal__noContract__header {
    height: 72px;
    align-self: stretch;
    flex-grow: 0;
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal !important;
    text-align: center;
    color: black !important;
    margin: auto;
    max-width: 250px;
    padding: 0;
    @media(max-width: 600px) {
      margin-top: 20px;
    }
  }
  .searchSub {
    &::placeholder {
      color: var(--black) !important;
      font-weight: 300;
    }
  }

  .customSearchInput__iconsS {
    display: flex;
    position: absolute;
    right: 15px;
    label {
      margin-right: 10px;
    }
  }
  .customSearchInput__input {
    color: var(--black) !important;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__body {
    padding-inline: 30px;
    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }
    &__upperText {
      margin-top: 30px;
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #444444;
    }
    &__selectArea {
      margin-top: 30px;
    }
    &__tesisatlar {
      &__card {
        background-color: #f3f3f3;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        &__hr {
          width: 100%;
          background-color: #dadada;
          height: 1px;
          margin: 20px 0;
        }
        &__hrx {
          width: 100%;
          background-color: #dadada;
          height: 1px;
          margin: 20px 0;
          display: none;

          @media screen and (max-width: 768px) {
            display: block;
          }
        }
        &__header {
          display: grid;
          grid-template-columns: 19px 1fr;
          align-items: center;
          gap: 20px;
          &--checkbox {
            .customCheckBox {
              margin: 0;
              // .customCheckBox__label {
              //   div {
              //     background-color: transparent !important;
              //   }
              // }
            }
          }
          &--name {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            /* identical to box height, or 120% */

            /* Turuncu */

            color: #f3bc45;
          }
        }
        &__info1 {
          display: grid;
          grid-template-columns: 317px 317px;
          margin-top: 20px;
          @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
          }
          &--left {
            &--headerText {
              font-family: Hind;
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #444444;
            }
            &--bottomText {
              font-family: Hind;
              font-style: normal;
              font-weight: 300;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #444444;
              margin-top: 10px;
              i {
                color: #adb3bc;
                margin-right: 12px;
                font-size: 16px;
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          &--right {
            @media screen and (max-width: 768px) {
              // margin-top: 20px;
            }
            &--headerText {
              font-family: Hind;
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #444444;
            }
            &--bottomText {
              font-family: Hind;
              font-style: normal;
              font-weight: 300;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #444444;
              margin-top: 10px;
            }
          }
        }
        &__address {
          div {
            &:first-child {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;

              color: #444444;
            }
            &:last-child {
              font-family: "Hind";
              font-style: normal;
              font-weight: 300;
              margin-top: 10px;
              font-size: 16px;
              line-height: 24px;
              color: #444444;
            }
          }
        }
        &__yearlyConsumption {
          div {
            &:first-child {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;

              color: #444444;
            }
            &:last-child {
              font-family: "Hind";
              font-style: normal;
              font-weight: 300;
              margin-top: 10px;
              font-size: 16px;
              line-height: 24px;
              color: #444444;
            }
          }
        }
      }
    }
    &__footerButtons {
      display: grid;
      grid-template-columns: 332px 332px;
      gap: 30px;
      margin-bottom: 40px;
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        button {
          &:last-child {
            margin-top: 0px;
          }
        }
      }
    }
  }
}
