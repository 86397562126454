.elektrik-kesintileri-kesinti-bildir-step3 {
  padding-inline: 30px;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__headerText {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #f3bc45;
  }

  &__description {
    margin-top: 30px;
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }

  &__card {
    display: flex;
    align-items: center;
    width: 332px;
    margin: 0 auto;
    margin-top: 30px;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f3f3f3;
    border-radius: 10px;

    &__item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &__left {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #486072;
        min-width: 130px;
      }

      &__right {
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #486072;

        i {
          margin-left: 5px;
          font-size: 18px;
          filter: gray saturate(0%) brightness(70%) contrast(1000%);

          &:hover {
            cursor: pointer;
          }
        }
      }

      .addressPart {
        display: inline-block;
        width: 160px;
        word-wrap: break-word;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}