.customer-operations-home-page {
  margin-bottom: 30px;
  &__item {
    background-color: #f3f3f3;
    padding: 20px;
    margin-top: 30px;
    border-radius: 10px;
    &__body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &__left {
        display: flex;
        align-items: center;
        img {
          margin-right: 20px;
        }
        span {
          font-family: Hind;
          font-size: 20px;
          line-height: 1.5;
          letter-spacing: 0.4px;
          color: var(--lacivert);
        }
      }
      &__right {
        display: flex;
        align-items: center;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.6;
        letter-spacing: 0.3px;
        text-align: center;
        color: var(--turuncu);
        span {
          margin-right: 5px;
        }
        i {
          transform: scale(1.3);
          margin-right: 2px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }

    &__dropdown {
      display: none;
      width: 100%;
      background-color: #e8ecf2;
      padding: 20px;
      border-radius: 0 0 10px 10px;

      &:hover {
        cursor: pointer;
      }
      &__body {
        margin-left: 43px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
          margin-left: 0px;
        }

        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0px;
        }
        &__text {
          font-family: Hind;
          font-size: 20px;
          line-height: 1.5;
          letter-spacing: 0.4px;
          color: var(--lacivert);
        }
        &__detail {
          display: flex;
          a {
            width: 150px;
            @media screen and (max-width: 280px) {
              width: 100px;
            }
          }
          span {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            line-height: 1.6;
            letter-spacing: 0.3px;
            text-align: center;
            color: var(--turuncu);
            margin-right: 5px;
          }
        }
      }
    }
  }
}
