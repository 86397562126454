@import "../../../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-basvuru-islemleri-odeme-step1 {
  .customer-operations-abonelik-yaptirma-diger-abonelik-step1__body__belgeYukle__section__item__fileUpload__right {
    width: 100%;
    margin-left: 30px;
  }

  .customer-operations-abonelik-yaptirma-diger-abonelik-step1__body__belgeYukle__section__item__fileUpload__right__noUpload {
    background: rgba(243, 243, 243, 0.5);
    width: 100%;
    border-radius: 3px;
  }

  .customer-operations-abonelik-yaptirma-diger-abonelik-step1__body__belgeYukle__section__item__fileUpload__right__body__uploadedFile {
    background: rgba(243, 243, 243, 0.5);
    width: 100%;
    border-radius: 3px;
  }

  .dropzone {
    position: relative;
  }

  %obligation {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  .infoComponent {
    margin-top: 0;

    &__info {
      padding: 2px;
      display: flex;
      // justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;

      &__detail {
        display: flex;
        align-items: flex-start;

        &__icon {
          width: 24px;
          padding: 12px;
          height: 24px;
          margin-right: 14px;

          &__img {
            width: 20px;
            height: 20px;
          }
        }
      }

      button {
        margin: 0;
      }
    }
  }

  &__header {
    justify-content: space-between;
  }

  &__body {
    padding-inline: 30px;

    &__selectArea {
      margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(2, 332px);
      gap: 30px;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      input {
        margin-bottom: 0;
      }

      .customSelect {
        .ant-select-selection-item {
          opacity: 1;
        }
      }

      .customInput {
        width: 332px;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        .errorBox {
          margin-top: 0px;
        }
      }
    }

    &__checkboxArea {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      &__label {
        margin-top: 30px;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--lacivert);
      }

      &__checkbox {
        margin-top: 10px;
        padding: 12px;
        border: 1px solid #f3f3f3;
        gap: 10px;
        border-radius: 3px;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .rounded-checkbox {
          align-items: center;
          cursor: pointer;

          .spanFirst {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
          }
        }

        &__right {
          display: flex;
          justify-content: space-between;
          width: 100%;

          // @media screen and (max-width: 768px) {
          //   display: flex;
          //   flex-direction: column;
          //   align-items: flex-start;
          //   justify-content: center;

          // }
        }

        &__left {
          display: flex;
          width: 100%;

          @media screen and (max-width: 768px) {
            align-items: center;
          }

          &__text {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #444444;

            @media screen and (max-width: 420px) {
              display: flex;
              flex-direction: column;
            }

            span {
              &:first-child {
                @media screen and (max-width: 420px) {
                  display: none;
                }
              }
            }
          }
        }

        &__price {
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: right;
          color: #444444;
        }
      }
    }

    &__card {
      width: 694px;
      background-color: #f3f3f3;
      border-radius: 10px;
      height: max-content;
      margin: 30px 0;

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;

        div {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          align-items: center;
          color: #486072;

          &:last-child {
            margin-top: 10px;
          }

          &:first-child {
            color: #f3bc45;
          }
        }
      }
    }

    &__option {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      input {
        width: 100%;
      }

      &__select {
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #486072;

        .customSelect {

          .ant-select-selection-item {
            opacity: 1;
          }

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }

      .customInput {
        width: 332px;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        input {
          margin-bottom: 0px !important;
        }

        .errorBox {
          padding: 12px 12px 0px 12px;
          margin-top: 0px;
          margin-left: 0px;
          width: 292px;
        }
      }
    }

    &__notes {
      margin-top: 30px;

      .customTextArea {
        width: 100%;
      }
    }

    &__file {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: #486072;
        margin-bottom: -10px;
      }

      &__button {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        button {
          margin-top: 30px;
        }

        .obligation {
          @extend %obligation;
          margin-left: 30px;
        }

        .obligation2 {
          @extend %obligation;
          margin-top: 30px;
          display: none;
        }
      }
    }

    &__text {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: var(--pure-black);
      margin-top: 30px;
      margin-bottom: 30px;

      &__highlight {
        font-weight: 600;
        color: var(--turuncu);
      }
    }

    &__firstlabel {
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--lacivert);
      margin-bottom: 10px;
    }

    .yellowLabel {
      color: #f3bc45;
    }

    &__resend {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      &__label {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--lacivert);
      }

      &--resendBtn {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 0.3px;
        text-align: center;
        color: var(--turuncu);
        margin-left: 20px;
        display: flex;
        align-items: center;

        label {
          margin-right: 10px;

          &:hover {
            cursor: pointer;
          }
        }

        img {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__modal {
    .modal__content {
      max-height: 682px !important;
    }

    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
      margin-bottom: 30px;
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }

    &__buttons {
      width: 100%;
    }

    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }

      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;

        span {
          font-size: 22px;
          font-weight: 600;

          a {
            color: #444;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .customer-operations-basvuru-islemleri-odeme-step1 {
    &__body {
      padding-inline: 0px;

      &__card {
        width: 100% !important;
      }

      &__file {
        &__button {
          flex-direction: column;

          .obligation {
            display: none;
          }

          .obligation2 {
            display: block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-abonelik-yaptirma-diger-abonelik-step1__body__belgeYukle__section__item__fileUpload__right {
    margin-left: 0px !important;
  }

  .customer-operations-basvuru-islemleri-odeme-step1 {
    .dropzone {
      width: 100%;
    }

    .infoComponent {
      &__info {
        flex-direction: column;

        button {
          display: block;
          margin-top: 0px;
          width: 100% !important;
        }
      }
    }

    &__body {
      &__option {
        flex-direction: column;
        gap: 30px;
      }

      &__belgeYukle {
        &__section {
          &__item {
            &__fileUpload {
              &__right {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

.selected-g {
  .ant-select-selection-item {
    opacity: 1 !important;
  }
}