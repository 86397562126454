.more-info-enerjisa-pozitif {
  &__prev {
    margin-bottom: 30px;
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    width: 150px;
    color: #000;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 694px;
    margin: 0 auto;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      width: 100% !important;
      margin-bottom: 30px;
    }

    &__btn {
      margin-top: 20px;

      @media (max-width: 1024px) {
        width: 100% !important;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      width: 100% !important;
      padding: 12px;
      border-radius: 10px;
      box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 #fff;
      background-color: #fff;

      &__image {
        border-radius: 10px;
        width: 100% !important;

        // height: 259px;
        @media (max-width: 768px) {
          width: 100% !important;
        }
      }

      &__text {
        font-family: Hind;
        font-size: 26px;
        font-weight: 600;
        line-height: 1.4;
        text-align: left;
        color: #444;
        margin-top: 12px;
      }

      &__details {
        display: flex;
        align-items: center;
        margin-top: 12px;

        &:hover {
          cursor: pointer;
        }

        &__text {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          line-height: 1.6;
          letter-spacing: 0.3px;
          text-align: center;
          color: #f3bc45;
          margin-right: 10px;
        }
      }
    }
  }



  .infoBox {
    @media (max-width: 768px) {
      height: 80px;
    }

    .infoBox__img.opacity {
      @media (max-width: 768px) {
        width: 100px !important;
        margin-right: 15px !important;
      }
    }
  }

  .infoBox__title {
    @media (max-width: 768px) {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0.56px;
      color: #fff;
      font-family: Hind;
      margin-left: 30px;
      margin-right: 10px;
      line-height: 1.3;
    }

    br {
      @media (min-width: 768px) {
        display: none !important;
      }
    }

  }
}