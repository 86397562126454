.musteriIslemleri {
  .backBtn {
    margin: 0 !important;
    margin-bottom: 30px !important;
  }
}

.musteri-islemleri-abonelik-yaptirma-abonelik-step3 {
  margin-top: 30px;
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__body {
    padding-inline: 30px;
    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }
    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: var(--pure-black);
      margin-top: 30px;
      margin-bottom: 30px;
    }
    &__firstlabel {
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--lacivert);
      margin-bottom: 10px;
    }
    &--smsCodeCont {
      display: flex;
      margin-bottom: 30px;
      align-items: center;
      margin-top: 10px;
      @media (max-width: 600px) {
        flex-direction: column;
      }
      &--countDown {
        display: flex;
        @media (max-width: 600px) {
          width: 100%;
          align-items: center;
          justify-content: center;
        }
        span {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          color: #000000;
          margin-left: 10px;
        }
        margin-left: 30px;
        @media (max-width: 600px) {
          margin-top: 30px;
          margin-left: 0px;
        }
      }
      @media (max-width: 600px) {
      }
    }
    .yellowLabel {
      color: #f3bc45;
    }
    &__resend {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      &__label {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--lacivert);
      }
      &--resendBtn {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 0.3px;
        text-align: center;
        color: var(--turuncu);
        margin-left: 20px;
        display: flex;
        align-items: center;
        label {
          margin-right: 10px;
          &:hover {
            cursor: pointer;
          }
        }
        img {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
