.hesabim-iletisim-tercihleri {
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__body {
    padding-inline: 30px;
    @media (max-width: 768px) {
      padding-inline: 0px;
    }
    &__card {
      background-color: var(--soft-grey);
      padding: 20px;
      margin-top: 30px;
      border-radius: 10px;
      &__header {
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: var(--turuncu);
      }
      &__title {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #444;
        margin-top: 20px;
      }
      &__description {
        font-family: Hind;
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #444;
        margin-top: 20px;
        &__highlight {
          &:hover {
            cursor: pointer;
          }
          font-weight: 600;
          color: var(--turuncu);
        }
      }
      &__hr {
        height: 1px;
        background-color: #dadada;
        width: 100%;
        margin-top: 20px;
      }
      &__checkboxArea {
        display: flex;
        flex-direction: column;
        &__checkbox {
          display: flex;
          align-items: center;
          width: 45%;
          justify-content: space-between;
          margin-top: 20px;
          @media (max-width: 768px) {
            width: 100%;
          }
          span {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #444;
          }
        }
      }
    }
    &__footer {
      margin-top: 30px;
      &__description {
        font-family: Hind;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #444;
        b {
          font-weight: 600;
        }
      }
    }
  }
  &__modal {
    .modal2__content {
      width: 754px !important;
      height: 605px !important;
      max-height: 605px !important;
      max-width: 754px !important;
      align-items: flex-start !important;
      @media (max-width: 768px) {
        width: calc(100% - 25px) !important;
      }
    }
    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: left;
      color: var(--lacivert);
    }
    .rpv-core__viewer {
      width: 694px !important;
      @media (max-width: 768px) {
        width: 100% !important;
      }
    }
    .rpv-core__viewer .rpv-core__canvas-layer {
      width: 100% !important;
    }

    .rpv-core__viewer .rpv-core__inner-page {
      height: 100% !important;
    }
    &__pdfViewer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      width: 100%;
      a {
        @media (max-width: 768px) {
          width: 100% !important;
        }
      }
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      @media (max-width: 768px) {
        width: 100% !important;
      }
      button {
        margin-top: 8px;
      }
    }
  }
  .infoComponent_basvuru {
    .infoComponent {
      &__info {
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 3px;
        @media screen and (max-width: 1180px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &__detail {
          display: flex;
          align-items: flex-start;
          &__icon {
            width: 24px;
            padding: 12px;
            height: 24px;
            margin-right: 14px;
            &__img {
              width: 20px;
              height: 20px;
            }
          }
        }

        button {
          margin: 0;

          @media screen and (max-width: 768px) {
            display: block;
            margin-top: 0px;
            width: 100% !important;
          }
        }

        .randevu-detay__info__infoButton {
          button {
            @media screen and (max-width: 1180px) {
              width: 100% !important;
            }
          }

          @media screen and (max-width: 1180px) {
            width: 100% !important;
            padding: 0 10px 10px 10px;
          }
        }

        .randevu-detay__info__infoButton2 {
          padding: 9px 10px;

          button {
            @media screen and (max-width: 1180px) {
              width: 100% !important;
            }
          }

          @media screen and (max-width: 1180px) {
            width: 100% !important;
            padding: 0 10px 10px 10px;
          }
        }
      }

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }

  .customer-operations-basvuru-islemleri-basvuru-olustur__modal {
    .customInput  {
      width: unset !important;
    }
    .modal2__content {
      max-height: 800px !important;
    }
    .errorBoxLogin__errorText {
      font-size: 15px !important;
    }
  }
}
