.yasal-bilgiler-aydinlatma {
  &__prev {
    width: 150px;
    margin-bottom: 30px;
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  .aydinlatma-metni-page {
    background-color: #f3f3f3;
    padding: 20px;
    margin-top: 30px;
    border-radius: 10px;
    &__text {
      font-family: Hind;
      font-size: 25px;
      font-weight: 600;
      line-height: 1.2;
      text-align: left;
      color: #f3bc45;
    }
    &__pdfviewer {
      margin-top: 20px;
      .rpv-core__viewer {
        width: 100% !important;
        height: 800px !important;

        @media (max-width: 768px) {
          width: 100% !important;
          height: 490px !important;
        }
      }
      .rpv-core__viewer .rpv-core__inner-pages {
        div {
          &:first-child {
            background-color: #878787;
          }
        }
      }
      .rpv-core__viewer .rpv-core__canvas-layer {
        width: 100% !important;
        background-color: #878787;
        @media (max-width: 768px) {
          width: 100% !important;
        }
      }
      .rpv-core__viewer .rpv-core__inner-page {
        border-radius: 3px;
      }
    }
  }
}
