@import "../../../assets/styles/layouts/authLayout.scss";

.randevu-guncelleme {
  @include authLayout;
  &__content {
    &--left {
      &--prev {
        width: fit-content;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 30px;
        &:hover {
          cursor: pointer;
        }
        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
    }
    &--right {
      margin-top: 57px;
      .progressBox {
        height: 154px;
      }
    }
  }
}
