.header-tabs-one {
  background-color: #e8f2ff;
  height: 534px;
  border-radius: 10px;
  padding: 30px;
  position: absolute;
  width: 754px;
  top: 100px;
  z-index: 9999;
  @media (max-width: 1200px) {
    width: 100% !important;
  }
  @media (max-width: 1025px) {
    display: none;
  }
  &__headerText {
    font-family: Hind;
    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    color: #486072;
  }
  &__nav {
    display: flex;
    flex-wrap: wrap;
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;
      margin-top: 12px;
      width: calc(50% - 15px);
      height: 138px;
      &:hover {
        cursor: pointer;
      }
      &:nth-child(2) {
        margin-left: 30px;
      }
      &:nth-child(4) {
        margin-left: 30px;
      }
      &:nth-child(6) {
        margin-left: 30px;
      }
      @media (max-width: 1250px) {
        width: calc(50% - 15px);
      }
      &__icon {
        padding-left: 10px;
        @media (max-width: 1250px) {
          padding: 0px;
          padding-left: -20px;
        }
      }
      &__rightarrow {
        padding-right: 10px;
        filter: invert(36%) sepia(18%) saturate(745%) hue-rotate(163deg)
          brightness(91%) contrast(85%);
        width: 45px;
        @media (max-width: 1250px) {
          padding: 0px;
        }
        @media (max-width: 1090px) {
          display: none;
        }
      }
      &__text {
        display: flex;
        flex-direction: column;
        padding: 10px;
        @media (max-width: 1250px) {
          padding: 0px;
        }
        &__big {
          font-family: Hind;
          width: 170px;
          font-size: 20px;
          font-weight: 600;
          line-height: 1.25;
          text-align: left;
          color: #486072;
        }
        &__small {
          width: 197px;
          margin-top: 8px;
          font-family: Hind;
          font-size: 14px;
          line-height: 1.43;
          text-align: left;
          color: #486072;
        }
      }
    }
  }
}

.header-tabs-two {
  background-color: #e8f2ff;
  height: 534px;
  border-radius: 10px;
  padding: 30px;
  position: absolute;
  width: 754px;
  top: 100px;
  z-index: 9999;
  @media (max-width: 1200px) {
    width: 100% !important;
  }
  @media (max-width: 1025px) {
    display: none;
  }
  &__headerText {
    font-family: Hind;
    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    color: #486072;
  }
  &__nav {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;
      margin-top: 30px;
      width: calc(50% - 15px);
      height: 60px;
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      &:hover {
        cursor: pointer;
        height: 88px;
        margin-top: 15px;
        border: 2px solid #486072;
        .header-tabs-two__nav__item__text__desc {
          display: block;
        }

        transition: height 0.3s, margin-top 0.3s;
      }

      img {
        height: 30px;
        margin-right: 10px;
        filter: invert(36%) sepia(18%) saturate(745%) hue-rotate(163deg)
          brightness(91%) contrast(85%);
        width: 35px;
      }
      &:nth-child(2) {
        margin-left: 30px;
      }
      &:nth-child(4) {
        margin-left: 30px;
      }
      &:nth-child(6) {
        margin-left: 30px;
      }
      &:nth-child(8) {
        margin-left: 30px;
      }
      &:nth-child(10) {
        margin-left: 30px;
      }
      @media (max-width: 1250px) {
        width: calc(50% - 15px);
      }
      &__icon {
        padding-left: 10px;
      }
      &__rightarrow {
        padding-right: 10px;
      }
      &__text {
        display: flex;
        flex-direction: column;
        padding: 10px;
        font-family: Hind;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #486072;
        &__desc {
          display: none;
          font-family: Hind;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          text-align: left;
          color: #486072;
        }
      }
    }
    &__item {
      &:nth-child(odd) {
        &:hover {
          + .header-tabs-two__nav__item {
            + .header-tabs-two__nav__item {
              animation: margintop 0.3s forwards;
              + .header-tabs-two__nav__item {
                animation: margintop 0.3s forwards;
              }
            }
          }
        }
      }
      &:nth-child(even) {
        &:hover {
          + .header-tabs-two__nav__item {
            animation: margintop 0.3s forwards;
            + .header-tabs-two__nav__item {
              animation: margintop 0.3s forwards;
            }
          }
        }
      }
    }
  }
}

@keyframes margintop {
  0% {
    margin-top: 30px;
  }
  100% {
    margin-top: 17px;
  }
}
