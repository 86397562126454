.dilekce-basvuru-list-view {
  margin-top: 30px;

  &__text {
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #444;
  }

  .ant-select-selector {
    height: 44px !important;
  }

  .ant-select-selection-item {
    margin-top: 7px;
  }

  &__selectArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &__select {
      display: flex;
      flex-direction: column;
      width: 332px !important;
      position: relative;

      @media screen and (max-width: 768px) {
        margin-bottom: 30px !important;

        &:last-child {
          margin-bottom: 0 !important;
        }

        width: 100% !important;
      }

      label {
        margin-bottom: 10px;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--lacivert);
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
      button {
        margin-top: 30px;
      }

      &:last-child {
        margin-bottom: 67px;
      }
    }
  }

  &__mhm {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;

      &__card {
        margin-bottom: 30px;
        width: 100% !important;

        &:last-child {
          margin-bottom: 10px;
        }
      }
    }

    &__card {
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 10px;
      width: 332px;
      min-height: 167px;

      @media screen and (max-width: 768px) {
        margin-bottom: 0 !important;
      }

      &__checkbox {}

      &__description {
        font-family: Hind;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #444;
        margin-top: 10px;
      }

      &__hr {
        height: 1px;
        background-color: #dadada;
        margin: 10px 0;
      }

      &__address {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #444;
        width: 282px;
      }
    }
  }

  .sogutozu__modal {
    .modal2__content {
      max-width: 900px;
      max-height: fit-content;
      width: fit-content;
      height: fit-content;

      @media (max-width: 768px) {
        width: calc(100% - 30px);
      }

      .dilekce-basvuru-list-view_sogutozu__modal {

        &__description {
          img {
            width: 100%;
          }
        }

        &__button {
          display: flex;
          justify-content: space-around;
          width: 100%;
          gap: 30px;

          @media (max-width: 1024px) {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            gap: 0px;
          }
        }
      }
    }
  }
}

.activeSele {
  .ant-select-selection-item {
    opacity: 1 !important;
  }
}