.customer-operations-abonelik-onayi-single-page-step2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-inline: 30px;
  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }
  &__header {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #f3bc45;
    margin-top: 30px;
  }
  &__description {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 30px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    &__button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      @media screen and (max-width: 768px) {
        button {
          width: 100%;
        }
      }
    }
    img {
      position: absolute;
      left: -55px;
      top: 75px;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .address {
      text-align: right !important;
    }
    &__card {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      margin: 0 auto;
      margin-top: 30px;
      padding: 20px;
      background-color: var(--soft-grey);
      width: 332px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .infoComponent {
        margin-top: 20px;
      }
      &__button {
        button {
          margin-top: 20px;
        }
      }
      &__area2 {
        display: grid;
        grid-template-columns: auto 70px;
      }
      &__area3 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 20px;
        div {
          &:first-child {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #486072;
          }
          &:last-child {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: right;
            color: #486072;
          }
        }
      }
      &__area {
        display: grid;
        grid-template-columns: auto 130.5px;
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        &__left {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          @media(max-width: 768px) {
            display: flex;
            align-items: center;
          }
        }
        &__right {
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: right;
          color: var(--lacivert);
        }
      }
    }
  }
}
