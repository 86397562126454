.legalInformationPageGoBack {
  font-family: Hind;
  font-size: 15px;
  font-weight: 600;
  width: 150px;
  text-align: left;
  color: #000000;
  margin-bottom: 30px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.more-info-legal-information {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
    &__text {
      display: flex;
      align-items: center;
      img {
        margin-right: 20px;
      }
      span {
        font-family: Hind;
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: 0.4px;
        text-align: left;
        color: #486072;
      }
    }
    &__details {
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      span {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.6;
        letter-spacing: 0.3px;
        text-align: center;
        color: #f3bc45;
        margin-right: 10px;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
