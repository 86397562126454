.customer-operations-guvence-bedeli-bilgilerim {
  .ant-select-selection-item {
    opacity: 1 !important;
  }
  .infoComponent {
    margin-bottom: 0 !important;
    &__info {
      padding: 12px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 3px;
      @media screen and (max-width: 768px) {
        display: block;
      }
      &__infoArea {
        display: flex;
        align-items: flex-start;

        @media screen and (max-width: 768px) {
        }
        &__icon {
          width: 24px;
          margin: 0 !important;
        }
        &__text {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          margin-right: -8px;
          padding: 0px;
          margin-left: 15px !important;
          width: 274px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }

      button {
        margin-top: 0px;
        @media screen and (max-width: 768px) {
          display: block;
          margin-top: 10px;
        }
      }
    }
  }
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__body {
    padding-inline: 30px;
    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }
    &__select {
      margin-top: 30px;
    }
    &__card {
      padding: 20px;
      background-color: #f3f3f3;
      margin: 30px 0;
      width: 100%;
      border-radius: 10px;
      &__hr {
        border-top: 1px solid #dadada;
        opacity: 1;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      &__header {
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        line-height: 1.2;
        text-align: left;
        color: var(--turuncu);
      }

      &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 85%;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
        }
        &__detail {
          display: flex;
          flex-direction: column;
          position: relative;

          &__copy {
            display: flex;
            align-items: center;
            font-family: "Hind";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
          }

          span {
            &:hover {
              // cursor: pointer;
              .tooltip {
                visibility: visible;
                opacity: 1;
              }
            }
            &:first-child {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              color: #444;
              margin-bottom: 10px;
              &:hover {
                .tooltip {
                  visibility: visible;
                }
              }
            }
            &:last-child {
              font-family: Hind;
              font-size: 16px;
              // font-weight: 300;
              line-height: 1.5;
              color: #444;
            }
            img {
              margin-left: 5px;
            }
          }
          > div {
            display: flex;
            align-items: center;
            font-family: Hind;
            font-size: 16px;
            font-weight: 300;
            line-height: 1.5;
            color: #444;
            i {
              margin-right: 10px;
              color: #adb3bc;
              transform: scale(1.2);
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      &__whiteInfo {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          flex-wrap: wrap;
        }
        &__body {
          border: 1px solid #486072;
          background-color: #fff;
          padding: 20px;
          width: 317px;
          margin-top: 30px;
          border-radius: 3px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          &__taksit {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            color: #444;
          }
          &__date {
            display: flex;
            justify-content: space-between;
            width: 100%;
            span {
              margin-top: 10px;
              &:first-child {
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                color: #444;
              }
              &:last-child {
                font-family: Hind;
                font-size: 16px;
                font-weight: 300;
                line-height: 1.5;
                text-align: right;
                color: #444;
              }
            }
          }
        }
      }
    }
  }
  .higherTooltip__left__span__top__tooltip__content {
    @media screen and (max-width: 768px) {
      width: calc(100vw - 48px) !important ;
    }
  }
}
.mInfo {
  @media (max-width: 1180px) {
    .mInfo .higherTooltip__left__span__top__tooltip:after {
      left: 156% !important;
    }
    .higherTooltip__left__span__top__tooltip__content {
      width: 75vw !important;
      left: 10px !important;
    }
  }
}
