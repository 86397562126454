.register {
  line-height: 1.414;
  margin-top: 1px;
  
  &__desciription {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: #486072;
    margin-bottom: 20px;
  }
  &__button {
    cursor: pointer;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 1;
    justify-content: center;
    border: none;
    border-radius: 3px;
    background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%);
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #fff;
    padding-top: 3px;
  }
  &__button:not(:last-child) {
    margin-bottom: 30px;
  }
  &__button2 {
    cursor: pointer;
    height: 65px;
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 1.5;
    justify-content: center;
    border: solid 1px #f3bc45;
    border-radius: 3px;
    background-color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #f3bc45;
    padding-top: 3px;
    margin-bottom: 19px;
  }
  &__divider {
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin-bottom: 20px;
  }
  &__content {
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: #486072;
    margin-bottom: 20px;
  }
}
