.more-info-enerjisa-pozitif-blog {
  &__prev {
    margin-bottom: 30px;
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    color: #000;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-inline: 30px;
    margin-top: 30px;

    margin-bottom: 50px;

    @media (max-width: 768px) {
      padding-inline: 0px !important;
    }

    &__image {
      border-radius: 10px;
    }

    &__headerText {
      display: flex;
      // justify-content: center;
      align-items: center;
      margin-top: 30px;
      font-family: Hind;
      font-size: 29px;
      font-weight: 600;
      line-height: 1.07;
      letter-spacing: 0.56px;
      text-align: left;
      color: #f3bc45;
    }

    &__dateText {
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      text-align: left;
      color: #000;
      margin-top: 30px;
    }

    &__bodyText {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      margin-top: 30px;

      .text-style-1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.5;
        margin-top: 30px;
      }

      .text-style-2 {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.5;
      }

      .text-style-3 {
        font-size: 20px;
        font-weight: 500;
      }

      a {
        color: #f3bc45;
        font-weight: 500;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }



  .infoBox {
    @media (max-width: 768px) {
      height: 80px;
    }


    .infoBox__img.opacity {
      @media (max-width: 768px) {
        width: 100px !important;
        margin-right: 15px !important;
      }
    }
  }

  .infoBox__title {
    @media (max-width: 768px) {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0.56px;
      color: #fff;
      font-family: Hind;
      margin-left: 30px;
      margin-right: 10px;
      line-height: 1.3;
    }

    br {
      @media (min-width: 768px) {
        display: none !important;
      }
    }
  }

}