.customer-operations-abonelik-islemleri-yaptirma {
  @media (max-width: 768px) {
    .customInput {
      width: 100%;
    }
    .selB {
      width: 100%;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e8ecf2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #486072;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .infoComponent {
    &__info {
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;
      @media screen and (max-width: 1180px) {
        flex-direction: column;
        align-items: flex-start;

      }

      &__detail {
        display: flex;
        align-items: flex-start;
        &__icon {
          width: 24px;
          padding: 12px;
          height: 24px;
          margin-right: 14px;
          &__img {
            width: 20px;
            height: 20px;
          }
        }
      }

      button {
        margin: 0;

        @media screen and (max-width: 768px) {
          display: block;
          margin-top: 0px;
          width: 100% !important;
        }
      }

      .randevu-detay__info__infoButton {
        button {
          @media screen and (max-width: 1180px) {
            width: 100% !important;
          }
        }

        @media screen and (max-width: 1180px) {
          width: 100% !important;
          padding: 0 10px 10px 10px;
        }
      }

      .randevu-detay__info__infoButton2 {
        padding: 9px 10px;

        button {
          @media screen and (max-width: 1180px) {
            width: 100% !important;
          }
        }

        @media screen and (max-width: 1180px) {
          width: 100% !important;
          padding: 0 10px 10px 10px;
        }
      }
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__body {
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__items {
      display: flex;
      align-items: flex-start;
      margin-top: 30px;
      width: 100%;
      .customInput .errorBox {
        padding-right: 0px;
        // border: 1px solid #ff0000 !important;
        margin-top: -10px !important;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      &__query {
        display: flex;
        align-items: center;
        margin-left: 30px;
        margin-top: 43px;
        color: #f3bc45;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 0.3px;
        text-align: center;
        &:hover {
          cursor: pointer;
        }

        @media screen and (max-width: 768px) {
          margin-top: 10px;
          margin-left: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
    }

    &__button {
      @media screen and (max-width: 768px) {
        margin-top: 30px;
      }

      button {
        margin-top: 10px !important;
      }
    }
    &__showMore {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      &__selectArea {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          .customSelect {
            &:last-child {
              margin-top: 30px;
            }
          }
        }
      }
      &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 1025px) {
          flex-direction: column;
          margin-bottom: 80px;
        }
        &__info {
          display: flex;
          align-items: center;
          width: 332px;
          height: 50px;
          background: #ffffff;
          border: 1px solid rgba(68, 68, 68, 0.5);
          border-radius: 3px;
          padding: 12px;
          gap: 10px;
          margin-top: 30px;
          div {
            margin-left: 2px;
            display: flex;
            font-family: Hind;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 13px;
            color: rgba(68, 68, 68, 0.5);
          }
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
    &__modalNormal {
      b {
        font-weight: 600;
      }
      &__buttons {
        width: 100%;
      }
      &__description {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: var(--black);
        a {
          color: var(--black);
          font-weight: 600;
          text-decoration: underline;
        }
        .spanBold {
          color: var(--black);
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
    &__modal {
      .modal2__content {
        width: 100%;
        max-width: 528px;
        height: auto;
        max-height: none;
        @media screen and (max-width: 600px) {
          width: calc(100% - 30px);

          overflow-y: auto;
          max-height: calc(100vh - 30px);
          justify-content: start;
        }
        @media screen and (max-height: 768px) {
          height: calc(100% - 30px);
          justify-content: start;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }

      &__header {
        font-family: Hind;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: 0.56px;
        text-align: center;
        color: var(--lacivert);
        margin-bottom: 30px;
      }
      &__content {
        display: flex;
        flex-direction: column;
        &__item {
          display: flex;
          flex-direction: column;
          &__hr {
            width: 100%;
            height: 1px;
            background-color: #f3f3f3;
            margin-bottom: 10px;
          }
          &__header {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &__left {
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #f3bc45;
              border-radius: 50%;
              margin-right: 10px;
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
            }
            &__right {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }
          }
          &__info {
            display: grid;
            grid-template-columns: 90px 1fr;
            margin-bottom: 30px;
            align-items: center;

            b {
              font-weight: 600 !important;
            }
            img {
              margin-right: 18px;
            }
            div {
              font-family: Hind;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }
          }
        }
        &__buttons {
          button {
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
    &__modalTesisat {
      .modal2__content {
        width: 100%;
        max-width: 528px;
        height: auto;
        max-height: max-content;
        @media screen and (max-width: 768px) {
          width: calc(100% - 30px);
          overflow-y: auto;
          justify-content: start;
        }
        @media screen and (max-height: 768px) {
          height: calc(100% - 30px);
          justify-content: start;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
      &__header {
        font-family: Hind;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: 0.56px;
        text-align: center;
        color: var(--lacivert);
        margin-bottom: 30px;
      }
      &__content {
        display: flex;
        flex-direction: column;
        &__item {
          display: flex;
          flex-direction: column;
          &__hr {
            width: 100%;
            height: 1px;
            background-color: #f3f3f3;
            margin-bottom: 10px;
          }
          &__header {
            display: grid;
            grid-template-columns: 40px 1fr;
            margin-bottom: 10px;
            &__left {
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #f3bc45;
              border-radius: 50%;
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
            }
            &__right {
              font-family: Hind;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }
          }
          &__info {
            display: grid;
            grid-template-columns: 40px 1fr;
            margin-bottom: 30px;
            @media screen and (max-width: 550px) {
              grid-template-columns: 1fr;
            }
            img {
              @media screen and (max-width: 550px) {
                width: calc(100%);
              }
            }
            div {
              font-family: Hind;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }
          }
        }
      }
    }
  }
}
