// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.information {
  font-family: "Hind", sans-serif !important;
  @media  (max-width: 600px) {
    width: 100% !important;
  }
  &__Prev {
    margin-top: 30px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
    img {
      width: 24px;
      height: 24px;
    }
    &__prevBtn {
      background-color: #fff;
      border: none;
      z-index: 1;
      cursor: pointer;
      margin-left: 10px;
      font-weight: 600;
      font-size: 15px;
      text-align: left;
      margin-top: 5px;
    }
    @media (max-width: 600px) {
      margin-left: 0px;
    }
  }
  @media (max-width: 600px) {
    margin-left: 0px;
  }
  &__text {
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
    text-align: left;
  }
  &__infoBox {
    width: 694px;
    height: 96px;
    border: 1px solid #486072;
    display: flex;
    align-items: stretch;
    margin-top: 30px;
    border-radius: 3px;
    @media  (max-width: 600px) {
      width: 100% !important;
      height: 144px;
    }
    @media(max-width: 390px){
      height: 165px !important;
    }
    @media(max-width: 350px){
      height: 190px !important;
    }
    @media(max-width: 322px){
      height: 220px !important;
    }
    img {
      width: 20px;
      height: 20px;
      margin-left: 12px;
      margin-top: 12px;
    }
    span {
      text-align: left;
      font-size: 15px;
      font-weight: 600;
      margin-left: 12px;
      margin-top: 12px;
      padding-right: 12px;
      width: 100%;
      overflow-wrap: break-word;
      line-height: 1.6;
      color: #486072;
      p {
        display: inline;
        color: #f3bc45;
      }
    }
  }
}
