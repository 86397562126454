@import "../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-elektrik-kesintileri-bildirim-detayi {
  @include withAuthLayout;

  %left {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #486072;
  }

  %right {
    font-family: "Hind";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: right;
  }

  %right2 {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    text-align: right;
    color: #f3bc45;
    width: 228px !important;
  }

  &__header {
    justify-content: space-between;
  }

  &__content {
    &--left {
      &__prev {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 30px;
        width: fit-content;

        &:hover {
          cursor: pointer;
        }
      }

      &__body {
        padding-inline: 30px;

        &__card {
          background-color: #f3f3f3;
          border-radius: 10px;
          padding: 20px;
          margin-top: 30px;

          &__map {
            margin-top: 20px;
          }

          &__header {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            color: #f3bc45;
          }

          &__button {
            button {
              margin-top: 20px;
            }
          }

          &__activeApplications {
            background: #fff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            padding: 12px;
            margin-top: 20px;

            hr {
              margin-top: 10px;
              border: 0.5px solid #f3f3f3 !important;
            }

            .type {
              display: flex;
              justify-content: space-between;

              @media screen and (max-width: 768px) {
                align-items: center;
              }

              &:not(&:first-child) {
                margin-top: 10px;
              }

              &__left {
                @extend %left;
              }

              &__right {
                @extend %right;
              }

              &__right2 {
                @extend %right2;
              }
            }
          }

          &__activeApplications2 {
            background: #fff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // justify-content: space-between;
            padding: 20px;
            margin-top: 13px;

            hr {
              display: none;
              border: 0.5px solid #f3f3f3 !important;
            }

            .type {
              display: flex;
              margin-top: 10px;
              align-items: baseline;
              justify-content: space-between;
              // flex-direction: column;
              // width: 327px;

              &__top {
                font-family: "Hind";
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #486072;
              }

              &__bottom {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
                margin-left: 20px;

                @media screen and (max-width: 768px) {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }

    &--right {
      margin-top: 0 !important;

      .progressBox {
        margin: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .customer-operations-elektrik-kesintileri-bildirim-detayi__content {
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-elektrik-kesintileri-bildirim-detayi {
    &__content {
      &--left {
        &__prev {
          margin-bottom: 30px;
          width: fit-content;
        }

        &__body {
          padding-inline: 0px;

          &__card {
            &__description {
              flex-direction: column;
              width: 100%;

              &__inputs {
                &__input {
                  width: 100% !important;
                }
              }
            }

            &__activeApplications {
              img {
                margin-left: 0px;
                margin-right: 0px;
              }
            }

            &__activeApplications2 {
              flex-direction: column;
              margin-top: 20px;

              hr {
                display: block;
              }

              .type {
                justify-content: space-between;
                flex-direction: row;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.customer-operations-elektrik-kesintileri-bildirim-detayi__content--left__body__card__map {
  position: relative;

  .leaflet-left {
    right: 20px !important;
    left: auto !important;
  }

  // map + - button hide
  .leaflet-touch .leaflet-control-layers,
  .leaflet-touch .leaflet-bar {
    display: flex !important;
    flex-direction: column;
    top: 70px;
  }

  .leaflet-control-attribution {
    display: none;
  }
}