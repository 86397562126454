.maintainace_page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;

    @media(max-width:800px) {
        flex-direction: column-reverse;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    &_left {
        width: 352px;
        height: 581px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px;
        padding: 30px;
        border-radius: 10px;
        background-color: var(--white);

        @media(max-width:800px) {
            width: 295px;
            height: auto;
            justify-content: center;
            align-items: center;
        }

        &_title {
            width: 292px;
            height: 105px;
            flex-grow: 0;
            font-family: 'Hind', sans-serif;
            font-size: 28px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 35px;
            letter-spacing: 0.56px;
            text-align: left;
            color: var(--lacivert);

            @media(max-width:800px) {
                text-align: center;
            }
        }

        &_description {
            width: 292px;
            height: 96px;
            flex-grow: 0;
            font-family: 'Hind', sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 24px;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);

            @media(max-width:800px) {
                text-align: center;
            }
        }

        &_contact {
            width: 292px;
            height: 100px;
            flex-grow: 0;
            border-radius: 10px;
            background-color: var(--soft-grey);

            a {
                display: flex;
                justify-content: space-between;
                padding: 26px 20px 27px 30px;

                div {
                    width: 201px;
                    height: 47px;
                    flex-grow: 0;
                    margin: 0 0 0 17px;
                    font-family: 'Hind', sans-serif;
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 24px;
                    letter-spacing: normal;
                    text-align: center;
                    color: #000;

                    span {
                        font-size: 22px;
                        font-weight: 600;
                    }
                }

            }

            .ydaContact {
                display: flex;
                justify-content: space-between;
                padding: 26px 20px 27px 30px;

                div {
                    width: 201px;
                    height: 47px;
                    flex-grow: 0;
                    margin: 0 0 0 17px;
                    font-family: 'Hind', sans-serif;
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 24px;
                    letter-spacing: normal;
                    text-align: center;
                    color: #000;

                    span {
                        font-size: 22px;
                        font-weight: 600;
                    }
                }

            }
        }

        &_buttons {
            width: 292px;
            margin-top: -30px;
        }
    }

    &_right {
        width: 561px;
        margin: 80px 0px 0px -105px;

        @media(max-width:800px) {
            width: 295px;
            height: 349px;
            margin: 0;
        }

        img {
            @media(max-width:800px) {
                width: 295px;
                height: 409px;
            }
        }
    }
}