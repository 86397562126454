.musteri-islemleri-abonelik-yaptirma-abonelik-step1 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .btnss {
    button {
      margin-top: 0;
    }
  }

  @media (max-width: 768px) {
    .radioMO {
      flex-direction: column !important;
      justify-content: flex-start !important;
      width: 100%;
    }

    .radioMOIn {
      margin-right: auto;
      // margin-bottom: 10px;
    }

    .eTeminat {
      width: 100%;

      input {
        width: 100% !important;
        max-width: 100% !important;

        @media(max-width: 768px) {
          margin-top: 10px;
        }
      }
    }
  }

  .errorBox {
    margin-top: -10px !important;
  }

  .eTeminat {
    .errorBox {
      margin-top: 10px !important;
    }
  }

  .infoComponent {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .paddingB {
    .errorBox {
      margin-bottom: 10px;
    }
  }

  @mixin cardLayout {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
  }

  @mixin headerText {
    font-family: Hind;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: var(--turuncu);
    margin-bottom: 20px;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__body {
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__card {
      @include cardLayout;

      &__hr {
        width: 100%;
        height: 1px;
        background-color: #dadada;
        margin: 20px 0;
      }

      &__header {
        display: flex;
        flex-direction: column;

        div {
          &:first-child {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);
          }

          &:last-child {
            font-family: Hind;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);
            margin-top: 10px;
          }
        }
      }

      &__detail {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }

        &__left {
          div {
            &:first-child {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }

            &:last-child {
              font-family: Hind;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
              margin-top: 10px;
            }
          }
        }

        &__right {
          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }

          div {
            &:first-child {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }

            &:last-child {
              font-family: Hind;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
              margin-top: 10px;
            }
          }
        }
      }
    }

    &__dask {
      .customInput {
        .errorBox {
          margin-top: 10px !important;
        }
      }

      @include cardLayout;

      &__header {
        @include headerText;
      }

      &__description {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--pure-black);
      }

      &__input {
        margin-top: 20px;

        .customInput__label .higherTooltip__left__span__top__tooltip::after {
          @media screen and (max-width: 768px) {
            left: 54%;
          }
        }

        input {
          margin-bottom: 0;
        }
      }
    }

    &__guvenceBedel {
      b {
        font-weight: 600;
      }

      @include cardLayout;

      &__header {
        @include headerText;
      }

      &__tooltip {
        .higherTooltip__left__span__top {
          div {
            font-weight: 600 !important;
          }
        }

        .higherTooltip__left__span__top__tooltip__content {}

        .higherTooltip__left__span__top__tooltip {
          &:after {
            @media screen and (max-width: 768px) {
              left: 100%;
              top: -7px;
            }
          }
        }

        width: 230px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        &__price {
          font-family: Hind;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--black);
          margin-top: 10px;
        }
      }

      &__description {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--pure-black);
      }

      &__input {
        margin-top: 20px;
      }

      &__checkboxArea {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 20px;

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }

        &__checkbox {
          background-color: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 20px;
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--pure-black);

          @media(max-width: 768px) {
            height: 72px;
          }

          @media(min-width: 769px) {
            height: 50px;
          }

          a {
            color: #f3bc45;
          }

          .rounded-checkbox__label>div .checked {
            // margin-top: 1px;
          }

          &:first-child {
            u {
              color: #f3bc45;
              font-weight: 600;
            }
          }

          // &:last-child {
          //   margin-left: 10px;
          //   @media screen and (max-width: 768px) {
          //     margin-left: 0;
          //   }
          // }
          .rounded-checkbox {
            margin-left: 10px;
          }
        }
      }
    }

    &__mulkiyet {
      @include cardLayout;

      &__header {
        @include headerText;
      }

      &__description {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--pure-black);
      }

      &__toggleCategory {
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);
        height: 50px;
        margin-top: 20px;

        button {
          margin-top: 0;
        }

        &__hr {
          margin-top: 0px;
          height: 30px;
          width: 1px;
          margin-left: 5px;
          margin-right: 5px;
          background-color: #486072;
        }

        .ohm-tertiaryBtn {
          border-radius: 3px;
          background-color: rgba(255, 255, 255, 0.5);
          color: #f3bc45;
        }
      }

      &__moreSection {
        margin-top: 20px;

        // margin-bottom: 10px;
        &__checkboxArea {
          grid-template-columns: 130px 100px;
          display: grid;
          margin-top: 10px;
        }

        &__label {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
        }

        &__inputArea2 {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: -20px;
          width: 100%;
          margin-top: 20px;

          &__input2 {
            @media screen and (max-width: 768px) {
              width: 100%;

              &:last-child {
                margin-top: 20px;
              }
            }
          }

          .customInput {
            width: 317px;

            @media screen and (max-width: 768px) {
              width: 100% !important;

              input {
                width: 100% !important;
              }
            }

            input {
              width: 317px;
            }
          }

          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        }

        &__inputArea {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          margin-top: 20px;
          margin-bottom: -20px;

          &__input {
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }

          .customInput {
            width: 317px;

            @media screen and (max-width: 768px) {
              width: 100% !important;

              input {
                width: 100% !important;
              }
            }

            input {
              width: 317px;
            }
          }

          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        }
      }
    }

    &__modal {
      &__description {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: var(--black);
      }

      &__button {
        width: 100%;
      }
    }
  }

  &__eTeminatInfo {
    .infoComponent {
      margin-top: 0px;

      &__info {
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 3px;

        @media screen and (max-width: 1180px) {
          flex-direction: column;
          align-items: flex-start;

        }
        &__detail {
          display: flex;
          align-items: flex-start;
          &__icon {
            width: 24px;
            padding: 12px;
            height: 24px;
            margin-right: 14px;
            &__img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}