@import "../../../assets/styles/layouts/authLayout.scss";

.x-randevu-detay {
  @include authLayout;

  .leaflet-bottom .leaflet-right {
    display: none !important;

    .leaflet-control-attribution .leaflet-control {
      display: none !important;
    }
  }

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-control-zoom {
    display: none;
  }

  .customInput {
    input {
      width: 318px;
    }
  }

  .customInputDisabled {
    opacity: 1 !important;
  }

  .infoComponent {
    &__info {
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;
      @media screen and (max-width: 1180px) {
        flex-direction: column;
        align-items: flex-start;

      }

      &__detail {
        display: flex;
        align-items: flex-start;
        &__icon {
          width: 24px;
          padding: 12px;
          height: 24px;
          margin-right: 14px;
          &__img {
            width: 20px;
            height: 20px;
          }
        }
      }

      button {
        margin: 0;

        @media screen and (max-width: 768px) {
          display: block;
          margin-top: 0px;
          width: 100% !important;
        }
      }

      .x-randevu-detay__info__infoButton {
        button {
          @media screen and (max-width: 1180px) {
            width: 100% !important;
          }
        }

        @media screen and (max-width: 1180px) {
          width: 100% !important;
          padding: 0 10px 10px 10px;
        }
      }

      .x-randevu-detay__info__infoButton2 {
        padding: 9px 10px;

        button {
          @media screen and (max-width: 1180px) {
            width: 100% !important;
          }
        }

        @media screen and (max-width: 1180px) {
          width: 100% !important;
          padding: 0 10px 10px 10px;
        }
      }
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  &__content {
    &--left {
      &--prev {
        width: fit-content;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 30px;

        &:hover {
          cursor: pointer;
        }

        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
      }

      &__body {
        padding-inline: 30px;

        @media screen and (max-width: 768px) {
          padding-inline: 0px;
        }

        &__card {
          background-color: var(--soft-grey);
          border-radius: 10px;
          padding: 20px;
          margin-top: 30px;

          &__header {
            font-family: Hind;
            font-size: 25px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: var(--turuncu);
          }

          &__detail {
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
            margin-top: 20px;

            &__header {
              font-family: Hind;
              font-size: 17px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: #444;
            }

            &__info {
              font-family: Hind;
              font-size: 15px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.6;
              letter-spacing: normal;
              text-align: left;
              color: #444;
              margin: 10px 0;
            }

            &__hr {
              height: 1px;
              background-color: #dadada;
            }

            &__address {
              font-family: Hind;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: #444;
              margin-top: 10px;
            }

            &__button {
              button {
                margin-top: 10px;
              }
            }
          }

          &__inputs {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            &__input {
              margin-top: 20px;

              &:nth-child(odd) {
                // margin-right: 2px; //? changed & added
              }

              @media screen and (max-width: 768px) {
                width: 100% !important;
              }

              input {
                margin-bottom: 0;
              }
            }

            @media screen and (max-width: 768px) {
              flex-direction: column;

              input {
                margin-bottom: 0;
                width: 100% !important;
              }
            }
          }
        }

        &__buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;

          button {
            @media screen and (max-width: 1024px) and (min-width: 768px) {
              width: 332px !important;
            }
          }

          @media screen and (max-width: 768px) {
            flex-direction: column;
            width: 100% !important;
          }
        }
      }
    }

    &--right {
      margin-top: 84px;

      &__map {
        padding: 20px 20px 19px;
        border-radius: 10px;
        background-color: var(--cool-grey);

        .leaflet-container {
          z-index: 50;

          .x-randevu-detay__content--right__map__address {
            position: absolute;
            z-index: 9999;
            background-color: #fff;
            width: 282px;
            padding: 20px;
            border-radius: 10px;
            left: 5%;
            top: 5%;

            &__header {
              font-family: Hind;
              font-size: 17px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: #444;
              margin-bottom: 10px;
            }

            &__hr {
              height: 1px;
              background-color: #dadada;
            }

            &__detail {
              font-family: Hind;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: #444;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;

    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
      margin-top: 30px;
    }

    &__button {
      width: 100%;
    }
  }
}
