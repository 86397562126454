.corporate-register {
  .corporate-step3 {
    .webcam {
      &__bottom {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center !important;

        video {
          max-width: 292px !important;
        }

        &__captured {
          img {
            width: 292px;
            max-width: 292px;
            height: 292px;
            max-height: 292px;
          }
        }
      }
    }
  }
}