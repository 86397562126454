@import "../../../assets/styles/layouts/authLayout.scss";

.randevu-sorgula {
  @include authLayout;

  .progressBox {
    height: 154.09px;
  }

  &__content {
    &--left {
      .infoBox {
        img {
          opacity: 1 !important;
          width: 120px !important;
        }
      }

      &--prev {
        width: fit-content;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 27px;

        &:hover {
          cursor: pointer;
        }

        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
    }

    &--right {
      margin-top: 54px;
    }
  }
}

.display-none-progress-box {
  .progressBoxMobile__headerWrapper__step--verticalLine {
    display: none !important;
  }
}