.customer-operations-serbest-tuketici {
  .infoComponent {
    &__info {
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;
      &__detail {
        display: flex;
        align-items: flex-start;

        &__icon {
          width: 24px;
          padding: 12px;
          height: 24px;
          margin-right: 14px;

          &__img {
            width: 20px;
            height: 20px;
          }
        }
      }

      button {
        margin: 0;
      }
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  .randevu-detay__info__infoButton2 {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &__bubble {
      position: absolute;
      right: 10px;
      top: 10px;

      &__top {
        width: 30px;
        height: 30px;
        background-color: #f3bc45;
        border-radius: 20px;
        position: relative;

        span {
          position: absolute;
          color: #fff;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
    @media(max-width: 768px) {
      margin-top: 30px;
    }
  }

  &__body {
    padding-inline: 30px;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

    &__warning {
      display: flex;
      padding: 12px;
      background: #ffffff;
      border: 1px solid #486072;
      border-radius: 3px;
      align-items: center;

      &__icon {
        margin-right: 14px;
        margin-top: -2px;

        img {
          width: 20px;
        }
      }

      &__text {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: #486072;
      }
    }

    &__text {
      margin-top: 30px;
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #444444;
    }

    &__text2 {
      margin-top: 30px;
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #444444;
    }

    &__items {
      margin-top: 30px;
      justify-content: space-between;
      flex-wrap: wrap;
      display: flex;
      margin-bottom: -50px;

      &__card {
        border-radius: 10px;
        background-color: #f3f3f3;
        padding: 20px;
        height: 100%;
        width: 332px;
        gap: 20px;

        button {
          margin-top: 0;
          width: 100% !important;
        }

        .randevu-detay__info__infoButton {
          &:first-child {
            margin-bottom: 20px;
          }
        }
      }

      &__maskot {
        margin-top: -50px;
        margin-right: 50px;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px;

      a {
        align-items: center;
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #f3bc45;
        text-decoration: none;
      }

      &__items {
        padding: 12px;
        background: #ffffff;
        box-shadow: -5px -5px 20px #ffffff,
          5px 5px 20px rgba(174, 174, 192, 0.4);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 332px;

        &__top {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 35px;
          color: #444444;
          margin-bottom: 12px;
          width: 100%;
          text-align: left;
        }

        &__bottom {
          width: 100%;
          display: flex;

          div {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__other {
    padding-inline: 30px;
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    background: #fbfbfb;
    margin-top: 64px;
    align-items: center;
    padding-top: 10px;

    &__maskot {
      img {
        width: 300px;
        height: 300px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__text {
        font-family: "Hind";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #444444;
      }

      button {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .customer-operations-serbest-tuketici {
    .infoComponent {
      &__info {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-serbest-tuketici {
    margin-top: -30px;

    .infoComponent {
      &__info {
        display: block;
        margin-top: 0px;
        width: 100% !important;
      }
    }

    .randevu-detay__info__infoButton2 {
      margin-top: 30px !important;

      button {
        @media screen and (max-width: 1180px) {
          width: 100% !important;
        }
      }

      &__bubble {
        right: 30px;
      }
    }

    &__other {
      padding-inline: 0px;
      flex-direction: column;
    }

    &__prev {
      margin-bottom: 20px;
      width: fit-content;
    }

    &__body {
      padding-inline: 0px;

      &__footer {
        flex-direction: column;
        gap: 30px;

        &__items {
          width: 100%;
        }
      }

      &__items {
        flex-direction: column;
        margin-bottom: 30px;
        margin-top: 30px;

        &__card {
          background-color: unset;
          width: 100%;
          padding: 0px;
        }

        &__maskot {
          display: none;
          margin-top: 0px;
        }
      }

      &__text2 {
        margin-top: 0px;
      }

      &__warning {
        margin-bottom: 30px;
        align-items: flex-start;

        &__icon {
          margin-top: 0px;
        }
      }
    }
  }
}
