.login-page {
    .swiper-pagination-bullet {
        height: 15px;
        width: 15px;
        background-color: #fff;
        opacity: 1;
        box-shadow: 0px 0px 16px rgba(255, 255, 255, 1);
    }

    .mySwiper {
        border-radius: 10px;
        width: 100%;

        img {
            width: 100%;
            background-repeat: 10px;
        }

        .swiper-slide {
            width: 100% !important;
            // padding: 0px 2px;
        }

        .swiper-pagination-bullet {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            transition: background-color 1s !important;

            &:hover {
                width: 25px !important;
                border-radius: 8px;
                transition: width 0.4s !important;
            }
        }

        .swiper-pagination-bullet-active {
            background-color: #f3bc45;

            &:hover {
                width: 25px !important;
                border-radius: 8px;
                transition: width 0.4s !important;
            }
        }
    }
}