.after-login-header {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 10000;
  height: 100px;

  @media (max-width: 768px) {
    width: 100% !important;
    height: 80px;
  }

  img {
    &:hover {
      cursor: pointer;
    }
  }

  .infoBox {
    width: 310px !important;
    height: 90px !important;

    span {
      font-family: 'Hind', sans-serif;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.4px;
      text-align: left;
      color: var(--white);
      z-index: 5;
    }

    &__img {
      opacity: 0.25 !important;
      // width: 50%;
      height: 100%;
    }
  }

  &__logo {
    width: 242px;
    height: 48px;
    margin-right: 50px;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      margin-right: 0px;
      width: 194px;
      height: 38px;
    }

    @media (max-width: 300px) {
      height: 30px;
    }
  }

  &__home {
    margin-right: 30px;

    @media (max-width: 768px) {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__notification {
    margin-right: 30px;
    position: relative;
    width: 24px;
    height: 24px;

    @media screen and (max-width: 768px) {
      display: none;
    }

    &__icon {
      margin-right: 30px;
    }

    &__number {
      position: absolute;
      left: 47%;
      bottom: 50%;
      transform: translateY(50%) translateX(-65%);
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 9px;
      line-height: 10px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #ffffff;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .active {
    .customInput {
      input {
        border: 1px solid #486072;
      }
    }

    @media (min-width: 768px) {
      width: 100%;

      .customInput {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }
  }

  &__searchInput {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-right: 30px;

    .customInput {
      input {
        margin-bottom: 6px !important;
      }
    }

    @media (max-width: 1180px) {
      display: none;
      width: 100%;
      margin-right: 0;
    }

    input {
      background: #f3f3f3 !important;
      border-radius: 10px !important;
      z-index: 2 !important;

      &::placeholder {
        font-family: Hind;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 600;
        color: #000;
      }

      &:focus {
        border: 1px solid #486072;
      }

      @media (max-width: 380px) {
        width: 310px !important;
      }
    }

    &__icons {
      position: absolute;
      right: 10px;
      top: 20px;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;

      &__searchIcon {
        margin-right: 10px;
      }

      &__crossIcon {
        display: none;

        @media (max-width: 768px) {
          display: block;
        }
      }
    }

    &__lastSearch {
      position: absolute;
      width: 100%;
      top: 30px;
      height: auto;
      background-color: #fff;
      border: 1px solid #486072;
      border-radius: 10px;
      z-index: 1;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      max-height: 400px;
      overflow-y: auto;

      .infoBox {
        margin-top: 20px;
        width: 100% !important;
      }

      &__finally {
        display: flex;
        flex-direction: column;

        &__items {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 10px;
          background-color: var(--soft-grey);
          height: 60px;
          width: 100%;
          margin-top: 20px;
          /* this */
          padding: 0 15px;
          cursor: pointer;

          &__content {
            font-family: 'Hind', sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: var(--pure-black);
          }

          &__caption {
            font-family: 'Hind', sans-serif;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: var(--turuncu);
          }
        }
      }

      &__top {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #f3bc45;

        &__header {
          display: flex;
          color: #f3bc45;
          justify-content: space-between;
          margin: 20px 0;

          img {
            // margin-right: 30px;
          }
        }

        &__content {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          &__item {
            border: 1px solid #f3bc45;
            background-color: #fff;
            width: auto;
            // padding: 5px;
            height: 40px;
            border-radius: 3px;
            margin-right: 20px;
            // margin-bottom: 20px;
            padding: 10px 15px;
            cursor: pointer;
          }
        }
      }

      &__bottom {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #f3bc45;
        margin-top: 20px;

        &__header {
          display: flex;
          color: #f3bc45;
          justify-content: space-between;
          margin-bottom: 20px;

          img {
            margin-right: 30px;
          }
        }

        &__content {
          display: flex;
          flex-wrap: wrap;

          &__item {
            border: 1px solid #f3bc45;
            background-color: #fff;
            width: auto;
            // padding: 5px;
            height: 40px;
            border-radius: 3px;
            margin-right: 20px;
            margin-bottom: 20px;
            padding: 10px 15px;
            cursor: pointer;
          }
        }
      }
    }
  }

  &__profileCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 30px;
    background-color: #e8ecf2;
    border-radius: 0 0 10px 10px;
    // height: auto;
    z-index: 2;
    width: 100%;
    height: 100px;
    position: relative;

    @media (max-width: 768px) {
      margin-left: 0px;
      background-color: #fff !important;
      position: unset;
    }

    @media (max-width: 1180px) {
      height: 78px;
    }

    &__nav {
      position: absolute;
      top: 94%;
      right: 0;
      width: auto;
      min-height: calc(100vh - 50px);

      @media (max-width: 1180px) {
        width: 100%;
      }

      @media (max-width: 768px) {
        // height: 100vh !important;
        height: auto !important;
        width: 100% !important;
        top: 77%;
        background: #fff;
      }

      &__dropdown {
        background-color: var(--cool-grey);
        border-radius: 0 0 10px 10px;

        @media (max-width: 768px) {
          background-color: var(--cool-grey);
          color: var(--lacivert);
        }

        &__body {
          height: auto;
          width: 361px;
          box-shadow: 5px 0px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 var(--white);
          background-color: var(--cool-grey);
          border-radius: 0 0 10px 10px;

          @media (max-width: 1180px) {
            width: 100%;
          }

          @media (max-width: 768px) {
            box-shadow: none;
            padding: 0;
            background-color: #fff;
          }

          &__items {
            // display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 15px;

            @media (max-width: 768px) {
              padding: 0px;
            }

            &__top {
              display: flex;
              align-items: center;
              padding: 25px;
              width: 100%;
              background-color: #e8ecf2;
              border-radius: 10px;
              height: auto;
              margin-top: 20px;

              @media (min-width: 768px) {
                display: none;
              }

              &__photo {
                width: 50px;
                height: 50px;
                border-radius: 50%;

                @media (max-width: 768px) {
                  right: 65px;
                }
              }

              &__name {
                margin-left: 10px;
                font-family: Hind;
                font-size: 22px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.02;
                letter-spacing: normal;
                text-align: left;
                color: var(--lacivert);
              }
            }

            &__bottom {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: center;
              min-height: 50px;
              min-width: 303px;
              margin: 12px 0;

              &__yellow {
                width: 10px;
                height: 10px;
                background-color: #f3bc45;
                border-radius: 50%;
              }

              @media (min-width: 768px) {
                display: none;
              }

              div {
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 0.3px;
                color: #486072;
                margin-bottom: 7px !important;
                display: flex;
                align-items: center;
                width: 100%;
                padding: 12px;
                min-height: 50px;
                min-width: 303px;

                &:hover {
                  cursor: pointer;
                  background-color: #fff;
                  border: 1px solid #486072;
                  border-radius: 10px;

                  @media (max-width: 768px) {
                    border: solid 1px var(--lacivert);
                    background-color: rgba(72, 96, 114, 0.1);
                  }
                }

                &:last-child {
                  margin-top: 8px !important;
                }

                &:first-child {
                  margin-top: 8px !important;
                }

                img {
                  margin-right: 20px;
                }
              }
            }

            &__hr {
              background-color: var(--cool-grey);
              height: -1px;
              margin-bottom: 20px;

              @media (min-width: 768px) {
                display: none;
              }
            }

            &__hr2 {
              background-color: var(--cool-grey);
              height: -1px;
              margin-top: 2px;
              margin-bottom: 20px;

              @media (min-width: 768px) {
                display: none;
              }
            }

            &__item {
              display: flex;
              align-items: center;
              width: 100%;
              padding: 0px 0px 0px 12px;
              margin-bottom: 15px;
              min-height: 50px;
              min-width: 303px;

              @media (max-width: 768px) {
                margin-bottom: 15px !important;

                &:first-child {
                  margin-top: 20px;
                }
              }

              @media (min-width: 768px) {
                margin-bottom: 0px;
              }

              &:last-child {
                margin-bottom: 0px;
              }

              &:hover {
                cursor: pointer;
                background-color: #fff;
                border: 1px solid #486072;
                border-radius: 10px;

                @media (max-width: 768px) {
                  border: solid 1px var(--lacivert);
                  background-color: rgba(72, 96, 114, 0.1);
                }
              }

              img {
                margin-right: 20px;
              }

              span {
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 0.3px;
                color: #486072;
              }

              a {
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 0.3px;
                color: #486072;
                width: 250px !important;
                display: block;
                padding: 12px 12px 12px 0px;
              }
            }
          }
        }
      }
    }

    &__profileInfo {
      display: flex;
      align-items: center;
      padding: 25px;
      width: 100%;
      height: 100px;

      @media (max-width: 768px) {
        padding: 0px;
        justify-content: end;
      }

      &__picture {
        position: relative;

        @media (max-width: 768px) {
          pointer-events: none;
          margin-right: 50px;
        }

        &__photo {
          width: 50px;
          height: 50px;
          border-radius: 50%;

          @media (max-width: 768px) {
            position: absolute;
            right: 50px;
            margin-left: 50px;
            width: 40px;
            height: 40px;
          }
        }

        @media (max-width: 768px) {
          padding: 0px;
        }

        &__photo2 {
          position: absolute;
          bottom: 0;
          right: 0;

          @media (max-width: 768px) {
            display: none;
          }
        }
      }

      &__picture2 {
        position: relative;

        &__photo {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        &__photo2 {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      &__name {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.33;
        text-align: left;
        color: #486072;
        margin-left: 10px;

        @media (max-width: 768px) {
          display: none;
        }

        @media (max-width: 1024px) and (min-width: 768px) {
          font-size: 16px;
        }
      }
    }

    &__menuIcon {
      padding: 25px;

      // filter: invert(33%) sepia(4%) saturate(3713%) hue-rotate(164deg)
      //   brightness(99%) contrast(82%);
      filter: invert(33%) sepia(4%) saturate(3713%) hue-rotate(164deg) brightness(99%) contrast(82%);

      &:hover {
        cursor: pointer;
      }

      @media (max-width: 768px) {
        position: absolute;
        right: 0px;
        padding: 0px;
        margin-left: 10px;
        filter: none;
      }

      @media (max-width: 300px) {
        right: 10px;
      }
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;

    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: 0.56px;
      color: #486072;
      text-align: center;
    }
  }
}

.hA {
  font-family: Hind;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #486072;
  width: 250px !important;
  display: block;

  &:hover {
    color: #486072;
  }
}

.profilePhoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #e8ecf2;
  background-color: #486072;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
}