@import "../../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-basvuru-islemleri-basvuru-olustur {
  @include withAuthLayout;

  &__header {
    justify-content: space-between;
  }

  .account-information-phone__body__item__card__inputArea__input {
    input {
      padding-left: 15px;
    }
  }

  &__content {
    &--left {
      &__prev {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 30px;
        width: fit-content;

        &:hover {
          cursor: pointer;
        }
      }

      &__body {
        padding-inline: 30px;

        &__info {
          margin-top: 30px;

          .infoComponent {
            margin-top: 0;

            &__info {
              padding: 12px;
              background-color: #fff;
              display: flex;
              align-items: center;
              border-radius: 3px;

              &__infoArea {
                display: flex;
                align-items: flex-start;

                &__icon {
                  width: 24px;
                  margin: 0 !important;
                  padding: 2px;
                }

                &__text {
                  font-family: Hind;
                  font-size: 15px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.6;
                  letter-spacing: normal;
                  text-align: left;
                  color: var(--lacivert);
                  margin-right: -8px;
                  padding: 0px;
                  margin-left: 15px !important;
                  width: 269px;
                }
              }

              button {
                margin-top: 0px;
                margin-left: 40px;
              }
            }
          }
        }

        &__card {
          background-color: #f3f3f3;
          border-radius: 10px;
          padding: 20px;
          margin-top: 30px;

          .customSelect {
            .ant-select-selection-item {
              opacity: 1 !important;
            }

            .ant-select-selector {
              background-color: #ffffff !important;

            }


          }

          .customSelect img {
            z-index: 0;


          }

          &__header {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            color: #f3bc45;
          }

          &__description {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            margin-top: 20px;
          }

          &__button {
            button {
              margin-top: 20px;
            }
          }

          &__selectArea {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            margin-top: 20px;

            .customSelect {
              width: 317px;

              &__select2 {
                .customSelect {
                  width: #f3bc45 !important;

                  .ant-select-item {
                    height: 60px !important;
                  }

                  .ant-select-item-option-content {
                    flex: unset;
                    overflow: unset;
                    white-space: unset;
                    text-overflow: unset;
                  }
                }
              }
            }
          }

          &__inputArea {
            display: grid;
            grid-template-columns: 317px 317px;
            gap: 20px;
            margin-top: 20px;

            &__input {
              .customInput {
                input {
                  width: 317px;
                }
              }

              .customInput .errorBox {
                margin-bottom: 0;
              }

              input {
                margin-bottom: 0;
              }
            }

            &__captcha {
              @media screen and (max-width: 768px) {
                margin-left: -10px;

                div>div {
                  width: 150px !important;
                }

                iframe {
                  transform: scale(0.875);
                }
              }
            }
          }
        }
      }
    }

    &--right {
      margin-top: 57px;

      &__maskot {
        background: #e8ecf2;
        border-radius: 10px;
        width: 362px;
        height: 308px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 188px;
          height: 287px;
        }
      }
    }
  }

  &__modal {
    .modal2__content {
      max-height: 650px !important;
    }

    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
      margin-bottom: 20px;
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }

    &__buttons {
      width: 100%;
    }

    .activeX {
      button {
        &:first-child {
          cursor: unset;
          opacity: 1 !important;

          span {
            opacity: 0.5 !important;
          }
        }
      }
    }

    a {
      color: #000;
    }

    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }

      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;

        span {
          font-size: 22px;
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-basvuru-islemleri-basvuru-olustur {
    &__content {
      &--left {
        &__prev {
          margin-bottom: 30px;
          width: fit-content;
        }

        &__body {
          padding-inline: 0px;

          &__info {
            .infoComponent {
              &__info {
                display: block;

                button {
                  display: block;
                  margin-top: 10px;
                  margin-left: 0;
                }

                &__infoArea {
                  &__text {
                    width: 100%;
                  }
                }
              }
            }
          }

          &__card {
            &__selectArea {
              flex-direction: column;
            }

            &__inputArea {
              grid-template-columns: 100%;

              &__input {
                .customInput {
                  input {
                    @media screen and (max-width: 768px) {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}