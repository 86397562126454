.customer-operations-abonelik-onayi-single-page-step1 {
  padding-inline: 30px;
  .smsCode__resend__resendBtn {
    margin-bottom: 13px;
  }
  .customInputBoxes {
    input {
      &:focus {
        border-color: rgb(204, 204, 204) !important;
        box-shadow: none !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }
  &__text {
    margin-top: 30px;
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    span {
      font-weight: 600;
      color: #f3bc45;
    }
  }
  &__button {
    margin-bottom: 30px;
  }
  &__body {
    &__but {
      margin-bottom: 30px;
    }
    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: var(--pure-black);
      margin-top: 30px;
      margin-bottom: 30px;
      span {
        font-weight: 600;
        color: #f3bc45; 
      }
    }
    &__firstlabel {
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--lacivert);
      margin-bottom: 10px;
    }
    &--smsCodeCont {
      display: flex;
      margin-bottom: 30px;
      align-items: center;
      margin-top: 10px;
      @media (max-width: 600px) {
        flex-direction: column;
      }
      &--countDown {
        display: flex;
        @media (max-width: 600px) {
          width: 100%;
          align-items: center;
          justify-content: center;
        }
        span {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          color: #000000;
          margin-left: 10px;
        }
        margin-left: 30px;
        @media (max-width: 600px) {
          margin-top: 30px;
          margin-left: 0px;
        }
      }
      @media (max-width: 600px) {
      }
    }
    .yellowLabel {
      color: #f3bc45;
    }
    &__resend {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      &__label {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--lacivert);
      }
      &--resendBtn {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 0.3px;
        text-align: center;
        color: var(--turuncu);
        margin-left: 20px;
        display: flex;
        align-items: center;

        label {
          margin-right: 10px;
          &:hover {
            cursor: pointer;
          }
        }
        img {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    &__smsCode {
      font-family: "Hind", sans-serif !important;
      @media (max-width: 600px) {
        margin-left: 0;
      }
      &__Prev {
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
        img {
          width: 24px;
          height: 24px;
        }
        &__prevBtn {
          background-color: #fff;
          border: none;
          z-index: 1;
          cursor: pointer;
          margin-left: 10px;
          font-weight: 600;
          font-size: 15px;
          text-align: left;
          margin-top: 5px;
        }
        @media (max-width: 600px) {
          margin-left: 0px;
        }
      }
      &__helper {
        margin-bottom: 30px;
        &__text {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
        }
      }
      &__section {
        &__text {
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #486072;
          margin-top: 30px;
        }
        &__input {
          margin-top: 10px;
          display: flex;
          align-items: center;
          @media (max-width: 600px) {
            flex-direction: column;
          }
          &__codeInput {
            margin-right: 30px;
            @media (max-width: 600px) {
              margin-right: 0px;
              margin-bottom: 30px;
              width: 100% !important;
              .customInputBoxes {
                width: 100%;
              }
            }
          }
          &__countdown {
            display: flex;
            align-items: center;

            span {
              margin-left: 10px;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.3px;
              text-align: left;
            }
          }
        }
      }
      &__resend {
        display: flex;
        align-items: center;
        margin-top: 30px;
        @media (max-width: 600px) {
          flex-direction: column;
          margin-top: 30px;
        }
        &__label {
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #486072;
          @media (max-width: 600px) {
            margin-bottom: 10px;
          }
        }
        &__resendBtn {
          display: flex;
          align-items: center;
          margin-left: 20px;

          &__label {
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #f3bc45;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      &__resendError {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 30px;
        width: 332px;
        &__textWrapper {
          display: flex;
          align-items: flex-start;
          align-self: stretch;
        }
        &__icon {
          margin-right: 12px;
        }
        &__label {
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          color: red;
          margin-bottom: 10px;
        }
        &__resendBtn {
          display: flex;
          align-items: center;
          margin-left: 20px;

          &__label {
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #f3bc45;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
