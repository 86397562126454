.customer-operations-abonelik-basvuru-sonuc-page-step1 {
  padding-inline: 30px;
  @mixin cardLayout {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;

    margin-top: 30px;
  }
  @mixin cardHeader {
    font-family: Hind;
    font-style: normal;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    color: #f3bc45;
  }
  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }
  &__description {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 30px 0;
    color: #000000;
    span {
      font-weight: 600;
    }
  }
  &__card {
    @include cardLayout();
    &__header {
      @include cardHeader();
    }
    &__inputArea {
      display: grid;
      grid-template-columns: 317px 317px;
      gap: 20px;
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 0px;
      }
      &__input {
        margin-top: 20px;
        label {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #486072;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .higherTooltip__left__span__top__tooltip__content {
            @media screen and (max-width: 768px) {
              right: -45px !important;
            }
          }
        }
        &__holder {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px 15px;
          gap: 6px;
          margin-top: 10px;
          width: 317px;
          height: 44px;
          background: #ffffff;
          border: 1px solid #e8ecf2;
          border-radius: 3px;
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: #486072;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
    &__fileUpload {
      display: flex;
      flex-direction: column;
      &__hr {
        height: 1px;
        width: 100%;
        // background-color: #dadada;
        border-bottom: 1px solid #dadada;
        margin-top: 20px;
        margin-bottom: 20px;
        &:last-child {
          display: none;
        }
      }
      &__item {
        display: grid;
        grid-template-columns: 1fr 25px;
        &:first-child {
          margin-top: 20px;
        }
        &__left {
          display: grid;
          grid-template-columns: 40px 1fr;
          div {
            &:first-child {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background: #f3bc45;
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: #ffffff;
            }
            &:last-child {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              color: #444444;
            }
          }
        }
        &__right {
          .higherTooltip__left__span__top__tooltip {
            &::after {
              @media screen and (max-width: 768px) {
                left: 125%;
                top: -7px;
              }
            }
          }
          .higherTooltip__left__span__top__tooltip__content {
            @media screen and (max-width: 768px) {
              right: -45px !important;
            }
          }
        }
      }
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
}
