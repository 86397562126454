@import "../../../assets/styles/layouts/authLayout.scss";
.d2d-izin {
  @include authLayout;
  .btttn {
    button {
      width: 305px !important;
      margin: auto;
      margin-top: 30px;
    }
    @media (max-width: 768px) {
      margin: auto;
    }
  }
  &__content {
    &--left {
      &__body {
        padding-inline: 30px;
        @media screen and (max-width: 768px) {
          padding-inline: 0px;
        }
        &__header {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 35px;
          letter-spacing: 0.02em;
          color: #486072;
        }
        &__description {
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #444444;
          margin-top: 30px;
        }
        &__card {
          background-color: #f3f3f3;
          border-radius: 10px;
          padding: 20px;
          margin-top: 30px;

          &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:not(:first-child) {
              margin-top: 20px;
            }
            &__left {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #444444;
            }
            &__right {
              img {
                // &:hover {
                //   cursor: pointer;
                // }
              }
            }
          }
          &__header {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            color: #f3bc45;
          }
          &__description {
            font-family: "Hind";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
            margin-top: 20px;
          }
          &__hr {
            height: 1px;
            width: 100%;
            margin: 20px 0;
            background-color: #dadada;
          }
        }
      }
    }
  }
}
