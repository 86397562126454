@import "../../../../assets/styles/layouts/withAuthLayout.scss";
.customer-operations-abonelik-iptal {
  .searchSub {
    &::placeholder {
      color: var(--black) !important;
      font-weight: 300;
    }
  }
  .customSearchInput__iconsS {
    display: flex;
    position: absolute;
    right: 15px;
    label {
      margin-right: 10px;
    }
  }
  .customSearchInput__input {
    color: var(--black) !important;
  }
  .infoComponent {
    margin-bottom: 0 !important;
  }
  @mixin HeaderTitle {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
  }
  @mixin Description {
    font-family: Hind;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
  }
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  .infoComponent {
    margin-top: 0;
    &__info {
      padding: 12px;
      background-color: #fff;
      display: flex;
      align-items: center;
      border-radius: 3px;
      @media screen and (max-width: 768px) {
        display: block;
      }
      &__infoArea {
        display: flex;
        align-items: flex-start;

        @media screen and (max-width: 768px) {
        }
        &__icon {
          width: 24px;
          margin: 0 !important;
          padding: 2px;
        }
        &__text {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          margin-right: -8px;
          padding: 0px;
          margin-left: 15px !important;
          width: 269px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }

      button {
        margin-top: 0px;
        margin-left: 28px;
        @media screen and (max-width: 768px) {
          display: block;
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }
  }
  &__noContract {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    background-image: url("../../../../assets/images/ENERJISA_maskot.png");
    background-position: center;
    height: 398px;
    width: 518px;
    margin: 0 auto;
    margin-top: 118px;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 40px !important;
    }
    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
      margin-top: 50px;
      padding: 0 20px;
    }
  }
  &__modal {
    .modal2__content {
      max-height: none;
    }
    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
    }
    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: var(--black);
      margin-top: 30px;
    }
    &__buttons {
      width: 100%;
    }
  }
  &__body {
    padding-inline: 30px;
    input {
    }
    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }
    &__card {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }
        &__left {
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: var(--turuncu);
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
        &__right {
          opacity: 0.5;
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: var(--turuncu);
          @media screen and (max-width: 768px) {
            margin-top: 20px;
            width: 100%;
          }
        }
      }
      &__address {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        div {
          &:first-child {
            @include HeaderTitle();
          }
          &:last-child {
            @include Description();
            margin-top: 10px;
          }
        }
      }
      &__hr {
        height: 1px;
        width: 100%;
        background-color: #dadada;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .btn-no-margin-top {
        margin-top: 0 !important;
      }
      &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &__button {
          width: 317px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          &:last-child {
            @media screen and (max-width: 768px) {
              margin-top: 20px;
            }
          }
        }
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
        button {
          margin-top: 0;
        }
      }
      &__details {
        display: grid;
        grid-template-columns: 317px 317px;
        gap: 20px;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }
        .red {
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #e63323;
        }
        .special {
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--black);
        }
        &__left {
          &__firstDiv {
            @include HeaderTitle();
          }
          &__lastDiv {
            display: flex;
            align-items: center;
            margin-top: 10px;

            @include Description();
            i {
              &:hover {
                cursor: pointer;
              }
              color: #adb3bc;
              margin-right: 10px;
            }
          }
        }
        &__right {
          &__firstDiv {
            @include HeaderTitle();
          }
          &__lastDiv {
            display: flex;
            align-items: center;
            margin-top: 10px;
            @include Description();
            i {
              &:hover {
                cursor: pointer;
              }
              color: #adb3bc;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
