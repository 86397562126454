@import "../../../assets/styles/layouts/authLayout.scss";

.ek-ekran-e-arsiv-onay {
  @include authLayout;
  .progressBox {
    height: 215px !important;
  }
}

.prepayment-o {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 80px;
}

.prepayment-modal {
  .modal2__content {
    max-height: unset !important;
    height: auto !important;
    img {
      width: 75%;
    }
  }
}

.prepayment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 80px;
  &__spinner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100px;
    margin-top: 100px;
    img {
      height: 50px;
    }
  }

  img {
    width: 180px;
    height: 180px;
  }
  .prepayment-content-t-cont-m {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .title {
      font-size: 28px;
      font-weight: 600;
      line-height: 35px;
      color: #486072;
      margin-left: 20px;
    }
    img {
      width: 100px;
      height: 100px;
    }
  }
  .prepayment-content-t-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 480px;
    @media (max-width: 570px) {
      display: none !important;
    }
  }
  .prepayment-content-t-cont-r {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 280px;
    margin-left: 20px;
    .title {
      font-size: 28px;
      font-weight: 600;
      line-height: 35px;
      margin-bottom: 30px;
      color: #486072;
    }
    .text {
      font-size: 16px;
      line-height: 28px;
      span {
        font-weight: 700;
      }
    }
  }
  .prepayment-content-c-cont {
    max-width: 480px;
    margin-top: 30px;
    font-size: 16px;
    line-height: 28px;
    span {
      font-weight: 700;
    }
  }
  .c-box {
    margin-top: 30px;
    width: 480px;
    height: 218px;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    img {
      width: 164px;
      height: 160px;
      margin-right: 30px;
    }
    .c-box-r {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .inputs-wrapper {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .inputs {
          width: 160px;
          height: 44px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #486072;
          border-radius: 3px;
        }
        input {
          width: 10px;
          height: 20px;
          border: none;
          border-bottom: 2px solid #486072;
          margin: 0 4px;
          text-align: center;
          font-size: 16px;
          border-radius: 0;
          &:focus {
            box-shadow: none !important;
          }
        }
        span {
          margin-left: 10px;
          font-weight: 700;
          font-size: 16px;
          color: #486072;
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .prepayment {
    margin-top: 30px !important;
  }
  .prepayment-content-t-cont {
    max-width: 100% !important;
  }
  .prepayment-content-c-cont {
    max-width: 100% !important;
  }
  .prepayment-content-t-cont-r {
    margin-left: 0 !important;
    max-width: 100% !important;
  }
  .c-box {
    width: 100% !important;
    flex-direction: column;
    height: 396px !important;
    img {
      margin-right: 0 !important;
      margin-bottom: 20px;
    }
    button {
      width: 290px !important;
    }
  }
}

@media (min-width: 571px) {
  .prepayment-content-t-cont-m {
    display: none !important;
  }
}
