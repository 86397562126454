@import "../../../assets/styles/layouts/authLayout.scss";

.ek-ekranlar-fatura-odeme {
  @include authLayout;

  .progressBox {
    height: 100%;
    padding-bottom: 30px;
  }

  &__spinner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100px;
    margin-top: 100px;
  }

  &__whiteBody {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 136px;

    @media screen and (max-width: 768px) {
      margin-top: 30px;
    }

    &__noPageValidity {
      background-color: #fbfbfb;
      padding: 30px;
      width: 740px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &__body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
          gap: 0px;
        }

        &__left {
          @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }

          &__desktopmaskot {
            display: block;

            @media screen and (max-width: 768px) {
              display: none;
            }
          }

          &__mobilmaskot {
            display: none;

            @media screen and (max-width: 768px) {
              display: flex;
            }
          }

          &__mobilHeader {
            display: none;

            @media screen and (max-width: 768px) {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 28px;
              line-height: 35px;
              text-align: center;
              letter-spacing: 0.02em;
              color: #486072;
            }
          }
        }

        &__right {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 292px;

          &__button {
            width: 100%;
          }

          &__moreoptions {
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 100%;
            margin-top: 30px;

            &__card {
              background-color: #f3f3f3;
              padding: 20px;
              border-radius: 10px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              div {
                &:first-child {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 24px;
                  color: #486072;
                }

                &:last-child {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  letter-spacing: 0.02em;
                  color: #f3bc45;
                  cursor: pointer;
                }
              }
            }
          }

          &__links {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;

            &__link {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            img {
              width: 95%;
            }
          }

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          &__header {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 35px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #486072;

            @media screen and (max-width: 768px) {
              display: none;
            }
          }

          &__description {
            margin-top: 30px;
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #444444;
          }
        }
      }
    }
  }



  &-step4 {
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__header {
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      margin-top: 30px;
      text-align: center;
      color: #f3bc45;

      span {
        color: #e63323;
      }
    }

    &__description {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      margin-top: 30px;
    }

    &__text {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000;
      margin-top: 30px;

      span {
        font-weight: 600;
        color: #f3bc45;
        text-decoration: underline;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &__more {
      margin-top: 30px;
      width: 332px;
      margin-left: auto;
      margin-right: auto;

      &__text {
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #444444;
      }

      a {
        color: #000;
      }

      &__contactsection {
        display: flex;
        gap: 30px;
        align-items: center;
        width: 332px;
        height: 100px;
        background-color: #f3f3f3;
        border-radius: 10px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;

        img {
          width: 24px;
          height: 24px;
          margin-left: 30px;
        }

        span {
          font-family: Hind;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: center;

          span {
            font-size: 22px;
            font-weight: 600;

            a {
              color: #444;
            }
          }
        }
      }
    }

    &__modal {

      .modal2__content {
        max-height: fit-content;
      }

      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #486072;
      }

      &__input {
        margin-top: 30px;

        .customInput {
          width: 292px;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        input {
          width: 292px;
          margin-bottom: 0;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }

      &__button {
        width: 100%;
      }
    }
  }

  &-step2 {
    padding-inline: 30px;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__header {
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      color: #f3bc45;
    }

    &__description {
      font-family: "Hind";
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000;
      margin-top: 30px;

      span {
        color: #f3bc45;
        font-weight: 600;
        text-decoration: underline;
      }
    }

    &__bills {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__button {
        display: flex;
        justify-content: center;
      }

      &__footertext {
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 30px;
        color: #444444;
        width: 332px;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }

      &__bill {
        margin-top: 30px !important;
        background-color: #f3f3f3;
        border-radius: 10px;
        padding: 12px;
        display: flex;
        align-items: center;
        width: 332px;
        // margin: 0 auto;
        max-width: 332px;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        &__right {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;

          &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__left {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #486072;
            }

            &__right {
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: right;
              color: #486072;
            }
          }

          &__item2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            border-radius: 10px;
            padding: 10px;
          }
        }
      }

      &__contactsection {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 332px;
        height: 100px;
        background-color: #f3f3f3;
        border-radius: 10px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;

        img {
          width: 24px;
          height: 24px;
        }

        span {
          font-family: Hind;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: center;

          span {
            font-size: 22px;
            font-weight: 600;

            a {
              color: #444;
            }
          }
        }
      }
    }
  }

}