.customer-operations-abonelik-yaptirma-diger-abonelik-step2 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @mixin cardLayout {
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
  }

  @mixin cardHeader {
    font-family: Hind;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: var(--turuncu);
  }

  @mixin cardDescription {
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
    margin-top: 20px;
  }

  @mixin checkboxAreaLabel {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--lacivert);
    margin-top: 20px;
  }
  @mixin checkboxText {
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
  }
  @mixin checkboxBg {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding: 12px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .higherTooltip__left__span__top__tooltip {
    z-index: 520;
  }
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__body {
    padding-inline: 30px;
    .infoComponent {
      margin-top: 30px;
      @media screen and (max-width: 768px) {
        margin-top: 0;
      }
    }
    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }
    &__card {
      @include cardLayout;
      display: flex;
      flex-direction: column;

      &__header {
        &__left {
          @include cardHeader;
        }
        display: flex;
        justify-content: space-between;
        width: 100%;
        .higherTooltip__left__span__top__tooltip__content {
          @media screen and (max-width: 1024px) {
            right: -45px !important;
          }
        }
        .higherTooltip__left__span__top__tooltip {
          &::after {
            @media screen and (max-width: 768px) {
              top: -7px;
              margin-left: 10px;
            }
          }
        }
      }
      &__description {
        @include cardDescription;
      }
      &__checkboxArea {
        display: flex;
        flex-direction: column;
        &__colCheckbox {
          display: flex;
          flex-direction: column;
          &__checkbox {
            @include checkboxBg;
            &:first-child {
              margin-top: 10px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .spanFirst {
              @include checkboxText;
            }
            .rounded-checkbox {
              align-items: center;
            }
          }
          &__detailCheckbox {
            width: calc(100% - 42px);
            margin-left: 42px;
            &:last-child {
              margin-bottom: 0;
            }
            @include checkboxBg();
            .customCheckBox {
              margin: 0px !important;
              .spanFirst {
                @include checkboxText;
              }
              .customCheckBox__label > div {
                align-items: flex-start;
              }
            }
          }
        }
        &__detailCheckboxes {
          @include checkboxBg();
          margin-bottom: 0;
          margin-top: 20px;
          width: calc(100% - 40px);
          margin-left: 40px;
          .customCheckBox {
            margin: 0 !important;
          }
        }
        &__label {
          @include checkboxAreaLabel;
          margin-bottom: 10px;
        }
        &__checkbox {
          display: grid;
          grid-template-columns: 140px 150px;
          .spanFirst {
            @include checkboxText;
          }
        }
        .infoComponent {
          display: block;
          background-color: #fff;
          width: calc(100% - 28px);
          margin-left: 28px;
          margin-top: 0;
          @media screen and (max-width: 768px) {
            margin-left: 0px;
            width: 100%;
          }
        }
        &__checkbox2 {
          display: grid !important;
          align-items: center !important;
          grid-template-columns: 30px 490px 110px !important;
          @media screen and (max-width: 768px) {
            grid-template-columns: 30px 1fr !important;
          }
          margin-bottom: 0 !important;
          &:last-child {
            margin-top: 20px;
            margin-bottom: 0;
          }
          .rounded-checkbox__label > div {
            margin-right: 0;
          }
          @include checkboxBg();
          &__label {
            @include checkboxText();
          }
          &__saveGreen {
            width: 110px;
            height: 25px;
            display: grid;
            grid-template-columns: 15px 1fr;
            gap: 5px;
            align-items: center;
            padding: 5px 10px;
            border-radius: 11px;
            background-color: #00c13b;

            @media screen and (max-width: 768px) {
              display: none;
            }
            div {
              font-family: Hind;
              font-size: 10px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 0.5px;
              text-align: left;
              color: var(--white);
            }
          }
          .rounded-checkbox {
            align-items: center;
          }
        }
        &__checkbox3 {
          display: grid !important;
          grid-template-columns: 30px auto;
          grid-template-rows: 24px auto;
          @media screen and (max-width: 768px) {
            grid-template-rows: 1fr;
          }
          grid-template-areas:
            "Icon Text"
            "Info Info";
          margin-bottom: 0 !important;
          &:last-child {
            margin-top: 20px;
            margin-bottom: 0;
          }
          .rounded-checkbox__label > div {
            margin-right: 0;
          }
          .infoComponent {
            grid-area: Info;
            margin-top: 10px;
          }
          @include checkboxBg();
          &__label {
            @include checkboxText();
            grid-area: Text;
          }
          .rounded-checkbox {
            align-items: center;
            grid-area: Icon;
          }
        }
      }

      &__mainHeader {
        @include cardHeader;
      }
      &__hr {
        height: 1px;
        width: 100%;
        background-color: #dadada;
        margin-top: 20px;
      }
      &__input {
        margin-top: 20px;
        width: 317px;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        .customInput .errorBox {
          margin-top: 0px;
          margin-bottom: 0;
        }
        input {
          width: 317px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          margin-bottom: 0;
        }
      }
      &__pdfSection {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
        .rpv-core__viewer {
          width: 317px !important;
          @media screen and (max-width: 768px) {
            width: 100% !important;
          }
        }
        &__pdf {
          display: flex;
          flex-direction: column;
          &:last-child {
            @media screen and (max-width: 768px) {
              margin-top: 20px;
            }
          }
          .customCheckBox {
            align-items: flex-start;
            width: 317px;
            margin-top: 20px;
            margin-bottom: 0;
            .customCheckBox__label {
              div {
                background-color: transparent !important;
              }
            }
            .spanFirst {
              font-family: Hind;
              font-size: 16px;
              font-weight: 500;
              color: var(--pure-black);
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
            }
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
    &__button {
      margin-bottom: 30px;
      @media screen and (max-width: 768px) {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
}
