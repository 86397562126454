.customer-register-page {
  .ant-select-selection-item {
    opacity: 1 !important;
  }
  .userInformation {
    display: flex;
    flex-direction: column;

    &__inputsLine {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      @media (max-width: 800px) {
        flex-direction: column;
      }
    }

    .customInput {
      width: 332px;
      input {
        width: 332px;
        padding: 12px 15px 10px;
        @media(max-width: 600px) {
          width: 100%;
        }
      }
      @media(max-width: 600px) {
        width: 100%;
      }
    }

    &__prev {
      display: flex;
      align-items: center;
      margin-top: 30px;
      &:hover {
        cursor: pointer;
      }
      &__text {
        font-family: "Hind", sans-serif !important;
        font-size: 15px;
        font-weight: 600;
        padding-top: 1px;
        margin-left: 10px;
      }
    }
    &__button {
      display: flex;
      align-items: center;
      margin-top: 30px;
      padding-bottom: 30px;
      margin-bottom: 100px;
      height: 50px;
      &__text {
        display: flex;
        align-items: center;
        margin-left: 30px;
        margin-top: 25px;
        font-family: Hind;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        @media (max-width: 600px) {
          display: none;
        }
      }
      &__mobileText {
        display: none;
        @media (max-width: 600px) {
          display: block;
          margin-top: 30px;
          font-family: Hind;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5;
        }
      }
      @media (max-width: 600px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        margin-left: 0px;
      }
    }
    &__infoBoxes {
      display: flex;
      flex-direction: column;
      align-items: center;
      .infoComponent {
        margin-bottom: 0 !important;
      }
      @media (max-width: 600px) {
        align-items: stretch;
      }
    }
    &__userInputs {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      .errorBox {
        margin-top: 0px !important;
      }
      @media (max-width: 600px) {
        align-items: stretch;
        margin-left: 0px;
      }
      &__Input {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        &:first-child {
          margin-top: 0px;
        }
        &:nth-child(2) {
          margin-top: 0px;
        }
        @media (max-width:600px) {
          width: 100%;
        }
        &__date {
          margin-bottom: 10px;
        }
        &__label {
          margin-bottom: 10px;
        }
        @media (max-width: 600px) {
          input {
            margin-right: 0px !important;
            width: 100% !important;
          }
        }
        .ant-picker-input {
          input {
            width: 290px !important;
            height: 34px !important;
            @media (max-width:600px) {
              width: 100%;
            }
          }
        }
        &__label {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          line-height: 1.5;
          color: #486072;
        }
        &__select {
          width: 332px;
          height: 100%;
          @media (max-width: 600px) {
            width: 100%;
          }
          .ant-select-selector {
            width: 332px;
            height: 44px !important;
            align-items: center;
            @media (max-width: 600px) {
              width: 100%;
            }
          }
          .ant-select-item {
            height: 44px !important;
            flex-grow: 1;
            font-family: Hind;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: red !important;
          }
          @media (max-width: 600px) {
            width: 100%;
          }
        }
      }
      .date-input {
        position: relative;
        margin-top: 11px;
        .userInformation__userInputs__Input__label {
          top: -5px !important;
        }
        label {
          position: absolute;
          top: -15px;
        }
        .ant-picker {
          margin-top: 22px;
        }
      }
    }
    .ant-picker {
      border: 1px solid #e8ecf2 !important;
      .ant-picker-input {
        input {
          &::placeholder {
            color: #486072 !important;
            opacity: 0.5 !important;
          }
        }
      }
      &:hover {
        border: 1px solid #e8ecf2 !important;
      }
    }
  }
}