.yasal-bilgiler-dask-page {
  &__prev {
    width: 150px;
    margin-bottom: 30px;
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    padding-inline: 30px;
    img {
      width: 700px;
      border-radius: 10px;
      @media (max-width: 768px) {
        width: 100% !important;
      }
    }
    &__text {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      font-family: Hind;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      color: #444;
    }
  }
}
