.account-information-otomatik-odeme-talimatlarim {
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__select {
      margin-top: 30px;
      .ant-select-selection-item{
        opacity: 1;
      }
    }

    &__card {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: var(--turuncu);

        span {
          &:first-child {
            font-family: Hind;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: var(--turuncu);
            font-size: 25px;
          }

          &:last-child {
            font-family: Hind;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: var(--turuncu);
            font-size: 15px;

            &:hover {
              cursor: pointer;
            }
          }
        }

        input {
          border: none;
          width: 100%;
          background-color: #f3f3f3;
          height: 30px;
          opacity: 1;
          font-family: Hind;
          font-size: 25px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);

          &:focus {
            outline: none;
          }
        }
      }

      &__address {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        span {
          &:first-child {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #444;
            margin-bottom: 10px;
          }

          &:last-child {
            font-family: Hind;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #444;
          }
        }
      }

      &__detailArea {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        &__details {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: flex-start;
          margin-top: 20px;

          &__detail {
            div {
              &:first-child {
                margin-bottom: 10px;
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #444;
              }

              &:last-child {
                font-family: Hind;
                font-size: 16px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #444;
                display: flex;
                align-items: center;

                i {
                  margin-right: 10px;
                  color: #adb3bc;
                  font-size: 18px;

                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }

      &__infoBox {
        .infoComponent {
          margin-top: 20px;
          margin-bottom: 0px !important;
        }

        .infoComponent__info__text {
          display: flex;

          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        }

        &__button {
          button {
            margin-top: 0;
          }
        }
      }
    }

    &__nothing {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #f3f3f3;
      width: 518px;
      margin: 0 auto;
      margin-top: 120px;
      padding: 50px 20px 20px 10px;
      border-radius: 10px;

      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 20px;
        margin-top: 30px;
      }

      span {
        font-family: Hind;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: 0.56px;
        text-align: center;
        color: var(--lacivert);
        display: inline-block;
        margin-bottom: 20px;
      }

      img {
        @media screen and (max-width: 768px) {
          width: calc(50%);
        }
      }
    }
  }

  .text-successfully-copied{
    font-family: "Hind" !important;
    font-style: normal !important;
    font-weight: 600 !important;

    font-size: 16px !important;
    line-height: 24px !important;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    color: #adb3bc !important;
  }
}