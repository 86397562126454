.higherTooltip {
  font-family: Hind;
  font-size: 15px;
  font-weight: 600;
  color: #444;

  .active {
    visibility: visible !important;
    opacity: 1 !important;
  }

  &__left {
    font-family: Hind;
    font-size: 15px;
    font-weight: 400;
    color: #444;

    &__span {
      margin-right: 5px;
      position: relative;

      &:hover {
        .higherTooltip__left__span__top__tooltip {
          visibility: visible;
          opacity: 1;
        }

        @media screen and (max-width: 1180px) {
          .higherTooltip__left__span__top__tooltip {
            visibility: hidden;
            opacity: 0;
          }
        }
      }

      &__top {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 5px;
        }

        &__icon {
          &:hover {
            cursor: pointer;
          }
        }

        &__tooltip {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: -4px;
          right: -7px;
          transition: all 0.3s ease-in-out;
          transform: translateX(100%);
          z-index: 1;

          button {
            display: none;
          }

          @media screen and (max-width: 1180px) {
            width: 100%;
            left: -20px;
            top: 33px;
            transform: translateX(0);

            button {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          &:after {
            content: "";
            position: absolute;
            top: 16px;
            left: -7px;
            transform: translate(-50%, -50%);
            border-width: 10px;
            border-style: solid;
            border-color: transparent #486072 transparent transparent;

            @media screen and (max-width: 1180px) {
              content: " ";
              position: absolute;
              top: -7px;
              left: 100%;
              margin-left: 7px;
              border-width: 10px;
              border-style: solid;
              border-color: transparent transparent #486072 transparent;
            }
          }

          &__content {
            width: 317px;
            background-color: #486072;
            color: #fff;
            font-family: Hind;
            font-size: 15px;
            font-weight: 400;
            border-radius: 3px;
            padding: 15px 20px;

            @media screen and (max-width: 1180px) and (min-width: 830px) {
              position: absolute;
              width: 694px;
            }

            @media screen and (max-width: 830px) and (min-width: 768px) {
              position: absolute;
              width: calc(100vw - 125px);
            }

            @media screen and (max-width: 768px) {
              position: absolute;
              width: calc(100vw - 50px);
            }

            &__text {
              width: 100%;

              button {
                margin-top: 10px;
                border: solid 1px #f3bc45 !important;
                background-color: #fff !important;

                span {
                  margin: 0 !important;
                  color: #f3bc45 !important;
                  font-weight: 600 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}