.customer-operations-tarife-degistirme {
  .infocomponentwidth {
    width: 285px;

    @media screen and (max-width: 768px) {
      width: unset;
    }
  }

  .infoComponent {
    margin-top: 20px;

    &__info {
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;

      @media screen and (max-width: 1180px) {
        flex-direction: column;
        align-items: flex-start;
      }

      // &__text {
      //   @media screen and (max-width: 768px) {
      //     padding: 12px 12px 12px 0px !important;
      //   }
      // }

      &__detail {
        display: flex;
        align-items: flex-start;

        &__icon {
          width: 24px;
          padding: 12px;
          height: 24px;
          margin-right: 14px;

          @media screen and (max-width: 1024px) {
            padding-top: 12px !important;
          }

          &__img {
            width: 20px;
            height: 20px;
          }
        }
      }

      button {
        margin: 0;

        @media screen and (max-width: 768px) {
          display: block;
          margin-top: 0px;
          width: 100% !important;
        }
      }

      .randevu-detay__info__infoButton {
        button {
          @media screen and (max-width: 1180px) {
            width: 100% !important;
          }
        }

        @media screen and (max-width: 1180px) {
          width: 100% !important;
          padding: 0 10px 10px 10px;
        }
      }

      .randevu-detay__info__infoButton2 {
        padding: 9px 10px;

        button {
          @media screen and (max-width: 1180px) {
            width: 100% !important;
          }
        }

        @media screen and (max-width: 1180px) {
          width: 100% !important;
          padding: 0 10px 10px 10px;
        }
      }
    }
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__body {
    padding-inline: 30px;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__headerInfo {
      &__button {
        width: 100%;
      }

      .infoComponent__info__text {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        button {
          margin-top: 0;
        }
      }
    }

    &__card {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;

      &__hr {
        height: 1px;
        width: 100%;
        background-color: #dadada;
        margin-top: 20px;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }

        span {
          &:first-child {
            font-family: Hind;
            font-size: 25px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: var(--turuncu);
          }

          &:last-child {
            opacity: 0.5;
            font-family: Hind;
            font-size: 25px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: var(--turuncu);

            @media screen and (max-width: 768px) {
              display: inline-block;
              margin-top: 10px;
            }
          }
        }
      }

      &__address {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        div {
          &:first-child {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);
            margin-bottom: 10px;
          }

          &:last-child {
            font-family: Hind;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);
          }
        }
      }

      &__secondinfoArea {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        // width: 67%;
        @media screen and (max-width: 768px) {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
        }

        .singleLine {
          width: 100% !important;

          @media screen and (max-width: 768px) {
            margin-top: 0 !important;
          }
        }

        &__info {
          &:first-child {
            width: 55%;
          }

          &:last-child {
            width: 45%;
            padding-right: 12px;
          }

          @media screen and (max-width: 768px) {
            &:first-child {
              width: 100%;
            }

            &:last-child {
              margin-top: 20px;
              width: 100%;

            }
          }

          div {
            display: flex;
            align-items: center;

            &:first-child {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
              margin-bottom: 10px;
            }

            &:last-child {
              font-family: Hind;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }
          }
        }
      }

      &__infoArea {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        width: 80%;

        @media screen and (max-width: 768px) {
          width: 100%;
          align-items: flex-start;
          flex-direction: column;
          margin-top: 0;
        }

        &__info {
          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }

          div {
            display: flex;
            align-items: center;

            &:first-child {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
              margin-bottom: 10px;
            }

            &:last-child {
              font-family: Hind;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }

            i {
              margin-right: 10px;
              color: #adb3bc;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      &__infobox {
        &__button {
          width: 100%;
        }

        .infoComponent {
          margin-bottom: 0px !important;

          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }

          &__info {
            @media screen and (max-width: 768px) {
              align-items: flex-start;
            }
          }
        }



        .infoComponent__info__text {
          display: flex;
          min-height: 72px;
          min-width: 310px;
          padding: 12px 12px 12px 0px;

          @media screen and (max-width: 1024px) {
            flex-direction: column;
            min-height: 30px;
            min-width: auto;

          }
        }

        button {
          margin-top: 0;

          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }
        }
      }
    }
  }
}