@import "../../assets/styles/layouts/authLayout.scss";

.appointment-page {
  @include authLayout;
  &__content {
    .infoComponent {
      width: 100%;
      margin-bottom: 0 !important;
      &__info {
        padding: 5px;
        display: flex;
        background-color: #fff;
        border-radius: 3px;
        // @media (max-width: 768px) {
        //   flex-direction: column;
        // }
  
        &__detail {
          display: flex;
          align-items: flex-start;
          img {
            width: 20px !important;
          }
          &__iconArea {
            &__icon {
              margin-left: 6px;
              margin-top: 10px;
              font-size: 20px !important;
              width: 20px !important;
            }
          }
        }
  
        button {
          margin-top: 10px;
  
          @media (max-width: 768px) {
            display: block;
            width: 100% !important;
          }
        }
  
        .randevu-sorgula-step2__info__infoButton {
          padding: 0 10px 0px 10px;
  
          @media (max-width: 1024px) and (min-width: 768px) {
            button {
              width: 325px !important;
            }
          }
  
          @media (max-width: 768px) {
            width: 100% !important;
            padding: 0 10px 10px 10px;
          }
        }
      }
    }
    &--left {
      &--prev {
        font-family: "Hind", sans-serif;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 30px;
        &:hover {
          cursor: pointer;
        }
        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
      &__body {
        padding-inline: 30px;
        display: flex;
        flex-direction: column;
        @media (max-width: 768px) {
          padding-inline: 0px;
        }
        &__card {
          padding: 20px;
          background-color: #f3f3f3;
          border-radius: 10px;
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          &__header {
            font-family: "Hind", sans-serif;
            font-size: 25px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: var(--turuncu);
          }
          &__text {
            font-family: "Hind", sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #444;
            margin-top: 20px;
          }
          &__selects {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            &__select {
              background-color: #fff;
              border: 1px solid #486072;
              padding: 10px;
              margin-top: 20px;
              width: 317px;
              display: flex;
              align-items: center;
              border-radius: 3px;
              height: 50px;
              &:hover {
                cursor: pointer;
              }
              i {
                font-size: 24px;
                color: #486072;
                margin-right: 20px;
              }
              img {
                width: 28px;
                height: 28px;
                margin-right: 20px;
              }
              span {
                font-family: "Hind", sans-serif;
                font-size: 15px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.3px;
                text-align: left;
                color: var(--lacivert);
              }
            }
          }
          &__checkboxes {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 50%;
            margin-top: 20px;
            font-family: "Hind", sans-serif;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.3px;
            text-align: left;
            color: var(--lacivert);
            @media (max-width: 768px) {
              width: 100%;
            }
          }
          &__inputs {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            @media (max-width: 768px) {
              flex-direction: column;
            }
            &__input {
              position: relative;
              margin-bottom: auto;
              @media (max-width: 768px) {
                width: 100% !important;
              }
              input {
                width: 317px;
                margin-bottom: 10px !important;
                @media (max-width: 768px) {
                  width: 100%;
                }
              }
              .errorBox {
                margin: 0;
                margin-bottom: 10px;
                @media (max-width: 770px) {
                  .errorBox__errorText {
                    max-width: 100%;
                  }
                }
              }
              // &__error {
              //   background-color: #fff;
              //   padding: 12px;
              //   border-radius: 3px;
              //   display: flex;
              //   align-items: flex-start;
              //   margin-bottom: 30px;
              //   @media  (max-width: 768px) {
              //     margin-bottom: 20px;
              //   }
              //   img {
              //     margin-right: 10px;
              //   }
              //   span {
              //     font-family: "Hind", sans-serif;
              //     font-size: 15px;
              //     font-weight: 600;
              //     font-stretch: normal;
              //     font-style: normal;
              //     line-height: 1.6;
              //     letter-spacing: normal;
              //     text-align: left;
              //     color: var(--error);
              //   }
              // }
            }
          }
          &__button {
            button {
              margin-top: 0px;
            }
            // @media(max-width: 770px) {
            //   margin-top: 90px;
            // }
          }
        }
      }
    }
    &--right {
      &--maskot {
        margin-top: 84px;
        background-color: #e8ecf2;
        border-radius: 10px;
        height: 308px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        img {
          width: 188px;
          height: 287px;
        }
      }
    }
  }
  &__modal {
    display: flex;
    flex-direction: column;
    &__description {
      font-family: "Hind", sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }
    &__button {
      width: 100%;
    }
    &__info {
      font-family: "Hind", sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: #444;
      margin-top: 30px;
    }
  }
}


.customer-operations-home {
  &__modal {
    &__header {
      font-family: "Hind", sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }
    &__description {
      margin-top: 20px;
      font-family: "Hind", sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }
  }
  &__content {
    &--left {
      &--prev {
        font-family: "Hind", sans-serif;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 30px;
        width: fit-content;
        &:hover {
          cursor: pointer;
        }
        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
      &--body {
        &--boxes {
          padding-inline: 30px;
          @media screen and (max-width: 768px) {
            padding-inline: 0px;
          }

          &--card {
            background-color: #f3f3f3;
            border-radius: 10px;
            padding: 20px;
            margin-top: 30px;
            &:last-child {
              margin-bottom: 30px;
            }
            .infoComponent {
              margin-top: 20px !important;
            }
            &--header {
              font-family: "Hind", sans-serif;
              font-size: 25px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.2;
              letter-spacing: normal;
              text-align: left;
              color: var(--turuncu);
            }
            &--description {
              font-family: "Hind", sans-serif;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: center;
              color: #444;
              margin-top: 20px;
            }
            &--selects {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              justify-content: space-between;
              &--select {
                background-color: #fff;
                border: 1px solid #486072;
                padding: 10px;
                width: 317px;
                display: flex;
                align-items: center;
                border-radius: 3px;
                margin-top: 20px;
                height: 50px !important;

                @media screen and (max-width: 768px) {
                  width: 100%;
                }

                &:hover {
                  cursor: pointer;
                }
                i {
                  font-size: 24px;
                  color: #486072;
                  margin-right: 20px;
                }
                img {
                  width: 24px;
                  margin-right: 20px;
                }
                span {
                  font-family: "Hind", sans-serif;
                  font-size: 15px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: 0.3px;
                  text-align: left;
                  color: var(--lacivert);
                }
              }
            }
            &--appointment {
              display: flex;
              flex-direction: column;
              background-color: #fff;
              border-radius: 10px;
              padding: 12px;
              margin-top: 20px;
              &--header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                  &:first-child {
                    font-family: "Hind", sans-serif;
                    font-size: 15px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--lacivert);
                  }
                  &:last-child {
                    font-family: "Hind", sans-serif;
                    font-size: 15px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: right;
                    color: var(--turuncu);
                  }
                }
              }
              &--hr {
                width: 100%;
                border: 1px solid #f3f3f3;
                margin-top: 10px;
                margin-bottom: 10px;
              }
              &--contactCenter {
                font-family: "Hind", sans-serif;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: var(--lacivert);
              }
              &--address {
                display: flex;
                // align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                @media screen and (max-width: 1024px) {
                  flex-direction: column;
                  button {
                    margin-top: 10px !important;
                  }
                }
                div {
                  font-family: "Hind", sans-serif;
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: left;
                  color: var(--lacivert);
                }
                button {
                  margin-top: 0;
                }
              }
              &--buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media screen and (max-width: 1025px) {
                  flex-direction: column;
                  &--button {
                    width: 100%;
                    margin-bottom: 10px;
                    &:last-child {
                      margin-bottom: 0 !important;
                    }
                  }
                }
                button {
                  margin-top: 0;
                }
              }
            }
            &--previousAppointments {
              display: flex;
              flex-direction: column;
              &--button {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              &--appointment {
                background-color: #fff;
                border-radius: 10px;
                padding: 12px;
                margin-top: 20px;

                &--header {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  span {
                    &:first-child {
                      font-family: "Hind", sans-serif;
                      font-size: 15px;
                      font-weight: 600;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: normal;
                      letter-spacing: normal;
                      text-align: left;
                      color: var(--lacivert);
                    }
                    &:last-child {
                      font-family: "Hind", sans-serif;
                      font-size: 15px;
                      font-weight: 600;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: normal;
                      letter-spacing: normal;
                      text-align: right;
                      color: var(--turuncu);
                    }
                  }
                }
                &--hr {
                  width: 100%;
                  border: 1px solid #f3f3f3;
                  margin-top: 10px;
                  margin-bottom: 10px;
                }
                &--contactCenter {
                  font-family: "Hind", sans-serif;
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: left;
                  color: var(--lacivert);
                }
              }
            }
          }
        }
      }
    }
  }
}
