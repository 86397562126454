// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.customer-register-page {
  

  .information {
    font-family: "Hind", sans-serif !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 600px) {
      width: 100% !important;
      &__infoBox {
        width: 100%;
        margin-inline: 0;
      }
    }

    .modal__content {
      .modal__content__header {
        margin-bottom: 0 !important;
      }
      .modal__content__footer {
        margin-top: 75px;
      }
    }
    
    b {
      font-weight: 500;
    }
    &__title {
      margin-top: 30px;
      span {
        font-family: "Hind", sans-serif !important;
        font-size: 25px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #f3bc45;
      }
    }
    &__content {
      margin-top: 30px;
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: var(--pure-black);
      &__underline {
        text-decoration: underline;
        font-weight: 600;
      }
    }

    &__body {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      &__hr {
        margin-top: 30px;
        border: 0;
        border-top: 1px solid #486072;
        width: 50%;
        @media (max-width: 600px) {
          &__hr {
            width: 100% !important;
          }
        }
      }
      @media (max-width: 600px) {
        &__hr {
          width: 100% !important;
        }
        &__image {
          display: none;
        }
      }
      &__image {
        height: 409px;
        width: 332px;
        background-image: url("../../../assets/images/Rectangle139.png");
        margin-top: 30px;
        position: relative;
        img {
          position: absolute;
          right: -30px;
          bottom: -18px;
        }
      }
      &__text {
        margin-top: 30px;

        &__title {
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: var(--turuncu);
        }
        &__description {
          margin-top: 30px;
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--pure-black);
          width: 332px;
        }
      }
    }
    &__bodyFooter {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      &__text {
        font-family: Hind;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: var(--pure-black);
      }
    }
    &__hr {
      margin-top: 30px;
      border: 0;
      border-top: 1px solid #486072;
      width: 100%;
      @media (max-width: 600px) {
        &__hr {
          width: 100% !important;
        }
      }
    }
    &__footer {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      &__title {
        font-size: 25px;
        font-weight: 600;
        line-height: 1.2;
      }
      &__text {
        margin-top: 30px;
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--pure-black);
      }
      &__btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
      }
    }
  }

  .orange {
    color: #f3bc45;
    font-weight: 500;
  }

  .mt-3 {
    margin-top: 30px;
  }

  .underline {
    text-decoration: underline;
    font-weight: 500;
  }
}
