@import "../../../../assets/styles/layouts/withAuthLayout.scss";
.user-settings-new-password {
  @include withAuthLayout;
  &__goback {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    &:hover {
      cursor: pointer;
    }
    @media  (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  .progressBox {
    margin: 0;
    height: 150px;
    padding-bottom: 30px;
  }
}
