.fatura-bilgilendirme {
  .infoComponent {
    margin-bottom: 0 !important;
    @media (max-width: 768px) {
      margin-top: 20px;
    }
    &__info {
      padding: 12px;
      display: flex;
      align-items: flex-start;
      background-color: #fff;
      justify-content: space-between;
      border-radius: 3px;
      @media (max-width: 768px) {
        display: block;
      }
      &__infoArea {
        display: flex;
        align-items: flex-start;
        &__icon {
          width: 20px;
          margin: 0 !important;
        }
        &__text {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          width: 269px;
          margin-right: -8px;
          padding: 0px;
          margin-left: 15px !important;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
      button {
        margin-top: 0px;
        @media (max-width: 768px) {
          display: block;

          margin-top: 10px;
        }
      }
    }
  }
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__body {
    padding-inline: 30px;
    @media (max-width: 768px) {
      padding-inline: 0px;
    }
    &__card {
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 10px;
      margin: 30px 0;
      &__hr {
        border-top: 1px solid #dadada;
        margin-bottom: 20px;
      }
      &__header {
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        line-height: 1.2;
        color: var(--turuncu);
        margin-bottom: 20px;
      }

      &__information2 {
        margin-bottom: -10px;

        .infoComponent {
          &__info {
            &__infoArea {
              align-items: center;
              display: block;

              @media (max-width: 768px) {
                display: flex;
                align-items: flex-start;
              }

              img {
                margin-top: -3px !important;

                @media (max-width: 768px) {
                  margin-top: 0px !important;
                }
              }
              &__text {
                margin-right: 0;
              }
            }
          }
        }
      }

      &__info {
        display: flex;
        flex-direction: column;

        &__hesapNo {
          font-family: Hind;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: var(--black);
        }

        span {
          &:first-child {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            color: #444;
            margin-bottom: 10px;
          }
          &:last-child {
            font-family: Hind;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #000;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            i {
              &::before {
                font-size: 16px;
              }
            }
          }
          i {
            margin-right: 10px;
            color: #adb3bc;
            transform: scale(1.2);
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      &__fatura {
        display: flex;
        justify-content: space-between;
        width: 80%;
        @media (max-width: 768px) {
          width: 100%;
          flex-direction: column;
        }
        &__left {
          display: flex;
          flex-direction: column;
          div {
            @media (max-width: 768px) {
              display: flex;
              flex-wrap: wrap;
              word-break: break-all;
            }
          }
          span {
            &:first-child {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              color: #444;
              margin-bottom: 10px;
            }
            &:last-child {
              font-family: Hind;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: #444;
              span {
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: 0.3px;
                text-align: right;
                color: var(--turuncu);
                margin-left: 5px;
                @media (max-width: 768px) {
                  letter-spacing: 0;
                }
                @media(max-width: 400px) {
                  margin-left: 0;
                  display: flex;
                }
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
        &__right {
          display: flex;
          flex-direction: column;
          min-width: 154px;
          &__title {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #444;
            margin-bottom: 10px;
          }
          &__content {
            font-family: Hind;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #444;
          }
          div {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
