.abonelik-randevu-step4 {
  padding-inline: 30px;
  margin-top: 30px;

  .ant-select-selection-item {
    opacity: 1;
  }

  .customInput {
    @media (min-width: 769px) {
      .errorBox {
        margin-top: -10px !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-inline: 0;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100% !important;
    background-color: rgba(243, 243, 243, 0.5);
    margin-top: 30px;

    .ohm-secondaryBtn {
      background-color: rgba(243, 243, 243, 0.5);
    }

    button {
      margin-top: 0;
    }

    &__hr {
      margin-right: 5px;
      margin-left: 5px;
      border-left: 1px solid #486072;
      width: 1px;
      height: 30px;
      background-color: rgba(243, 243, 243, 0.5);
      box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 var(--white);

      @media screen and (max-width: 768px) {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .customInput {
    width: 100% !important;

    input {
      width: 100% !important;
    }
  }

  &__inputArea {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;

    &__input {
      width: 332px !important;

      input {
        width: 332px !important;
        @media screen and (max-width: 768px) {
          margin-bottom: 0;
        }
      }

      .errorBox {
        // margin-top: 0px !important;
        margin-bottom: 10px !important;
      }

      @media screen and (max-width: 768px) {
        width: 100% !important;

        input {
          width: 100% !important;
        }
      }
    }

    &__select {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 332px !important;
      .ant-select-item {
        height: 90px !important;
      }

      @media screen and (max-width: 768px) {
        width: 100% !important;
        margin-top: 20px;
      }
      label {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--lacivert);
        margin-bottom: 10px;
      }
    }
  }

  .abonelik-randevu-step4__rent {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .abonelik-randevu-step4__inputArea__select {
      @media screen and (max-width: 768px) {
        &:last-child {
          margin-top: 0px;
        }
      }
    }

    .abonelik-randevu-step4__rent__checkboxArea {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        width: 80%;
      }

      .rounded-checkbox {
        &:last-child {
          margin-left: 30px;
        }
      }
    }

    &__caption {
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: var(--lacivert);
      margin-bottom: 10px;
      display: block;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    &__inputArea {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px;

      &__input {
        width: 332px;

        @media screen and (max-width: 768px) {
          width: 100% !important;
        }

        .errorBox {
          // margin-top: 0px !important;
          margin-bottom: 10px !important;
        }
      }
    }
  }

  &__footerbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    button {
      margin-top: 30px;
    }
  }

  &__own {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &__input {
      width: 332px;

      @media screen and (max-width: 768px) {
        width: 100% !important;

        input {
          width: 100% !important;
        }
      }

      input {
        width: 332px;
      }
    }
  }
}

.select-opacity {
  .ant-select-selection-item {
    opacity: 0.5;
  }
}
