@import "../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-odeme-islemleri-home {

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
  }
  .infoBox img {
    margin-right: 35px !important;
  }
  &__body {
    padding-inline: 30px;
    margin-top: 30px;
    @media screen and (max-width: 768px) {
      padding-inline: 15px;
    }
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }
      &__left {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--pure-black);
      }
      &__right {
        img {
          margin-top: -50px;
          margin-left: 20px;
        }
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    &__bottomText {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: var(--pure-black);
      span {
        display: inline-block;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.3px;
        text-align: center;
        color: var(--turuncu);
        margin-left: 10px;
        &:hover {
          cursor: pointer;
        }
      }
      @media screen and (max-width: 768px) {
        margin-top: 30px;
      }
    }
  }
}
