.login-page {
    display: flex;
    justify-content: space-between;
    &__content {
        max-width: 1024px;
        width: 100%;
        margin-right: 30px;

        &__slider {
            position: relative;
            &--white-bar {
                position: absolute;
                top: 7px;
                width: 1px;
                background-color: white;
                right: 0;
                height: calc(100% - 14px);
                z-index: 9999;
            }
        }
    }

    &__login {
        max-width: 362px;
        width: 100%;
    }
    @media  (max-width: 1024px) {
        flex-direction: column;
        &__content {
            .mySwiper {
                display: none;
            }
        }
        &__login {
            max-width: 100%;
        }
    }
}
.sabanci-footer {
    margin-top: 99px;
    margin-bottom: 41px;
    &__hr {
      position: relative;
      height: 2px;
      width: 100%;
      border: 1px solid #004b93;
      bottom: 58px;
      img {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-bottom: 50px;
      }
      @media screen and (max-width: 768px) {
        bottom: 41px;
      }
    }
    @media screen and (max-width: 768px) {
      margin-top: 83px;
      margin-bottom: 15px;
      background: #fff;
    }
  }
  