.more-info-faq {
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
}
