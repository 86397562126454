.customer-operations-iban-bilgilerim {
  .ibanInput {
    input {
      padding-left: 12.5px;
      padding-right: 12.5px;
    }
  }
  .pasteSection {
    cursor: pointer;
    height: 45px;
    padding: 0 9px;
    width: 100%;
    background-color: #e8ecf2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #486072;
    font-size: 13px;
    @media screen and (max-width: 415px) {
      height: 45px !important;
      padding: 15px;
    }
  }
  .noIbanInfo {
    .infoComponent {
      margin: 0;
    }
  }
  .ibanList {
    @media (min-width: 769px) {
      margin-top: 40px;
    }
  }
  .btn-duzenle-iban {
    button {
      margin-top: 0;
      height: 30px;
      margin-left: auto;
      margin-bottom: 10px;
    }
  }
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;

    &__icon {
      margin-bottom: 20px;
      img {
        height: 60px;
        width: 60px;
        border-radius: 100%;
      }
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #444;
    }
    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }
      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #444;
        span {
          font-size: 22px;
          font-weight: 600;
        }
      }
    }
  }

  &__body {
    padding-inline: 30px;
    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }
    &__content {
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      &__item {
        display: flex;
        flex-direction: column;

        width: 332px;
        @media screen and (max-width: 768px) {
          width: 100%;
          margin-top: 10px;
        }
        &__header {
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          line-height: 1.2;
          color: var(--turuncu);
          margin-bottom: 30px;
        }
        .active-box {
          transform: translateY(-50%) translateZ(-50%) scale(1);
          transition: transform 1s ease !important;
          z-index: 50;
          position: relative;

          background-color: white;
        }
        &__box {
          display: flex;
          flex-direction: column;
          position: relative;
          z-index: 0;
          margin-bottom: 20px;
          &__item {
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #486072;
            padding: 10px;
            width: 332px;
            border-radius: 3px;

            @media screen and (max-width: 768px) {
              width: 100%;
            }
            &__left {
              input {
                display: flex;
                align-items: center;
                padding: 5px 10px;
                height: 35px;
                width: 223px;
                margin-top: 10px;
                border: none;
                resize: none;
                &:focus {
                  outline: none;
                }
              }
              span {
                font-family: Hind;
                font-size: 15px;
                font-weight: 500;
                line-height: 1;
                color: #444;
                @media screen and (max-width: 768px) {
                  font-weight: 600;
                  font-size: 14px;
                }
              }
              &__info {
                display: flex;
                align-items: center;
                margin-top: 5px;
                div {
                  &:first-child {
                    font-family: Hind;
                    font-size: 15px;
                    line-height: 1;
                    color: #444;
                  }
                  &:last-child {
                    padding: 5px 10px;
                    background-color: #30a353;
                    border-radius: 11px;
                    display: flex;
                    width: fit-content;
                    font-family: Hind;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 1;
                    letter-spacing: 0.5px;
                    color: #fff;
                    margin-left: 5px;
                    @media screen and (max-width: 768px) {
                      padding: 5px;
                    }
                  }
                }
              }
            }
            &__right {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              line-height: 1.6;
              letter-spacing: 0.3px;
              text-align: center;
              color: var(--turuncu);
              &:hover {
                cursor: pointer;
              }
              span {
                &:last-child {
                  margin-left: 5px;
                }
              }
            }
          }
        }
        &__new {
          padding: 20px;
          background-color: #f3f3f3;
          border-radius: 10px;
          height: max-content;
          position: relative;
          &__text {
            font-family: Hind;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: var(--pure-black);
          }
          &__button {
            button {
              margin-top: 20px !important;
            }
          }
          &__maskot {
            display: flex;
            justify-content: center;
            align-content: center;
          }
          &__photo {
            position: relative;
            bottom: -20px;
            right: -10px;
          }
          &__inputs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
            &__button {
              width: 100%;
              button {
                margin-top: 20px;
              }
            }
            .lastBox {
              input {
                padding-left: 15px;
              }
              .errorBox {
                margin-bottom: 0px;
              }
            }
            &__input {
              position: relative;
              width: 292px;
              @media screen and (max-width: 768px) {
                width: 100%;
              }
              &__prefix {
                position: absolute;
                top: 42px;
                left: 15px;
                font-family: Hind;
                font-size: 18px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: var(--lacivert);
              }
              input {
                width: 292px;
                padding-left: 35px;
                @media screen and (max-width: 768px) {
                  width: 100%;
                }
              }
              .errorBox {
                background: #fff;
                margin-left: 0;
                padding: 12px;
                border: 1px solid #ff0000;
                // margin-bottom: 20px;
                &:first-child {
                  margin-top: -10px;
                }
                &:last-child {
                  // margin-top: -10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
