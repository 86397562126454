.modal__content {
    .ohm-btn {
        margin-left: 0px !important;
    }
}

.btnStyleCOde {
    .ohm-btn {
        margin-left: 30px;
        margin-top: 0;

        @media (max-width: 1000px) {
            margin-left: 0;
        }
    }
}

.loginCode-page {
    display: flex;
    flex-direction: column;

    .modal__content__body {
        margin-top: 30px;
    }

    .progressBox {
        height: 90px;

        &__step--content span:first-child {
            padding-top: 5px;
        }
    }

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__tabsBox {
            width: 362px;
            max-width: 362px;
            background-color: #e8ecf2;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 0 20px;
            padding-top: 25px;

            &__tabs {
                height: 50px;
                width: 100%;
                display: flex;
                background: rgba(255, 255, 255, 0.5);
                margin-bottom: 25px;

                .active {
                    border-radius: 3px;
                    box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 #fff;
                    background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%);
                    font-size: 15px;
                    font-weight: 600;
                    color: white;
                }

                &--tab {
                    padding-top: 3px;
                    cursor: pointer;
                    color: #f3bc45;
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 15px;
                    font-weight: 600;
                    border-radius: 3px;
                }
            }

            @media (max-width: 1000px) {
                display: none;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;

        &--left {
            margin-top: 30px;
            width: 754px;
            display: flex;
            flex-direction: column;

            &--body {
                &--text {
                    margin: 30px !important;
                    margin-top: 0 !important;

                    @media (max-width: 600px) {
                        margin: 30px 0;
                    }

                    span {
                        display: block;
                        font-family: Hind;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #000000;

                        &:last-child {
                            margin-top: 25px;
                        }
                    }
                }

                .ohm-btn {
                    margin-left: 30px;
                }
            }
        }

        &--right {
            width: 362px;
            max-width: 362px;

            @media (max-width: 1180px) {
                display: none;
            }
        }
    }
}

.loginCode-page-inputCont {
    margin: 30px;
    margin-bottom: 0;
    margin-top: 0;

    @media (max-width: 600px) {
        margin: 30px 0;
    }

    input {
        @media (max-width: 600px) {
            width: 100%;
        }

        &:focus {
            outline: none;
        }
    }

    &__input {
        margin-bottom: 30px;
        padding: 10px 15px;
        width: 100%;
        height: 44px;
        border-radius: 3px;
        color: #486072;
        border-radius: 3px;
        border: solid 1px #e8ecf2;
        width: 332px;
        font-size: 18px;

        &::placeholder {
            color: #486072;
            opacity: 0.5;
        }

        @media (max-width: 600px) {
            margin-bottom: 0px;
        }
    }

    &__label {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        color: #486072;
        display: block;
        margin-bottom: 10px;
    }

    &--smsCodeCont {
        display: flex;
        margin-bottom: 30px;
        align-items: center;

        input {
            &:focus {
                border-color: rgb(204, 204, 204) !important;
                box-shadow: none !important;
            }
        }

        @media (max-width: 600px) {
            flex-direction: column;
        }

        &--countDown {
            display: flex;

            @media (max-width: 600px) {
                width: 100%;
                align-items: center;
                justify-content: center;
            }

            span {
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.3px;
                color: #000000;
                margin-left: 10px;
            }

            margin-left: 30px;

            @media (max-width: 600px) {
                margin-top: 30px;
                margin-left: 0px;
            }
        }

        @media (max-width: 600px) {}
    }

    .yellowLabel {
        cursor: pointer;
        color: #f3bc45;
    }

    &__resend {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        label {
            &:first-child {
                margin-right: 15px;
            }
        }

        @media (max-width: 600px) {
            flex-direction: column;
        }

        &--resendBtn {
            cursor: pointer;
            display: flex;

            .forgotPassword-inputCont__label {
                @media (max-width: 600px) {
                    margin-right: 0 !important;
                }
            }

            img {
                margin-left: 10px;
                height: 21.56px;
            }
        }
    }

    &__newPassword {
        display: flex;

        .forgotPassword-inputCont {
            margin: 0px;
            margin-right: 30px;
        }

        @media (max-width: 600px) {
            flex-direction: column;

            .forgotPassword-inputCont {
                width: 100%;
                margin-bottom: 30px;
            }
        }
    }

    &__select {
        width: 332px !important;
        height: 100%;
        margin-bottom: 30px;

        .ant-select-selector {
            height: 44px !important;
            align-items: center;
        }

        .ant-select-item {
            height: 44px !important;
            flex-grow: 1;
            font-family: Hind;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: red !important;
        }

        @media (max-width: 600px) {
            width: 100% !important;
        }
    }

    .smsErrorBox {
        margin-left: 0;
    }
}

.customInputBoxes {
    input {
        width: 44px;
        height: 44px;
        border-radius: 3px;
        border: solid 1px #e8ecf2;
        margin-right: 13.6px;
        color: #486072;
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;

        &:last-child {
            margin-right: 0;
        }

        @media (max-width: 600px) {
            margin-right: 0;
        }
    }
}

.errorStyle {
    .loginCode-page-inputCont__label {
        margin-left: 33px;
    }

    @media (max-width: 600px) {
        align-items: flex-start;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.disabled {
    opacity: 0.7;
}

.disabled-cursor {
    cursor: default !important;
    opacity: 0.65;
}

.customInputBoxes--error {
    input {
        border: solid 1px #ff0000 !important;
        color: red !important;

        &:focus {
            border-color: #ff0000 !important;
        }
    }
}