.kurumsal-musteri-ol {
  .fnt-n {
    font-weight: 400 !important;
    color: #486072 !important;
    font-size: 16px !important;
    text-align: right;
  }

  .corporate-step5 {
    padding-inline: 30px;
    padding-top: 30px;
    padding-bottom: 48px;

    @media (max-width: 600px) {
      padding-inline: 0px;
    }

    &__headerText {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Hind", sans-serif;
      font-size: 25px;
      font-weight: 600;
      line-height: 1.2;
      color: #f3bc45;
      text-align: center;
    }

    &__bodyText {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-family: "Hind", sans-serif;
      font-size: 16px;
      line-height: 1.5;
      margin-top: 30px;
      font-weight: 500;
    }

    &__bodyInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f3f3f3;
      padding: 20px;
      margin: 30px auto;
      width: 332px;

      @media (max-width: 600px) {
        width: 100% !important;
        margin: 30px auto 0;
      }

      &__text {
        font-family: "Hind", sans-serif;
        font-size: 15px;
        font-weight: 600;
        color: #486072;
      }
    }

    &__corporateInfo {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      &__section {
        background-color: #f3f3f3;
        padding: 20px;
        margin-right: 30px;
        width: 332px;

        @media (max-width: 600px) {
          width: 100% !important;
          margin-top: 30px;
        }

        &__text {
          font-family: "Hind", sans-serif;
          font-size: 15px;
          font-weight: 600;
          color: #486072;
        }

        &__body {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;

          span {
            font-family: "Hind", sans-serif;
            font-size: 15px;
            font-weight: 600;
            color: #486072;
          }
        }

        &__rightHeader {
          display: flex;
          justify-content: space-between;
          font-family: "Hind", sans-serif;
          font-size: 15px;
          font-weight: 600;
          color: #486072;
        }
      }
    }

    &__footerBtn {
      display: flex;
      justify-content: center;
    }
  }
}