.customer-operations-odeme-islemleri-faturami-ode {

  .nothing {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 30px;
    border-radius: 10px;
    background-color: #F3F3F3;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 20px;
      margin-top: 30px;
    }

    span {
      width: 100%;
      margin: 0 0 10px;
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
    }

    p {
      width: 100%;
      margin: 10px 0;
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: var(--lacivert);
    }

    img {
      @media screen and (max-width: 768px) {
        width: calc(50%);
      }
    }
  }

  // @media screen and (max-width: 768px) {
  //   margin-top: -30px;
  // }

  .infoBox img {
    margin-right: 35px !important;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__select {
      margin-top: 30px;

      .customSelect {
        .ant-select-selection-item {
          opacity: 1;
        }
      }
    }

    &__haveBill {
      &__card {
        background-color: #f3f3f3;
        padding: 20px;
        border-radius: 10px;
        margin-top: 30px;

        &__hr {
          background-color: #dadada;
          width: 100%;
          height: 1px;
          margin: 20px 0px;
        }

        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: unset;
          }

          &__left {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            color: #f3bc45;
          }

          &__right {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            color: #f3bc45;
            opacity: 0.5;

            @media screen and (max-width: 768px) {
              margin-top: 20px;
            }
          }
        }

        &__content {
          margin-top: 20px;

          &__item {
            display: grid;

            &:not(:first-child) {
              grid-template-columns: 1fr 1fr;
              margin-top: 20px;
            }

            grid-template-columns: 1fr;

            @media screen and (max-width: 768px) {
              grid-template-columns: 1fr !important;
            }

            &__left {
              div {
                &:first-child {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  color: #444444;
                }

                &:last-child {
                  display: flex;
                  align-items: center;
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 300;
                  font-size: 16px;
                  line-height: 24px;
                  color: #444444;
                  margin-top: 10px;

                  i {
                    margin-right: 10px;
                    color: #adb3bc;

                    &:hover {
                      cursor: pointer;
                    }
                  }
                }
              }
            }

            &__right {
              @media screen and (max-width: 768px) {
                margin-top: 20px;
              }

              div {
                &:first-child {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  color: #444444;
                }

                &:last-child {
                  display: flex;
                  align-items: center;
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 300;
                  font-size: 16px;
                  line-height: 24px;
                  color: #444444;
                  margin-top: 10px;

                  i {
                    margin-right: 10px;
                    color: #adb3bc;

                    &:hover {
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }

        &__footer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: 768px) {
            align-items: flex-start;
          }

          @media screen and (max-width: 768px) {
            flex-direction: column;
            height: fit-content;
          }

          &__left {
            div {
              &:first-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
              }

              &:last-child {
                font-family: "Hind";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
                margin-top: 10px;
              }
            }
          }

          &__right {
            @media screen and (max-width: 768px) {
              width: 100%;
            }

            button {
              margin-top: 0;

              @media screen and (max-width: 768px) {
                margin-top: 20px;
                width: 100% !important;

              }

              @media screen and (min-width: 768px) {
                width: 317px !important;
              }
            }
          }
        }
      }
    }
  }
  .infoComponent {
    margin: 0 !important;

    &__info {
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;

      &__detail {
        display: flex;
        align-items: flex-start;

        &__icon {
          width: 24px;
          padding: 12px;
          height: 24px;
          margin-right: 14px;

          &__img {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__infoArea {
        display: flex;
        align-items: flex-start;

        &__icon {
          width: 24px;
          margin: 0 !important;
          padding: 2px;
        }

        &__text {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          margin-right: -8px;
          padding: 0px;
          margin-left: 15px !important;
          width: 269px;
        }
      }

      button {
        margin: 0;
      }
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }
}