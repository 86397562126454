.ek-ekran-ssb-step3 {
  .fileLoader {
    .infoComponent {
      margin-top: 20px;
      margin-bottom: 0;

      &__info {
        padding: 2px;
        display: flex;
        // justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 3px;

        &__detail {
          display: flex;
          align-items: flex-start;

          &__icon {
            width: 24px;
            padding: 12px;
            height: 24px;
            margin-right: 14px;

            &__img {
              width: 20px;
              height: 20px;
            }
          }
        }

        button {
          margin: 0;
        }
      }
    }

    &__header {
      font-family: 'Hind', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 24px;
      color: #F3BC45;
    }

    &__description {
      margin: 20px 0px;
      font-family: 'Hind', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      ;
    }

    @mixin cardLayout {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 30px;
    }

    @mixin headerText {
      font-family: 'Hind', sans-serif;
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: var(--turuncu);
      margin-bottom: 20px;
    }

    .dropzone {
      position: relative;
    }

    &__body {
      padding-inline: 30px;

      &__belgeYukle {
        @include cardLayout;

        &__mainHeader {
          @include headerText;
        }

        &__description {
          font-family: 'Hind', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }

        &__section {
          display: flex;
          flex-direction: column;

          &__hr {
            margin-top: 20px;
            height: 1px;
            width: 100%;
            background-color: #dadada;
          }

          &__item {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            &__belge {
              display: flex;
              align-items: center;
              justify-content: space-between;

              &__left {
                display: flex;
                align-items: center;

                div {
                  &:first-child {
                    margin-right: 10px;
                    background-color: #f3bc45;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                      font-family: 'Hind', sans-serif;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      color: #ffffff;
                    }
                  }

                  &:last-child {
                    font-family: 'Hind', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    color: #444444;
                  }
                }
              }

              &__right {
                &__editBtn {
                  display: flex;
                  align-items: center;

                  &__editBtnDesktop {
                    display: block;

                    &:hover {
                      cursor: pointer;
                    }
                  }

                  &__mobileEdit {
                    display: none;
                  }

                  img {
                    &:last-child {
                      &:hover {
                        cursor: pointer;
                      }
                    }

                    &:first-child {
                      margin-right: 10px;
                    }
                  }
                }
              }
            }

            &__fileUpload {
              display: flex;
              align-items: flex-start;

              &__left {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 332px;
                height: 317px;
                left: 77px;
                top: 813px;
                background: rgba(255, 255, 255, 0.5);
                border: 1px dashed #486072;
                border-radius: 3px;
                margin-top: 10px;
                flex-direction: column;

                ::-webkit-scrollbar {
                  width: 5px;
                }

                ::-webkit-scrollbar-track {
                  background: E8ECF2;
                }

                ::-webkit-scrollbar-thumb {
                  background: #486072;
                }

                .cancelButtonFileUpload {
                  position: absolute;
                  bottom: 20px;
                  transition: all 0.3s ease-in-out;
                }

                &__uploadingBody {
                  width: calc(100% - 40px);
                  max-height: calc(100% - 70px);
                  overflow-y: auto;

                  &__uploading {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 30px;

                    div {
                      &:first-child {
                        font-family: 'Hind', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                        overflow: hidden;
                        width: 180px;
                        text-overflow: clip;
                        white-space: nowrap;
                      }

                      &:last-child {
                        font-family: 'Hind', sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 24px;
                        text-align: center;
                        color: #486072;
                        display: flex;
                        align-items: center;

                        &:last-child {
                          margin-left: 10px;

                          svg {
                            path {
                              &:first-child {
                                stroke: #f3bc45 !important;
                                stroke-width: 4;
                              }

                              &:last-child {
                                stroke: #d9d9d9 !important;
                                stroke-width: 4;
                              }
                            }
                          }
                        }
                      }
                    }

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }

                &__button {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 50px;
                  border-radius: 3px;
                  font-family: 'Hind', sans-serif;
                  font-size: 15px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: 0.3px;
                  text-align: center;
                  margin-top: 10px;
                  width: 277px;
                  cursor: pointer;
                  color: #f3bc45;
                  border: solid 1px #f3bc45;
                  background-color: #fff;

                  input {
                    display: none;
                  }
                }

                &__upperText {
                  font-family: 'Hind', sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #000000;
                }

                &__lowerText {
                  font-family: 'Hind', sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #000000;
                  margin-top: 10px;
                }

                label {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 100%;
                }
              }

              &__right {
                margin-top: 10px;
                margin-left: 30px;
                width: 100%;

                &__noUpload {
                  border-radius: 3px;
                  height: 274px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-family: 'Hind', sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  background: rgba(255, 255, 255, 0.5);
                  width: 290px;
                  text-align: center;
                  color: #000000;
                  margin-top: 20px;
                }

                &__header {
                  font-family: 'Hind', sans-serif;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 24px;
                  text-align: left;
                  color: #486072;
                }

                ::-webkit-scrollbar {
                  width: 5px;
                }

                ::-webkit-scrollbar-track {
                  background: E8ECF2;
                }

                ::-webkit-scrollbar-thumb {
                  background: #486072;
                }

                &__body {
                  background-color: #ffffff80;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 317px;
                  height: 274px;
                  margin-top: 20px;
                  font-family: 'Hind', sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #000000;

                  &__uploadedFile {
                    padding: 20px;
                    border-radius: 3px;
                    display: grid;
                    grid-template-columns: 1fr 40px;
                    margin-top: 20px;
                    background: rgba(255, 255, 255, 0.5);
                    width: 100%;

                    &:first-child {
                      margin-top: 0;
                    }

                    &__left {
                      display: flex;
                      align-items: center;
                      background: linear-gradient(90deg,
                          #000000 59.12%,
                          rgba(0, 0, 0, 0) 100%);
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                      background-clip: text;
                      text-emphasis-color: transparent;

                      div {
                        margin-left: 10px;
                        width: 12.1rem;
                        text-overflow: clip;
                        overflow: hidden;
                        white-space: nowrap;
                        font-family: 'Hind', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                      }
                    }

                    &__right {
                      position: relative;
                      width: 60px;

                      @media screen and (max-width: 768px) {
                        display: flex;
                        align-items: center;
                      }

                      img {
                        &:hover {
                          cursor: pointer;
                        }

                        &:last-child {
                          margin-left: 5px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &__modal {
        &__header {
          font-family: 'Hind', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 35px;
          text-align: center;
          letter-spacing: 0.02em;
          color: #486072;
        }

        &__description {
          font-family: 'Hind', sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: var(--black);
          margin-top: 0px;
        }

        &__button {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .fileLoader {
      &__body {
        &__belgeYukle {
          &__section {
            &__item {
              &__fileUpload {
                &__left {
                  &__button {
                    width: 100% !important;
                    margin-top: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .fileLoader {
      .dropzone {
        width: 100%;
      }

      &__body {
        padding-inline: 0px;

        &__belgeYukle {
          &__section {
            &__item {
              &__belge {
                &__right {
                  &__editBtn {
                    &__editBtnDesktop {
                      display: none;
                    }

                    &__mobileEdit {
                      display: block;

                      &:hover {
                        cursor: pointer;
                      }
                    }
                  }
                }
              }

              &__fileUpload {
                flex-direction: column;

                &__right {
                  width: 100%;
                  margin-left: 0;
                  margin-top: 30px;

                  &__noUpload {
                    width: 100%;
                  }

                  &__uploadedFile {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: space-between !important;
                    overflow-x: hidden;

                    &__left {
                      width: calc(100% - 45px);
                    }

                    &__right {
                      display: flex;
                      align-items: center;
                    }
                  }
                }

                &__left {
                  width: 100%;
                  height: max-content;
                  padding: 20px;

                  .cancelButtonFileUpload {
                    position: relative;
                  }

                  &__upperText {
                    display: none;
                  }

                  &__lowerText {
                    display: none;
                  }

                  &__uploadingBody {
                    max-height: 250px;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}