.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
  border: 1px solid #486072 !important;
}

.ant-select-dropdown {
  z-index: 999999;
  border-radius: 2px !important;
  padding: 0 !important;
}

.ant-select-item .ant-select-item-option {
  font-size: 18px !important;
}

.ant-select-item-option-content {
  font-size: 18px !important;
}

.ant-select-selector {
  border-radius: 2px !important;
}