.account-information-tarife-degistirme-detay-step2 {
  padding-inline: 30px;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__headerinfo {
    font-family: Hind;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
  }

  &__card {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      span {
        &:first-child {
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: var(--turuncu);
        }

        &:last-child {
          opacity: 0.5;
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: var(--turuncu);

          @media screen and (max-width: 768px) {
            display: inline-block;
            margin-top: 10px;
          }
        }
      }
    }

    &__address {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      div {
        &:first-child {
          margin-bottom: 10px;
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--black);
        }

        &:last-child {
          font-family: Hind;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--black);
        }
      }
    }

    &__details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      margin-top: 20px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }

      &__detail {
        display: flex;
        flex-direction: column;

        &:last-child {
          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }
        }

        div {
          &:first-child {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);
            margin-bottom: 10px;
          }

          &:last-child {
            font-family: Hind;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);
            display: flex;
            align-items: center;

            i {
              margin-right: 10px;
              color: #adb3bc;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  &__yenitarife {
    margin-top: 30px;

    &__header {
      font-family: Hind;
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: var(--turuncu);
    }

    &__card {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;

      &__header {
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: var(--turuncu);
      }

      &__description {
        font-family: Hind;
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--black);
        margin-top: 20px;
      }

      &__hr {
        height: 1px;
        width: 100%;
        background-color: #dadada;
        margin-top: 20px;
      }

      &__details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
        }

        &__detail {
          margin-top: 20px;
          width: 44%;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          div {
            &:first-child {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
              margin-bottom: 10px;
            }

            &:last-child {
              font-family: Hind;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }
          }
        }
      }
    }
  }

  &__footerinfo {
    font-family: Hind;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
    margin-top: 30px;
  }

  &__footerbuttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
  }
}