.account-information-tarife-degistirme-detay-step3 {
  padding-inline: 30px;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__text {
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--pure-black);

    span {
      color: var(--turuncu);
      font-weight: 600;
    }
  }

  &__smscode {
    margin-top: 30px;

    &__label {
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      color: #486072;
      display: block;
      margin-bottom: 10px;
    }

    &--smsCodeCont {
      display: flex;
      margin-bottom: 30px;
      align-items: center;
      .customInputBoxes {
        input {
          &:focus {
            border-color: rgb(204, 204, 204) !important;
            box-shadow: none !important;
          }
        }
      }

      @media (max-width: 600px) {
        flex-direction: column;
      }

      &--countDown {
        display: flex;
        margin-left: 30px;

        @media (max-width: 600px) {
          width: 100%;
          align-items: center;
          justify-content: center;
        }

        span {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          color: #000000;
          margin-left: 10px;
        }

        @media (max-width: 600px) {
          margin-top: 30px;
          margin-left: 0px;
        }
      }

      @media (max-width: 600px) {
      }
    }
    .yellowLabel {
      color: #f3bc45;
    }

    &__resend {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      label {
        &:first-child {
          margin-right: 15px;
        }
      }

      @media (max-width: 600px) {
        flex-direction: column;
      }

      &--resendBtn {
        display: flex;

        .forgotPassword-inputCont__label {
          @media (max-width: 600px) {
            margin-right: 0 !important;
          }
        }

        img {
          margin-left: 10px;
          height: 21.56px;
        }
      }
    }
    .smsCode__resendError {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      margin-top: 30px;
      width: 332px;
      margin-left: 14px;

      &__label {
        max-width: 272px;
        font-family: Hind;
      }

      &__icon {
        max-width: 24px !important;

      }

      .smsCode__resend__resendBtn {
        margin-left: 34px;
      }
    }
  }
}
