// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.register-page {
  .registerComplete {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    font-family: "Hind", sans-serif !important;

    &__headerText {
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #f3bc45;
      margin-top: 30px;
    }
    &__bodyText {
      margin-top: 30px;

      display: flex;
      justify-content: center;
      span {
        font-family: "Hind", sans-serif !important;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
      }
    }
    &__photo {
      position: relative;
      .registerCompleteMaskot {
        position: absolute;
        top: 35px;
        left: -56px;
        @media(max-width: 768px) {
          display: none;
        }
      }
    }
    &__bodyInfo {
      position: relative;
      width: 332px;
      height: auto;
      background-color: #f3f3f3;
      padding: 20px;
      gap: 31px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      span {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #486072;
      }
      &__copyText {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        background-color: #486072;
        color: #fff;
      }
      img {
        &:hover {
          cursor: pointer;
        }
      }
      &__sections {
        display: flex;
        flex-direction: column;
        &__section {
          display: flex;
          width: 292px;
          justify-content: space-between;
          margin-bottom: 20px;
          &__text {
            width: 130px;
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    &__footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.copiedToClipboard {
  width: 110px;
  cursor: pointer;
  height: 30px;
  background-color: #f3f3f3;
  border: 1px solid #486072;
  position: absolute;
  top: -15px;
  right: -20px;
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
