.dilekce-basvuru-step3 {
  padding-inline: 30px;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  .customInput {
    input {
      margin-bottom: 0 !important;
    }
    margin-bottom: 20px;
  }


  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 10px;
    // margin-top: 10px;
    @media screen and (max-width: 768px) {
      gap: 0px;
    }
    &__input {
      width: 332px;
      height: max-content;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      input {
        width: 332px;

        @media screen and (max-width: 768px) {
          width: 100% !important;
        }
      }
      &:nth-child(4) {
        margin-top: 34px;
        @media screen and (max-width: 768px) {
          margin-top: 0;
        }
      }

      &__checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @media screen and (max-width: 768px) {
          margin-left: 0;
          margin-bottom: 20px;
          margin-top: 10px;
        }

        .rounded-checkbox {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          text-align: left;
          color: var(--lacivert);

          &:last-child {
            margin-left: 20px;
          }
        }
      }
    }
  }

  &__detailText {
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--pure-black);
    margin-top: 10px;

    b {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  &__pdfViewer {
    width: 100% !important;
    margin-top: 30px;
    // margin-bottom: 30px;

    .rpv-core__viewer {
      width: 100% !important;
      height: 432px !important;
    }

    .rpv-core__inner-page {
      height: unset !important;
    }

    .rpv-core__viewer .rpv-core__canvas-layer {
      width: 100% !important;
    }
  }

  &__footerCheckbox {
    a {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  &__footerBtn {
    align-items: center;
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    button {
      margin-top: 0px;
    }

    .obligation {
      display: none;
      font-family: "Hind";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-left: 30px;

      @media screen and (max-width: 768px) {
        display: block;
        margin-left: 0px;
        margin-bottom: 30px;
      }
    }
    .active {
      display: block;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
