// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.ant-picker-input {
  input {
    font-family: "Hind", sans-serif !important;  
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: normal !important;
    color: #486072 !important;
  }
  font-family: "Hind", sans-serif !important;
  font-size: 18px !important;
}

.register-page {

  .customInput input {
    margin-bottom: 15px !important;
  }

  .userInformation {
    display: flex;
    flex-direction: column;

    &__inputsLine {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      @media(max-width: 800px) {
        flex-direction: column;
      }
    }

    .customInput {
      width: 332px;
      input {
        width: 332px;
        padding: 12px 15px 10px;
        @media (max-width:600px) {
          width: 100%;
        }
      }
      @media (max-width:600px) {
        width: 100%;
      }
    }

    &__prev {
      display: flex;
      align-items: center;
      margin-top: 30px;
      &:hover {
        cursor: pointer;
      }
      &__text {
        font-family: "Hind", sans-serif !important;
        font-size: 15px;
        font-weight: 600;
        padding-top: 1px;
        margin-left: 10px;
      }
    }
    &__button {
      display: flex;
      align-items: center;
      margin-top: 30px;
      padding-bottom: 30px;
      margin-bottom: 100px;
      height: 50px;
      &__text {
        display: flex;
        align-items: center;
        margin-left: 30px;
        margin-top: 25px;
        font-family: Hind;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 600;
        @media  (max-width: 600px) {
          display: none;
        }
      }
      &__mobileText {
        display: none;
        @media  (max-width: 600px) {
          display: block;
          margin-top: 30px;
          font-family: Hind;
          font-weight: 600;
          font-size: 16px;
          line-height: 1.5;
        }
      }
      @media (max-width: 600px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        margin-left: 0px;
      }
    }
    &__infoBoxes {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 600px) {
        align-items: stretch;
      }
    }
    &__userInputs {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;
      .customInput {
        // min-height: 122px;
      }
      .errorBox {
        margin-top: 0px !important;
      }
      @media (max-width: 600px) {
        align-items: stretch;
        margin-left: 0px;
      }
      &__Input {
        display: flex;
        flex-direction: column;
        // margin-top: 10px;
        &:first-child {
          margin-top: 0px;
        }
        &:nth-child(2) {
          margin-top: 0px;
        }
        @media (max-width:600px) {
          width: 100%;
        }
        &__date {
          margin-bottom: 0px !important;
        }
        @media (max-width: 600px) {
          input {
            margin-right: 0px !important;
            width: 100% !important;
          }
        }
        .ant-picker-input {
          input {
            width: 280px !important;
            height: 34px !important;
            @media (max-width:600px) {
              width: 100%;
            }
          }
        }
        &__label {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          line-height: 1.5;
          color: #486072;
        }
        &__select {
          width: 332px;
          height: 100%;
          @media  (max-width: 600px) {
            width: 100%;
          }
          .ant-select-selector {
            width: 332px;
            height: 44px !important;
            align-items: center;
            @media  (max-width: 600px) {
              width: 100%;
            }
          }
          .ant-select-item {
            height: 44px !important;
            flex-grow: 1;
            font-family: Hind;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: red !important;
          }
          @media  (max-width: 600px) {
            width: 100%;
          }
        }
      }
      .date-input {
        position: relative;
        // min-height: 100px;
        font-family: "Hind", sans-serif !important;
        .p-date-time-picker  {
          width: 322px !important;

          @media  (max-width: 600px) {
            width: 100% !important;
          }
        }
        input {
          font-family: "Hind", sans-serif !important;
          font-size: 17px !important;
          font-weight: normal !important;
          font-stretch: normal !important;
          font-style: normal !important;
          line-height: 1.33 !important;
          letter-spacing: normal !important;
          color: #486072 !important;
          &::placeholder {
            font-size: 16px !important;
            color: #486072;
            opacity: 0.5;
        }
        }
        label {
          position: absolute;
          top: -10px;
        }
        .ant-picker {
          margin-top: 22px;
        }
      }
    }
    .ant-picker {
      border: 1px solid #e8ecf2 !important;
      .ant-picker-input {
        input {
          &::placeholder {
            color: #486072 !important;
            opacity: 0.5 !important;
          }
        }
      }
      &:hover {
        border: 1px solid #e8ecf2 !important;
      }
    }
  }
  .registerButton {
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 50px;
    width: 100%;
    &__btnSpan {
      display: none;
      @media  (max-width: 600px) {
        display: block;
        font-family: "Hind", sans-serif !important;
        font-size: 16px;
        font-weight: 400;
      }
    }
    @media  (max-width: 600px) {
      width: 100%;
      margin-left: 0px;
    }
    .btn_span {
      display: flex;
      align-items: center;
      height: 24px;
      margin: 43px 60px 13px 30px;
      font-family: "Hind", sans-serif !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      color: #000;
      @media  (max-width: 600px) {
        display: none;
      }
    }
  }
}
