.corporate-register {
  .step1 {
    padding-inline: 30px;
    padding-top: 30px;
    padding-bottom: 48px;

    .modal__content__header {
      margin-bottom: 0px;
    }

    p {
      font-family: "Hind", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: #000;
      margin-bottom: 30px;
    }

    .viewer {
      display: flex;
      justify-content: space-between;

      @media (max-width: 850px) {
        flex-direction: column;
      }

      .highlight {
        font-weight: 500 !important;
      }
    }

    .wrapper__btn {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 850px) {
      padding-inline: 0;

      .ohm-primaryBtn {
        margin-top: 0;
      }
    }
  }
}