@import "../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-elektrik-kesintileri {
  @include withAuthLayout;

  &__header {
    justify-content: space-between;
  }

  &__content {
    &--left {
      &__prev {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 30px;
        width: fit-content;

        &:hover {
          cursor: pointer;
        }
      }

      &__body {
        padding-inline: 30px;

        &__info {
          margin-top: 30px;

          .infoComponent {
            margin-top: 0;

            &__info {
              padding: 12px;
              background-color: #fff;
              display: flex;
              align-items: center;
              border-radius: 3px;

              &__infoArea {
                display: flex;
                align-items: flex-start;

                &__icon {
                  width: 24px;
                  margin: 0 !important;
                  padding: 2px;
                }

                &__text {
                  font-family: Hind;
                  font-size: 15px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.6;
                  letter-spacing: normal;
                  text-align: left;
                  color: var(--lacivert);
                  margin-right: -8px;
                  padding: 0px;
                  margin-left: 15px !important;
                  width: 269px;
                }
              }

              button {
                margin-top: 0px;
                margin-left: 40px;
              }
            }
          }
        }

        &__card {
          background-color: #f3f3f3;
          border-radius: 10px;
          padding: 20px;
          margin-top: 30px;

          .customSelect img {
            z-index: 0;
          }

          &__header {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            color: #f3bc45;
          }

          &__description {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            margin-top: 20px;
          }

          &__button {
            button {
              margin-top: 20px;
            }
          }

          &__inputArea {
            display: grid;
            grid-template-columns: 317px 317px;
            gap: 20px;
            margin-top: 20px;

            &__input {
              .customInput {
                input {
                  width: 317px;
                  margin-bottom: 0px;

                }
              }

              .customInput .errorBox {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    &--right {
      margin-top: 57px;

      &__maskot {
        background: #e8ecf2;
        border-radius: 10px;
        width: 362px;
        height: 308px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 188px;
          height: 287px;
          margin-top: 45px;
        }
      }


    }
  }

  &__modal {
    .modal__content {
      max-height: 682px !important;
    }

    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
      margin-bottom: 30px;
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
      margin-top: 0 !important;
    }

    &__buttons {
      width: 100%;
    }

    .activeX {
      button {
        &:first-child {
          cursor: unset;
        }
      }
    }

    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }

      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;

        span {
          font-size: 22px;
          font-weight: 600;
        }
      }
    }
  }

  .contentRight {
    background-color: #e8ecf2;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;

    .infoComponent {
      margin-bottom: 0px !important;
      margin-top: 0px !important;

      &__info {
        display: flex;
        background-color: #fff;
        border-radius: 3px;

        &__text {
          span {
            color: #f3bc45;
          }
        }

        &__detail {
          display: flex;
          align-items: flex-start;
          padding: 0;

          &__icon {
            width: 24px;
            padding: 12px;
            height: 24px;
            margin-right: 14px;

            &__img {
              width: 20px;
              height: 20px;
            }
          }
        }

        &__infoArea {
          display: flex;
          align-items: flex-start;

          &__icon {
            width: 24px;
            margin: 0 !important;
            padding: 2px;
          }

          &__text {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: left;
            color: var(--lacivert);
            margin-right: -8px;
            padding: 0px;
            margin-left: 15px !important;
            width: 269px;
          }
        }

        button {
          margin: 0;
        }
      }

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }

  .contentBottom {
    border-radius: 10px;
    margin-top: 30px;

    .infoComponent {
      margin-bottom: 0px !important;
      margin-top: 0px !important;

      &__info {
        display: flex;
        background-color: #fff;
        border-radius: 3px;

        &__text {
          span {
            color: #f3bc45;
          }
        }

        &__detail {
          display: flex;
          align-items: flex-start;
          padding: 0;

          &__icon {
            width: 24px;
            padding: 12px;
            height: 24px;
            margin-right: 14px;

            &__img {
              width: 20px;
              height: 20px;
            }
          }
        }

        &__infoArea {
          display: flex;
          align-items: flex-start;

          &__icon {
            width: 24px;
            margin: 0 !important;
            padding: 2px;
          }

          &__text {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: left;
            color: var(--lacivert);
            margin-right: -8px;
            padding: 0px;
            margin-left: 15px !important;
            width: 269px;
          }
        }

        button {
          margin: 0;
        }
      }

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-elektrik-kesintileri {
    &__content {
      &--left {
        &__prev {
          margin-bottom: 30px;
          width: fit-content;
        }

        &__body {
          padding-inline: 0px;

          &__info {
            .infoComponent__info {
              display: block;

              &__infoArea__text {
                width: 100%;
              }

              button {
                display: block;
                margin-top: 10px;
                margin-left: 0;
              }
            }
          }

          &__card {
            &__selectArea {
              flex-direction: column;
            }

            &__inputArea {
              grid-template-columns: 1fr;
              gap: 20px;

              &__input {
                .customInput {
                  input {
                    @media screen and (max-width: 768px) {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}