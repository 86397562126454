.new-password-step2 {
  padding-inline: 30px;

  @media (max-width: 768px) {
    padding-inline: 0px;
  }

  &__text {
    &__desc {
      font-family: Hind;
      font-size: 16px;
      line-height: 1.5;
      color: var(--pure-black);
      display: block;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
    }

    &__header {
      font-family: Hind;
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #f3bc45;
      display: block;
      margin: 30px 0px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}