.randevu-sorgula-step1 {
  padding-inline: 30px;
  @media (max-width: 768px) {
    padding-inline: 0px;
  }
  input {
    &:focus {
      border-color: rgb(204, 204, 204) !important;
      box-shadow: none !important;
    }
  }
  .errorStyle {
    margin-left: 45px !important;
  }
  .smsCode__resend__resendBtn {
    margin-left: 0 !important;
    img {
      margin-bottom: 10px !important;
    }
  }
  .smsCode__resend__resendBtn__label {
    font-family: Hind !important;
    margin-bottom: 10px !important;
  }
  .errorBox__errorIcon {
    img {
      max-width: 24px !important;
    }
  }
  &__text {
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--pure-black);
    margin-top: 30px;
    margin-bottom: 30px;
    &__highlight {
      font-weight: 600;
      color: var(--turuncu);
    }
  }
  &__firstlabel {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--lacivert);
    margin-bottom: 10px;
  }
  &--smsCodeCont {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    margin-top: 10px;
    @media (max-width: 600px) {
      flex-direction: column;
    }
    &--countDown {
      display: flex;
      @media (max-width: 600px) {
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      span {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #000000;
        margin-left: 10px;
      }
      margin-left: 30px;
      @media (max-width: 600px) {
        margin-top: 30px;
        margin-left: 0px;
      }
    }
    @media (max-width: 600px) {
    }
  }
  .yellowLabel {
    color: #f3bc45;
  }
  &__resend {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    &__label {
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--lacivert);
    }
    &--resendBtn {
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 0.3px;
      text-align: center;
      color: var(--turuncu);
      margin-left: 20px;
      display: flex;
      align-items: center;
      label {
        margin-right: 10px;
        &:hover {
          cursor: pointer;
        }
      }
      img {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
