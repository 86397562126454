.customer-operations-abonelik-yaptirma-diger-abonelik-step1 {
  .nPTop {
    .errorBox {
      margin-top: -10px;
    }
  }

  @media (max-width: 768px) {
    .radioMO {
      flex-direction: column !important;
      justify-content: flex-start !important;
      width: 100%;
    }

    .radioMOIn {
      margin-right: auto;

      @media (min-width: 768px) {
        margin-bottom: 10px;
      }
    }

    .eTeminat {
      width: 100%;

      input {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }

  .btnss {
    button {
      margin-top: 0;
    }
  }

  .paddingB {
    .errorBox {
      margin-bottom: 10px;
    }
  }

  .eTeminat {
    .errorBox {
      margin-top: 10px !important;
    }
  }

  .uploadBtn {
    button {
      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
  }

  .check--btn {
    @media (max-width: 390px) {
      margin-top: 0 !important;
    }
  }

  .infoComponent {
    margin-top: 0;
    margin-bottom: 0;
  }

  .errorBox {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .modal2__content {
    img {
      max-height: 100% !important;
    }
  }

  margin-top: 30px;

  .dropzone {
    position: relative;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  @mixin cardLayout {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
  }

  @mixin headerText {
    font-family: Hind;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: var(--turuncu);
    margin-bottom: 20px;
  }

  .customModalImageHeader {
    font-family: Hind;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.56px;
    text-align: center;
    color: var(--lacivert);
    margin-bottom: 20px;
  }

  .customModalImageShow {
    object-fit: contain;
    width: 100%;
    height: 300px;
  }

  .customInput__label .higherTooltip__left__span {
    position: relative !important;

    @media screen and (max-width: 768px) {
      position: initial !important;
    }
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__body {
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__card {
      @include cardLayout;

      &__hr {
        width: 100%;
        height: 1px;
        background-color: #dadada;
        margin: 20px 0;
      }

      &__mainHeader {
        @include headerText;
      }

      &__header {
        display: flex;
        flex-direction: column;

        div {
          &:first-child {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);
          }

          &:last-child {
            font-family: Hind;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);
            margin-top: 10px;
          }
        }
      }

      &__detail {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }

        &__left {
          div {
            &:first-child {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }

            &:last-child {
              font-family: Hind;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
              margin-top: 10px;
            }
          }
        }

        &__right {
          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }

          div {
            &:first-child {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
            }

            &:last-child {
              font-family: Hind;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--black);
              margin-top: 10px;
            }
          }
        }
      }
    }

    &__dask {
      .customInput {
        .errorBox {
          margin-top: 10px !important;
        }
      }

      @include cardLayout;

      &__header {
        @include headerText;
      }

      &__description {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--pure-black);
      }

      &__input {
        margin-top: 20px;

        input {
          margin-bottom: 0;
        }
      }
    }

    &__guvenceBedel {
      .higherTooltip__left__span__top {
        div {
          &:first-child {
            font-weight: 600 !important;
          }
        }
      }

      b {
        font-weight: 600;
      }

      @include cardLayout;

      &__header {
        @include headerText;
      }

      &__tooltip {
        width: 230px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        &__price {
          font-family: Hind;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--black);
          margin-top: 10px;
        }
      }

      &__description {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--pure-black);
      }

      &__input {
        margin-top: 20px;
      }

      &__checkboxArea {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 20px;

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }

        &__checkbox {
          background-color: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          width: 100%;

          @media (max-width: 768px) {
            height: 72px;
          }

          @media (min-width: 769px) {
            height: 50px;
          }

          a {
            color: #f3bc45;
          }

          &:not(:first-child) {
            margin-top: 20px;
          }

          &:first-child {
            u {
              color: #f3bc45;
              font-weight: 600;
            }
          }

          .rounded-checkbox {
            margin-left: 10px;
          }
        }

        &__checkbox2 {
          background-color: rgba(255, 255, 255, 0.5);
          display: flex;
          flex-direction: column;
          padding: 12px;
          height: 50px;
          width: calc(100% - 10px);

          .infoComponent {
            margin-top: 20px;
            background-color: #fff;
          }

          &__left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: Hind;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: var(--pure-black);

            div {
              display: flex;
              align-items: center;
            }

            input {
              margin: 0;
            }

            // margin-top: 10px;
            @media (min-width: 768px) {
              // margin-bottom: 10px;
            }
          }

          &:not(:first-child) {
            // margin-top: 20px;
          }
        }
      }
    }

    &__mulkiyet {
      @include cardLayout;

      &__header {
        @include headerText;
      }

      &__description {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--pure-black);
      }

      &__toggleCategory {
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);
        height: 50px;
        margin-top: 20px;

        button {
          margin-top: 0;
        }

        &__hr {
          margin-top: 0px;
          height: 30px;
          width: 1px;
          margin-left: 5px;
          margin-right: 5px;
          background-color: #486072;
        }

        .ohm-tertiaryBtn {
          border-radius: 3px;
          background-color: rgba(255, 255, 255, 0.5);
          color: #f3bc45;
        }
      }

      &__moreSection {
        margin-top: 20px;

        &__checkboxArea {
          grid-template-columns: 130px 100px;
          display: grid;
          margin-top: 10px;
        }

        &__label {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
        }

        &__inputArea2 {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: -20px;
          width: 100%;
          margin-top: 20px;

          &__input2 {
            @media screen and (max-width: 768px) {
              width: 100%;

              &:last-child {
                margin-top: 20px;
              }
            }
          }

          .customInput {
            width: 317px;

            @media screen and (max-width: 768px) {
              width: 100% !important;

              input {
                width: 100% !important;
              }
            }

            input {
              width: 317px;
            }
          }

          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        }

        &__inputArea {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          margin-top: 20px;
          margin-bottom: -20px;

          &__input {
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }

          .customInput {
            width: 317px;

            @media screen and (max-width: 768px) {
              width: 100% !important;

              input {
                width: 100% !important;
              }
            }

            input {
              width: 317px;
            }
          }

          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        }
      }
    }

    &__modal {
      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 35px;
        /* or 125% */

        text-align: center;
        letter-spacing: 0.02em;

        /* Lacivert */

        color: #486072;
      }

      &__description {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: var(--black);
      }

      &__button {
        width: 100%;
      }
    }

    &__belgeYukle {
      @include cardLayout;

      &__mainHeader {
        @include headerText;
      }

      &__description {
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }

      &__section {
        display: flex;
        flex-direction: column;

        &__item {
          display: flex;
          flex-direction: column;
          border-top: 1px solid #dadada;
          padding-top: 20px;
          margin-top: 20px;

          &__belge {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__left {
              display: flex;
              align-items: center;

              &:hover {
                // cursor: pointer;
              }

              div {
                &:first-child {
                  margin-right: 10px;
                  background-color: #f3bc45;
                  height: 30px;
                  width: 30px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  span {
                    font-family: "Hind";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    text-align: center;
                    color: #ffffff;
                  }
                }

                &:last-child {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 24px;
                  color: #444444;
                }
              }
            }

            &__right {
              &__editBtn {
                display: flex;
                align-items: center;

                &__editBtnDesktop {
                  display: block;

                  &:hover {
                    cursor: pointer;
                  }

                  @media screen and (max-width: 768px) {
                    display: none;
                  }
                }

                &__mobileEdit {
                  display: none;

                  @media screen and (max-width: 768px) {
                    &:hover {
                      cursor: pointer;
                    }

                    display: block;
                  }
                }

                img {
                  &:last-child {
                    &:hover {
                      cursor: pointer;
                    }
                  }

                  &:first-child {
                    margin-right: 10px;
                  }
                }
              }
            }
          }

          &__fileUpload {
            display: flex;
            align-items: flex-start;

            @media screen and (max-width: 768px) {
              flex-direction: column;
            }

            &__leftyda {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 317px;
              height: 317px;
              left: 77px;
              top: 813px;
              // background: rgba(255, 255, 255, 0.5);
              // border: 1px dashed #486072;
              border-radius: 3px;
              margin-top: 20px;
              flex-direction: column;

              @media screen and (max-width: 768px) {
                width: 100%;
                height: max-content;
                padding: 20px;
              }


              &__button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                border-radius: 3px;
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.3px;
                text-align: center;
                margin-top: 50px;
                width: 277px;
                cursor: pointer;
                color: #f3bc45;
                // box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 #fff;
                border: solid 1px #f3bc45;
                background-color: #fff;

                @media screen and (max-width: 1024px) {
                  width: 100% !important;
                  margin-top: 0;
                }
              }

              .webcamModalAbonelik {
                .modal2__content {
                  max-height: max-content !important;
                }
              }
            }

            &__left {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 317px;
              height: 317px;
              left: 77px;
              top: 813px;
              background: rgba(255, 255, 255, 0.5);
              border: 1px dashed #486072;
              border-radius: 3px;
              margin-top: 20px;
              flex-direction: column;

              @media screen and (max-width: 768px) {
                width: 100%;
                height: max-content;
                padding: 20px;
              }

              ::-webkit-scrollbar {
                width: 5px;
              }

              /* Track */
              ::-webkit-scrollbar-track {
                background: E8ECF2;
              }

              /* Handle */
              ::-webkit-scrollbar-thumb {
                background: #486072;
              }

              .cancelButtonFileUpload {
                position: absolute;
                bottom: 20px;
                transition: all 0.3s ease-in-out;
                margin-top: 20px;

                @media screen and (max-width: 768px) {
                  //width: calc(100% - 40px) !important;
                  position: relative;
                }
              }

              &__uploadingBody {
                width: calc(100% - 40px);
                /* max-height: calc(100% - 60px); */
                overflow-y: auto;

                @media screen and (max-width: 768px) {
                  max-height: 250px !important;
                  width: 100%;
                }

                &__uploading {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 30px;

                  div {
                    &:first-child {
                      font-family: "Hind";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                      overflow: hidden;
                      width: 180px;
                      text-overflow: clip;
                      white-space: nowrap;

                      @media screen and (max-width: 768px) {
                        width: 150px;
                        text-overflow: ellipsis;
                      }
                    }

                    &:last-child {
                      font-family: "Hind";
                      font-style: normal;
                      font-weight: 600;
                      font-size: 15px;
                      line-height: 24px;
                      text-align: center;
                      color: #486072;
                      display: flex;
                      align-items: center;

                      &:last-child {
                        margin-left: 10px;

                        svg {
                          path {
                            &:first-child {
                              stroke: #f3bc45 !important;
                              stroke-width: 4;
                            }

                            &:last-child {
                              stroke: #d9d9d9 !important;
                              stroke-width: 4;
                            }
                          }
                        }
                      }
                    }
                  }

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }

              &__button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                border-radius: 3px;
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.3px;
                text-align: center;
                margin-top: 10px;
                width: 277px;
                cursor: pointer;
                color: #f3bc45;
                // box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 #fff;
                border: solid 1px #f3bc45;
                background-color: #fff;

                @media screen and (max-width: 1024px) {
                  width: 100% !important;
                  margin-top: 0;
                }

                input {
                  display: none;
                }
              }

              &__upperText {
                font-family: "Hind";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #000000;

                @media screen and (max-width: 768px) {
                  display: none;
                }
              }

              &__lowerText {
                font-family: "Hind";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #000000;
                margin-top: 10px;

                @media screen and (max-width: 768px) {
                  display: none;
                }
              }

              label {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
              }
            }

            &__right {
              margin-top: 20px;
              margin-left: 10px;
              width: 317px;

              @media screen and (max-width: 768px) {
                width: 100%;
                margin-left: 0;
              }

              &__noUpload {
                background: rgba(255, 255, 255, 0.5);
                border-radius: 3px;
                width: 317px;
                height: 274px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Hind";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                @media screen and (max-width: 768px) {
                  width: 100%;
                  max-height: 92px;
                }

                /* identical to box height, or 150% */

                text-align: center;

                /* Pure Black */

                color: #000000;
                margin-top: 20px;
              }

              &__header {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                text-align: left;
                color: #486072;
              }

              ::-webkit-scrollbar {
                width: 5px;
              }

              /* Track */
              ::-webkit-scrollbar-track {
                background: E8ECF2;
              }

              /* Handle */
              ::-webkit-scrollbar-thumb {
                background: #486072;
              }

              &__body {
                background-color: #ffffff80;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 317px;
                height: 274px;
                margin-top: 20px;
                font-family: "Hind";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                text-align: center;

                /* Pure Black */

                color: #000000;

                /* width */

                &__uploadedFile {
                  width: 100%;
                  padding: 20px;
                  background: rgba(255, 255, 255, 0.5);
                  border-radius: 3px;
                  display: grid;
                  grid-template-columns: 1fr 40px;
                  margin-top: 20px;

                  @media screen and (max-width: 768px) {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: space-between !important;
                    overflow-x: hidden;
                  }

                  &:first-child {
                    margin-top: 0;
                  }

                  &__left {
                    display: flex;
                    align-items: center;

                    div {
                      margin-left: 10px;
                      width: 12.1rem;
                      text-overflow: clip;
                      overflow: hidden;
                      white-space: nowrap;
                      font-family: "Hind";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                    }

                    @media screen and (max-width: 768px) {
                      width: calc(100% - 45px);
                    }
                  }

                  &__right {
                    position: relative;
                    width: 60px;

                    @media screen and (max-width: 768px) {
                      display: flex;
                      align-items: center;
                    }

                    &__bg {
                      position: absolute;
                      height: 100%;
                      width: 100px;
                      background: rgb(0, 0, 0);

                      background: linear-gradient(270deg, rgba(249, 249, 249, 0.8) 0%, rgba(0, 0, 0, 0) 120%);

                      -webkit-text-fill-color: transparent;
                      background-clip: border-box;

                      left: -100px;
                    }

                    img {
                      &:hover {
                        cursor: pointer;
                      }

                      &:last-child {
                        margin-left: 5px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__eTeminatInfo {
    .infoComponent {
      margin-top: 0px;

      &__info {
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 3px;

        @media screen and (max-width: 1180px) {
          flex-direction: column;
          align-items: flex-start;

        }

        &__detail {
          display: flex;
          align-items: flex-start;

          &__icon {
            width: 24px;
            padding: 12px;
            height: 24px;
            margin-right: 14px;

            &__img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}