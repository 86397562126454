// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// .infoComponent {
//   width: 694px;
//   height: auto;
//   font-family: "Hind", sans-serif !important;
//   margin-top: 30px;
//   border-radius: 3px;

//   gap: 10px;
//   border: 1px solid #486072;
//   @media (max-width: 600px) {
//     width: 100% !important;
//   }
//   &__info {
//     display: flex;
//     justify-content: flex-start;
//     align-items: stretch;
//     &__icon {
//       color: #486072;
//       display: flex;
//       font-size: 20px;
//       margin-left: 12px;
//       margin-top: 12px;
//     }
//     &__text {
//       font-size: 15px;
//       font-weight: 600;
//       line-height: 1.6;
//       padding: 12px;
//       color: #486072;
//     }
//   }
// }


// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.infoComponent {
  width: 100%;
  height: auto;
  font-family: "Hind", sans-serif !important;
  margin-top: 30px;
  border-radius: 3px;
  gap: 10px;
  border: 1px solid #486072;

  &--error {
    border: 1px solid #ff0000;
  }

  &--success {
    border: 1px solid #6cb425;
  }

  @media (max-width: 600px) {
    width: 100% !important;
  }

  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    &__icon {
      display: flex;
      margin-left: 12px;
      margin-top: 12px;
      font-size: 20px;
      color: #486072;

      @media (max-width: 768px) {
        margin: 14px 0 12px 12px !important;
      }

      &--error {
        color: #ff0000;
      }

      &--success {
        color: #6cb425;
      }
    }

    &__text {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.6;
      padding: 12px;
      color: #486072;

      &--error {
        color: #ff0000;
      }

      &--success {
        color: #6cb425;
      }
    }
  }

  &__button {
    padding: 10px;

    button {
      margin-top: 0;
    }
  }
}
