// $icon-maskot: url("../../assets/icons/maskot-anket.svg");
// $icon-maskot-hover: url("../../assets/icons/maskot-anket-hover.svg");
$navbar-abonelik-white: url("../../assets/icons/add-afterlogin.svg");
$navbar-abonelik-black: url("../../assets/icons/add-afterlogin-black.svg");
$navbar-documents-white: url("../../assets/icons/add-white.svg");
$navbar-documents-black: url("../../assets/icons/add-black.svg");
$navbar-counter: url("../../assets/icons/counter-afterlogin.svg");
$navbar-counter-black: url("../../assets/icons/counter-afterlogin-black.svg");
$navbar-documentsx: url("../../assets/icons/documentx.svg");
$navbar-documentsx-black: url("../../assets/icons/documentx-black.svg");
$navbar-flash: url("../../assets/icons/flash-afterlogin.svg");
$navbar-flash-black: url("../../assets/icons/flash-afterlogin-black.svg");
$navbar-location: url("../../assets/icons/location-black.svg");
$navbar-location-black: url("../../assets/icons/location.svg");
$navbar-plus: url("../../assets/icons/plus-afterlogin.svg");
$navbar-plus-black: url("../../assets/icons/plus-afterlogin-black.svg");
$navbar-question: url("../../assets/icons/question.svg");
$navbar-question-black: url("../../assets/icons/question-black.svg");
$navbar-wallet-white: url("../../assets/icons/wallet-white.svg");
$navbar-wallet-black: url("../../assets/icons/wallet-black.svg");

$navbar-arrow-white: url("../../assets/icons/down-white.svg");
$navbar-arrow-black: url("../../assets/icons/down-blue.svg");

@mixin theme($bgc, $color) {
  background-color: $bgc;
  color: $color;
}

.after-login-component {
  display: flex;
  flex-direction: column;
  width: 1146px !important;


  .after-login-component__body__card__header__moreInfo {
    min-width: 143px !important;
  }

  &__modal2 {
    .headerNoMargin {
      margin-bottom: -10px;
    }

    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
      margin-bottom: 14px;

      &__text {
        font-family: Hind;
        font-size: 28px;
        font-weight: 600;
        line-height: 1.25;
        letter-spacing: 0.56px;
        color: #486072;
        text-align: center;
      }

      &__icon {
        cursor: pointer;
      }
    }

    &__buttonS {
      display: flex;
      justify-content: center;
    }

    &__buttonS2 {
      position: relative;

      #addFromLocal {
        display: none;
      }

      label {
        position: absolute;
        // margin-top: 30px;
        width: 292px;
        height: 50px;
        z-index: 0;
        top: 0px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &__headerS {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      &__text {
        font-family: Hind;
        font-size: 28px;
        font-weight: 600;
        line-height: 1.25;
        letter-spacing: 0.56px;
        color: #486072;
        text-align: center;
      }

      &__icon {
        cursor: pointer;
      }
    }

    .modal2__content {
      max-height: max-content !important;
    }

    .modal__content {
      max-height: max-content !important;
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
      // margin-top: 30px;
    }

    &__buttons {
      width: 100%;
    }

    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }

      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;

        span {
          font-size: 22px;
          font-weight: 600;

          a {
            color: #444;
          }
        }
      }
    }
  }

  &__modal {
    .headerNoMargin {
      margin-bottom: -10px;
    }

    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
      margin-bottom: 14px;

      &__text {
        font-family: Hind;
        font-size: 28px;
        font-weight: 600;
        line-height: 1.25;
        letter-spacing: 0.56px;
        color: #486072;
        text-align: center;
      }

      &__icon {
        cursor: pointer;
      }
    }

    .modal__content {
      max-height: max-content !important;
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
      // margin-top: 30px;
    }

    &__buttons {
      width: 100%;
    }

    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }

      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;

        span {
          font-size: 22px;
          font-weight: 600;

          a {
            color: #444;
          }
        }
      }
    }
  }

  &__maskot {
    position: fixed;
    bottom: 40px;
    right: 60px;
    z-index: 1000;
    cursor: pointer;

    @media screen and (max-width: 1400px) {
      bottom: 10px;
    }

    @media screen and (max-width: 1180px) {
      display: none;
    }

    img {
      transition: 500ms;
    }

    &:hover {
      img {
        transform: scale(1.3);
      }
    }

    &__top {
      position: relative;

      img {
        width: 138.25px;
        height: 175px;
      }
    }

    &__bottom {
      position: absolute;
      left: 10px;
      bottom: 0px;
      background: linear-gradient(131.92deg, #fcee50 -5.41%, #eea540 107.64%);
      box-shadow: -5px -5px 20px #ffffff, 5px 5px 20px rgba(174, 174, 192, 0.4);
      color: #fff;
      width: 136px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 10px;
      border-radius: 10px;
    }
  }

  @media (max-width: 1180px) {
    width: 100% !important;
  }

  .customSelect {
    width: 100% !important;
    padding: 4px 0;

    img {
      top: 27% !important;
      z-index: 10 !important;
      right: 10px;
    }

    &__label {
      margin-bottom: unset;
      font-family: unset;
      font-size: unset;
      font-weight: unset;
      text-align: unset;
      color: unset;
    }
  }

  .activeN1 {
    background-color: #fff;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #f3bc45;

    span {
      color: #486072;
    }

    .icon {
      content: $navbar-documentsx-black !important;
    }

    .after-login-component__header__item__dropdown1__arrow {
      content: $navbar-arrow-black;
    }
  }

  .activeN2 {
    background-color: #fff;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #f3bc45;

    span {
      color: #486072;
    }

    .icon {
      content: $navbar-documentsx-black !important;
    }

    .after-login-component__header__item__dropdown2__arrow {
      content: $navbar-arrow-black;
    }
  }

  .n1 {
    .icon {
      content: $navbar-abonelik-white;
    }

    &:hover {
      .icon {
        content: $navbar-abonelik-black;
      }

      .after-login-component__header__item__dropdown1__arrow {
        content: $navbar-arrow-black;
      }
    }
  }

  .n2 {
    img {
      content: $navbar-counter;
    }

    &:hover {
      img {
        content: $navbar-counter-black;
      }
    }
  }

  .n3 {
    img {
      content: $navbar-plus;
    }

    &:hover {
      img {
        content: $navbar-plus-black;
      }
    }
  }

  .n4 {
    .icon {
      content: $navbar-abonelik-white;
    }

    &:hover {
      .icon {
        content: $navbar-abonelik-black;
      }

      .after-login-component__header__item__dropdown2__arrow {
        content: $navbar-arrow-black;
      }
    }
  }

  .n5 {
    img {
      content: $navbar-flash;
    }

    &:hover {
      img {
        content: $navbar-flash-black;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%);
    border-radius: 10px;
    height: 60px;
    gap: 5px;
    position: relative;

    @media (max-width: 1180px) {
      display: none;
    }

    &__item {
      display: flex;
      align-items: center;
      height: 60px;
      width: max-content;
      justify-content: flex-start;
      padding: 0 20px;

      &:hover {
        cursor: pointer;
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #f3bc45;

        span {
          color: #486072;
        }
      }

      img {
        margin-bottom: 3px;
      }

      span {
        font-family: Hind;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.3px;
        margin-left: 10px;
        color: #ffffff;
        width: max-content;
      }

      &__menu {
        position: absolute;
        width: 235px;
        left: 0;
        top: 59px;
        z-index: 1000;

        &__cards {
          width: 100%;
          border-radius: 10px;
          // padding: 10px 10px 10px 20px;
          border: 1px solid #f3bc45;
          background-color: #fff;

          .hr {
            border: 10px solid #f3f3f3;
            width: 230px;
            margin-left: -53px;
          }

          &:last-child {
            .hr {
              display: none;
            }

            &__card {
              &__data {
                margin-bottom: 10px;
              }
            }
          }

          &__card {
            padding: 10px 10px 10px 54px;
            color: #486072;

            &__data {
              font-family: "Hind";
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              align-items: center;
              letter-spacing: 0.02em;
              height: 40px;
              display: flex;
              justify-content: flex-start;
            }

            &:hover {
              background: linear-gradient(131.92deg, #fcee50 -5.41%, #eea540 107.64%) padding-box;
              color: #fff;
              border-radius: 10px;
            }
          }
        }
      }

      &__menu2 {
        position: absolute;
        width: 284px;
        left: 654px;
        top: 59px;
        z-index: 1000;

        &__cards {
          width: 100%;
          border-radius: 10px;
          // padding: 10px 10px 10px 20px;
          border: 1px solid #f3bc45;
          background-color: #fff;

          .hr {
            border: 10px solid #f3f3f3;
            width: 230px;
            margin-left: -53px;
          }

          &:last-child {
            .hr {
              display: none;
            }

            &__card {
              &__data {
                margin-bottom: 10px;
              }
            }
          }

          &__card {
            padding: 10px 10px 10px 54px;
            color: #486072;

            &__data {
              font-family: "Hind";
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              align-items: center;
              // letter-spacing: 0.02em;
              height: 40px;
              display: flex;
              justify-content: flex-start;
            }

            &:hover {
              background: linear-gradient(131.92deg, #fcee50 -5.41%, #eea540 107.64%) padding-box;
              color: #fff;
              border-radius: 10px;
            }
          }
        }
      }

      &__dropdown1 {
        &__arrow {
          margin-left: 15px;
          animation: rotateRSelectComponent 0.3s;
          z-index: -1;

          content: $navbar-arrow-white;

          &:hover {
            cursor: pointer;
          }
        }
      }

      &__dropdown2 {
        &__arrow {
          margin-left: 15px;
          animation: rotateRSelectComponent 0.3s;
          z-index: -1;

          content: $navbar-arrow-white;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__footer {
    background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%);
    border-radius: 10px;
    height: 60px;

    .n1 {
      position: relative;

      img {
        content: $navbar-wallet-white;
      }

      &:hover {
        img {
          content: $navbar-wallet-black;
        }
      }
    }

    .n2 {
      img {
        content: $navbar-documents-white;
      }

      &:hover {
        img {
          content: $navbar-documents-black;
        }
      }
    }

    .n3 {
      img {
        content: $navbar-question;
      }

      &:hover {
        img {
          content: $navbar-question-black;
        }
      }
    }

    .n4 {
      img {
        content: $navbar-location;
      }

      &:hover {
        img {
          content: $navbar-location-black;
        }
      }
    }

    .n5 {
      img {
        content: $navbar-documents-white;
      }

      &:hover {
        img {
          content: $navbar-documents-black;
        }
      }
    }

    .container {
      padding: 0 50px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }

    @media (max-width: 1180px) {
      display: none;
    }

    &__item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      justify-content: center;

      &:hover {
        cursor: pointer;
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #f3bc45;

        span {
          color: #486072;
        }
      }

      span {
        margin-right: 10px;
        font-family: Hind;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.3px;
        margin-left: 10px;
        color: #ffffff;
      }
    }
  }

  &__body {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 1180px) {
      flex-direction: column;
      width: 100% !important;
      justify-content: center;
    }

    &__card {
      width: 558px;
      background-color: #f3f3f3;
      padding: 20px;
      padding-bottom: 0px;
      border-radius: 10px;
      height: auto;
      margin-bottom: 30px;

      @media (max-width: 1180px) {
        width: 100% !important;
      }

      @media (max-width: 1180px) {
        padding-bottom: 20px;

        &:first-child {
          padding-bottom: 10px;
        }
      }

      .swiper-button-disabled {
        display: none !important;
      }

      @media (max-width: 1180px) {
        .after-login-component__body__card__content__slider {
          display: flex;
        }

        .customer-operations-dilekce-randevu-map-view__slider__nextBtn {
          transform: translateY(-50%);
          display: flex;
          right: 0;
          top: 100px;
          background-color: #fff;
        }

        .customer-operations-dilekce-randevu-map-view__slider__prevBtn {
          transform: translateY(-50%);
          display: flex;
          top: 100px;
          left: 0;
          background-color: #fff;
        }

        .customer-operations-dilekce-randevu-map-view__slider__nextBtn__rectangle__right {
          background: transparent;
        }

        .customer-operations-dilekce-randevu-map-view__slider__prevBtn__rectangle__left {
          background: transparent;
        }
      }

      .customInput {
        width: 100% !important;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__text {
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          line-height: 1.2;
          text-align: left;
          color: #f3bc45;
        }

        &__moreInfo {
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          span {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            line-height: 1.6;
            letter-spacing: 0.3px;
            text-align: center;
            color: #f3bc45;
            margin-right: 10px;
            width: 109px;
          }

          &__footer {
            display: flex;
            align-items: center;

            span {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              line-height: 1.6;
              letter-spacing: 0.3px;
              text-align: center;
              color: #f3bc45;
              // margin-right: 10px;
              width: 109px;
            }

            &:hover {
              cursor: pointer;
            }

            img {
              margin-top: -1px;
            }
          }
        }
      }

      &__content {
        display: flex !important;
        flex-direction: column !important;
        margin-top: 30px !important;

        &__maskot {}

        &__slider {
          position: relative;
          width: 100%;

          .customer-operations-dilekce-randevu-map-view__slider__nextBtn {
            top: 35%;
          }

          .customer-operations-dilekce-randevu-map-view__slider__prevBtn {
            top: 35%;
          }

          .customer-operations-dilekce-randevu-map-view__slider__nextBtn__rectangle__right {
            top: -70px;
            right: -10px;
            height: 300px;
          }

          .customer-operations-dilekce-randevu-map-view__slider__prevBtn__rectangle__left {
            top: -70px;
            left: -10px;
            height: 300px;
          }

          &__text {
            display: block;
            width: 100%;
            font-family: Hind;
            font-size: 34px;
            font-weight: 600;
            line-height: 1.18;
            text-align: center;
            margin-bottom: 15px;
          }

          @media (max-width: 768px) {
            display: none;
          }

          .swiper-wrapper {
            font-family: "Hind", sans-serif !important;
            height: auto;
          }

          .swiper-slide {
            padding: 12px;
            border-radius: 10px !important;
            background-color: var(--white);

            img {
              width: 100%;
            }

            .text {
              font-size: 25px;
              font-weight: 600;
              color: #444;
              line-height: 1.4;
            }

            .link {
              display: block;
              color: #f3bc45;
              font-size: 15px;
              font-weight: 600;
              margin-top: 12px;
              cursor: pointer;

              &::after {
                font-family: enerjisaohm !important;
                content: "\f152";
                font-size: 24px;
                font-weight: 300;
                position: absolute;
                margin-top: -9px;
                margin-left: 10px;
              }
            }
          }

          .swiper-slide-active {
            opacity: 1;
          }
        }

        &__slider__positive {
          position: relative;
          width: 100%;

          .customer-operations-dilekce-randevu-map-view__slider__nextBtn {
            top: 35%;
          }

          .customer-operations-dilekce-randevu-map-view__slider__prevBtn {
            top: 35%;
          }

          .customer-operations-dilekce-randevu-map-view__slider__nextBtn__rectangle__right {
            top: -70px;
            right: -10px;
            height: 300px;
          }

          .customer-operations-dilekce-randevu-map-view__slider__prevBtn__rectangle__left {
            top: -70px;
            left: -10px;
            height: 300px;
          }

          &__text {
            display: block;
            width: 100%;
            font-family: Hind;
            font-size: 34px;
            font-weight: 600;
            line-height: 1.18;
            text-align: center;
            margin-bottom: 15px;
          }

          // @media (max-width: 768px) {
          //   display: none;
          // }

          .swiper-wrapper {
            font-family: "Hind", sans-serif !important;
            height: auto;
          }

          .swiper-slide {
            padding: 12px;
            border-radius: 10px !important;
            background-color: var(--white);
            height: 322px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            img:first-child {
              width: 100%;
              border-radius: 10px;
              // height: 250px;
              object-fit: contain;
              overflow: hidden;
            }

            .textContainer {
              height: 100px;
              overflow: hidden;
              margin-top: 12px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }

            .text {
              font-size: 18px;
              font-weight: 600;
              color: #444;
              line-height: 1.4;
              overflow: hidden;
            }

            .link {
              display: block;
              color: #f3bc45;
              font-size: 15px;
              font-weight: 600;
              margin-top: 12px;
              height: 36px;
              cursor: pointer;

              &::after {
                font-family: enerjisaohm !important;
                content: "\f152";
                font-size: 24px;
                font-weight: 300;
                position: absolute;
                margin-top: -9px;
                margin-left: 10px;
              }
            }
          }

          .swiper-slide-active {
            opacity: 1;
          }
        }

        &__footer {
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          span {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            line-height: 1.6;
            letter-spacing: 0.3px;
            text-align: center;
            color: #f3bc45;
            margin-right: 10px;
            width: 109px;
          }

          &__moreInfo {
            display: flex;
            align-items: center;
            margin-top: 10px;

            span {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              line-height: 1.6;
              letter-spacing: 0.3px;
              text-align: center;
              color: #f3bc45;
              margin-right: 10px;
              width: 109px;
            }

            &:hover {
              cursor: pointer;
            }

            img {
              width: 24px !important;
            }
          }
        }

        &__info {
          display: flex !important;
          align-items: center !important;
          width: 100% !important;
          justify-content: space-between !important;

          @media (max-width: 1180px) {
            flex-direction: column;
            width: 100% !important;
            justify-content: center;
          }

          div {
            @media (max-width: 1180px) {
              width: 100% !important;
            }

            .infoComponent__info__icon {
              @media (max-width: 1180px) {
                margin: 12px 0 12px 12px !important;
              }
            }
          }
        }

        &__dropdownArea {
          display: flex;
          justify-content: space-between;

          @media (max-width: 1180px) {
            flex-direction: column;
          }

          .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            height: 44px;
          }

          .ant-select-arrow {
            display: none;
          }

          .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
          .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
            margin-top: 5px;
          }

          .ant-select-selection-item {
            opacity: 1;
          }

          &__dropdown {
            display: flex;
            flex-direction: column;
            position: relative;

            &__icon {
              position: absolute;
              top: 55%;
              right: 2%;
            }

            label {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              color: #486072;
            }

            &__select {
              width: 249px !important;
              margin-top: 5px;
              margin-bottom: 20px;

              @media (max-width: 1180px) {
                width: 100% !important;
              }

              .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                background-color: #fff !important;
              }
            }
          }
        }

        &__inputArea {
          display: flex;
          flex-direction: column;
          margin-top: -10px;
          width: 100%;

          .addressInput {
            label {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--lacivert);
            }

            input {
              height: 100px !important;
            }
          }

          &__input {
            width: 100% !important;
            margin-top: 5px;
            position: relative;
            pointer-events: none;

            &:last-child {
              input {
                @media (max-width: 768px) {
                  margin-bottom: 10px;
                }
              }
            }

            input {
              width: 100% !important;
            }

            span {
              display: none;
            }

            &__icon {
              position: absolute;
              right: 15px;
              top: 50%;
            }
          }

          &__recipeInput {
            width: 100% !important;
            margin-bottom: 14px;

            .customTextArea {
              width: 100% !important;
            }

            textarea {
              overflow-y: auto !important;
              height: 44px !important;
              background-color: #fff;

              &:hover {
                border: none;
                border: 1px solid #fff;
              }

              @media (max-width: 1180px) {
                height: 70px !important;
              }
            }

            span {
              display: none;
            }
          }

          &__adressInput {
            width: 100% !important;
            margin-bottom: 10px;

            .customTextArea {
              width: 100% !important;
            }

            textarea {
              height: 80px !important;
              overflow-y: auto !important;
              background-color: #fff;

              &:hover {
                border: none;
                border: 1px solid #fff;
              }

              @media (max-width: 1180px) {
                height: 100px !important;
              }
            }

            span {
              display: none;
            }
          }

          &__statusInput {
            width: 100% !important;
            margin-bottom: 10px;
            position: relative;

            .customTextArea {
              width: 100% !important;
            }

            textarea {
              height: 50px !important;
              overflow-y: auto !important;
              background-color: #fff;

              &:hover {
                border: none;
                border: 1px solid #fff;
              }

              // @media (max-width: 1180px) {
              //   height: 100px !important;
              // }
            }

            span {
              display: none;
            }
          }
        }
      }
    }

    &__card2 {
      width: 558px;
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 10px;
      height: 100%;
      margin-bottom: 30px;

      @media (max-width: 1180px) {
        width: 100% !important;
      }

      .swiper-wrapper {
        display: flex;
        align-items: flex-end;
        height: 250px;
      }

      .swiper-slide {
        height: auto;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__text {
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          line-height: 1.2;
          text-align: left;
          color: #f3bc45;
        }

        &__moreInfo {
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          a {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            line-height: 1.6;
            letter-spacing: 0.3px;
            text-align: center;
            color: #f3bc45;
            margin-right: 10px;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;

        .after-login-component__body__card__content__slider .swiper-slide {
          background-color: unset !important;
        }

        .customer-operations-dilekce-randevu-map-view__slider__prevBtn {
          top: 60%;

          img {
            border-radius: 50%;
            background: #fff;
            width: 100%;
            height: 100%;
          }

          @media (max-width: 768px) {
            margin-left: -8px;
          }
        }

        .customer-operations-dilekce-randevu-map-view__slider__nextBtn {
          top: 60%;

          img {
            border-radius: 50%;
            background: #fff;
            width: 100%;
            height: 100%;
          }

          @media (max-width: 768px) {
            margin-right: -8px;
          }
        }

        .customer-operations-dilekce-randevu-map-view__slider__prevBtn__rectangle__left {
          top: 0;
          height: 240px;
          width: 40px;
          left: 0;
          background: rgba(243, 243, 243);
          background: linear-gradient(90deg, rgba(243, 243, 243, 1) 25%, rgba(243, 243, 243, 0.3) 100%);

          @media (max-width: 768px) {
            width: 50px;
            margin-left: -20px;
          }
        }

        .customer-operations-dilekce-randevu-map-view__slider__nextBtn__rectangle__right {
          top: 0;
          height: 240px;
          width: 55px;
          z-index: 300;
          right: 0;
          background: rgb(0, 0, 0);
          background: linear-gradient(90deg, rgba(243, 243, 243, 0.3) 0%, rgba(243, 243, 243, 1) 75%);

          @media (max-width: 768px) {
            width: 50px;
            margin-right: -20px;
          }
        }

        .active-slide {
          .after-login-component__body__card2__content__billbar__bars__top__bar__content {
            color: #fcee50 !important;
            background-image: linear-gradient(131.92deg, #fcee50 -5.41%, #eea540 107.64%);
          }

          .after-login-component__body__card2__content__billbar__bars__top__dates {
            font-weight: bold !important;
          }

          .after-login-component__body__card2__content__billbar__bars__top__fee {
            font-weight: bold !important;
          }
        }

        .customer-operations-dilekce-randevu-map-view__slider__nextBtn {
          display: flex;
        }

        .customer-operations-dilekce-randevu-map-view__slider__prevBtn {
          display: flex;
          // left: 4px;
        }

        .swiper-button-disabled {
          visibility: hidden !important;
        }

        // .swiper-slide-active {
        //   .after-login-component__body__card2__content__billbar__bars__top__dates {
        //     font-weight: bold !important;
        //   }

        //   .after-login-component__body__card2__content__billbar__bars__top__fee {
        //     font-weight: bold !important;
        //   }

        //   .after-login-component__body__card2__content__billbar__bars__top__bar__content {
        //     background-image: linear-gradient(
        //       159deg,
        //       #fcee50 -1%,
        //       #eea540 103%
        //     ) !important;
        //   }
        // }

        &__billbar {
          border-radius: 5px;

          &__bars {
            // height: 230px;
            width: 30px;
            display: flex;

            &__top {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              flex-direction: column;
              height: 100%;
              cursor: pointer;

              &__dates {
                display: flex;
                flex-direction: column;
                font-family: Hind;
                font-size: 13px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.08;
                letter-spacing: normal;
                text-align: center;
                color: var(--lacivert);
                margin-top: 20px;

                .swiper-slide .active {
                  font-weight: 700;
                }
              }

              &__fee {
                font-family: Hind;
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: normal;
                text-align: center;
                width: 60px;
                color: var(--lacivert);
              }

              &__bar {
                border-radius: 5px;
                width: 50px;
                height: 100% !important;
                display: flex;
                align-items: center;
                justify-content: center;

                &__content {
                  // transform: rotate(180deg);
                  border-radius: 5px;
                  display: flex;
                  align-items: center;
                  margin-top: 10px;
                  width: 30px;
                  color: #fff;
                  background-color: #fff;
                }
              }
            }
          }
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: #fff !important;
        }

        &__info {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;

          .infoComponent {
            margin-bottom: 0px;
          }

          @media (max-width: 1180px) {
            flex-direction: column;
            width: 100% !important;
            justify-content: center;
          }

          .infoComponent__info {
            justify-content: flex-start;
          }

          .infoComponent__info {
            button {
              width: 100% !important;
            }
          }

          .infoComponent__button {
            padding-top: 0;

            button {
              width: 100% !important;
            }
          }

          div {
            @media (max-width: 1180px) {
              width: 100% !important;
            }

            .infoComponent__info__icon {
              @media (max-width: 1180px) {
                margin: 12px 0 12px 12px !important;
              }
            }
          }
        }

        &__dropdownArea {
          display: flex;
          justify-content: space-between;

          @media (max-width: 1180px) {
            flex-direction: column;
          }

          .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            height: 44px;
          }

          .ant-select-arrow {
            display: none;
          }

          .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
          .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
            margin-top: 5px;
          }

          &__dropdown {
            display: flex;
            flex-direction: column;
            position: relative;

            &__icon {
              position: absolute;
              top: 55%;
              right: 2%;
            }

            label {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              color: #486072;
            }

            &__select {
              width: 249px !important;
              margin-top: 5px;
              margin-bottom: 20px;

              @media (max-width: 1180px) {
                width: 100% !important;
              }

              .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                background-color: #fff !important;
              }
            }
          }
        }

        &__billsArea {
          display: flex;
          margin-top: 10px;
          margin-bottom: 30px;

          @media (max-width: 1180px) {
            flex-direction: column;
            width: 100% !important;
          }

          &__selectArea {
            position: relative;
            display: flex;
            flex-direction: column;

            .customSelect {
              .ant-select-selection-item {
                opacity: 1;
              }
            }

            &__select {
              width: 156px !important;

              @media (max-width: 1180px) {
                width: 145px !important;
              }
            }

            @media (max-width: 1180px) {
              display: flex;
              align-items: center;
              width: 100% !important;
              justify-content: space-between;
            }

            &__icon {
              position: absolute;
              right: 4%;
              top: 4%;

              @media (max-width: 1180px) {
                left: 40%;
                top: 27%;
              }
            }

            button {
              margin-top: 15px;
              height: 44px;

              @media (max-width: 1180px) {
                margin-top: 20px;
                width: 100% !important;
              }
            }
          }

          &__bills {
            display: flex;
            align-items: center;
            margin-left: 20px;
            justify-content: space-between;
            width: 100%;

            @media (max-width: 1180px) {
              margin: 0;
              width: 100% !important;
            }

            &__billItem {
              padding: 18px 12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;
              font-size: 13px;
              width: 109px;
              height: 109px;
              border-radius: 50%;
              border: 5.4px solid transparent;
              background: linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, #fcee50, #eea540) border-box;

              @media (max-width: 1180px) {
                padding: 16px 12px;
                width: 101px;
                height: 101px;
                margin-top: 30px;
              }

              @media (max-width: 400px) {
                padding: 14px 8px 16px 8px;
                width: 92px;
                height: 92px;
              }

              &__headerText {
                width: 90px;
                color: #444;
                font-family: Hind;
                font-size: 13px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: center;

                @media (max-width: 400px) {
                  font-size: 11px;
                }
              }

              &__detailText {
                font-family: Hind;
                font-size: 17.4px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.13;
                letter-spacing: normal;
                text-align: center;
                color: var(--black);

                @media (max-width: 768px) {
                  font-size: 15px;
                }
              }
            }
          }
        }

        &__inputArea {
          display: flex;
          flex-direction: column;
          margin-top: -10px;
          width: 100%;

          .addressInput {
            label {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--lacivert);
            }

            input {
              height: 100px !important;
            }
          }

          &__input {
            width: 100% !important;
            margin-top: 5px;
            position: relative;

            input {
              width: 100% !important;
            }

            span {
              display: none;
            }

            &__icon {
              position: absolute;
              right: 15px;
              top: 45%;
            }
          }

          &__recipeInput {
            width: 100% !important;
            margin-bottom: 20px;

            .customTextArea {
              width: 100% !important;
            }

            textarea {
              overflow: hidden !important;
              height: 70px !important;
            }

            span {
              display: none;
            }
          }

          &__adressInput {
            width: 100% !important;
            margin-bottom: 10px;

            .customTextArea {
              width: 100% !important;
            }

            textarea {
              height: 120px !important;
              overflow: hidden !important;
            }

            span {
              display: none;
            }
          }
        }
      }

      .nothing {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 518px;
        padding: 30px;
        border-radius: 10px;
        background-color: var(--white);

        @media screen and (max-width: 999px) {
          width: 100%;
          padding: 20px;
          margin-top: 30px;
        }

        @media screen and (max-width: 500px) {
          padding: 10px;
        }

        span {
          width: 478px;
          margin: 0 0 10px;
          font-family: Hind;
          font-size: 28px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: 0.56px;
          text-align: center;
          color: var(--lacivert);

          @media screen and (max-width: 999px) {
            width: 100%;
            height: 100%;
          }

          @media screen and (max-width: 500px) {
            font-size: 26px;
          }
        }

        p {
          width: 478px;
          margin: 10px 0;
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: var(--lacivert);

          @media screen and (max-width: 768px) {
            width: 100%;
            height: 100%;
          }
        }

        img {
          width: 70%;

          @media screen and (max-width: 768px) {
            width: calc(50%);
          }
        }
      }

      .infoComponent {
        height: 140px;

        @media screen and (max-width: 1024px) {
          height: auto;
        }
      }
    }

    &__card3 {
      width: 558px;
      border-radius: 10px;
      height: auto;
      margin-bottom: 30px;

      @media (max-width: 1180px) {
        width: 100% !important;
        margin-top: 0;
      }

      &__picture {
        width: 558px;
        min-height: 350px;
        max-height: 450px;

        @media (max-width: 1180px) {
          width: 100% !important;
          height: auto;
        }

        @media (max-width: 768px) {
          display: none;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        &__footerMaskot {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          background-color: #f3f3f3;
          padding: 20px;
          border-radius: 10px;
          height: 179px !important;
          overflow: hidden;

          @media (max-width: 1180px) {
            display: none;
          }

          div {
            @media (max-width: 1180px) {
              width: 100% !important;
            }
          }

          &__top {
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
              // margin-bottom: 0;
              padding-right: 33px;
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--lacivert);
            }

            div {
              width: 249px;

              button {
                width: 100% !important;
                margin-top: 4px !important;
              }
            }
          }

          &__bottom {
            width: 420px;
            height: 220px;
            margin-left: -50px;
            margin-bottom: -50px;

            img {
              width: 105%;
              height: 105%;
            }
          }
        }
      }
    }

    &__card4 {
      width: 558px;
      border-radius: 10px;
      height: 100%;
      margin-bottom: 30px;
      background-color: var(--soft-grey);
      display: none;

      @media (max-width: 1180px) {
        width: 100% !important;
      }

      @media (max-width: 768px) {
        display: block;
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;

        &__info {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          padding: 20px;

          .infoComponent {
            margin-top: 0;
            background-color: var(--soft-grey);
            border: none;

            @media (max-width: 768px) {
              width: 100% !important;
              margin-bottom: 0;
            }

            i {
              display: none;
            }

            span {
              padding: 0;
            }
          }

          .infoComponent__button {
            padding: 20px 0 0 0 !important;

            button {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

.activeDropdown {
  .after-login-component__header__item__dropdown1__arrow {
    animation: rotateSelectComponent 0.3s;
    animation-fill-mode: forwards;
  }
}

.activeDropdown2 {
  .after-login-component__header__item__dropdown2__arrow {
    animation: rotateSelectComponent 0.3s;
    animation-fill-mode: forwards;
  }
}

@keyframes rotateSelectComponent {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotateRSelectComponent {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.home-before-login__body__slider {
  position: relative;
  width: 100%;

  .active-slide {
    .after-login-component__body__card2__content__billbar__bars__top__bar__content {
      color: #fcee50 !important;
      background-image: linear-gradient(131.92deg, #fcee50 -5.41%, #eea540 107.64%);
    }

    .after-login-component__body__card2__content__billbar__bars__top__dates {
      font-weight: bold !important;
    }

    .after-login-component__body__card2__content__billbar__bars__top__fee {
      font-weight: bold !important;
    }
  }
}

.home-before-login {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 36px;

    &__headerText {
      font-family: Hind;
      font-size: 34px;
      font-weight: 600;
      text-align: center;
      color: #444;
    }

    &__text {
      font-family: Hind;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      color: #444;
      width: 693px;
      margin-bottom: 40px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &__map {
    margin-bottom: 30px;
    position: relative;
    border-radius: 10px;
    background-color: #f3f3f3;

    .leaflet-container {
      border-radius: 10px;
    }

    &__card {
      position: absolute;
      right: 2%;
      top: 5%;
      z-index: 500;
      border-radius: 3px;
      padding: 20px;
      width: 348px;
      background: #fff;

      .ant-select-arrow {
        display: none;
      }

      @media (max-width: 768px) {
        width: 90%;
        right: 5%;
      }

      display: flex;
      flex-direction: column;

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 44px;
      }

      .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        margin-top: 8px;
      }

      &__text {
        font-family: Hind;
        font-size: 25px;
        font-weight: 600;
        line-height: 1.2;
        text-align: left;
        color: #486072;
        margin-bottom: 40px;

        @media (max-width: 768px) {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }

      &__selectArea {
        position: relative;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0px;
        }

        &__select {
          width: 100% !important;
        }

        &__icon {
          position: absolute;
          right: 15px;
          top: 20%;
        }
      }
    }

    .leaflet-control-attribution {
      display: none;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__section {
      display: flex;
      flex-direction: column;
      max-width: 1140px;
      z-index: 2;

      @media (max-width: 1199px) {
        width: 100%;
        justify-content: center;
      }

      &__img {
        height: 228px;
        object-fit: cover;
        width: 558px;
        border-radius: 10px;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);

        @media (max-width: 1200px) {
          width: 100%;
          height: 372px;
          margin: 0 auto;
        }

        @media (max-width: 768px) {
          display: none;
        }
      }

      &__header {
        &__headerText {
          display: inline-block;
          font-family: Hind;
          font-size: 20px;
          font-weight: 600;
          line-height: 1.5;
          text-align: left;
          color: #444;
          margin-left: 20px;
          border-bottom: 5px solid #f0b143;

          @media (max-width: 768px) {
            margin-bottom: 115px;
            margin-top: 0px;
          }
        }
      }

      &__rect {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__text {
          font-family: Hind;
          font-size: 18px;
          line-height: 1.33;
          text-align: left;
          color: #444;
          margin-top: -63px;
          z-index: 2;
          padding: 8px 25px;
          width: 480px;
          border-radius: 5px 5px 0 0;
          background: #fff;
          padding-inline: 25px;

          @media (max-width: 768px) {
            width: 100%;
            padding: 0px;
            margin-top: -85px;
            margin-bottom: 20px;
            padding-left: 20px;
          }
        }

        &__bottom {
          display: flex;
          background-image: linear-gradient(101deg, #fcee50 -10%, #eea540 112%);

          &:hover {
            cursor: pointer;
          }

          height: 110px;
          width: 480px;
          border-radius: 0 0 5px 5px;
          padding: 20px;
          margin-bottom: 70px;
          color: #fff;

          @media (max-width: 768px) {
            width: 100% !important;
            margin-bottom: 30px;
            border-radius: 10px;
          }

          &__text {
            display: flex;
            flex-direction: column;
            margin-left: 30px;
            justify-content: center;

            &__header {
              margin-bottom: 15px;
              font-family: Hind;
              font-size: 25px;
              font-weight: 600;
              line-height: 1.2;
              text-align: left;

              @media (max-width: 768px) {
                font-size: 20px;
              }
            }

            &__footer {
              display: flex;
              align-items: center;

              img {
                margin-top: -3px;
              }

              span {
                font-family: Hind;
                font-size: 15px;
                font-weight: 600;
                line-height: 1.6;
                letter-spacing: 0.3px;
                text-align: center;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    &__enerjisa {
      position: relative;
      width: calc(100% - 41px);
      height: 223px;
      // z-index: -5;
      border-radius: 120px 0 0 120px;
      margin: 58px 30px 140px 41px;
      padding: 46px 125px 47px 367px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 53.81%, #ffffff 99.28%),
        linear-gradient(131.92deg, rgba(252, 238, 80, 0.75) -5.41%, rgba(238, 165, 64, 0.75) 107.64%);

      @media (max-width: 1100px) {
        padding-left: 150px;
        padding-right: 80px;
      }

      @media (max-width: 1100px) {
        display: none;
      }

      &__maskot {
        position: absolute;
        width: 590px;
        transform: scale(-1, 1);
        z-index: -4;
        left: -192px;
        top: -264px;
      }

      &__content {
        display: flex;
        flex-direction: column;

        &__text {
          font-family: Hind;
          width: 593px;
          font-size: 20px;
          font-weight: 600;
          line-height: 1.5;
          text-align: center;
          color: #444;

          @media (max-width: 1100px) {
            width: 100%;
          }
        }

        &__links {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;

          img {
            margin-right: 18px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    &__enerjisa-mobile {
      font-family: "Hind", sans-serif !important;
      width: 100%;
      background-image: url("../../assets/images/bg-maskot.png");
      margin-bottom: 50px;
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: contain;

      &__content {
        @media (max-width: 350px) {
          padding-top: 250px;
        }

        width: 100%;
        padding-top: 350px;

        &__text {
          display: block;
          margin: 0 auto;
          width: 320px;
          line-height: 1.5;
          padding-inline: 30px;
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          color: #444;

          @media (max-width: 350px) {
            width: 220px;
          }
        }

        &__links {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            margin-bottom: 20px;
          }
        }
      }

      @media (min-width: 1100px) {
        display: none;
      }
    }

    &__slider {
      position: relative;
      width: 100%;

      .customer-operations-dilekce-randevu-map-view__slider__nextBtn__rectangle__right {
        top: -120px;
        right: -10px;
        height: 300px;
      }

      .customer-operations-dilekce-randevu-map-view__slider__prevBtn__rectangle__left {
        top: -120px;
        left: -10px;
        height: 300px;
      }

      &__text {
        display: block;
        width: 100%;
        font-family: Hind;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.18;
        text-align: center;
        margin-bottom: 15px;
      }

      @media (max-width: 768px) {
        display: none;
      }

      .swiper-wrapper {
        font-family: "Hind", sans-serif !important;
        height: auto;
        // padding-block: 15px;
      }

      .swiper-slide {
        // width: calc(50% - 15px) !important;
        // opacity: 0.3;
        padding: 12px;
        border-radius: 10px !important;
        background-color: var(--white);

        img {
          width: 100%;
        }

        .text {
          font-size: 25px;
          font-weight: 600;
          color: #444;
          line-height: 1.4;
        }

        .link {
          display: block;
          color: #f3bc45;
          font-size: 15px;
          font-weight: 600;
          margin-top: 12px;
          cursor: pointer;

          &::after {
            font-family: enerjisaohm !important;
            content: "\f152";
            font-size: 24px;
            font-weight: 300;
            position: absolute;
            margin-top: -8px;
            margin-left: 10px;
          }
        }
      }

      .swiper-slide-active {
        opacity: 1;
        // box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4),
        //   -5px -5px 20px 0 #fff;
      }
    }

    &__slider2 {
      margin-bottom: 50px;
      width: 100%;

      .swiper-wrapper {
        font-family: "Hind", sans-serif !important;
        height: auto;
        padding-block: 15px;
      }

      &__text {
        display: block;
        width: 100%;
        font-family: Hind;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.18;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 20px;
      }

      .swiper-slide {
        width: calc(85%) !important;
        opacity: 0.3;
        padding: 12px;

        img {
          width: 100%;
        }

        .text {
          font-size: 25px;
          font-weight: 600;
          color: #444;
          line-height: 1.4;
        }

        .link {
          display: block;
          color: #f3bc45;
          font-size: 15px;
          font-weight: 600;
          margin-top: 12px;
          cursor: pointer;

          &::after {
            font-family: enerjisaohm !important;
            content: "\f152";
            font-size: 24px;
            position: absolute;
            margin-top: -8px;
            margin-left: 10px;
            font-weight: 400;
          }
        }
      }

      .swiper-slide-active {
        opacity: 1;
        box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 #fff;
      }

      .swiper-button-next {
        display: none;
      }

      .swiper-button-prev {
        display: none;
      }

      @media (min-width: 768px) {
        display: none;
      }
    }

    &__afterSlider {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding: 0 30px;
      height: 60px;
      border-radius: 10px;
      background: radial-gradient(at top left, #fcee50 -10%, #eea540 112%);
      margin-bottom: 30px;

      @media (max-width: 992px) {
        flex-direction: column;
        height: auto;
      }

      &__link {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-top: -4px;
        }

        &:hover {
          cursor: pointer;
        }

        @media (max-width: 992px) {
          margin-top: 20px;
          display: block;
          width: 100%;

          &:last-child {
            margin-bottom: 20px;
          }

          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: #fff;
            margin-top: 20px;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }

        &__text {
          font-family: Hind;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0.3px;
          text-align: left;
          color: #fff;
          margin-left: 10px;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;

    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;

    .unvisible {
      @media (max-width: 768px) {
        display: none;
      }
    }

    &__hr {
      width: 100%;
      height: 1px;
      background-color: #486072;
      margin-top: 20px;
      opacity: 0.5;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media (max-width: 768px) {
        // justify-content: center;
        flex-direction: column;
        align-items: center;
      }

      &__social {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area: CallCenter;

        .linkdin {
          width: 14px;
          height: 13.7px;
        }

        div {
          margin-left: 13px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #486072;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        img {
          width: 20px;
          height: 20px;

          &:hover {
            cursor: pointer;
          }
        }

        @media (max-width: 768px) {
          // display: none;
          margin-top: 30px;
          margin-right: 13px;
        }
      }

      &__info {
        display: flex;
        justify-content: space-between;

        &__icon {
          display: none;

          @media (max-width: 768px) {
            display: block;
            width: 50px;
            height: 50px;
            margin-right: 14px;
          }
        }

        &__logo {
          width: 200px;
          height: 38px;
          object-fit: contain;

          @media (max-width: 768px) {
            display: none;
          }
        }

        &__hr {
          height: 50px;
          background-color: #486072;
          border-left: 1px solid #486072;
          margin-left: 40px;
          margin-right: 40px;
          opacity: 0.5;

          @media (max-width: 768px) {
            display: none;
          }
        }

        &__textArea {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          resize: none;

          @media (max-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }

          &__small {
            font-family: Hind;
            font-size: 15px;
            font-weight: 500;
            text-align: center;
            color: #486072;
          }

          &__big {
            font-family: Hind;
            font-size: 25px;
            font-weight: 600;
            line-height: 1;
            text-align: center;
            color: #486072;
          }
        }
      }
    }

    &__download {
      display: flex;
      align-items: center;
      margin-top: 20px;

      img {
        margin-right: 20px;
        width: 150px;

        &:hover {
          cursor: pointer;
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    &__body {
      &__content {
        width: 100%;
        display: grid;
        grid-template-columns: 4fr 4fr 3fr;
        grid-template-rows: 1fr auto;
        grid-row-start: auto;
        padding: 30px 0;

        grid-template-areas:
          "Link1 Link5 Link9"
          "Link2 Link6 Link10"
          "Link3 Link7 Link11"
          "Link4 Link8 Link12";

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr 1fr;
          grid-template-areas:
            "Link1 Link6"
            "Link2 Link7"
            "Link3 Link8"
            "Link4 Link9"
            "Link5 Link10"
            "Link11 Link12";

          grid-row-gap: 30px;
        }

        .link-1 {
          grid-area: Link1;
        }

        .link-2 {
          grid-area: Link2;
        }

        .link-3 {
          grid-area: Link3;
        }

        .link-4 {
          grid-area: Link4;
        }

        .link-5 {
          grid-area: Link5;
        }

        .link-6 {
          grid-area: Link6;
        }

        .link-7 {
          grid-area: Link7;
        }

        .link-8 {
          grid-area: Link8;
        }

        .link-9 {
          grid-area: Link9;
        }

        .link-10 {
          grid-area: Link10;
        }

        &__link {
          font-family: Hind;
          font-size: 20px;
          font-weight: 500;
          line-height: 2;
          color: #000;

          &:hover {
            cursor: pointer;
            color: #000;
          }

          @media (max-width: 850px) {
            margin-right: 0px;
            font-size: 15px;
            line-height: 1.33;
          }
        }
      }
    }

    &__copyright {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 25px;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &__logo {
        // margin-left: -50px;
        display: flex;
        justify-content: space-between;
        gap: 30px;
      }

      &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Hind;
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
        color: #000;

        &__one {
          margin-right: 10px;
        }

        &__two {
          margin-left: 10px;
        }

        @media (max-width: 768px) {
          flex-direction: column;
          margin-left: 0px;
          margin-bottom: 10px;

          &__one {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  // .sabanci-footer {
  //   position: relative;
  //   height: 2px;
  //   width: 100%;
  //   background-color: #004b93;
  //   margin-top: 58px;
  //   margin-bottom: 41px;

  //   img {
  //     position: absolute;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //   }

  //   @media (max-width: 768px) {
  //     margin-top: 30px;
  //     margin-bottom: 31px;
  //     background: #fff;
  //   }
  // }
}

.customer-operations-dilekce-randevu-map-view__slider__nextBtn__rectangle__right {
  top: -120px;
  right: -10px;
  height: 300px;
}

.customer-operations-dilekce-randevu-map-view__slider__prevBtn__rectangle__left {
  top: -120px;
  left: -10px;
  height: 300px;
}

.customer-operations-dilekce-randevu-map-view {
  margin-top: 30px;

  .leaflet-bottom .leaflet-right {
    display: none !important;

    .leaflet-control-attribution .leaflet-control {
      display: none !important;
    }
  }

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-container {
    border-radius: 10px !important;
  }

  .swiper-button-disabled {
    display: none !important;
  }

  .swiper-slide {
    width: 332px !important;
    margin: 0;

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    display: none !important;
  }

  .swiper-container {
    padding: 10px 20px;
  }

  .swiper-slide-prev {
    margin-left: 200px !important;

    @media (max-width: 768px) {
      margin-left: 0px !important;
    }
  }

  .swiper-slide-active {
    margin: 0 30px !important;

    @media (max-width: 768px) {
      margin: 0 !important;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-bottom: 58px;
    position: relative;
    bottom: 190px;
  }

  &__slider {
    display: flex;
    position: relative;
    bottom: 190px;
    z-index: 500;
    width: 100% !important;

    &__prevbg {
      position: absolute;
      background: rgb(255, 255, 255);
      background: linear-gradient(90deg,
          rgba(255, 255, 255, 1) 5%,
          rgba(255, 255, 255, 0.8) 15%,
          rgba(255, 255, 255, 0) 50%);
      height: 249px;
      width: 10%;
      top: 0;
      z-index: 99;
      left: 0;
    }

    &__nextbg {
      position: absolute;
      background: rgb(255, 255, 255);
      background: linear-gradient(90deg,
          rgba(255, 255, 255, 0) 15%,
          rgba(255, 255, 255, 0.8) 70%,
          rgba(255, 255, 255, 1) 100%);
      height: 249px !important;
      width: 10%;
      top: 0;
      z-index: 99;
      right: 0;
    }

    &__prevBtn {
      position: absolute;
      left: 10px;
      top: 50%;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      transform: translateY(-100%);
      z-index: 500;

      img {
        z-index: 501;
      }

      &:hover {
        cursor: pointer;
      }

      &__rectangle__left {
        position: absolute;
        width: 67px;
        height: 229px;
        z-index: 1;
        background: linear-gradient(90deg,
            rgba(255, 255, 255, 1) 15%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0) 100%);
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    &__nextBtn {
      position: absolute;
      right: 10px;
      top: 50%;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(-100%);
      z-index: 500;
      background-color: #fff;
      border-radius: 50%;

      img {
        z-index: 501;
      }

      &:hover {
        cursor: pointer;
      }

      &__rectangle__right {
        position: absolute;
        width: 67px;
        height: 229px;
        z-index: 1;
        background: linear-gradient(270deg,
            rgba(255, 255, 255, 1) 15%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0) 100%);
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    &__card {
      left: 90px;
      display: flex;
      flex-direction: column;
      padding: 20px;
      background-color: #f3f3f3;
      margin-bottom: 20px;
      border-radius: 10px;
      width: 332px;
      z-index: 499;

      @media (max-width: 768px) {
        width: calc(100% - 20px) !important;
        margin: 0 auto;
      }

      &__headerText {
        font-family: Hind;
        font-size: 17px;
        font-weight: 600;
        text-align: left;
        color: #444;
      }

      &__descText {
        font-family: Hind;
        font-size: 15px;
        line-height: 1.6;
        text-align: left;
        color: #444;
        margin-top: 10px;
      }

      &__content {
        font-size: 14px;
        margin-bottom: 10px;
      }

      &__hr {
        border: 1px solid #e6e6e6;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &__address {
        font-family: Hind;
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
        color: #444;
      }
    }
  }
}

.spanC {
  font-family: Hind;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.3px;
  text-align: center;
  color: #f3bc45 !important;
  margin-right: 10px;
}

.after-login-component__header {
  .n4 {
    .icon {
      content: $navbar-abonelik-white !important;
    }

    &:hover {
      .icon {
        content: $navbar-abonelik-black !important;
      }

      .after-login-component__header__item__dropdown2__arrow {
        content: $navbar-arrow-black !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .after-login-component__modal2 {
    .modal__content {
      align-items: center;
    }
  }
}