.customer-operations-serbest-tuketici-basvurusu-step2 {
  margin-top: 30px;
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__body {
    padding-inline: 30px;
    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }
    &__headerText {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #444444;
    }
    &__input {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      width: 332px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      input {
        margin-bottom: 0;
      }
      &__text {
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        text-decoration-line: underline;
        margin-top: 10px;
        color: #444444;
        a {
          color: #444444;
          &:hover {
            color: #444444;
          }
        }
      }
    }
    &__button {
      display: flex;
      button {
        &:last-child {
          margin-left: auto;
        }
      }
      @media(max-width: 1024px) {
        flex-direction: column;
        button {
        &:last-child {
          margin-left: none;
        }
      }
      }
    }
  }
}
