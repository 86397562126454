.musteri-islemleri-abonelik-yaptirma-abonelik-step4 {
  margin-top: 30px;
  padding-inline: 30px;
  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }
  &__header {
    font-family: Hind;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: var(--turuncu);
  }
  &__description {
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--pure-black);
    margin-top: 30px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    position: relative;
    &__button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      @media screen and (max-width: 768px) {
        button {
          width: 100%;
        }
      }
    }
    img {
      position: absolute;
      left: -55px;
      top: 75px;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .address {
      text-align: right !important;
    }
    &__card {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      margin: 0 auto;
      margin-top: 30px;
      padding: 20px;
      background-color: var(--soft-grey);
      width: 332px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      &__area2 {
        display: grid;
        grid-template-columns: auto 70px;
        @media(max-width: 400px) {
          display: flex;
          // flex-direction: column;
          .musteri-islemleri-abonelik-yaptirma-abonelik-step4__body__card__area__right {
            text-align: left;
          }
        }
      }
      &__area {
        display: grid;
        grid-template-columns: auto 130.5px;
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        &__left {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          @media(max-width: 768px) {
            display: flex;
            align-items: center;
          }
        }
        &__right {
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: right;
          color: var(--lacivert);
        }
      }
    }
  }
}
