.rounded-checkbox {
  font-family: "Hind", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  // align-items: center !important;

  input {
    margin-right: 10px;
  }

  &__label {
    // display: flex;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    color: #486072;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      width: 19px;
      height: 19px;
      background-color: transparent;
      border-radius: 12px;
      cursor: pointer;

      .checked {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
        }
      }
    }

    input {
      display: none;
    }
  }

  .spanFirst {
    // margin-left: 10px;
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    // text-align: left;
    color: var(--lacivert);
    // margin-top: 3px;
  }
}
