.ek-ekran-e-arsiv-onay-step3 {
  .mla {
    button {
      margin-left: auto;
    }
  }
  .smsCodeINput {
      input {
        &:focus {
          border-color: #e8ecf2 !important;
          box-shadow: none !important;
        }
      }
  }

  margin-top: 30px;
  padding-inline: 30px;
  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }
  &__description {
    font-family: "Hind";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
    span {
      font-weight: 600;
    }
  }
  &__check {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &__card {
    background-color: #f3f3f3;
    padding: 12px;
    border-radius: 3px;
    .spanFirst {
      line-height: 1.5 !important;
      letter-spacing: normal !important;
    }
    .rounded-checkbox__label {
      @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
      }
    }
    &:not(:first-child) {
      margin-top: 10px;
    }
    .rounded-checkbox .spanFirst {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #444444;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    &__button {
      width: 100%;
    }
  }
  &__more {
    margin-top: 30px;
    &__text {
      font-family: "Hind";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #444444;
      span {
        font-weight: 600;
        color: #f7a600;
      }
    }
    &-inputCont {
      margin-top: 30px;
      .react-code-input {
        @media screen and (max-width: 768px) {
          gap: 13.5px !important;
        }
      }
      @media (max-width: 600px) {
        margin: 30px 0;
      }
      input {
        @media (max-width: 600px) {
          // width: 100%;
        }
        &:focus {
          outline: none;
        }
      }
      &__input {
        margin-bottom: 30px;
        padding: 10px 15px;
        width: 100%;
        height: 44px;
        border-radius: 3px;
        color: #486072;
        border: solid 1px #e8ecf2;
        font-size: 18px;
        &::placeholder {
          color: #486072;
          opacity: 0.5;
        }
        @media (max-width: 600px) {
          margin-bottom: 0px;
        }
      }
      &__label {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        color: #486072;
        display: block;
        margin-bottom: 10px;
        @media screen and (max-width: 768px) {
          margin-bottom: 0;
        }
      }
      &--smsCodeCont {
        display: flex;
        margin-bottom: 30px;
        align-items: center;
        @media (max-width: 600px) {
          flex-direction: column;
        }
        &--countDown {
          display: flex;
          @media (max-width: 600px) {
            width: 100%;
            align-items: center;
          }
          span {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.3px;
            color: #000000;
            margin-left: 10px;
          }
          margin-left: 30px;
          @media (max-width: 600px) {
            margin-top: 10px;
            margin-left: 0px;
          }
        }
        @media (max-width: 600px) {
        }
      }
      .yellowLabel {
        color: #f3bc45;
      }
      &__resend {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        label {
          &:first-child {
            margin-right: 10px;
            @media screen and (max-width: 768px) {
              margin-right: 0;
            }
          }
        }

        @media (max-width: 600px) {
          flex-direction: column;
        }
        &--resendBtn {
          display: flex;
          .forgotPassword-inputCont__label {
            @media (max-width: 600px) {
              margin-right: 0 !important;
            }
          }
          img {
            margin-left: 10px;
            height: 21.56px;
          }
        }
      }
    }
  }
}
