.more-info-hizmet-noktalari-page {
  &__prev {
    margin-bottom: 30px;
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  .infoBox {
    img {
      @media screen and (max-width: 768px) {
        transform: scale(0.8);
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    padding-inline: 30px;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__desktopmap {
      display: block;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &__mobilemap {
      display: block;
      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      width: 100% !important;
      button {
        border: none !important;
        background-color: rgba(243, 243, 243, 0.5);
      }
      // .ohm-primaryBtn {
      //   box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4),
      //     -5px -5px 20px 0 #ffffff;
      // }

      &__verticalhr {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 30px;
        border-left: 1px solid #486072;
        width: 1px;
        height: 30px;
        box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4),
          -5px -5px 20px 0 var(--white);

        @media screen and (max-width: 768px) {
          margin-right: 10px;
          margin-left: 10px;
        }
      }
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;

    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: 0.56px;
      color: #486072;
      text-align: center;
      margin-bottom: 30px;
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }

    &__button {
      width: 100%;
    }
  }

  .infoComponent {
    margin-bottom: 0 !important;
    &__info {
      padding: 12px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 3px;
      @media screen and (max-width: 768px) {
        display: block;
      }
      &__infoArea {
        display: flex;
        align-items: flex-start;

        @media screen and (max-width: 768px) {
        }
        &__icon {
          width: 24px;
          margin: 0 !important;
        }
        &__text {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          margin-right: -8px;
          padding: 0px;
          margin-left: 15px !important;
          width: 274px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }

      button {
        margin-top: 0px;
        @media screen and (max-width: 768px) {
          display: block;
          margin-top: 10px;
        }
      }
    }
  }

  .bluebox-wrapper {
    .infoComponent {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .appointmentInfoBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 50%;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      div {
        width: 100%;
        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }
  }
}
