.customer-operations-basvuru-islemleri-odeme-step2 {
  .customer-operations-abonelik-iptal-detay-step1__body__card__tesisatNo__right__copy {
    cursor: pointer;
    margin-top: 0;

    div {
      font-weight: 400 !important;
      margin-right: 12px;
    }
  }

  &__body {
    padding-inline: 30px;

    &__header {
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      color: #f3bc45;
      margin: -5px 0;
    }

    &__content {
      font-family: "Hind";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      margin-top: 30px;
    }

    &__card {
      background-color: #f3f3f3;
      border-radius: 10px;
      height: 100%;
      margin: 30px auto;
      width: 332px;

      &__content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__rows {
          display: flex;
          justify-content: space-between;

          div {
            &:first-child {
              width: 130px;
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              align-items: center;
              color: #486072;
            }

            &:last-child {
              width: 130px;
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              align-items: center;
              text-align: right;
              color: #486072;
              word-wrap: break-word;
            }
          }
        }
      }
    }

    &__buttons {
      button {
        margin-left: auto;
        margin-right: auto;
      }

      width: 100%;
    }
  }
}

@media screen and (max-width: 1180px) {
  .customer-operations-basvuru-islemleri-odeme-step2 {
    &__body {
      padding-inline: 0px;

      &__card {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-basvuru-islemleri-odeme-step2 {
    &__body {
      &__card {
        &__content {
          &__rows {
            div {
              &:first-child {
                font-size: 15px;
              }

              &:last-child {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}