.list-view-hizmet-noktalari {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  &__text {
    font-family: Hind;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: #444;
  }

  &__listBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__selectArea {
    display: flex;
    align-items: center;

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }

    &__slct {
      display: flex;
      flex-direction: column;
      position: relative;

      @media screen and (max-width: 800px) {
        width: 100% !important;
      }
      .customSelect img {
        top: 68%;
      }
      label {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        color: #486072;
        margin-top: 30px;
        margin-bottom: 10px;
      }
      &:first-child {
        margin-right: 30px;
        @media screen and (max-width: 768px) {
          margin-right: 0;
        }
      }
    }
  }

  &__resultArea {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    &__card {
      min-height: 250px;
      width: 332px;
      margin-top: 30px;
      padding: 20px;
      background-color: #f3f3f3;
      border-radius: 10px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        width: 100% !important;
        margin-right: 0px;
      }

      &__btn {
        // margin-top: -10px;
        margin-top: auto;
      }

      &__headerText {
        font-family: Hind;
        font-size: 17px;
        font-weight: 600;
        color: #444;
      }

      &__text {
        font-family: Hind;
        font-size: 15px;
        line-height: 1.6;
        color: #444;
        margin-top: 10px;
      }

      &__address {
        font-family: Hind;
        font-size: 16px;
        line-height: 1.5;
        color: #444;
        margin-bottom: 0px;
      }

      &__hr {
        width: 100%;
        height: 1px;
        background-color: #dadada;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.activeSele {
  .ant-select-selection-item {
    opacity: 1 !important;
  }
}