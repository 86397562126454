@import "../../../assets/styles/layouts/authLayout.scss";

.email-verification-ek-ekran {
  @include authLayout;
  .mbtop {
    @media(max-width: 768px) {
      margin-top: 80px;
    }
  }
  .btttn {
    button {
      width: 305px !important;
      margin: auto;
      margin-top: 30px;
    }
    @media (max-width: 768px) {
      margin: auto;
    }
  }
  &__whiteBody {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 136px;
    @media screen and (max-width: 768px) {
      padding-inline: 30px;
      margin-top: 30px;
    }
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        // margin-top: 80px;
      }
      &__left {
        @media screen and (max-width: 768px) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        &__desktopMaskot {
          margin-top: -100px;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
        &__mobilMaskot {
          display: none;
          @media screen and (max-width: 768px) {
            display: block;
            margin-top: -130px;
          }
        }
      }
      &__right {
        display: flex;
        flex-direction: column;
        width: 352px;
        &__header {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 35px;
          letter-spacing: 0.02em;
          color: #486072;
          width: 292px;
          @media screen and (max-width: 768px) {
            width: 100%;
            text-align: center;
            margin-top: -30px;
          }
        }
        &__description {
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-top: 30px;
          width: 292px;
          @media screen and (max-width: 768px) {
            margin: 0 auto;
            margin-top: 30px;
            text-align: center;
          }
        }
        &__recaptcha {
          @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &__recaptchaclass {
            margin-top: 30px;
          }
        }
        &__showMore {
          display: flex;
          flex-direction: column;
          &__description {
            margin-top: 30px;
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
            width: 292px;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
    &__noPageValidity {
      background-color: #fbfbfb;
      padding: 30px;
      width: 740px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      &__body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
          gap: 0px;
        }
        &__left {
          @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          &__desktopmaskot {
            display: block;
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
          &__mobilmaskot {
            display: none;
            @media screen and (max-width: 768px) {
              display: flex;
            }
          }
          &__mobilHeader {
            display: none;
            @media screen and (max-width: 768px) {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 28px;
              line-height: 35px;
              text-align: center;
              letter-spacing: 0.02em;
              color: #486072;
            }
          }
        }
        &__right {
          display: flex;
          flex-direction: column;
          width: 292px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          a {
            color: #444444;
          }
          &__header {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 35px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #486072;
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
          &__description {
            margin-top: 30px;
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #444444;
          }
        }
      }
    }
  }
}
.guvence-bedeli-hesaplama__modal__contactsection {
  @media screen and (max-width: 768px) {
    // display: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 292px;
  height: 100px;
  background-color: #f3f3f3;
  border-radius: 10px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 24px;
    height: 24px;
    margin-left: 0px;
    margin-right: 30px;
    margin-top: 38px;
    margin-bottom: 38px;
  }
  span {
    font-family: Hind;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    span {
      font-size: 22px;
      font-weight: 600;
      a {
        color: #444;
      }
    }
  }
}