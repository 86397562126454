.has-notification-modal {
  .modal2__content {
    max-width: 814px;
    max-height: 755px;
    width: 100%;
    min-height: 500px;

    @media screen and (max-height: 800px) {
      max-height: calc(100% - 50px);
      overflow-y: auto;
      justify-content: start;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__left {
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 35px;
      letter-spacing: 0.02em;
      color: #486072;
    }

    &__right {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    width: 100%;
    margin-top: 30px;

    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e8ecf2;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #486072;
      border-radius: 3px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &__left {
      display: flex;
      flex-direction: column;
      max-height: 488px;
      overflow-y: auto;
      padding-right: 5px;

      &__item {
        display: flex;
        flex-direction: column;
        padding: 12px;
        border-radius: 10px;
        margin-top: 30px;

        &:first-child {
          margin-top: 0;
        }

        &:hover {
          cursor: pointer;
        }

        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          &__title {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            /* identical to box height */

            /* Lacivert */

            color: #486072;
            display: flex;
            align-items: center;

            span {
              display: flex;
              width: 10px;
              height: 10px;
              background-color: #f3bc45;
              border-radius: 50%;
              content: "";
              color: #f3bc45;
              margin-right: 10px;
              font-size: 1px;
            }
          }

          &__date {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            text-align: right;
            color: #486072;
          }
        }

        &__hr {
          margin: 10px 0;
          background-color: #f3f3f3;
          height: 1px;
          width: 100%;
        }

        &__description {
          line-height: 1em;
          max-height: 3em;
          display: -webkit-box;
          /*! autoprefixer: off */
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          word-wrap: break-word;
          // line-height: 24px;
          /* or 150% */

          /* Lacivert */

          color: #486072;
        }
      }

      .has-notification-middleware-hr {
        background-color: #486072;
        width: 100%;
        height: 1px;
        margin: 20px 0;
      }

      .has-notification-middleware {
        &:last-child {
          .has-notification-middleware-hr {
            display: none;
          }
        }
      }
    }

    &__right {
      height: max-content;
      max-height: 488px;
      background: #e8ecf2;
      border-radius: 10px;
      padding: 20px;
      overflow-y: auto;

      &__item {
        display: flex;
        flex-direction: column;

        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          &__title {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: #486072;
          }

          &__date {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            text-align: right;
            color: #486072;
          }
        }

        &__body {
          margin-top: 20px;
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #486072;
          max-width: 320px;
          word-wrap: break-word;
        }

        &__buttons {
          button {
            margin-top: 20px;
          }
        }
      }
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    width: 100%;

    &__left {
      width: 100%;
    }

    &__right {
      width: 100%;
    }
  }

  .no-notification-modal {
    .modal2__content {
      max-width: 422px;
      width: 100%;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &__left {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 35px;
        /* identical to box height, or 125% */

        letter-spacing: 0.02em;

        /* Lacivert */

        color: #486072;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      background-color: #fbfbfb;
      border-radius: 10px;
      width: 100%;

      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 35px;
        /* or 140% */
        padding-top: 25px;
        text-align: center;
        letter-spacing: 0.02em;

        /* Lacivert */

        color: #486072;
      }

      &__maskot {
        width: 50%;
      }
    }
  }
}