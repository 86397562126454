.customer-operations-mybills {
  .nothing {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 30px;
    border-radius: 10px;
    background-color: #f3f3f3;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 20px;
      margin-top: 30px;
    }

    span {
      width: 100%;
      margin: 0 0 10px;
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
    }

    p {
      width: 100%;
      margin: 10px 0;
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: var(--lacivert);
    }

    img {
      @media screen and (max-width: 768px) {
        width: calc(50%);
      }
    }
  }

  .mobileNotBillbar {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .infoComponent {
    &__info {
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;

      &__detail {
        display: flex;
        align-items: flex-start;

        &__icon {
          width: 24px;
          padding: 12px;
          height: 24px;
          margin-right: 14px;

          &__img {
            width: 20px;
            height: 20px;
          }
        }
      }

      button {
        margin: 0;
      }

      .randevu-detay__info__infoButton2 {
        padding: 9px 10px;
      }
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  .swiper {
    margin-left: unset;
    margin-right: unset;
  }

  .swiper-wrapper {
    display: flex;
    align-items: flex-end;
    height: 250px;
  }

  &__modal {
    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
      margin-top: 30px;
    }

    &__buttons {
      width: 100%;
    }
  }

  .swiper-button-disabled {
    display: none !important;
  }

  .active2 {
    background: linear-gradient(131.92deg, #fcee50 -5.41%, #eea540 107.64%);
    border-radius: 3px;
    color: #fff !important;
    height: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    padding-inline: 30px;

    &__card2 {
      width: 694px;
      background-color: #f3f3f3;
      padding: 10px 20px 20px;
      border-radius: 10px;
      height: 100%;
      margin: 30px 0;

      .customer-operations-mybills__body__card2__content__slider .swiper-slide {
        padding: 0;
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;

        &:hover {
          .nextIconBills {
            filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(26%) hue-rotate(174deg) brightness(105%) contrast(100%);
          }
        }

        &_annual_consumption {
          display: flex;
          background-color: white;
          align-items: center;
          padding: 10px;
          border-radius: 10px;
          font-size: 15px;
          font-weight: 600;
          line-height: 1.6;
          color: #486072;

          .higherTooltip {
            color: #486072;
          }

          span {
            padding-top: 2px;
          }
        }

        .customer-operations-mybills__body__card2__content__slider .swiper-slide {
          background-color: unset !important;
        }

        .customer-operations-dilekce-randevu-map-view__slider__prevBtn {
          top: 60%;

          @media screen and (max-width: 768px) {
            top: 55%;
            transform: translateY(-100%);
          }

          img {
            border-radius: 50%;
            background: #fff;
            width: 100%;
            height: 100%;
          }
        }

        .customer-operations-dilekce-randevu-map-view__slider__nextBtn {
          top: 60%;

          @media screen and (max-width: 768px) {
            top: 55%;
            transform: translateY(-100%);
          }

          img {
            border-radius: 50%;
            background: #fff;
            width: 100%;
            height: 100%;
          }
        }

        .customer-operations-dilekce-randevu-map-view__slider__prevBtn__rectangle__left {
          top: -105px;
          height: 240px;
          background: rgba(243, 243, 243);
          background: linear-gradient(90deg,
              rgba(243, 243, 243, 1) 0%,
              rgba(243, 243, 243, 0.3) 100%);
        }

        .customer-operations-dilekce-randevu-map-view__slider__nextBtn__rectangle__right {
          top: -105px;
          height: 240px;
          background: rgb(0, 0, 0);
          background: linear-gradient(90deg,
              rgba(243, 243, 243, 0.1) 0%,
              rgba(243, 243, 243, 1) 75%);
        }

        .customer-operations-mybills__body__card2__content__slider {
          width: 100%;
          margin-left: -10px;

          .active-slide {
            .customer-operations-mybills__body__card2__content__billbar__bars__top__dates {
              font-weight: bold !important;
            }

            .customer-operations-mybills__body__card2__content__billbar__bars__top__fee {
              font-weight: bold !important;
            }

            .customer-operations-mybills__body__card2__content__billbar__bars__top__bar {
              color: #fcee50 !important;
              background-image: radial-gradient(at top left,
                  #fcee50 -10%,
                  #eea540 112%);
            }
          }
        }

        .customer-operations-dilekce-randevu-map-view__slider__nextBtn {
          display: flex;
          right: 4px;
        }

        .customer-operations-dilekce-randevu-map-view__slider__prevBtn {
          display: flex;
        }

        &__billbar {
          border-radius: 5px;

          &__bars {
            height: 230px;
            width: 30px;
            display: flex;

            &__top {
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              height: 100%;
              cursor: pointer;

              &__dates {
                display: flex;
                flex-direction: column;
                font-family: Hind;
                font-size: 13px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.08;
                letter-spacing: normal;
                text-align: center;
                color: var(--lacivert);
                margin-top: 20px;

                .swiper-slide .active {
                  font-weight: 700;
                }
              }

              &__fee {
                font-family: Hind;
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: normal;
                text-align: center;
                color: var(--lacivert);
                margin-bottom: 20px;
                width: 60px;
              }

              &__bar {
                border-radius: 5px;
                width: 30px;
                background-color: var(--white);
                color: #fff;
                background-image: #fff;
                align-self: center;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: #fff !important;
        }
      }
    }

    &__items {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
    }

    &__table {
      .active {
        background: linear-gradient(131.92deg,
            #fcee50 -5.41%,
            #eea540 107.64%) !important;
        border-radius: 10px !important;
        color: #fff !important;
        padding: 12px 0px;

        .customer-operations-mybills__body__table__content__state {
          color: #fff !important;
        }

        .active2 {
          background: unset;
          border-radius: unset;
          color: #fff !important;
          height: unset;
          display: unset;
          justify-content: unset;
          align-items: unset;
        }

        .customer-operations-mybills__body__table__content__amount {
          display: flex;
          align-items: center;
          justify-content: center;

          div {
            width: 30px;
            margin-top: -3px;
            margin-left: 30px;
          }

          .nextIconBills {
            filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(26%) hue-rotate(174deg) brightness(105%) contrast(100%);
          }
        }
      }

      &__header {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 30px;

        .date__container {
          max-width: 150px;
          padding-left: 30px;

          @media screen and (max-width: 768px) {
            max-width: 80px;
            padding-left: 15px;
          }
        }

        div {
          font-family: Hind;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
        }

        div:last-child {
          width: 110px;

          @media screen and (max-width: 768px) {
            width: 60px;
            margin-right: 30px;
          }
        }
      }

      &__content {
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: var(--pure-black);
        padding: 12px 0;

        &:hover {
          cursor: pointer;
          background: linear-gradient(131.92deg,
              #fcee50 -5.41%,
              #eea540 107.64%);
          border-radius: 10px;
          color: #fff;

          .customer-operations-mybills__body__table__content__state {
            color: #fff;
          }

          .active2 {
            background: unset;
            border-radius: unset;
            color: #fff !important;
            height: unset;
            display: unset;
            justify-content: unset;
            align-items: unset;
          }

          .customer-operations-mybills__body__table__content__amount {
            display: flex;
            align-items: center;
            justify-content: center;

            div {
              width: 30px;
              margin-top: -3px;
              margin-left: 30px;
            }

            img {
              filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(26%) hue-rotate(174deg) brightness(105%) contrast(100%);
            }
          }
        }

        .date__container {
          @media screen and (max-width: 768px) {
            width: 70px;
            padding-left: 20px;
          }
        }

        &__state {
          font-family: "Hind";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #6cb425;
        }

        &__amount {
          display: flex;
          align-items: center;
          justify-content: center;

          div {
            width: 30px;
            margin-top: -3px;
            margin-left: 30px;
          }
        }
      }

      hr {
        border: 0.5px solid #f3f3f3 !important;
        margin: 10px 0;
      }

      div {
        &:last-child {
          hr {
            border: none !important;
          }
        }
      }
    }

    &__footer {
      .akbank {
        button {
          cursor: unset;
        }
      }
    }
  }

  .customSelect {
    .ant-select-selection-item {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 1180px) {
  .customer-operations-mybills {
    .infoComponent {
      &__info {
        flex-direction: column;

        .randevu-detay__info__infoButton {
          button {
            @media screen and (max-width: 1180px) {
              width: 100% !important;
            }
          }

          @media screen and (max-width: 1180px) {
            width: 100% !important;
            padding: 0 10px 10px 10px;
          }
        }

        .randevu-detay__info__infoButton2 {
          padding: 9px 10px;

          button {
            @media screen and (max-width: 1180px) {
              width: 100% !important;
            }
          }

          @media screen and (max-width: 1180px) {
            width: 100% !important;
            padding: 0 10px 10px 10px;
          }
        }
      }
    }

    &__body {
      &__card2 {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-mybills {
    .infoComponent {
      &__info {
        button {
          display: block;
          margin-top: 0px;
          width: 100% !important;
        }
      }
    }

    &__prev {
      margin-bottom: 20px;
    }

    &__body {
      padding-inline: 0px;

      &__table {
        &__header div {
          font-size: 16px;
        }

        &__content {
          &:hover {
            &__amount {
              div {
                margin-left: 5px !important;
                margin-top: -2px;
              }
            }
          }

          &__amount {
            div {
              margin-left: 5px !important;
              margin-top: -2px;
            }
          }

          &__state {
            margin-right: 5px;
          }
        }
      }

      &__items {
        flex-direction: column;

        &__select {
          &:last-child {
            @media screen and (max-width: 768px) {
              margin-top: 20px;
            }
          }
        }
      }
    }

    .active {
      .customer-operations-mybills__body__table__content__amount {
        div {
          margin-left: 5px !important;
          margin-top: -2px;
        }

        .nextIconBills {
          filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(26%) hue-rotate(174deg) brightness(105%) contrast(100%);
        }
      }
    }
  }
}

@media screen and (max-width: 478px) {
  .customer-operations-mybills {
    .active2 {
      height: 100%;
      width: 100%;
      margin-left: -5px;
      padding: 3px 0;
    }
  }
}

.e-arsiv-box {
  display: flex;
  width: 362px;
  height: 240px;
  border-radius: 10px;
  position: relative;
  margin-top: 30px;

  @media (max-width: 1200px) {
    margin: 0 auto;
    margin-top: 30px;
  }

  .bg-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 20px;
    left: 20px;

    button {
      max-width: 180px;
    }
  }
}

@media (min-width: 1200px) {
  .mobile-e-arsiv-box {
    display: none !important;
  }
}

@media(max-width: 450px) {
  .mobile-e-arsiv-box {
    width: 100%;
    height: auto;
  }

  .content {
    button {
      width: 50% !important;
    }
  }
}