@mixin authLayout {
  display: flex;
  flex-direction: column;
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__tabsBox {
      width: 362px;
      max-width: 362px;
      background-color: #e8ecf2;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 0 20px;
      padding-top: 25px;
      &__tabs {
        height: 50px;
        width: 100%;
        display: flex;
        background: rgba(255, 255, 255, 0.5);
        margin-bottom: 25px;
        .active {
          border-radius: 3px;
          box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 #fff;
          background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%);
          font-size: 15px;
          font-weight: 600;
          color: white;
        }

        &--tab {
          padding-top: 3px;
          cursor: pointer;
          color: #f3bc45;
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          font-weight: 600;
          border-radius: 3px;
        }
      }
      @media (max-width: 1180px) {
        display: none;
      }
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;

    &--left {
      margin-top: 30px;
      width: 754px;
      display: flex;
      flex-direction: column;
      @media  (max-width: 800px) {
        width: 100% !important;
      }
      &--body {
        &--text {
          margin: 30px;
          @media (max-width: 600px) {
            margin: 30px 0;
          }
          span {
            display: block;
            font-family: Hind;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: normal;
            color: #000000;
            &:last-child {
              margin-top: 25px;
            }
          }
        }
        .ohm-btn {
          margin-left: 30px;
        }
      }
    }
    &--right {
      width: 362px;
      max-width: 362px;
      @media (max-width: 1180px) {
        display: none;
      }
    }
    @media  (max-width: 1180px) {
      margin: 0 auto;
    }
    @media  (max-width: 600px) {
      margin: 0;
    }
  }
}
