@import "../../../assets/styles/layouts/authLayout.scss";

.ek-ekranlar-faturalar {
    .earchiveInvoiceBtns {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .ohm-btn {
            margin-top: 10px;
            width: 45% !important;

            @media (max-width: 768px) {
                margin-top: 30px !important;
                width: 100% !important;
            }
        }
    }

    .email-verification-ek-ekran__whiteBody {
        padding-inline: 0 !important;
    }

    @include authLayout;

    .btttn {
        button {
            width: 305px !important;
            margin: auto;
            margin-top: 30px;
        }

        @media (max-width: 768px) {
            margin: auto;
        }
    }

    .cntR {
        @media (max-width: 330px) {
            margin-bottom: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100% !important;
        }
    }

    &__content {
        justify-content: center;

        &--left {
            display: flex;
            flex-direction: row;

            img {
                width: 370px;
                height: 370px;
            }

            span {
                cursor: pointer;
            }

            &__rhetorical {
                display: flex;
                flex-direction: column;
                padding: 30px;
                max-height: 700px;
                width: 360px;

                &__header {
                    font-family: "Hind";
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 35px;
                    letter-spacing: 0.02em;
                    color: #486072;
                    margin-bottom: 30px;
                }

                &__text {
                    font-family: "Hind";
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #444444;
                    margin-bottom: 30px;
                }

                &__button {
                    margin-bottom: 30px;

                    img {
                        width: 35px;
                        height: 35px;
                    }

                    .ohm-btn {
                        margin-top: 0px;
                    }
                }

                &__text2 {
                    font-family: "Hind";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #444444;
                    margin-bottom: 30px;

                    span {
                        text-decoration: underline;
                    }

                    a {
                        color: #444444;
                        text-decoration: underline;
                    }
                }

                &__text3 {
                    font-family: "Hind";
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 15px;
                    color: #444444;
                    margin-bottom: 30px;
                }

                &__tabs {
                    font-family: "Hind";
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #444444;

                    span {
                        color: #f7a600;
                        font-weight: 600;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .ek-ekranlar-faturalar {
        align-items: center;

        &__content {
            &--left {
                margin-top: 0px !important;
                flex-direction: column;
                align-items: center;

                img {
                    width: 350px;
                    height: 350px;
                }

                &>div {
                    text-align: center !important;
                }

                &__rhetorical {
                    align-items: center;

                    &__button {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .ek-ekranlar-faturalar {
        &__content {
            &--left {
                img {
                    width: 250px;
                    height: 250px;
                }
            }
        }
    }
}