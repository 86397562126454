.photoModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(68, 68, 68, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    position: absolute;
    display: flex;
    justify-content: space-around;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    margin: 0 auto;

    border-radius: 10px;

    @media (max-width: 600px) {
      width: 100% !important;
    }

    &__image {
      position: relative;
      background: #fff;

      border-radius: 10px;

      @media (max-width: 600px) {
        width: 100% !important;
        height: auto;
      }

      img {
        width: 100%;
        object-fit: contain;
        max-height: 484px;
        border-radius: 10px;

        @media (max-width: 1024px) {
          width: 100% !important;
          height: auto !important;
        }
      }
    }

    &__close {
      position: absolute;
      background: #fff;
      width: 50px;
      height: 50px;
      right: 16px;
      border-radius: 10px;
      top: 16px;

      @media (max-width: 600px) {
        display: none;
      }

      &__Icon {
        position: absolute;
        top: 0px;
        right: -12px;
        width: 50px;
        height: 50px;
        border-radius: 10px;
        cursor: pointer;

        &::after {
          display: inline-block;
          content: "\00d7";
          height: 30px;
          color: #f3bc45;
          font-size: 50px;

          @media (max-width: 600px) {
            font-size: 80px;
          }
        }

        @media (max-width: 600px) {
          width: 50px;
          height: 50px;
          top: 0px;
          left: 20px;
          transform: translate(0, -50%);
        }
      }
    }
  }

  &__close-mobile {
    position: absolute;

    width: 50px;
    height: 50px;
    right: 16px;
    border-radius: 10px;
    top: 20px;
    left: 20px;
    display: none;

    @media (max-width: 600px) {
      display: block;
    }

    &__Icon {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      cursor: pointer;

      &::after {
        display: inline-block;
        content: "\00d7";
        height: 30px;
        color: #f3bc45;
        font-size: 50px;

        @media (max-width: 600px) {
          font-size: 80px;
        }
      }

      @media (max-width: 600px) {
        width: 50px;
        height: 50px;
        top: 0px;
        left: 20px;
        transform: translate(0, -50%);
      }
    }
  }
}