.corporate-register {
  .ohm-btn {
    margin-left: 0px !important;
  }

  .mtC {
    margin-top: 20px;

    @media(max-width: 768px) {
      margin-top: 10px;
    }
  }

  .mbC {
    @media(max-width: 1024px) {
      margin-bottom: 30px;
    }
  }

  .mbC2 {
    @media(max-width: 1024px) {
      margin-bottom: 15px;
    }
  }

  .mgB {
    .customInput {
      margin-bottom: 0px !important;
    }
  }

  .step2 {
    padding-inline: 30px;
    padding-top: 30px;
    padding-bottom: 48px;

    .higherTooltip__left__span__top__tooltip {
      transform: translateX(30%);

      @media(max-width:600px) {
        transform: translateX(0%);
      }
    }

    .date-input {
      width: 320px;

      @media (max-width:600px) {
        width: 100%;
      }

      .p-date-time-picker__button {
        background-color: #fff;
        width: 320px;

        @media (max-width:600px) {
          width: 100%;
        }
      }

    }


    .customTextArea {
      width: 100%;
    }

    p {
      font-family: "Hind", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: #000;
      margin-bottom: 30px;
    }

    &_card {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;

      &_title {
        color: var(--turuncu);
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        margin-bottom: 20px;
      }
    }

    &__prev {
      margin-bottom: 30px;
      width: fit-content;

      &:hover {
        cursor: pointer;
      }

      &__icon {
        margin-bottom: 4px;
      }

      &__text {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        margin-left: 10px;
      }
    }

    &__input-group {
      .customInput {
        width: 320px;

        input {
          width: 320px;
          padding: 12px 15px 10px;

          @media (max-width:600px) {
            width: 100%;
          }
        }

        @media (max-width:600px) {
          width: 100%;
        }
      }

      display: flex;
      justify-content: space-between;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      .customInput {
        margin-bottom: 30px;
      }

      &__textarea {
        width: 332px;

        @media (max-width:600px) {
          width: 100%;
        }

        label {
          font-size: 15px;
          font-weight: 600;
          color: #486072;
          display: block;
          margin-bottom: 10px;
        }

        textarea {
          resize: none;
          font-family: "Hind", sans-serif;
          width: 100%;
          height: 128px;
          border-radius: 3px;
          border: solid 1px #e8ecf2;
          outline: 0;
          padding: 10px 15px;
          font-size: 18px;
          color: #486072;

          &::placeholder {
            color: #486072;
            opacity: 0.5;
          }
        }
      }
    }

    @media (max-width: 850px) {
      padding-inline: 0;

      .userInformation__button .ohm-primaryBtn {
        margin-top: 0;
      }
    }

    .userInformation__button {
      display: flex;
      margin-left: 0;
      padding-left: 0;

      .ohm-btn {
        margin-left: 0;
      }

      @media (max-width:1024px) {
        flex-direction: column;

        &__text {
          margin-left: 0;
        }
      }

      &__text {
        display: flex;
        align-items: center;
        margin-left: 30px;
        margin-top: 25px;
        font-weight: 400;
        font-family: Hind;
        font-size: 16px;
        line-height: 1.5;

        @media (max-width: 600px) {
          display: none;
        }
      }
    }
  }
}