.customer-operations-after-login-elektrik-kesintileri-kesinti-bildir-step1 {
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-top: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__spinner {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }

    &__select {
      margin-top: 30px;

      .ant-select-selection-item {
        opacity: 1;
      }
    }

    &__select1 {
      margin-top: 30px;

      .customSelect img {
        z-index: 1;
      }

      .ant-select-selection-item {
        opacity: 1;

      }

      .ant-select-selector {
        background: white !important;
      }
    }

    &__card {
      margin-top: 30px;
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 10px;

      &__textarea {
        margin-top: 20px;
        width: 100%;

        .customTextArea {
          width: 100%;
        }
      }

      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #f3bc45;
      }

      &__hr {
        width: 100%;
        height: 1px;
        background-color: #dadada;
        margin: 20px 0;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &__hr2 {
        width: 100%;
        height: 1px;
        background-color: #dadada;
        margin: 20px 0;
      }

      &__item2 {
        @media screen and (max-width: 768px) {
          margin-top: 20px;
        }
      }

      &__item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        margin-top: 20px;

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
          gap: 20px;
        }

        &__left {
          display: flex;
          flex-direction: column;

          &__upper {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #486072;
          }

          &__down {
            font-family: "Hind";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #444444;
            margin-top: 10px;

            i {
              margin-right: 10px;
              color: #adb3bc;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }

        &__right {
          display: flex;
          flex-direction: column;

          &__upper {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #486072;
          }

          &__down {
            font-family: "Hind";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #444444;
            margin-top: 10px;

            i {
              margin-right: 10px;
              color: #adb3bc;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}