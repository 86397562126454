.dilekce-basvuru-step2 {
  padding-inline: 30px;
  margin-top: 30px;

  .d-picker {
    .p-date-time-picker {
      max-width: 100% !important;
      // width: 332px !important;
    }
  }

  .customSelect {
    img {
      right: 10px !important;
    }
  }

  .ant-select-selection-item {
    opacity: 1 !important;
  }

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  .ant-select-selector {
    height: 44px !important;
  }

  .ant-picker-date-panel {
    width: 332px !important;
  }

  .ant-picker {
    height: 44px !important;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    &__select {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 332px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      label {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--lacivert);
        margin-bottom: 10px;
      }
    }

    &__selectArea {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 30px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      &__select {
        display: flex;
        flex-direction: column;
        width: 332px !important;
        position: relative;

        @media screen and (max-width: 768px) {
          margin-bottom: 30px !important;
          width: 100% !important;
        }

        label {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          margin-bottom: 10px;
        }
      }
    }

    &__info {
      .infoComponent {
        @media screen and (max-width: 768px) {
          margin-top: 0;
        }
      }

      &__detail {
        display: flex;

        @media screen and (max-width: "768px") {
          flex-direction: column;

          div {
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }

        &__flex {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        margin-top: 0 !important;
      }
    }
  }
}
