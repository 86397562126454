.randevu-sorgula-step2 {
  padding-inline: 30px;

  @media (max-width: 768px) {
    padding-inline: 0px;
  }

  .infoComponent {
    width: 100%;
    margin-bottom: 0 !important;
    &__info {
      padding: 5px;
      display: flex;
      background-color: #fff;
      border-radius: 3px;
      @media (max-width: 768px) {
        flex-direction: column;
      }

      &__detail {
        display: flex;
        align-items: flex-start;
        img {
          width: 20px !important;
        }
        &__iconArea {
          &__icon {
            margin-left: 6px;
            margin-top: 10px;
            font-size: 20px !important;
            width: 20px !important;
          }
        }
      }

      button {
        margin-top: 10px;

        @media (max-width: 768px) {
          display: block;
          width: 100% !important;
        }
      }

      .randevu-sorgula-step2__info__infoButton {
        padding: 0 10px 0px 10px;

        @media (max-width: 1024px) and (min-width: 768px) {
          button {
            width: 325px !important;
          }
        }

        @media (max-width: 768px) {
          width: 100% !important;
          padding: 0 10px 10px 10px;
        }
      }
    }
  }
  &__card {
    padding: 20px;
    background-color: #f3f3f3;
    border-radius: 10px;
    margin: 30px 0;

    &__header {
      font-family: Hind;
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: var(--turuncu);
    }

    &__appointment {
      background-color: #fff;
      padding: 12px;
      border-radius: 10px;
      margin-top: 20px;

      &__hr {
        border-top: 1px solid #f3f3f3;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &__header {
        display: flex;
        justify-content: space-between;

        span {
          &:first-child {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--lacivert);
          }

          &:last-child {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: var(--turuncu);
          }
        }
      }

      &__details {
        display: flex;
        flex-direction: column;
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--lacivert);

        &__detail {
          display: flex;
          // align-items: center;
          justify-content: space-between;
          margin-top: 10px;

          @media (max-width: 768px) {
            flex-direction: column;
          }

          span {
            width: 260px;
          }

          button {
            margin-top: 0;

            @media (max-width: 768px) {
              margin-top: 20px;
            }
          }
        }
      }
      &__buttons {
        display: flex;
        justify-content: space-between;
        // margin-top: 20px;

        button {
          margin-top: 0;
          @media (max-width: 1024px) and (min-width: 768px) {
            width: 203px !important;
          }
        }

        @media (max-width: 768px) {
          flex-direction: column;
          margin-top: 10px;

          button {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  &__modal {
    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
      margin-bottom: 10px;
    }
    width: 100%;
    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: var(--black);
    }
    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }
      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        span {
          font-size: 22px;
          font-weight: 600;
          a {
            color: #444;
          }
        }
      }
    }
  }
}
