.home-header {
  display: flex;
  max-width: 751px;
  width: 100%;
  height: 100px;

  @media screen and (max-width: 1180px) {
    margin: 0 auto;
    max-width: none;
    height: 78px;
  }

  .home-header-mobilenav__update {
    @media screen and (max-width: 330px) {
      display: flex;
    }
  }

  &__image {
    max-width: 242px;
    object-fit: contain;
    cursor: pointer;

    @media (max-width: 300px) {
      height: 30px;
      align-self: center;
    }
  }

  &__hmbgr {
    display: none;
    max-width: 30px;

    @media (max-width: 1180px) {
      display: flex;
      margin-left: auto;

      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: 300px) {
      right: 10px;
      height: 25px;
      align-self: center;
    }
  }

  &__nav {
    width: 100%;
    padding-inline: 34px 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 0;

    .active {
      display: inline-block;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 5px;
        background-color: #ffc600;
        top: 0;
        left: 0;
      }
    }

    li {
      a {
        font-size: 13px;
        font-weight: 600;
        line-height: 1.08;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
      }
    }

    @media (max-width: 1180px) {
      display: none;
    }

    &__icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-top: -5px;

      @media (max-width: 1180px) {
        display: none;
      }

      img {
        cursor: pointer;
      }
    }
  }

  .mobil-menu {
    position: absolute;
    overflow-y: auto;
    height: 200px;
    width: 100%;
    min-height: calc(100vh - 78px); //?some changes
    background: #fff;
    z-index: 10000;
    top: 100px;
    left: 0;
    margin-top: -23px; //?some changes
  }

  &__mobilenav {
    display: none;

    @media (max-width: 1180px) {
      display: block;
      background: #fff;
      width: 100%;
      height: auto;
      z-index: 50;

      &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          margin: 0 25px;

          &:nth-child(1) {
            margin-right: 15px;
          }
        }
      }

      &__dropdown {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 30px;

        &:hover {
          cursor: pointer;
        }

        &__text {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-family: 'Hind', sans-serif;
            font-size: 25px;
            font-weight: 600;
            line-height: 1.2;
            text-align: left;
            color: #486072;
            margin-left: 25px;
          }

          img {
            margin-right: 25px;
          }
        }

        &__list {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          &__withIcon {
            display: flex;
            justify-content: space-between;
            list-style: none;
            padding: 20px;
            background: #e8ecf2;
            width: 90%;
            border-radius: 5px;
            margin-top: 15px;

            a {
              font-family: 'Hind', sans-serif;
              font-size: 16.9px;
              font-weight: 600;
              line-height: 1.5;
              text-align: left;
              color: #486072;
              margin-left: 15px;

              &:hover {
                color: #486072;
              }
            }

            &__icon {
              width: 25px;
            }
          }

          &__withOutIcon {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            background: #e8ecf2;
            width: 90%;
            margin-top: 15px;

            a {
              font-family: 'Hind', sans-serif;
              font-size: 16.9px;
              font-weight: 600;
              line-height: 1.5;
              text-align: left;
              color: #486072;

              &:hover {
                color: #486072;
              }
            }

            img {
              width: 25px;
            }
          }
        }
      }
    }
  }

  &_contactmodal__modal {
    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }

      span {
        font-family: 'Hind', sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;

        span {
          font-size: 22px;
          font-weight: 600;

          a {
            color: #444;
          }
        }
      }


    }

    .firstSpan {
      font-family: 'Hind', sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: var(--black);
    }
  }
}