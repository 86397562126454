.fatura-bilgilendirme-step1 {
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  .disabled-btn {
    cursor: default !important;
    opacity: 0.5;
    pointer-events: none;
  }

  &__body {
    padding-inline: 30px;

    @media (max-width: 768px) {
      padding-inline: 0px;
    }

    &__label {
      margin-top: 30px;
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      color: #444;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      button {
        margin-top: 0;
      }
    }

    &__choice {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        flex-direction: column;

        &__left {
          margin-bottom: 20px;
        }
      }

      &__left {
        &__box {
          display: flex;
          align-items: center;
          padding: 10px 15px;
          border-radius: 3px;
          border: 1px solid #486072;
          width: 332px;
          height: 50px;
          @media (max-width: 768px) {
            width: 100%;
          }
          &:hover {
            cursor: pointer;
          }
          .rounded-checkbox {
            .rounded-checkbox__label > div > div {
              margin-top: 0 !important;
            }
          }
          span {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.3px;
            text-align: left;
            color: var(--lacivert);
          }
        }

        &__select {
          display: flex;
          flex-direction: column;
          background-color: #f3f3f3;
          width: 332px;
          margin-top: 20px;
          padding: 20px;
          border-radius: 10px;
          align-items: flex-start;

          @media (max-width: 768px) {
            width: 100%;
          }

          &:hover {
            cursor: pointer;
          }

          &__header {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.3px;
            text-align: left;
            color: var(--lacivert);
          }

          &__content {
            margin-top: 20px;
            font-family: Hind;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.3px;
            text-align: left;
            color: var(--lacivert);
          }
        }
      }

      &__right {
        display: flex;
        flex-direction: column;

        &__box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 15px;
          border-radius: 3px;
          border: 1px solid #486072;
          width: 332px;
          height: 50px;
          @media (max-width: 768px) {
            width: 100%;
          }
          &:hover {
            cursor: pointer;
          }
          .rounded-checkbox {
            .rounded-checkbox__label > div > div {
              margin-top: 0 !important;
            }
          }

          &__green {
            height: 25px;
            width: 110px;
            padding: 5px 10px;
            border-radius: 11px;
            background-color: #00c13b;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__text {
              font-family: Hind;
              font-size: 10px !important;
              font-weight: 500 !important;
              font-stretch: normal !important;
              font-style: normal !important;
              line-height: 1 !important;
              letter-spacing: 0.5px !important;
              text-align: left !important;
              color: #fff !important;
            }

            img {
              margin-right: 5px;
            }
          }

          .customCheckBox {
            margin: 0;
            align-items: center !important;

            .customCheckBox__label__first {
              border-radius: 50%;
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .checked {
              border-radius: 50%;
              height: 16px;
              width: 16px;

              &::before {
                display: none;
              }
            }
          }

          span {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.3px;
            text-align: left;
            color: var(--lacivert);
          }
        }

        &__select {
          margin-top: 20px;
          background-color: #f3f3f3;
          padding: 20px;
          border-radius: 10px;
          height: 63px;
          display: flex;
          align-items: center;

          .customCheckBox {
            align-items: center !important;
          }

          .customCheckBox__label > div {
            background-color: transparent !important;
          }

          .spanFirst {
            margin-top: 3px;
            margin-left: 15px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &__selected {
          border: 1px solid #486072;
          padding: 10px;
          border-radius: 3px;
          margin-top: 10px;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          &__left {
            // display: flex;
            // flex-direction: column;

            span {
              &:first-child {
                font-family: Hind;
                font-size: 15px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #444;
                margin-bottom: 10px;
              }

              // &:last-child {
              //   font-family: Hind;
              //   font-size: 10px;
              //   font-weight: 500;
              //   font-stretch: normal;
              //   font-style: normal;
              //   line-height: 1;
              //   letter-spacing: 0.5px;
              //   text-align: left;
              //   color: #fff;
              //   gap: 10px;
              //   padding: 5px 10px;
              //   border-radius: 11px;
              //   background-color: #30a353;
              //   display: flex;
              //   align-items: center;
              //   justify-content: center;
              // }
            }
          }

          &__right {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 0.3px;
            text-align: center;
            color: var(--turuncu);

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    &__infobox {
      .infoComponent {
        &__info {
          &__icon {
            font-size: 20px;
          }
          &__text {
            width: 90%;
          }

          @media (max-width: 768px) {
            flex-wrap: nowrap !important;
          }
        }
      }
    }
  }

  &__modal {
    &__header {
      font-family: Hind;
      font-size: 26px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
      margin-top: 30px;
    }

    &__buttons {
      width: 100%;
    }
  }
}

.not-disabled-btnn {
  opacity: 1 !important;
  pointer-events: pointer !important;
}