// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.login-register {
    font-family: "Hind", sans-serif !important;
    width: 100%;
    background-color: #e8ecf2;
    padding: 20px 20px 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @media (max-width: 1000px) {
        border-radius: 10px;
    }

    &__tabs {
        height: 50px;
        width: 100%;
        display: flex;
        background: rgba(255, 255, 255, 0.5);
        margin-bottom: 15px;

        .active {
            border-radius: 3px;
            box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 #fff;
            background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%);
            font-size: 15px;
            font-weight: 600;
            color: white;
        }

        &--tab {
            padding-top: 3px;
            cursor: pointer;
            color: #f3bc45;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            font-weight: 600;
            border-radius: 3px;
        }
    }

    .modal2__content {
        .ohm-primaryBtn {
            margin-top: 0px;
        }
    }
}

.login-register-point {
    animation: pointBoxShadow 1s forwards;
}

@keyframes pointBoxShadow {
    0% {
        box-shadow: 0px 0px 0px #ff0000;
    }

    100% {
        box-shadow: 0px 0px 10px #ff0000;
    }
}

@-moz-keyframes pointBoxShadow {
    0% {
        box-shadow: 0px 0px 0px #ff0000;
    }

    100% {
        box-shadow: 0px 0px 10px #ff0000;
    }
}

@-webkit-keyframes pointBoxShadow {
    0% {
        box-shadow: 0px 0px 0px #ff0000;
    }

    100% {
        box-shadow: 0px 0px 10px #ff0000;
    }
}