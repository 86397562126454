.randevu-guncelleme-step2 {
  padding-inline: 30px;
  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }
  &__header {
    font-family: Hind;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: var(--turuncu);
    margin-top: 30px;
  }
  &__detail {
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--pure-black);
    margin-top: 30px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
