.customer-operations-basvuru-islemleri-basvuru-olustur {
  &__prev {
    font-family: "Hind", sans-serif;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    padding-inline: 30px;

    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }

    &__card {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;

      .customSelect {
        .ant-select-selection-item {
          opacity: 1 !important;
        }
      }

      .ant-select-selector {
        background-color: #ffffff !important;
      }

      .customSelect img {
        z-index: 0;
      }

      &__header {
        font-family: "Hind", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #f3bc45;
      }

      &__description {
        font-family: "Hind", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-top: 20px;
      }

      &__button {
        button {
          margin-top: 20px;
        }
      }

      &__selectArea {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-top: 20px;

        .customSelect {
          width: 317px;
        }
      }

      &__inputArea {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-top: 20px;

        &__input {
          .customInput {
            input {
              width: 317px;
            }
          }

          .customInput .errorBox {
            margin-bottom: 0;
          }

          input {
            margin-bottom: 0;
          }
        }
      }

      &__lastApplications {
        &__header {
          margin-top: 20px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          align-items: center;
          font-family: "Hind", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #486072;
          padding: 12px;
        }

        &__button {
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            margin-top: 20px;
          }
        }

        &__data {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          margin-top: 20px;
          background-color: #fff;
          border-radius: 10px;
          padding: 12px;

          div {
            font-family: "Hind", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            align-items: center;
            color: #486072;
          }

          .state {
            display: flex;
            justify-content: end;
            font-weight: 600;
            cursor: pointer;

            img {
              margin-top: -1px;
              margin-left: 20px;
            }
          }
        }
      }

      &__activeApplications {
        background: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 2px 12px;
        margin-top: 20px;

        hr {
          border: 0.5px solid #f3f3f3 !important;
          margin-right: 30px;
        }

        .type {
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          margin-right: 35px;

          .higherTooltip__left__span__top__icon {
            margin-left: 5px;
            margin-top: -4px;

            @media (max-width: 768px) {
              margin-right: 0px;
              margin-left: 5px;
            }
          }

          .higherTooltip__left__span {
            @media (max-width: 768px) {
              margin-right: 0px;
            }
          }

          .higherTooltip__left__span__top__tooltip__content {
            @media (max-width: 768px) {
              right: -52px;
              width: calc(100vw - 90px);
            }
          }

          .higherTooltip__left__span__top__tooltip__content__text {
            text-align: left;
          }

          img {
            margin-top: -2px;
            margin-right: -5px;
            margin-left: 20px;
            cursor: pointer;
          }

          &__left {
            font-family: "Hind", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: #486072;
          }

          &__right {
            font-family: "Hind", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            text-align: right;
          }

          &__right2 {
            font-family: "Hind", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            text-align: right;
            color: #f3bc45;
            min-width: 130px !important;
          }

          .right3 {
            margin-right: -26px;
          }
        }

        .type1 {
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          gap: 2px;

          @media (min-width: 768px) {
            margin-right: 25px;
          }

          img {
            margin-top: -2px;
            margin-right: -5px;
            margin-left: 20px;
            cursor: pointer;
          }

          &__left {
            font-family: "Hind", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: #486072;
            width: 52%;
          }

          &__right {
            font-family: "Hind", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            text-align: right;
          }

          &__right2 {
            font-family: "Hind", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            text-align: right;
            color: #f3bc45;
            min-width: 130px !important;
            padding-right: 10px;
            width: 46%;
          }

          .right3 {
            margin-right: -26px;
          }
        }
      }
    }

    &__info {
      margin-top: 20px;

      .infoComponent {
        margin-top: 0;
        margin-bottom: 0;

        &__info {
          padding: 12px;
          background-color: #fff;
          display: flex;
          align-items: center;
          border-radius: 3px;

          &__detail1 {
            display: flex;
            align-items: baseline;
          }

          &__infoArea {
            display: flex;
            align-items: flex-start;

            &__icon {
              width: 24px;
              margin: 0 !important;
              padding: 2px;
            }

            &__text {
              font-family: "Hind", sans-serif;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.6;
              letter-spacing: normal;
              text-align: left;
              color: var(--lacivert);
              margin-right: -8px;
              padding: 0px;
              margin-left: 15px !important;
              width: 100%;
              margin-top: 2px;
            }
          }

          button {
            margin-top: 0px;
            margin-left: 40px;
          }
        }
      }
    }
  }

  &__modal {
    .modal__content {
      max-height: 682px !important;
    }

    &__header {
      font-family: "Hind", sans-serif;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
      margin-bottom: 30px;
    }

    &__description {
      font-family: "Hind", sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }

    &__buttons {
      width: 100%;
    }

    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }

      span {
        font-family: "Hind", sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;

        span {
          font-size: 22px;
          font-weight: 600;

          a {
            color: #444;
          }
        }
      }
    }
  }
  .infoComponent_basvuru {
    display: flex;
    justify-content: center;
    .infoComponent {
      @media(min-width: 768px) {
        width: 92%;
      }
      &__info {
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 3px;
        @media screen and (max-width: 1180px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &__detail {
          display: flex;
          align-items: center;
          &__icon {
            width: 24px;
            padding: 12px;
            // height: 24px;
            margin-right: 14px;
            &__img {
              width: 20px;
              height: 20px;
            }
          }
        }

        button {
          margin: 0;

          @media screen and (max-width: 768px) {
            display: block;
            margin-top: 0px;
            width: 100% !important;
          }
        }

        .randevu-detay__info__infoButton {
          button {
            @media screen and (max-width: 1180px) {
              width: 100% !important;
            }
          }

          @media screen and (max-width: 1180px) {
            width: 100% !important;
            padding: 0 10px 10px 10px;
          }
        }

        .randevu-detay__info__infoButton2 {
          padding: 9px 10px;

          button {
            @media screen and (max-width: 1180px) {
              width: 100% !important;
            }
          }

          @media screen and (max-width: 1180px) {
            width: 100% !important;
            padding: 0 10px 10px 10px;
          }
        }
      }

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }

  .customer-operations-basvuru-islemleri-basvuru-olustur__modal {
    .customInput  {
      width: unset !important;
    }
    .modal2__content {
      max-height: 800px !important;
    }
    .errorBoxLogin__errorText {
      font-size: 15px !important;
    }
  }
}

@media screen and (max-width: 1180px) {
  .customer-operations-basvuru-islemleri-basvuru-olustur__body__card__button {
    button {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-basvuru-islemleri-basvuru-olustur {
    &__prev {
      margin-bottom: 30px;
      width: fit-content;
    }

    &__body {
      &__info {
        .infoComponent {
          &__info {
            display: block;

            button {
              display: block;
              margin-top: 10px;
              margin-left: 0;
            }

            &__infoArea {
              &__text {
                width: 100%;
              }
            }
          }
        }
      }

      &__card {
        &__selectArea {
          flex-direction: column;
        }

        &__lastApplications {
          &__header {
            font-size: 12px;
            padding: 12px 6px;
          }

          &__data {
            padding: 8px;

            div {
              font-size: 10px;
              line-height: 20px;
            }
          }
        }

        &__activeApplications {
          .type {
            margin-right: 15px;

            img {
              margin-left: 7px;
              margin-right: 7px;
            }
          }
        }

        &__inputArea {
          grid-template-columns: 1fr;

          &__input {
            .customInput {
              input {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .customer-operations-basvuru-islemleri-basvuru-olustur__body__card__lastApplications__data {
    .state {
      width: 90px;
    }
  }

  .channel {
    margin-left: 10px;
  }
}

// .customer-operations-basvuru-islemleri-basvuru-olustur__body__card__selectArea__select2 {
//   .customSelect__select {
//     .ant-select-item {
//       height: 60px !important;

//       .ant-select-item-option-content {
//         flex: unset;
//         overflow: scroll !important;
//         white-space: unset !important;
//         text-overflow: unset !important;
//       }
//     }
//   }
// }
