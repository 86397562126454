@import "../../assets/styles/layouts/withAuthLayout.scss";

.after-login-page {
  @include withAuthLayout;

  &__modal {
    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
      margin-top: 30px;
    }

    &__buttons {
      width: 100%;
    }
  }

  &__content {
    &--body {
      width: 100%;
    }

    @media (max-width: 768px) {
      margin-top: 54px;
    }
  }

  .after-login-header {
    &__searchInput {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      margin-right: 30px;

      .customInput {
        input {
          margin-bottom: 6px !important;
        }

        @media (max-width: 1180px) {
          width: 100%;
        }
      }

      @media (max-width: 1180px) {
        display: none;
        width: 100%;
        margin-right: 0;
      }

      @media (max-width: 999px) {
        display: block;
        top: 80px;
        left: 0px;
        position: absolute;
        margin-right: 0;
        margin-top: 10px;
      }

      input {
        background: #f3f3f3;
        border-radius: 10px;
        z-index: 2;

        &::placeholder {
          font-family: Hind;
          font-size: 16px;
          line-height: 1.5;
          font-weight: 600;
          color: #000;
        }

        &:focus {
          border: 1px solid #486072;
        }

        @media (max-width: 768px) {
          padding-right: 70px;
        }

        @media (max-width: 380px) {
          width: 100% !important;
        }
      }

      &__icons {
        position: absolute;
        right: 10px;
        top: 20px;
        z-index: 2;
        display: flex;
        flex-wrap: wrap;

        &__searchIcon {
          margin-right: 10px;
        }

        &__crossIcon {
          display: none;

          @media (max-width: 768px) {
            display: block;
          }
        }
      }

      &__lastSearch {
        position: absolute;
        width: 100%;
        top: 30px;
        height: auto;
        background-color: #fff;
        border: 1px solid #486072;
        border-radius: 10px;
        z-index: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .infoBox {
          margin-top: 20px;
          width: 100% !important;

          @media (max-width: 768px) {
            height: 100% !important;
            padding: 10px;
          }

          span {

            @media (max-width: 768px) {
              width: 250px;
              word-wrap: break-word;
              height: 100%;
              margin: 0px 10px;
            }
          }
        }

        &__finally {
          display: flex;
          flex-direction: column;

          &__items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            background-color: var(--soft-grey);
            height: 60px;
            width: 100%;
            margin-top: 20px;
            /* this */
            padding: 0 15px;
            cursor: pointer;

            &__content {
              font-family: 'Hind', sans-serif;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: var(--pure-black);
            }

            &__caption {
              font-family: 'Hind', sans-serif;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: right;
              color: var(--turuncu);
            }
          }
        }

        &__top {
          display: flex;
          flex-direction: column;
          width: 100%;
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          text-align: left;
          color: #f3bc45;

          &__header {
            display: flex;
            color: #f3bc45;
            justify-content: space-between;
            margin: 20px 0;

            img {
              // margin-right: 30px;
            }
          }

          &__content {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            &__item {
              border: 1px solid #f3bc45;
              background-color: #fff;
              width: auto;
              // padding: 5px;
              height: 40px;
              border-radius: 3px;
              margin-right: 20px;
              // margin-bottom: 20px;
              padding: 10px 15px;
              cursor: pointer;
            }
          }
        }

        &__bottom {
          display: flex;
          flex-direction: column;
          width: 100%;
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          text-align: left;
          color: #f3bc45;
          margin-top: 20px;

          &__header {
            display: flex;
            color: #f3bc45;
            justify-content: space-between;
            margin-bottom: 20px;

            img {
              margin-right: 30px;
            }
          }

          &__content {
            display: flex;
            flex-wrap: wrap;

            &__item {
              border: 1px solid #f3bc45;
              background-color: #fff;
              width: auto;
              // padding: 5px;
              height: 40px;
              border-radius: 3px;
              margin-right: 20px;
              margin-bottom: 20px;
              padding: 10px 15px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  @import "../../assets/styles/layouts/withAuthLayout.scss";

  .after-login-page {
    @include withAuthLayout;

    &__modal {
      &__header {
        font-family: Hind;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: 0.56px;
        text-align: center;
        color: var(--lacivert);
      }

      &__description {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #444;
        margin-top: 30px;
      }

      &__buttons {
        width: 100%;
      }
    }

    &__content {
      &--body {
        width: 100%;
      }

      @media (max-width: 768px) {
        margin-top: 54px;
      }
    }

    .after-login-header {
      &__searchInput {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        justify-content: center;
        margin-right: 30px;

        .customInput {
          input {
            margin-bottom: 6px !important;
          }
        }

        @media (max-width: 1180px) {
          display: none;
          width: 100%;
          margin-right: 0;
        }

        @media (max-width: 768px) {
          display: block;
          top: 80px;
          left: 0px;
          position: absolute;
          margin-right: 0;
        }

        input {
          background: #f3f3f3;
          border-radius: 10px;
          z-index: 2;

          &::placeholder {
            font-family: Hind;
            font-size: 16px;
            line-height: 1.5;
            font-weight: 600;
            color: #000;
          }

          &:focus {
            border: 1px solid #486072;
          }

          @media (max-width: 380px) {
            width: 310px !important;
          }
        }

        &__icons {
          position: absolute;
          right: 10px;
          top: 20px;
          z-index: 2;
          display: flex;
          flex-wrap: wrap;

          &__searchIcon {
            margin-right: 10px;
          }

          &__crossIcon {
            display: none;

            @media (max-width: 768px) {
              display: block;
            }
          }
        }

        &__lastSearch {
          position: absolute;
          width: 100%;
          top: 30px;
          height: auto;
          background-color: #fff;
          border: 1px solid #486072;
          border-radius: 10px;
          z-index: 1;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .infoBox {
            margin-top: 20px;
            width: 100% !important;
          }

          &__finally {
            display: flex;
            flex-direction: column;

            &__items {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-radius: 10px;
              background-color: var(--soft-grey);
              height: 60px;
              width: 100%;
              margin-top: 20px;
              /* this */
              padding: 0 15px;
              cursor: pointer;

              &__content {
                font-family: 'Hind', sans-serif;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: var(--pure-black);
              }

              &__caption {
                font-family: 'Hind', sans-serif;
                font-size: 15px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;
                color: var(--turuncu);
              }
            }
          }

          &__top {
            display: flex;
            flex-direction: column;
            width: 100%;
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            text-align: left;
            color: #f3bc45;

            &__header {
              display: flex;
              color: #f3bc45;
              justify-content: space-between;
              margin: 20px 0;

              img {
                // margin-right: 30px;
              }
            }

            &__content {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;

              &__item {
                border: 1px solid #f3bc45;
                background-color: #fff;
                width: auto;
                // padding: 5px;
                height: 40px;
                border-radius: 3px;
                margin-right: 20px;
                // margin-bottom: 20px;
                padding: 10px 15px;
                cursor: pointer;
              }
            }
          }

          &__bottom {
            display: flex;
            flex-direction: column;
            width: 100%;
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            text-align: left;
            color: #f3bc45;
            margin-top: 20px;

            &__header {
              display: flex;
              color: #f3bc45;
              justify-content: space-between;
              margin-bottom: 20px;

              img {
                margin-right: 30px;
              }
            }

            &__content {
              display: flex;
              flex-wrap: wrap;

              &__item {
                border: 1px solid #f3bc45;
                background-color: #fff;
                width: auto;
                // padding: 5px;
                height: 40px;
                border-radius: 3px;
                margin-right: 20px;
                margin-bottom: 20px;
                padding: 10px 15px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}