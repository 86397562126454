.abonelik-islemleri-home-page-detail {
  margin-bottom: 30px;
  &__item {
    background-color: #f3f3f3;
    padding: 20px;
    margin-top: 30px;
    border-radius: 10px;
    &__body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        &:hover {
          color: #f3bc45;
        }
      }
      &__left {
        display: flex;
        align-items: center;

        img {
          margin-right: 20px;
        }
        span {
          font-family: Hind;
          font-size: 20px;
          line-height: 1.5;
          letter-spacing: 0.4px;
          color: var(--lacivert);
        }
      }
      &__right {
        display: flex;
        align-items: center;
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.6;
        letter-spacing: 0.3px;
        text-align: center;
        color: var(--turuncu);
        span {
          margin-right: 5px;
        }
        i {
          transform: scale(1.3);
          margin-right: 2px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
