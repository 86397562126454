@import "../../../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-basvuru-islemleri-odeme-sonuc {
  @include withAuthLayout;

  &__header {
    justify-content: space-between;
  }

  &__content {
    &--left {
      &__prev {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 30px !important;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &--right {
      margin-top: 60px !important;

      .progressBox {
        margin: 0 !important;
      }
    }
  }

  &__modal {
    .modal2__content {
      max-height: 682px !important;
    }

    &__header {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.56px;
      text-align: center;
      color: var(--lacivert);
      margin-bottom: 30px;
    }

    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #444;
    }

    &__card {
      background: #f3f3f3;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      padding: 12px;
      width: 100%;

      hr {
        border: 0.5px solid #f3f3f3 !important;
      }

      .type {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        &__left {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #486072;
        }

        &__right {
          font-family: "Hind";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          text-align: right;
        }
      }

      &__caption {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: #f3bc45;
      }
    }

    &__buttons {
      width: 100%;
    }
  }
}

.customer-operations-basvuru-islemleri-odeme-sonuc__content--left--prev {
  margin-bottom: 30px;
  margin-top: 0px;
}

@media screen and (max-width: 768px) {
  .customer-operations-basvuru-islemleri-odeme-sonuc__content--left {
    &__prev {}

    &__body {
      padding-inline: 0px;
    }
  }
}