.ek-ekranlar-fatura-odeme-step1 {
  padding-inline: 30px;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__text {
    font-family: "Hind";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
  }

  &__input {
    margin-top: 30px;

    input {
      width: 332px;
      margin-bottom: 0;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &__code {
    &__text {
      margin-top: 30px;
      font-family: "Hind";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #444444;
    }

    .loginCode-page-inputCont__label {
      margin-top: 30px;
    }

    &--smsCodeCont {
      display: flex;
      margin-bottom: 30px;
      align-items: center;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      .customInputBoxes {
        input {
          &:focus {
            border-color: rgb(204, 204, 204) !important;
            box-shadow: none !important;
          }
        }
      }

      &--countDown {
        display: flex;

        @media (max-width: 600px) {
          width: 100%;
          align-items: center;
          justify-content: center;
        }

        span {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          color: #000000;
          margin-left: 10px;
        }

        margin-left: 30px;

        @media (max-width: 600px) {
          margin-top: 30px;
          margin-left: 0px;
        }
      }

      @media (max-width: 600px) {}
    }

    &__footer {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      &__text {
        margin-right: 20px;
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #486072;
      }

      &__textYellow {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        color: #f3bc45;

        @media screen and (max-width: 768px) {
          margin-top: 10px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    &__button {
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }



  &__modal {

    &__contactsection {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
      color: #444;

      img {
        width: 24px;
        height: 24px;
      }

      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;

        span {
          font-size: 22px;
          font-weight: 600;

          a {
            color: #444;
          }
        }
      }
    }

    &__header {
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #486072;
    }

    &__description {
      font-size: 16px;
      font-family: "Hind";
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      text-align: center;
      color: #444;
    }

    &__input {
      margin-top: 30px;

      .customInput {
        width: 292px;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }

      input {
        width: 292px;
        margin-bottom: 0;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    &__button {
      width: 100%;
    }
  }
}