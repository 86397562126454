.customer-operations-abonelik-iptal-detay-step1 {
  margin-top: 30px;
  .infoBoxTop {
    .infoComponent {
      margin: 0 !important;
      margin-top: 20px !important;
      margin-bottom: -10px !important;
    }
  }
  @mixin cardBackground {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;
  }
  @mixin cardHeader {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #f3bc45;
  }
  @mixin cardBoldText {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #444444;
  }
  @mixin cardRegularText {
    font-family: "Hind";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
  }
  @mixin gridTemplate {
    display: grid;
    grid-template-columns: 317px 317px;
    gap: 20px;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e8ecf2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #486072;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__body {
    padding-inline: 30px;
    @media screen and (max-width: 768px) {
      padding-inline: 0px;
    }
    &__card {
      &__footerBtn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .infoComponent {
        margin-bottom: 0px !important;
      }
      @include cardBackground();
      &__header {
        @include cardHeader();
      }
      &__input {
        margin-top: 20px;
        input {
          margin-bottom: 0;
        }
      }
      &__description {
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
        color: #000000;
      }
      &__hr {
        height: 1px;
        width: 100%;
        background-color: #dadada;
        margin: 20px 0;
      }
      &__nameArea {
        display: flex;
        flex-direction: column;
        &__title {
          @include cardBoldText();
        }
        &__name {
          @include cardRegularText();
          margin-top: 10px;
        }
      }
      &__tesisatNo {
        @include gridTemplate();
        &__left {
          display: flex;
          flex-direction: column;
          &__title {
            @include cardBoldText();
          }
          &__copy {
            display: flex;
            align-items: center;
            margin-top: 10px;
            @include cardRegularText();
            i {
              margin-right: 10px;
              color: #adb3bc;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
        &__right {
          display: flex;
          flex-direction: column;
          &__title {
            @include cardBoldText();
          }
          &__copy {
            display: flex;
            align-items: center;
            margin-top: 10px;
            @include cardRegularText();
            i {
              margin-right: 10px;
              color: #adb3bc;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      &__addressArea {
        display: flex;
        flex-direction: column;
        div {
          &:first-child {
            @include cardBoldText();
          }
          &:last-child {
            @include cardRegularText();
            margin-top: 10px;
          }
        }
      }
      &__phoneArea {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        div {
          &:first-child {
            @include cardBoldText();
          }
          &:last-child {
            @include cardRegularText();
            margin-top: 10px;
          }
        }
      }
      &__datePicker {
        margin-top: 20px;
        height: max-content;
        position: relative;

        &__label {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          margin-bottom: 10px;
          color: #486072;
        }
        .p-date-time-picker {
          background-color: #fff;
        }
      }
      &__guncelBedel {
        width: 237px;
        margin-top: 20px;
        .higherTooltip__left__span__top {
          div {
            font-weight: 600 !important;
          }
        }
        .higherTooltip__left__span__top {
          justify-content: flex-start;
        }
        &__price {
          margin-top: 10px;
          margin-bottom: 10px;
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #444444;
        }
      }
      &__checkboxArea {
        display: flex;
        flex-direction: column;
        .df {
          flex-direction: column;
          align-items: flex-start;
        }
        .infoComponent {
          margin-top: 0;
        }
        .checkboxCheckedMargin {
          .rounded-checkbox__label > div .checked img {
            margin-top: -1px;
            margin-left: 1px;
          }
        }
        &__checkbox {
          background-color: #ffffff80;
          padding: 12px;
          border-radius: 3px;
          gap: 10px;
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
          }
          .rounded-checkbox {
            &__label {
              .checked {
                // align-items: flex-start;
              }
            }
          }
          .infoComponent {
            background-color: #fff;
          }
          &__secretInput {
            position: relative;
            width: 391px;
            background-color: #fff;
            padding: 9px;
            display: flex;
            border: 1px solid #e8ecf2;
            justify-content: space-between;
            @media screen and (max-width: 768px) {
              width: 100%;
              align-items: center;
            }
            &__text {
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #486072;
            }
            &__select {
              background-color: #f3bc45;
              padding: 5px;
              border-radius: 3px;
              width: max-content;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                cursor: pointer;
              }
              span {
                font-family: "Hind";
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #ffffff;
              }
            }
          }
          .spanFirst {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
          }
        }
      }
    }
  }
  &__modal {
    .modal2__content {
      overflow-y: auto;
      overflow-x: hidden;
      width: 392px;
      align-items: flex-start;
      justify-content: start;
      max-height: calc(100vh - 40px);
      @media screen and (max-width: 600px) {
        width: calc(100% - 30px);
        max-height: calc(100vh - 30px);
      }
    }
    &__header {
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      color: #f3bc45;
      &:last-child {
        margin-top: 30px;
      }
    }
    &__header2 {
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      color: #f3bc45;

      margin-top: 30px;
      margin-bottom: 30px;
    }
    &__newIban {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      width: 332px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .ibanTrInput {
        input {
          padding-left: 35px;
          font-size: 17px !important;
          &::placeholder {
            font-size: 14.5px;
          }
        }
      }
      .accoutnNameInput {
        input {
          font-size: 17px !important;
          &::placeholder {
            font-size: 14.5px;
          }
        }
      }
      &__input {
        width: 292px;
        position: relative;
        .ibanTr {
          font-family: Hind;
          position: absolute;
          top: 45px;
          left: 16px;
          font-size: 17px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: left;
          color: #486072;
          z-index: 100;
        }
        .accoutnNameInput {
        }
        .pasteSection {
          cursor: pointer;
          margin-top: -5px;
          margin-bottom: 15px;
          height: 45px;
          padding: 0 9px;
          width: 100%;
          background-color: #e8ecf2;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          border: 1px solid #486072;
          font-size: 13px;
          @media screen and (max-width: 415px) {
            height: 45px !important;
            padding: 15px;
          }
        }
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        input {
          width: 292px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
      &__button {
        width: 100%;
        button {
          margin-top: 0px;
        }
      }
    }
    &__info {
      width: 332px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    &__footerBtn {
      width: 332px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    &__registeredIbans {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 332px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      &__box {
        width: 332px;
        background-color: #fff;
        border: 1px solid #486072;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        &__left {
          display: flex;
          justify-content: center;
          flex-direction: column;
          div {
            &:first-child {
              font-family: "Hind";
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 15px;
              display: flex;
              align-items: center;
              color: #444444;
            }
            &:last-child {
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 15px;
              display: flex;
              align-items: center;
              color: #444444;
            }
          }
        }
        &__right {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.02em;
          color: #f3bc45;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  &__modal2 {
    .modal__content {
      max-height: calc(100vh - 100px);
    }
    &__mainHeader {
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #486072;
    }
    &__description {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
      text-align: center;
      color: #444444;
    }
    &__footerBtn {
      width: 100%;
    }
  }
  &__modal3 {
    b {
      font-weight: 600;
    }
    &__description {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #444444;
    }
    &__footerBtn {
      width: 100%;
    }
  }
}

.CustomeModal {
  .modal {
    .modal2__content {
      max-height: calc(100vh - 100px) !important;
      @media screen and (max-width: 768px) {
        max-height: calc(100vh - 30px) !important;
        overflow-y: auto !important;
        justify-content: stretch !important;
      }
      @media screen and (max-height: 768px) {
        max-height: calc(100vh - 30px) !important;
        overflow-y: auto !important;
        justify-content: stretch !important;
      }
      @media screen and (max-width: 290px) {
        overflow-x: hidden !important;
      }
    }
  }
  .customer-operations-abonelik-iptal-detay-step1__modal__mainHeader {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #486072;
  }
  .customer-operations-abonelik-iptal-detay-step1__modal__description {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;
    text-align: center;
    color: #444444;
  }
  .customer-operations-abonelik-iptal-detay-step1__modal__footerBtn {
    width: 100%;
  }
  .customer-operations-abonelik-iptal-detay-step1__modal__info {
    width: 100% !important;
    .infoComponent {
      margin-bottom: 0 !important;
    }
  }
}
