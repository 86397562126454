$disabled-color: #f9f9f9;
$disabled-opacity: 0.5;
.p-date-time-picker-container {
  position: relative;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.picker-shadow {
  box-shadow: -5px -5px 20px #ffffff, 5px 5px 20px rgba(174, 174, 192, 0.4);
}

.p-date-time-picker {
  position: relative;
  width: 334px;
  height: 44px;
  border: 1px solid #f3f3f3;
  border-radius: 5px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
  .disabled {
    background-color: $disabled-color;
    opacity: $disabled-opacity;
    &:hover {
      cursor: default !important;
    }
  }

  &__button {
    &:hover {
      cursor: pointer;
    }
    height: 44px;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #48607277;
    padding-left: 15px;
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    position: relative;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    b {
      color: #486072;
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
    }
    span {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      /* identical to box height, or 133% */

      display: flex;
      align-items: center;

      /* Lacivert */

      color: #486072;
    }
    img {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      animation: rotateR 0.3s;
    }
  }

  &__picker {
    width: 100%;
    position: absolute;
    height: 373px;
    top: 44px;
    display: none;

    &__tabs {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 11px 12px 12px 17px;

      img {
        &:hover {
          cursor: pointer;
        }
      }

      &__buttons {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 400px) {
          width: 150px;
        }
        @media screen and (max-width: 365px) {
          width: 100px;
        }
        &--button {
          margin-inline: 5px;
          &:hover {
            cursor: pointer;
          }
          width: 80px;
          height: 30px;
          border-radius: 10px;
          color: #486072;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          border: 1px solid #f3f3f3;
          line-height: 26px;
          font-size: 15px;
          @media screen and (max-width: 350px) {
            font-size: 12px;
          }
        }
      }

      .active {
        color: #ffffff;
        background-color: #486072;
      }
    }

    &__year-picker {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 12px 12px 12px;
      animation: open 0.8s;
      .active {
        background-color: #486072;
        color: #ffffff;
        border: 1px solid #e8ecf2;
        &:hover {
          background-color: #486072;
        }
      }
      &--year {
        &:hover {
          cursor: pointer;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30.8%;
        height: 65px;
        color: #486072;
        font-weight: 600;
        font-size: 15px;
        background: #ffffff;
        border: 1px solid #f3f3f3;
        border-radius: 10px;
        margin-bottom: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        transition: all 0.2s;
        &:hover:not(.disabled):not(.active) {
          background-color: #e8ecf2;
        }
      }
    }

    &__month-picker {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 12px 12px 12px;
      animation: open 0.8s;
      .active {
        background-color: #486072;
        color: #ffffff;
        border: 1px solid #e8ecf2;
        &:hover {
          background-color: #486072;
        }
      }
      &--month {
        &:hover {
          cursor: pointer;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30.8%;
        height: 65px;
        color: #486072;
        font-weight: 600;
        font-size: 15px;
        background: #ffffff;
        border: 1px solid #f3f3f3;
        border-radius: 10px;
        margin-bottom: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        transition: all 0.2s;
        &:hover:not(.disabled):not(.active) {
          background-color: #e8ecf2;
        }
      }
    }

    &__day-picker {
      padding: 0 8.5px 12px 8px;
      animation: open 0.8s;
      animation: fadeIn 0.3s;
      &__days {
        padding-right: 1px;
        padding-left: 2px;
        display: flex;
        justify-content: space-between;
        color: #444444;
        font-size: 14px;

        div {
          width: 11%;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &__picker-area {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 4.5px;

        .active {
          background-color: #486072;
          color: #ffffff;
          border: 1px solid #e8ecf2;
          &:hover {
            background-color: #486072;
          }
        }

        &--day {
          border-radius: 10px;
          width: 11%;
          margin-right: 3.83%;
          height: 36.5px;
          color: #486072;
          font-weight: 600;
          border: 1px solid #e8ecf2;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10.5px;
          &:hover {
            cursor: pointer;
          }
          transition: all 0.2s;
          &:hover {
            background-color: #e8ecf2;
          }
          &:nth-child(7) {
            margin-right: 0;
          }
          &:nth-child(14) {
            margin-right: 0;
          }
          &:nth-child(21) {
            margin-right: 0;
          }
          &:nth-child(28) {
            margin-right: 0;
          }
          &:nth-child(35) {
            margin-right: 0;
          }
        }
        &--sday {
          border-radius: 10px;
          width: 11%;
          margin-right: 3.83%;
          height: 36.5px;
          color: #486072;
          border: 1px solid #e8ecf2;
          display: flex;
          align-items: center;
          visibility: hidden;
          justify-content: center;
          margin-top: 10.5px;
        }
      }
    }
  }
  .opacity {
    opacity: 0.3;
    cursor: not-allowed !important;
  }
  &__timebutton {
    &:hover {
      cursor: pointer;
    }
    height: 44px;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    color: #48607277;
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    position: relative;
    b {
      color: #486072;
      font-weight: normal;
    }
    span {
      margin-inline: 6px;
    }
    img {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      animation: rotateR 0.3s;
    }
  }

  &__timepicker {
    width: 100%;
    position: absolute;
    top: 44px;
    display: none;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #486072;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      border-radius: 5px;
    }

    .active {
      background-color: #486072;
      color: #ffffff;
      border: 1px solid #e8ecf2;
      &:hover {
        background-color: #486072;
      }
    }
    &__time-picker {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 40px;
      animation: open 0.8s;
      border: 1px solid #e8ecf277;
      color: #486072;
      transition: all 0.3s;
      padding-bottom: 4px;
      cursor: pointer;
      font-family: "Hind";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.02em;
      &:hover {
        background-color: #e8ecf2;
      }
      &--time {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 93px;
        height: 65px;
        color: #486072;
        font-weight: 600;
        font-size: 15px;
        background: #ffffff;
        border: 1px solid #f3f3f3;
        border-radius: 10px;
        margin-bottom: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        transition: all 0.2s;
        &:hover {
          background-color: #e8ecf2;
        }
      }
    }
  }
}

.p-date-time-picker-opened {
  background-color: #fff;

  .p-date-time-picker__picker {
    display: block;
    position: absolute;
    background-color: #fff;
    z-index: 50;
    box-shadow: -5px -5px 20px #ffffff, 5px 5px 20px rgba(174, 174, 192, 0.4);
  }
  .p-date-time-picker__timepicker {
    padding-block: 5px;
    padding-inline: 5px;
    background-color: #fff;
    z-index: 50;
    box-shadow: -5px -5px 20px #ffffff, 5px 5px 20px rgba(174, 174, 192, 0.4);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    .disabled {
      color: #e8ecf2;
      opacity: 1;
      background-color: #ffffff;
    }
    &__time-picker {
      padding-top: 5px;
      width: 21.71% !important;
      margin-left: 1.64%;
      margin-right: 1.64%;
      margin-block: 5px;
      height: 30px;
      border-radius: 10px;
      border: 1px solid #f3f3f3;
      &--skip-times {
        padding-top: 3px;
        width: 21.71% !important;
        margin-left: 1.64%;
        margin-right: 1.64%;
        height: 30px;
        visibility: hidden;
      }
    }
  }

  .p-date-time-picker__button {
    border-bottom: 1px solid #e8ecf2;
    img {
      position: absolute;
      right: 15px;
      top: 50%;
      animation: rotate 0.3s;
      transform: translateY(-50%) rotate(0deg);
    }
  }
  .p-date-time-picker__timebutton {
    border-bottom: 1px solid #e8ecf2;
    img {
      position: absolute;
      right: 15px;
      top: 50%;
      animation: rotate 0.3s;
      transform: translateY(-50%) rotate(0deg);
    }
  }
}

.dPicker-fadeIn {
  display: none;
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    transform: translateY(-50%) rotate(180deg);
  }
  100% {
    transform: translateY(-50%) rotate(0deg);
  }
}
@keyframes rotateR {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(180deg);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
