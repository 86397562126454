.abonelik-randevu-step8 {
  padding-inline: 0px;
  .ant-select {
    background-color: #fff;
  }
  .customSelect {
    img {
      z-index: 1;
    }
  }

  @media screen and (max-width: 800px) {
    padding-inline: 0px;
  }

  .customSelect {
    width: 318px !important;
    @media (max-width: 376px) {
      width: 286px !important;
    }
  }

  .customInput {
    width: 318px !important;
    max-width: 318px !important;
    @media screen and (max-width: 800px) {
      width: 100%;
    }

    input {
      width: 318px !important;
      max-width: 318px !important;
      @media (max-width: 376px) {
        width: 286px !important;
      }
    }
  }

  .active {
    .ant-select-selector {
      border: 1px solid #f3bc45 !important;

      &:hover {
        border: 1px solid #f3bc45 !important;
      }
    }
  }

  .active {
    .ant-select-selector {
      border: 1px solid #f3bc45 !important;

      &:hover {
        border: 1px solid #f3bc45 !important;
      }
    }
  }

  .ant-select-selection-item {
    margin-top: 8px;
  }

  &__card {
    margin-top: 30px;
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;

    &__header {
      font-family: Hind;
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: var(--turuncu);

      @media screen and (max-width: 800px) {
        margin-bottom: 20px !important  ;
      }
    }

    &__inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      &__input {
        margin-top: 20px;

        @media screen and (max-width: 768px) {
          width: 100% !important;
        }

        input {
          margin-bottom: 0;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        input {
          margin-bottom: 0;
          width: 100% !important;
        }
      }
    }

    &__details {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      margin-top: 20px;

      &__header {
        font-family: Hind;
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #444;
        margin-bottom: 10px;
      }

      &__description {
        font-family: Hind;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #444;
        margin-bottom: 10px;
      }

      &__hr {
        height: 1px;
        background-color: #dadada;
        margin-bottom: 10px;
      }

      &__address {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #444;
      }
    }

    &__selectArea {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      @media screen and (max-width: 768px) {
        gap: 10px;
      }

      .ant-select {
        background-color: #fff;
        border-radius: 3px;
      }

      @media screen and (max-width: 800px) {
        flex-direction: column;
      }

      &__select {
        display: flex;
        flex-direction: column;
        width: 317px;
        position: relative;

        @media screen and (max-width: 800px) {
          width: 100% !important;
          margin-bottom: 10px;
        }
        .p-date-time-picker {
          background-color: #fff !important;
          width: 317px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
        label {
          margin-top: 20px;
          margin-bottom: 10px;
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);

          @media screen and (max-width: 800px) {
            margin-top: 0px;
          }
        }

        .ant-select-selector {
          height: 44px !important;
        }
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
  }
}

.abonelik-randevu-step8 {
  padding-inline: 30px;
  margin-top: 30px;

  &__card {
    margin-top: 30px;
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;

    &__header {
      font-family: Hind;
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: var(--turuncu);

      @media screen and (max-width: 800px) {
        margin-bottom: 20px !important  ;
      }
    }

    &__inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      &__input {
        margin-top: 20px;

        @media screen and (max-width: 768px) {
          width: 100% !important;
        }

        input {
          margin-bottom: 0;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        input {
          margin-bottom: 0;
          width: 100% !important;
        }
      }
    }

    &__details {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      margin-top: 20px;

      &__header {
        font-family: Hind;
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #444;
        margin-bottom: 10px;
      }

      &__description {
        font-family: Hind;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #444;
        margin-bottom: 10px;
      }

      &__hr {
        height: 1px;
        background-color: #dadada;
        margin-bottom: 10px;
      }

      &__address {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #444;
      }
    }

    &__selectArea {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      @media screen and (max-width: 768px) {
        gap: 10px;
      }

      .ant-select {
        background-color: #fff;
        border-radius: 3px;
      }

      @media screen and (max-width: 800px) {
        flex-direction: column;
      }

      &__select {
        display: flex;
        flex-direction: column;
        width: 317px;
        position: relative;

        @media screen and (max-width: 800px) {
          width: 100% !important;
          margin-bottom: 10px;
        }
        .p-date-time-picker {
          background-color: #fff !important;
          width: 317px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
        label {
          margin-top: 20px;
          margin-bottom: 10px;
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);

          @media screen and (max-width: 800px) {
            margin-top: 0px;
          }
        }

        .ant-select-selector {
          height: 44px !important;
        }
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
  }

  .ant-select-selection-item {
    opacity: 1;
  }

  .customInput {
    @media (min-width: 769px) {
      .errorBox {
        margin-top: -10px !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-inline: 0;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100% !important;
    background-color: rgba(243, 243, 243, 0.5);
    margin-top: 30px;

    .ohm-secondaryBtn {
      background-color: rgba(243, 243, 243, 0.5);
    }

    button {
      margin-top: 0;
      border: solid 1px #f3bc45;
    }

    &__hr {
      margin-right: 5px;
      margin-left: 5px;
      border-left: 1px solid #486072;
      width: 1px;
      height: 30px;
      background-color: rgba(243, 243, 243, 0.5);
      box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 var(--white);

      @media screen and (max-width: 768px) {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  &__inputArea {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;

    &__input {
      width: 318px !important;

      input {
        width: 318px !important;
        @media screen and (max-width: 768px) {
          margin-bottom: 0;
        }
      }

      .errorBox {
        // margin-top: 0px !important;
        margin-bottom: 10px !important;
      }

      @media screen and (max-width: 768px) {
        width: 100% !important;

        input {
          width: 100% !important;
        }
      }
    }

    &__select {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 318px !important;
      .ant-select-item {
        height: 90px !important;
      }

      @media screen and (max-width: 768px) {
        width: 100% !important;
        margin-top: 20px;
      }
      label {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--lacivert);
        margin-bottom: 10px;
      }
    }
  }

  .abonelik-randevu-step8__rent {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .abonelik-randevu-step8__inputArea__select {
      @media screen and (max-width: 768px) {
        &:last-child {
          margin-top: 0px;
        }
      }
    }

    .abonelik-randevu-step8__rent__checkboxArea {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        width: 80%;
      }

      .rounded-checkbox {
        &:last-child {
          margin-left: 30px;
        }
      }
    }

    &__caption {
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: var(--lacivert);
      margin-bottom: 10px;
      display: block;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    &__inputArea {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px;

      &__input {
        width: 318px;

        @media screen and (max-width: 768px) {
          width: 100% !important;
        }

        .errorBox {
          // margin-top: 0px !important;
          margin-bottom: 10px !important;
        }
      }
    }
  }

  &__footerbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    button {
      margin-top: 30px;
    }
  }

  &__own {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &__input {
      width: 332px;

      @media screen and (max-width: 768px) {
        width: 100% !important;

        input {
          width: 100% !important;
        }
      }

      input {
        width: 332px;
      }
    }
  }
}

.select-opacity {
  .ant-select-selection-item {
    opacity: 0.5;
  }
}
