.customer-operations-basvuru-islemleri {
  .infoComponent {
    &__info {
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;

      &__detail {
        display: flex;
        align-items: flex-start;

        &__icon {
          width: 24px;
          padding: 12px;
          height: 24px;
          margin-right: 14px;

          &__img {
            width: 20px;
            height: 20px;
          }
        }
      }

      button {
        margin: 0;
      }
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    padding-inline: 30px;

    &__info {
      margin-top: 30px;

      .infoComponent {
        margin-top: 0;

        &__info {
          padding: 12px;
          background-color: #fff;
          display: flex;
          align-items: center;
          border-radius: 3px;

          &__infoArea {
            display: flex;
            align-items: flex-start;

            &__icon {
              width: 24px;
              margin: 0 !important;
              padding: 2px;
            }

            &__text {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.6;
              letter-spacing: normal;
              text-align: left;
              color: var(--lacivert);
              margin-right: -8px;
              padding: 0px;
              margin-left: 15px !important;
              width: 269px;
            }
          }

          button {
            margin-top: 0px;
            margin-left: 40px;
          }
        }
      }
    }

    &__cardArea {
      display: grid;
      grid-template-columns: 332px 332px;
      gap: 30px;

      &__card {
        background-color: #f3f3f3;
        padding: 20px;
        border-radius: 10px;
        margin-top: 30px;

        &__header {
          font-family: Hind;
          font-size: 25px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: var(--turuncu);
        }

        &__description {
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--pure-black);
          margin-top: 20px;
        }

        ul {
          margin-top: 20px;
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--pure-black);
          padding-inline: 30px;
        }

        &__infoBox {
          border-radius: 10px;
          border: solid 2px var(--lacivert);
          background-color: var(--cool-grey);
          display: flex;
          align-items: center;
          padding: 20px;
          color: #444;
          justify-content: space-evenly;

          &:hover {
            color: #444;
          }

          img {
            margin-right: 20px;
          }

          &__text {
            font-family: Hind;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: var(--black);

            &__mobileText {
              display: none;
            }

            &__boldText {
              font-size: 22px;
              font-weight: 600;
              text-align: center;

              a {
                color: #444;
              }
            }
          }
        }

        &__button {
          margin-top: 87px;
        }
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .infoComponent {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-basvuru-islemleri__prev {
    margin-bottom: 30px;
  }

  .customer-operations-basvuru-islemleri__body {
    padding-inline: 0px;

    &__cardArea {
      grid-template-columns: 1fr;

      &__card {
        &__button {
          margin-top: 0;

          button {
            margin-top: 20px;
          }
        }

        &__infoBox {
          &__text {
            text-align: center;

            &__mobileText {
              display: inline;
            }
          }
        }
      }
    }

    &__info {
      display: block;

      .infoComponent__info__infoArea__text {
        width: 100%;
      }

      .infoComponent__info {
        display: block;
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }

  .customer-operations-basvuru-islemleri {
    .infoComponent__info {
      button {
        display: block;
        margin-top: 0px;
        width: 100% !important;
      }
    }
  }
}