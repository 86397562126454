.customSearchInput {
  display: grid;
  grid-template-columns: 1fr 24px;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 0 20px;
  border-radius: 10px;
  background-color: #f3f3f3;
  min-height: 44px;
  transition: all 0.3s ease-in-out;
  position: relative;
  //   &:focus-within {
  //     border-color: #2196f3;
  //     box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
  //       0 0 0 4px rgba(33, 150, 243, 0.2);
  //   }
  @keyframes showUp {
    0% {
      opacity: 0;
      transform: translateY(-10px);
      display: none;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
      display: block;
    }
  }
  .customSearchInput__icon {
    &:hover {
      cursor: pointer;
    }
  }
  .customSearchInput__icon2 {
    &:hover {
      cursor: pointer;
    }
  }
  .customSearchInput__input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: #f3f3f3;
    color: #000;
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    &::placeholder {
      color: #000;
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e8ecf2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #486072;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .customSearchInput__containerList {
    position: absolute;
    background-color: #fff;
    top: 0px;

    height: 44px;
    width: calc(100% - 1px);
    z-index: -1;
    border: 1px solid #486072;
    border-radius: 10px 10px 0px 0px;
    border-bottom: none;
  }

  .customSearchInput__list {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    width: 100%;
    max-height: 310px;
    background-color: #fff;
    border: 1px solid #486072;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
    overflow-y: auto;
    z-index: 9999;
    animation: showUp 0.3s ease-in-out;
    .customSearchInput__list__container {
      margin-inline: 20px;
      @media screen and (max-width: 768px) {
        margin-inline: 10px;
      }
    }
    .customSearchInput__listItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 51px;
      padding: 10px 15px;
      margin: 20px 0;
      border-radius: 10px;
      color: #424242;
      font-size: 14px;
      background-color: #f3f3f3;
      position: relative;
      z-index: 9999;
      cursor: pointer;
      animation: showUp 0.2s ease-in-out;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        height: fit-content;
      }
      &:hover {
        background-color: #f5f5f5;
      }
      &__left {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #000000;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      &__right {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #f3bc45;
        @media screen and (max-width: 768px) {
          align-items: flex-start;
          justify-content: start;
          width: 100%;
        }
      }
    }
    .customSearchInput__nothingToList {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      padding: 0 10px;
      color: #bdbdbd;
      font-size: 14px;
    }
  }
}

.customSearchInput-active {
  display: grid;
  // grid-template-columns: 1fr 24px 24px !important;
  gap: 10px;
  align-items: center;
  border: 1px solid #486072;
  min-height: 44px;
}
.customSearchInput-selected {
  display: grid;
  grid-template-columns: 1fr 24px 24px !important;
  gap: 10px;
  align-items: center;
  border: 1px solid #486072;
  min-height: 44px;
}
