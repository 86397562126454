@import "../../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-basvuru-islemleri-basvuru-detayi {
  @include withAuthLayout;
  &__header {
    justify-content: space-between;
  }

  &__content {
    &--left {
      &__prev {
        font-family: Hind;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        color: #000000;
        margin-bottom: 30px;
        width: fit-content;
        &:hover {
          cursor: pointer;
        }
      }

      &__body {
        padding-inline: 30px;
        .showCardBlur {
          filter: blur(5px);
          pointer-events: none !important;
        }
        &__card {
          background-color: #f3f3f3;
          border-radius: 10px;
          padding: 20px;
          margin-top: 30px;
          &__header {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            color: #f3bc45;
            margin-bottom: 20px;
          }

          &__header2 {
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            div {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 25px;
              line-height: 30px;
              color: #f3bc45;
            }
          }

          &__button {
            button {
              margin-top: 20px;
            }
          }

          &__notes {
            background-color: #fff;
            padding: 10px 15px;
            margin-top: 20px;
            border-radius: 3px;
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #486072;
          }

          &__result {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 20px;

            span {
              color: #f3bc45;
              font-weight: 600;
            }
          }

          &__upload {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .uploadFiles {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: #fff;
              border-radius: 3px;
              padding: 10px;

              img {
                cursor: pointer;
              }

              div {
                font-family: "Hind";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #486072;
              }
            }
          }

          &__description {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;
            gap: 20px;

            &__input {
              width: 100%;
              .customInput {
                width: 100%;

                input {
                  margin-bottom: 0px;
                  width: 100%;
                }
              }
            }
          }

          &__activeApplications {
            background: #fff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            padding: 12px;
            margin-top: 20px;

            hr {
              border: 0.5px solid #f3f3f3 !important;
            }

            .type {
              display: flex;
              justify-content: space-between;
              margin: 10px 0;

              img {
                margin-top: -2px;
                margin-right: -5px;
                margin-left: 5px;
                cursor: pointer;
              }

              &__left {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                color: #486072;
              }

              &__right {
                font-family: "Hind";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
                text-align: right;
              }

              &__right2 {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                text-align: right;
                color: #f3bc45;
              }
            }
          }
        }
        &__bottom {
          position: relative;
          &__card {
            background-color: #f3f3f3;
            border-radius: 10px;
            padding: 20px;
            margin-top: 30px;

            .customSelect img {
              z-index: 0;
            }

            &__header {
              font-family: "Hind";
              font-style: normal;
              font-weight: 600;
              font-size: 25px;
              line-height: 30px;
              color: #f3bc45;
              margin-bottom: 20px;
            }

            &__header2 {
              margin-bottom: 20px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              div {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 25px;
                line-height: 30px;
                color: #f3bc45;
              }
            }

            &__button {
              button {
                margin-top: 20px;
              }
            }

            &__notes {
              background-color: #fff;
              padding: 10px 15px;
              margin-top: 20px;
              border-radius: 3px;
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #486072;
            }

            &__result {
              font-family: "Hind";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #000000;
              margin-bottom: 20px;

              span {
                color: #f3bc45;
                font-weight: 600;
              }
            }

            &__upload {
              display: flex;
              flex-direction: column;
              gap: 20px;

              .uploadFiles {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #fff;
                border-radius: 3px;
                padding: 10px;

                img {
                  cursor: pointer;
                }

                div {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: #486072;
                }
              }
            }

            &__description {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-top: 20px;
              gap: 20px;

              &__input {
                width: 100%;
                .customInput {
                  width: 100%;

                  input {
                    margin-bottom: 0px;
                    width: 100%;
                  }
                }
              }
            }

            &__activeApplications {
              background: #fff;
              border-radius: 10px;
              display: flex;
              flex-direction: column;
              padding: 12px;
              margin-top: 20px;

              hr {
                border: 0.5px solid #f3f3f3 !important;
              }

              .type {
                display: flex;
                justify-content: space-between;
                margin: 10px 0;

                img {
                  margin-top: -2px;
                  margin-right: -5px;
                  margin-left: 5px;
                  cursor: pointer;
                }

                &__left {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 24px;
                  color: #486072;
                }

                &__right {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #000000;
                  text-align: right;
                }

                &__right2 {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 24px;
                  text-align: right;
                  color: #f3bc45;
                }
              }
            }
          }
          &__blur-image {
            width: 100%;
            margin-top: 30px;
          }

          &__blur-modal {
            position: absolute;
            padding: 12px;
            width: 332px;
            border: 1px solid #486072;
            border-radius: 3px;
            top: 10%;
            left: 27%;
            background-color: #fff;
            z-index: 999;
            &__description {
              position: relative;
              display: flex;
              flex-direction: row;
              gap: 42px;

              &__div {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #486072;
              }

              &__img {
                img {
                  position: absolute;
                }
              }
            }

            &__buttons {
              button {
                margin-top: 10px !important;
              }
            }
          }
        }

        &__text {
          font-family: Hind;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: var(--pure-black);
          margin-top: 30px;
          margin-bottom: 30px;

          &__highlight {
            font-weight: 600;
            color: var(--turuncu);
          }
        }

        &__firstlabel {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          margin-bottom: 10px;
        }

        &--smsCodeCont {
          display: flex;
          margin-bottom: 30px;
          align-items: center;
          margin-top: 10px;

          &--countDown {
            display: flex;
            margin-left: 30px;

            span {
              font-family: Hind;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.3px;
              color: #000000;
              margin-left: 10px;
            }
          }
        }

        .yellowLabel {
          color: #f3bc45;
        }

        &__resend {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          &__label {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--lacivert);
          }

          &--resendBtn {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 0.3px;
            text-align: center;
            color: var(--turuncu);
            margin-left: 20px;
            display: flex;
            align-items: center;

            label {
              margin-right: 10px;

              &:hover {
                cursor: pointer;
              }
            }

            img {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    &--right {
      margin-top: -3px !important;

      .progressBox {
        margin: 0 !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .customer-operations-basvuru-islemleri-basvuru-detayi {
    &__content {
      &--left {
        &__prev {
          margin-bottom: 30px;
          width: fit-content;
        }

        &__body {
          padding-inline: 0px;

          &__bottom {
            &__blur-modal {
              width: calc(100% - 40px);
              left: 20px;
            }
          }

          &--smsCodeCont {
            flex-direction: column;

            &--countDown {
              width: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 30px;
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .customer-operations-basvuru-islemleri-basvuru-detayi {
    &__content {
      &--left {
        &__body {
          &--smsCodeCont {
            flex-direction: column;

            &--countDown {
              width: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 30px;
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
}


.customer-operations-basvuru-islemleri-abonelik-tahliye-sonuc-step1 {
  padding-inline: 30px;
  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }
  &__body {
    &__secretButton {
      display: none;
      @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
      }
    }
    &__card {
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;

      .customSelect img {
        z-index: 0;
      }

      &__header {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #f3bc45;
        margin-bottom: 20px;
      }

      &__header2 {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
          color: #f3bc45;
        }
      }

      &__button {
        button {
          margin-top: 20px;
        }
      }

      &__notes {
        background-color: #fff;
        padding: 10px 15px;
        margin-top: 20px;
        border-radius: 3px;
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #486072;
      }

      &__result {
        font-family: "Hind";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 20px;

        span {
          color: #f3bc45;
          font-weight: 600;
        }
      }

      &__upload {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .uploadFiles {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fff;
          border-radius: 3px;
          padding: 10px;

          img {
            cursor: pointer;
          }

          div {
            font-family: "Hind";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #486072;
          }
        }
      }

      &__description {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        gap: 20px;

        &__input {
          width: 100%;
          .customInput {
            width: 100%;

            input {
              margin-bottom: 0px;
              width: 100%;
            }
          }
        }
      }

      &__activeApplications {
        background: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 12px;
        margin-top: 20px;

        hr {
          border: 0.5px solid #f3f3f3 !important;
        }

        .type {
          display: flex;
          justify-content: space-between;
          margin: 10px 0;

          img {
            margin-top: -2px;
            margin-right: -5px;
            margin-left: 5px;
            cursor: pointer;
          }

          &__left {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: #486072;
          }

          &__right {
            font-family: "Hind";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            text-align: right;
          }

          &__right2 {
            font-family: "Hind";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            text-align: right;
            color: #f3bc45;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-operations-basvuru-islemleri-abonelik-tahliye-sonuc-step1 {
    &__body {
      &__card {
        &__description {
          flex-direction: column;
          width: 100%;

          &__inputs {
            &__input {
              width: 100% !important;
            }
          }
        }

        &__activeApplications {
          img {
            margin-left: 0px;
            margin-right: 0px;
          }
        }

        &__upload {
          .uploadFiles {
            div {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.customer-operations-basvuru-islemleri-abonelik-tahliye-sonuc__content--right {
  margin-top: 60px !important;
}