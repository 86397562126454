.memnuniyet-anket-tamamlandi-modal {
  .modal2__content {
    max-height: none !important;
  }
  &__header {
    font-family: Hind;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.56px;
    text-align: center;
    color: var(--lacivert);
  }
  &__description {
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--lacivert);
    margin-top: 30px;
  }
  &__maskot {
    @media screen and (max-width: 350px) {
      width: 99%;
    }
  }
  &__button {
    width: 100%;
    button {
      margin-top: 0;
    }
  }
}
