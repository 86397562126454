.icon {
  line-height: 1;
}

.icon:before {
  font-family: enerjisaohm !important;
  font-style: normal;
}

%icon-add-3 {
  content: "\f101";
}

.icon-add-3:before {
  content: "\f101";
}

%icon-add-4 {
  content: "\f102";
}

.icon-add-4:before {
  content: "\f102";
}

%icon-add-active {
  content: "\f103";
}

.icon-add-active:before {
  content: "\f103";
}

%icon-add-afterlogin {
  content: "\f104";
}

.icon-add-afterlogin:before {
  content: "\f104";
}

%icon-add-menu-active {
  content: "\f105";
}

.icon-add-menu-active:before {
  content: "\f105";
}

%icon-add-menu {
  content: "\f106";
}

.icon-add-menu:before {
  content: "\f106";
}

%icon-add {
  content: "\f107";
}

.icon-add:before {
  content: "\f107";
}

%icon-approved {
  content: "\f108";
}

.icon-approved:before {
  content: "\f108";
}

%icon-arrow-back {
  content: "\f109";
}

.icon-arrow-back:before {
  content: "\f109";
}

%icon-arrow-forward {
  content: "\f10a";
}

.icon-arrow-forward:before {
  content: "\f10a";
}

%icon-arrowdown {
  content: "\f10b";
}

.icon-arrowdown:before {
  content: "\f10b";
}

%icon-back-dark {
  content: "\f10c";
}

.icon-back-dark:before {
  content: "\f10c";
}

%icon-back-slider {
  content: "\f10d";
}

.icon-back-slider:before {
  content: "\f10d";
}

%icon-back {
  content: "\f10e";
}

.icon-back:before {
  content: "\f10e";
}

%icon-bell-active {
  content: "\f10f";
}

.icon-bell-active:before {
  content: "\f10f";
}

%icon-call-footer {
  content: "\f110";
}

.icon-call-footer:before {
  content: "\f110";
}

%icon-call {
  content: "\f111";
}

.icon-call:before {
  content: "\f111";
}

%icon-cam {
  content: "\f112";
}

.icon-cam:before {
  content: "\f112";
}

%icon-carbon_meter {
  content: "\f113";
}

.icon-carbon_meter:before {
  content: "\f113";
}

%icon-card-active {
  content: "\f114";
}

.icon-card-active:before {
  content: "\f114";
}

%icon-card {
  content: "\f115";
}

.icon-card:before {
  content: "\f115";
}

%icon-close-dark {
  content: "\f116";
}

.icon-close-dark:before {
  content: "\f116";
}

%icon-close {
  content: "\f117";
}

.icon-close:before {
  content: "\f117";
}

%icon-closeIcon {
  content: "\f118";
}

.icon-closeIcon:before {
  content: "\f118";
}

%icon-copy-figma {
  content: "\f119";
}

.icon-copy-figma:before {
  content: "\f119";
}

%icon-copy {
  content: "\f11a";
}

.icon-copy:before {
  content: "\f11a";
}

%icon-counter-1 {
  content: "\f11b";
}

.icon-counter-1:before {
  content: "\f11b";
}

%icon-counter-active {
  content: "\f11c";
}

.icon-counter-active:before {
  content: "\f11c";
}

%icon-counter-afterlogin {
  content: "\f11d";
}

.icon-counter-afterlogin:before {
  content: "\f11d";
}

%icon-counter-big {
  content: "\f11e";
}

.icon-counter-big:before {
  content: "\f11e";
}

%icon-counter-dark {
  content: "\f11f";
}

.icon-counter-dark:before {
  content: "\f11f";
}

%icon-counter {
  content: "\f120";
}

.icon-counter:before {
  content: "\f120";
}

%icon-cross {
  content: "\f121";
}

.icon-cross:before {
  content: "\f121";
}

%icon-danger {
  content: "\f122";
}

.icon-danger:before {
  content: "\f122";
}

%icon-doc-iptal {
  content: "\f123";
}

.icon-doc-iptal:before {
  content: "\f123";
}

%icon-doc-iptal2 {
  content: "\f124";
}

.icon-doc-iptal2:before {
  content: "\f124";
}

%icon-done-fileup {
  content: "\f125";
}

.icon-done-fileup:before {
  content: "\f125";
}

%icon-done2 {
  content: "\f126";
}

.icon-done2:before {
  content: "\f126";
}

%icon-door-exit-line {
  content: "\f127";
}

.icon-door-exit-line:before {
  content: "\f11e";
}

%icon-double-arrow-back {
  content: "\f128";
}

.icon-double-arrow-back:before {
  content: "\f128";
}

%icon-double-arrow-forward {
  content: "\f129";
}

.icon-double-arrow-forward:before {
  content: "\f129";
}

%icon-down-blue {
  content: "\f12a";
}

.icon-down-blue:before {
  content: "\f12a";
}

%icon-down {
  content: "\f12b";
}

.icon-down:before {
  content: "\f12b";
}

%icon-download {
  content: "\f12c";
}

.icon-download:before {
  content: "\f12c";
}

%icon-error {
  content: "\f12d";
}

.icon-error:before {
  content: "\f12d";
}

%icon-exit-navbar {
  content: "\f12e";
}

.icon-exit-navbar:before {
  content: "\f12e";
}

%icon-eye-fileup {
  content: "\f12f";
}

.icon-eye-fileup:before {
  content: "\f12f";
}

%icon-eye-open-1 {
  content: "\f130";
}

.icon-eye-open-1:before {
  content: "\f130";
}

%icon-eye-open-active {
  content: "\f131";
}

.icon-eye-open-active:before {
  content: "\f131";
}

%icon-eye-open {
  content: "\f132";
}

.icon-eye-open:before {
  content: "\f132";
}

%icon-eyeclose {
  content: "\f133";
}

.icon-eyeclose:before {
  content: "\f133";
}

%icon-eyeopen {
  content: "\f134";
}

.icon-eyeopen:before {
  content: "\f134";
}

%icon-flash-afterlogin {
  content: "\f135";
}

.icon-flash-afterlogin:before {
  content: "\f135";
}

%icon-flash-navbar {
  content: "\f136";
}

.icon-flash-navbar:before {
  content: "\f136";
}

%icon-hamburger {
  content: "\f137";
}

.icon-hamburger:before {
  content: "\f137";
}

%icon-hamburger2 {
  content: "\f138";
}

.icon-hamburger2:before {
  content: "\f138";
}

%icon-home {
  content: "\f139";
}

.icon-home:before {
  content: "\f139";
}

%icon-icon_tick-2 {
  content: "\f13a";
}

.icon-icon_tick-2:before {
  content: "\f13a";
}

%icon-icon_tick {
  content: "\f13b";
}

.icon-icon_tick:before {
  content: "\f13b";
}

%icon-icon {
  content: "\f13c";
}

.icon-icon:before {
  content: "\f13c";
}

%icon-info-gray {
  content: "\f13d";
}

.icon-info-gray:before {
  content: "\f13d";
}

%icon-info-menu {
  content: "\f13e";
}

.icon-info-menu:before {
  content: "\f13e";
}

%icon-info {
  content: "\f13f";
}

.icon-info:before {
  content: "\f13f";
}

%icon-info1 {
  content: "\f140";
}

.icon-info1:before {
  content: "\f140";
}

%icon-invoice-2 {
  content: "\f141";
}

.icon-invoice-2:before {
  content: "\f141";
}

%icon-invoice-big {
  content: "\f142";
}

.icon-invoice-big:before {
  content: "\f142";
}

%icon-invoice {
  content: "\f143";
}

.icon-invoice:before {
  content: "\f143";
}

%icon-kumsaati {
  content: "\f144";
}

.icon-kumsaati:before {
  content: "\f144";
}

%icon-language-navbar {
  content: "\f145";
}

.icon-language-navbar:before {
  content: "\f145";
}

%icon-leaf {
  content: "\f146";
}

.icon-leaf:before {
  content: "\f146";
}

%icon-loading-indicator-orange {
  content: "\f147";
}

.icon-loading-indicator-orange:before {
  content: "\f147";
}

%icon-loading-indicator-white {
  content: "\f148";
}

.icon-loading-indicator-white:before {
  content: "\f148";
}

%icon-location-2 {
  content: "\f149";
}

.icon-location-2:before {
  content: "\f149";
}

%icon-location-active {
  content: "\f14a";
}

.icon-location-active:before {
  content: "\f14a";
}

%icon-location-navbar {
  content: "\f14b";
}

.icon-location-navbar:before {
  content: "\f14b";
}

%icon-location-white {
  content: "\f14c";
}

.icon-location-white:before {
  content: "\f14c";
}

%icon-location {
  content: "\f14d";
}

.icon-location:before {
  content: "\f14d";
}

%icon-location1 {
  content: "\f14e";
}

.icon-location1:before {
  content: "\f14e";
}

%icon-lock-pb {
  content: "\f14f";
}

.icon-lock-pb:before {
  content: "\f14f";
}

%icon-map-marker {
  content: "\f150";
}

.icon-map-marker:before {
  content: "\f150";
}

%icon-menudown {
  content: "\f151";
}

.icon-menudown:before {
  content: "\f151";
}

%icon-next-orange {
  content: "\f152";
}

.icon-next-orange:before {
  content: "\f152";
}

%icon-next-white {
  content: "\f153";
}

.icon-next-white:before {
  content: "\f153";
}

%icon-next-yellow {
  content: "\f154";
}

.icon-next-yellow:before {
  content: "\f154";
}

%icon-next {
  content: "\f155";
}

.icon-next:before {
  content: "\f155";
}

%icon-nextslider {
  content: "\f156";
}

.icon-nextslider:before {
  content: "\f156";
}

%icon-notification-2 {
  content: "\f157";
}

.icon-notification-2:before {
  content: "\f157";
}

%icon-notification-active {
  content: "\f158";
}

.icon-notification-active:before {
  content: "\f158";
}

%icon-notification-big {
  content: "\f159";
}

.icon-notification-big:before {
  content: "\f159";
}

%icon-notification-settings {
  content: "\f15a";
}

.icon-notification-settings:before {
  content: "\f15a";
}

%icon-notification {
  content: "\f15b";
}

.icon-notification:before {
  content: "\f15b";
}

%icon-openarrow-afterlogin {
  content: "\f15c";
}

.icon-openarrow-afterlogin:before {
  content: "\f15c";
}

%icon-orange-close {
  content: "\f15d";
}

.icon-orange-close:before {
  content: "\f15d";
}

%icon-out-2-active {
  content: "\f15e";
}

.icon-out-2-active:before {
  content: "\f15e";
}

%icon-out-2 {
  content: "\f15f";
}

.icon-out-2:before {
  content: "\f15f";
}

%icon-out-afterlogin {
  content: "\f160";
}

.icon-out-afterlogin:before {
  content: "\f160";
}

%icon-paper {
  content: "\f161";
}

.icon-paper:before {
  content: "\f161";
}

%icon-park-outline_agreement {
  content: "\f162";
}

.icon-park-outline_agreement:before {
  content: "\f162";
}

%icon-payment-big {
  content: "\f163";
}

.icon-payment-big:before {
  content: "\f163";
}

%icon-payment-small {
  content: "\f164";
}

.icon-payment-small:before {
  content: "\f164";
}

%icon-payment {
  content: "\f165";
}

.icon-payment:before {
  content: "\f165";
}

%icon-plus-active {
  content: "\f166";
}

.icon-plus-active:before {
  content: "\f166";
}

%icon-plus-afterlogin {
  content: "\f167";
}

.icon-plus-afterlogin:before {
  content: "\f167";
}

%icon-plus-navbar {
  content: "\f168";
}

.icon-plus-navbar:before {
  content: "\f168";
}

%icon-plus {
  content: "\f169";
}

.icon-plus:before {
  content: "\f169";
}

%icon-plus1 {
  content: "\f16a";
}

.icon-plus1:before {
  content: "\f16a";
}

%icon-question-1 {
  content: "\f16b";
}

.icon-question-1:before {
  content: "\f16b";
}

%icon-question-2-active {
  content: "\f16c";
}

.icon-question-2-active:before {
  content: "\f16c";
}

%icon-question-2 {
  content: "\f16d";
}

.icon-question-2:before {
  content: "\f16d";
}

%icon-question-navbar {
  content: "\f16e";
}

.icon-question-navbar:before {
  content: "\f16e";
}

%icon-question-white {
  content: "\f16f";
}

.icon-question-white:before {
  content: "\f16f";
}

%icon-question {
  content: "\f170";
}

.icon-question:before {
  content: "\f170";
}

%icon-red-error-box {
  content: "\f171";
}

.icon-red-error-box:before {
  content: "\f171";
}

%icon-red-info {
  content: "\f172";
}

.icon-red-info:before {
  content: "\f172";
}

%icon-refresh-active {
  content: "\f173";
}

.icon-refresh-active:before {
  content: "\f173";
}

%icon-refresh {
  content: "\f174";
}

.icon-refresh:before {
  content: "\f174";
}

%icon-right {
  content: "\f175";
}

.icon-right:before {
  content: "\f175";
}

%icon-search_small {
  content: "\f176";
}

.icon-search_small:before {
  content: "\f176";
}

%icon-search-2 {
  content: "\f177";
}

.icon-search-2:before {
  content: "\f177";
}

%icon-search-3 {
  content: "\f178";
}

.icon-search-3:before {
  content: "\f178";
}

%icon-search-4 {
  content: "\f179";
}

.icon-search-4:before {
  content: "\f179";
}

%icon-search-icon-infobox {
  content: "\f17a";
}

.icon-search-icon-infobox:before {
  content: "\f17a";
}

%icon-search {
  content: "\f17b";
}

.icon-search:before {
  content: "\f17b";
}

%icon-setting-big {
  content: "\f17c";
}

.icon-setting-big:before {
  content: "\f17c";
}

%icon-setting-navbar {
  content: "\f17d";
}

.icon-setting-navbar:before {
  content: "\f17d";
}

%icon-setting {
  content: "\f17e";
}

.icon-setting:before {
  content: "\f17e";
}

%icon-shipping-box {
  content: "\f17f";
}

.icon-shipping-box:before {
  content: "\f17f";
}

%icon-signout {
  content: "\f180";
}

.icon-signout:before {
  content: "\f180";
}

%icon-success {
  content: "\f181";
}

.icon-success:before {
  content: "\f181";
}

%icon-switch-2 {
  content: "\f182";
}

.icon-switch-2:before {
  content: "\f182";
}

%icon-switch-2active {
  content: "\f183";
}

.icon-switch-2active:before {
  content: "\f183";
}

%icon-switch-3 {
  content: "\f184";
}

.icon-switch-3:before {
  content: "\f184";
}

%icon-switch {
  content: "\f185";
}

.icon-switch:before {
  content: "\f185";
}

%icon-text-edit {
  content: "\f186";
}

.icon-text-edit:before {
  content: "\f186";
}

%icon-text-navbar {
  content: "\f187";
}

.icon-text-navbar:before {
  content: "\f187";
}

%icon-text-white {
  content: "\f188";
}

.icon-text-white:before {
  content: "\f188";
}

%icon-text {
  content: "\f189";
}

.icon-text:before {
  content: "\f189";
}

%icon-tick-2 {
  content: "\f18a";
}

.icon-tick-2:before {
  content: "\f18a";
}

%icon-tick-circle {
  content: "\f18b";
}

.icon-tick-circle:before {
  content: "\f18b";
}

%icon-tick-navbar {
  content: "\f18c";
}

.icon-tick-navbar:before {
  content: "\f18c";
}

%icon-tick {
  content: "\f18d";
}

.icon-tick:before {
  content: "\f18d";
}

%icon-tick1 {
  content: "\f18e";
}

.icon-tick1:before {
  content: "\f18e";
}

%icon-tooltip-info {
  content: "\f18f";
}

.icon-tooltip-info:before {
  content: "\f18f";
}

%icon-trash-fileup {
  content: "\f190";
}

.icon-trash-fileup:before {
  content: "\f190";
}

%icon-trash {
  content: "\f191";
}

.icon-trash:before {
  content: "\f191";
}

%icon-triangle {
  content: "\f192";
}

.icon-triangle:before {
  content: "\f192";
}

%icon-up-2 {
  content: "\f193";
}

.icon-up-2:before {
  content: "\f193";
}

%icon-up {
  content: "\f194";
}

.icon-up:before {
  content: "\f194";
}

%icon-user-1 {
  content: "\f195";
}

.icon-user-1:before {
  content: "\f195";
}

%icon-user-active {
  content: "\f196";
}

.icon-user-active:before {
  content: "\f196";
}

%icon-user-navbar {
  content: "\f197";
}

.icon-user-navbar:before {
  content: "\f197";
}

%icon-user {
  content: "\f198";
}

.icon-user:before {
  content: "\f198";
}

%icon-vector-white {
  content: "\f199";
}

.icon-vector-white:before {
  content: "\f199";
}

%icon-vector {
  content: "\f19a";
}

.icon-vector:before {
  content: "\f19a";
}

%icon-wallet-white {
  content: "\f19b";
}

.icon-wallet-white:before {
  content: "\f19b";
}

%icon-wallet1-navbar {
  content: "\f19c";
}

.icon-wallet1-navbar:before {
  content: "\f19c";
}

%icon-warning {
  content: "\f19d";
}

.icon-warning:before {
  content: "\f19d";
}

%icon-world-active {
  content: "\f19e";
}

.icon-world-active:before {
  content: "\f19e";
}

%icon-world {
  content: "\f19f";
}

.icon-world:before {
  content: "\f19f";
}