.customCheckBox {
  margin-top: 30px;
  font-family: "Hind", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  input {
    margin-right: 10px;
  }

  .highlight {
    color: #f3bc45;
    text-decoration: underline;
    cursor: pointer;
    a {
      color: #f3bc45;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__label {
    display: flex;
    font-size: 15px;
    font-weight: 600;
    color: #486072;
    cursor: pointer;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 19px;
      height: 19px;
      border-radius: 5px;
      background-color: #486072;
      border: solid 2px #486072;

      > div {
        background-color: #486072;
        border: 1px solid white;
        border-radius: 3px;
      }

      .checked {
        position: relative;
        width: 15.5px;
        height: 15.5px;
        background-color: #486072;
        display: flex;
        align-items: center;
        justify-content: center;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }

    input {
      display: none;
    }
  }

  .spanFirst {
    margin-left: 10px;
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    // text-align: left;
    color: var(--lacivert);
    // margin-top: 3px;
  }
}
