@import "../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations {
  @include withAuthLayout;

  .tuketim-karsilastirma-right-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    background: #f3f3f3;
    border-radius: 10px;
    margin-top: 30px;

    &__text {
      font-family: "Hind";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }

    &__button {
      width: 100%;

      button {
        margin-top: 20px;
      }
    }
  }

  .elektrik-kesintileri-right-card {
    background: #e8ecf2;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;

    .infoComponent {
      margin: 0 !important;

      &__info {
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 3px;

        &__detail {
          display: flex;
          align-items: flex-start;

          &__icon {
            width: 24px;
            padding: 12px;
            height: 24px;
            margin-right: 14px;

            &__img {
              width: 20px;
              height: 20px;
            }
          }
        }

        &__infoArea {
          display: flex;
          align-items: flex-start;

          &__icon {
            width: 24px;
            margin: 0 !important;
            padding: 2px;
          }

          &__text {
            font-family: Hind;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: left;
            color: var(--lacivert);
            margin-right: -8px;
            padding: 0px;
            margin-left: 15px !important;
            width: 269px;
          }
        }

        button {
          margin: 0;
        }
      }

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
}