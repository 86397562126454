.page_content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    width: 100%;
    background-color: #FFFFFF;

    &_receipt_body {
        margin: 0;
    }
}