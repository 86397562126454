.infoBox {
  width: 100%;
  max-width: 754px;
  height: 90px;
  background-image: radial-gradient(at top left, #fcee50 -10%, #eea540 112%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  @media screen and (max-width: 768px) {
    height: 75px;
  }

  .opacity {
    @media screen and (max-width: 768px) {
      width: 100px !important;
      margin-right: 30px !important;
    }
  }

  &__title {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.56px;
    color: #fff;
    font-family: Hind;
    margin-left: 30px;
    margin-right: 30px;

    @media screen and (max-width: 768px) {
      font-size: 25px;
      line-height: 1.2;
      letter-spacing: 0.5px;
    }
  }

  img {
    width: 175px;
    height: 145px;
    right: -12.3px;
    position: absolute;
  }
}

.activeBillMonth {
  justify-content: space-between !important;

  .infoBox__title2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // justify-content: space-between;
    // align-items: center;
    z-index: 3;
    width: 215px;
    position: absolute;
    right: 74px;

    @media screen and (max-width: 768px) {
      display: none;
    }

    &__billDetailsMonth {
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      span {
        font-family: "Hind";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #ffffff;
        line-height: 24px;
        width: 95px;
      }

      img {
        height: 12px;
        width: unset;
        right: 0;
        position: unset;
        margin-top: -2px;
      }
    }
  }
}