.corporate-register {
  .corporate-step3 {
    &__subtitle {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #486072;
      margin-top: 30px;
    }

    .webcamModalCorporateRegister {
      .modal2__content {
        max-height: max-content !important;
      }
    }

    .ohm-secondaryBtn-disabled {
      border: 1px solid #f3bc45 !important;
    }

    padding-inline: 30px;
    padding-top: 30px;
    padding-bottom: 48px;

    &__prev {
      width: fit-content;

      &__icon {
        margin-bottom: 2px;
      }

      &__text {
        font-family: "Hind", sans-serif;
        margin-left: 10px;
        font-size: 15px;
        font-weight: 600;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__footerBtn {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 850px) {
      padding-inline: 0;
    }

    &__downloadBox {
      display: flex;
      justify-content: space-between;
      background-color: #f3f3f3;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;

      @media (max-width: 850px) {
        flex-direction: column;
      }

      &__title {
        font-family: "Hind", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: #000;
      }

      &__download {
        color: #f3bc45;

        &__text {
          font-family: "Hind", sans-serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.6;
          margin-right: 10px;
        }

        @media (max-width: 850px) {
          margin-top: 20px;
        }
      }
    }

    &__boxes {
      display: flex;
      align-items: center;

      @media (max-width: 850px) {
        flex-direction: column;
      }

      &__box {
        height: auto;
        border-radius: 10px;
        background-color: #f3f3f3;
        margin-top: 30px;

        &:nth-child(1) {
          margin-right: 30px;

          @media (max-width: 850px) {
            margin-right: 0;
          }
        }

        @media (max-width: 1050px) {
          width: 100% !important;
          margin-top: 30px;
          margin-right: 0px;
        }

        &__body {
          display: flex;
          flex-direction: column;
          min-height: 178px;
          justify-content: space-between;
          padding: 20px;

          &__headerText {
            width: 290px;
            min-height: 58px;
            font-family: "Hind", sans-serif;
            font-weight: 600;
            font-size: 25px;
            color: #f3bc45;
            display: flex;
            justify-content: space-between;

            @media (max-width: 600px) {
              width: 100%;

              .higherTooltip__left__span__top__tooltip:after {
                left: 105% !important;
              }

              .higherTooltip__left__span__top__tooltip__content {
                width: 88vw !important;
                right: -50px !important;
              }
            }

          }

          &__bodyText {
            margin-top: 20px;
            font-family: "Hind", sans-serif;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.3;
            display: flex;
            justify-content: center;
            color: #f3bc45;
          }

          &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0px;
            position: relative;

            label {
              position: absolute;
              margin-top: 30px;
              width: 292px;
              height: 50px;
              z-index: 0px;
              top: 0;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    &__hiddenInput {
      display: none;
    }
  }
}