.customTextArea {
  width: 332px;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e8ecf2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #486072;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  &__textarea {
    position: relative;

    &__label {
      font-family: Hind;
      font-size: 15px;
      font-weight: 600;
      color: #486072;
      display: block;
      margin-bottom: 10px;
    }

    &__textArea {
      resize: none;
      font-family: "Hind", sans-serif;
      width: 100%;
      height: 150px;
      border-radius: 3px;
      border: solid 1px #e8ecf2;
      outline: 0;
      padding: 10px 15px;
      font-size: 18px;
      color: #486072;

      // :-webkit-autofill {
      //   background-color: white !important;
      // }

      &:disabled {
        appearance: none;
        opacity: 1;
        color: #486072 !important;
        -webkit-text-fill-color: #486072 !important;
      }

      &::placeholder {
        color: #486072;
        opacity: 0.5;
      }

      &:hover {
        border: 1px solid #486072;
      }

      &:focus {
        outline: none;
      }
    }

    span {
      position: absolute;
      right: 15px;
      bottom: 15px;
      opacity: 0.5;
      font-family: Hind;
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: right;
    }
  }

  .errorBox {
    display: flex;
    margin-left: 5px;
    margin-top: 5px;

    .errorBox__errorText {
      max-width: 400px;
    }
  }
}