.ek-ekran-ssb-step1 {
  margin-top: 30px;
  padding-inline: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__description {
    font-family: 'Hind', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--pure-black);
  }
}