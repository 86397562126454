.dilekce-basvuru-step4 {
  padding-inline: 30px;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__modal {
    width: 100%;
    &__description {
      font-family: Hind;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: var(--black);
    }
    &__contactsection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 292px;
      height: 100px;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      img {
        width: 24px;
        height: 24px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 38px;
        margin-bottom: 38px;
      }
      span {
        font-family: Hind;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        span {
          font-size: 22px;
          font-weight: 600;
          a {
            color: #444;
          }
        }
      }
    }
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__info {
    font-family: Hind;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #444;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // bottom: 190px;
    margin-bottom: 30px;
  }

  &__card {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    margin-top: 30px;

    &__inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      &__input {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
          width: 100% !important;
        }

        label {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          margin-bottom: 10px;
          margin-top: 20px;
        }

        &__info {
          background-color: #fff;
          padding: 10px 15px;
          border-radius: 3px;
          border: solid 1px var(--cool-grey);
          width: 317px;
          font-family: Hind;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: left;
          // color: var(--lacivert);
          appearance: none;
          opacity: 1;
          color: #486072 !important;
          -webkit-text-fill-color: #486072 !important;

          @media screen and (max-width: 768px) {
            width: 100% !important;
          }
        }
      }
    }

    &__header {
      font-family: Hind;
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: var(--turuncu);
    }

    &__detail {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      margin-top: 20px;

      &__header {
        font-family: Hind;
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #444;
      }

      &__info {
        font-family: Hind;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #444;
        margin-top: 10px;
      }

      &__hr {
        height: 1px;
        width: 100%;
        background-color: #dadada;
        margin: 10px 0;
      }

      &__address {
        font-family: Hind;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #444;
      }
    }
  }
}
