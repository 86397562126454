.elektrik-kesinti-step1 {
  padding-inline: 30px;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    padding-inline: 0px;
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    &__desktopmap {
      display: block;
      margin-top: 30px;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &__mobilemap {
      display: none;

      margin-top: 30px;
      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      width: 100% !important;
      background-color: rgba(243, 243, 243, 0.5);
      .ohm-secondaryBtn {
        background-color: rgba(243, 243, 243, 0.5);
      }
      button {
        margin-top: 0;
        border: none !important;
      }
      &__verticalhr {
        margin-right: 5px;
        margin-left: 5px;
        border-left: 1px solid #486072;
        width: 1px;
        height: 30px;
        background-color: rgba(243, 243, 243, 0.5);
        box-shadow: 5px 5px 20px 0 rgba(174, 174, 192, 0.4), -5px -5px 20px 0 var(--white);
        @media screen and (max-width: 768px) {
          margin-right: 10px;
          margin-left: 10px;
        }
      }
    }
  }
}
