.dilekce-basvuru-map-view {
  position: relative;
  margin-top: 30px 0;

  // map + - button hide
  .leaflet-touch .leaflet-control-layers,
  .leaflet-touch .leaflet-bar {
    display: none !important;
  }

  .leaflet-bottom .leaflet-right {
    display: none !important;

    .leaflet-control-attribution .leaflet-control {
      display: none !important;
    }
  }

  .leaflet-container {
    border-radius: 10px !important;
    // @media screen and (max-width: 768px) {
    //   height: 410px !important;
    // }
  }

  .leaflet-control-attribution {
    display: none;
  }

  .swiper-slide {
    // width: 332px !important;
    margin: 0;
    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    display: none !important;
  }

  .swiper-container {
    padding: 10px 20px;
  }
  .swiper-slide-prev {
    margin-left: 156.5px !important;
    // margin-left: 200px !important;
    @media screen and (max-width: 768px) {
      margin-left: 0px !important;
    }
  }
  .swiper-slide-active {
    margin: 0 17px !important;
    @media screen and (max-width: 768px) {
      margin: 0 !important;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__search {
    position: absolute;
    z-index: 9999;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    @media screen and (max-width: 768px) {
      left: 10px;
      width: calc(100% - 20px);
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__slider {
    display: flex;
    position: absolute;
    bottom: 80px;
    z-index: 500;
    width: 100% !important;
    @media screen and (max-width: 768px) {
      bottom: 90px;
    }
    &__prevbg {
      position: absolute;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 5%,
        rgba(255, 255, 255, 0.8) 15%,
        rgba(255, 255, 255, 0) 50%
      );
      height: 249px;
      width: 10%;
      top: 0;
      z-index: 99;
      left: 0;
    }

    &__nextbg {
      position: absolute;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 15%,
        rgba(255, 255, 255, 0.8) 70%,
        rgba(255, 255, 255, 1) 100%
      );
      height: 249px !important;
      width: 10%;
      top: 0;
      z-index: 99;
      right: 0;
    }

    &__prevBtn {
      position: absolute;
      left: 10px;
      top: 50%;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      transform: translateY(-100%);
      z-index: 500;
      @media screen and (max-width: 768px) {
        transform: translateY(-50%);
      }
      img {
        z-index: 501;
      }

      &:hover {
        cursor: pointer;
      }

      &__rectangle__left {
        position: absolute;
        width: 67px;
        height: 251px;
        z-index: 1;
        background: linear-gradient(
          90deg,
          #f3f3f3 0%,
          rgba(243, 243, 243, 0) 100%
        );
      }
    }

    &__nextBtn {
      position: absolute;
      right: 10px;
      top: 50%;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(-100%);
      z-index: 500;
      background-color: #fff;
      border-radius: 50%;
      @media screen and (max-width: 768px) {
        transform: translateY(-50%);
      }

      img {
        z-index: 501;
      }

      &:hover {
        cursor: pointer;
      }

      &__rectangle__right {
        position: absolute;
        width: 67px;
        height: 251px;
        z-index: 1;
        background: linear-gradient(
          270deg,
          #f3f3f3 0%,
          rgba(243, 243, 243, 0) 100%
        );
      }
    }

    &__card {
      left: 90px;
      display: flex;
      flex-direction: column;
      padding: 20px;
      background-color: #f3f3f3;
      margin-bottom: 20px;
      border-radius: 10px;
      width: 332px;
      z-index: 499;
      min-height: 167px !important;

      @media screen and (max-width: 768px) {
        width: calc(100% - 20px) !important;
        margin: 0 auto;
      }

      &__headerText {
        font-family: Hind;
        font-size: 17px;
        font-weight: 600;
        text-align: left;
        color: #444;
      }

      &__descText {
        font-family: Hind;
        font-size: 15px;
        line-height: 1.6;
        text-align: left;
        color: #444;
        margin-top: 10px;
      }

      &__content {
        font-size: 14px;
        margin-bottom: 10px;
      }

      &__hr {
        border: 1px solid #e6e6e6;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &__address {
        font-family: Hind;
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
        color: #444;
      }
    }
  }
}
