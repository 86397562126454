@import "../../../../assets/styles/layouts/withAuthLayout.scss";

.customer-operations-elektrik-kesintileri {
  .sub {
    padding: 12px;
  }

  .elektrik-kesintileri-right-card {
    // background: none;
    padding: 0;

    
    .infoComponent__info__detail {
      padding: 0;
    }
  }

  .infoComponent {
    margin-bottom: 0 !important;
    margin-top: 20px !important;

    &__info {
      display: flex;
      // align-items: center;
      background-color: #fff;
      border-radius: 3px;
      justify-content: space-between;
      &__text {
        span {
          color: #f3bc45;
        }
      }

      &__detail {
        display: flex;
        align-items: flex-start;
        padding: 12px;

        &__icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;

          &__img {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__infoArea {
        display: flex;
        align-items: flex-start;
        gap: 10px;

        &__icon {
          width: 20px;
          margin: 0 !important;
        }

        &__text {
          font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          color: var(--lacivert);
          width: 269px;

          @media screen and (max-width: 1024px) {
            width: 100%;
            margin-left: 0;
          }
        }
      }

      button {
        margin: 0;
      }
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  &__prev {
    font-family: Hind;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    padding-inline: 30px;

    &__card {
      background-color: #f3f3f3;
      padding: 20px;
      border-radius: 10px;
      margin-top: 30px;

      &__cell {
        &__header {
          font-family: "Hind";
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
          color: #f3bc45;
        }

        &__content {
          margin-top: 20px;
          font-family: "Hind";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }

        &__button {
          display: flex;
          flex-direction: row;
          gap: 20px;

          button {
            margin-top: 20px !important;
          }
        }
      }

      &__buttons {
        display: flex;
        align-items: center;
        // width: 100% !important;
        background-color: rgba(243, 243, 243, 0.5);

        .ohm-secondaryBtn {
          background-color: rgba(243, 243, 243, 0.5);
        }

        &__button {
          button {
            width: 322px !important;
            margin-top: 0;

            span {
              @media screen and (max-width: 768px) {
                word-break: break-word;
                max-width: 100px;
              }
            }
          }
        }

        &__hr {
          height: 30px;
          width: 1px;
          border-left: 1px solid #486072;
          margin: 0px 4px 0px 4px;
        }
      }

      &__info {
        &__insideCard {
          background-color: #fff;
          padding: 20px;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          border-radius: 10px;

          &__hr {
            margin: 20px 0;
            background-color: #f3f3f3;
            height: 1px;
            width: 100%;
          }

          .higherTooltip__left__span__top__tooltip__content {
            @media screen and (max-width: 768px) {
              left: calc(-20vw - 80px);
              width: calc(100vw - 80px);
            }

            // @media screen and (max-width: 300px) {
            //   right: calc(100% + 5%);
            //   transform: translateX(43%);
            //   width: calc(100vw - 52px);
            // }
          }

          &__item {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;

            @media screen and (max-width: 768px) {
              grid-template-columns: 1fr;
              gap: 20px;

            }

            &__left {
              display: flex;
              flex-direction: column;

              &:first-child {
                @media screen and (max-width: 768px) {
                  border-bottom: 1px solid #f3f3f3;
                  padding-bottom: 20px;
                }
              }

              @media screen and (max-width: 768px) {
                // flex-direction: row;
                // align-items: center;
                // justify-content: space-between;
                width: 100%;
              }

              &__upper {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #486072;
              }

              &__down {
                font-family: "Hind";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
                margin-top: 10px;

                @media screen and (max-width: 768px) {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  /* or 150% */

                  display: flex;
                  align-items: center;
                  text-align: right;

                  color: #444444;
                }
              }
            }

            &__right {
              display: flex;
              flex-direction: column;

              @media screen and (max-width: 768px) {
                // flex-direction: row;
                // align-items: center;
                // justify-content: space-between;
                width: 100%;
              }

              &__upper {
                font-family: "Hind";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #486072;
              }

              &__down {
                font-family: "Hind";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #444444;
                margin-top: 10px;

                @media screen and (max-width: 768px) {
                  font-family: "Hind";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  /* or 150% */

                  display: flex;
                  align-items: center;
                  text-align: left;

                  color: #444444;
                }

                span {
                  &:last-child {
                    margin-left: 10px;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__items {
      margin-top: 30px;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      gap: 20px;

      .customSelect {
        .ant-select-selection-item {
          opacity: 1;
        }
      }

      &__spinner {
        margin-top: 30px;
      }
    }
  }

  &__bottom-info-card {
    display: none;

    @media screen and (max-width: 1180px) {
      display: block;


    }

  }
}

@media screen and (max-width: 1180px) {
  .customer-operations-elektrik-kesintileri {
    .infoComponent {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 824px) {
  .customer-operations-elektrik-kesintileri {
    &__prev {
      margin-bottom: 30px;
    }

    &__body {
      padding-inline: 0px;

      &__card {
        &__buttons {
          &__button {
            width: 100% !important;

            button {
              width: 100% !important;
            }
          }
        }

        &__cell {
          &__button {
            flex-direction: column;
            gap: 0;
          }
        }
      }

      &__items {
        // flex-direction: column;

        &__select {
          width: 100%;

          &:last-child {
            margin-top: 20px;
          }
        }
      }

      .infoComponent {
        &__info {
          flex-direction: column;

          button {
            display: block;
            margin-top: 10px;
            width: 100% !important;
          }
        }
      }
    }
  }
}